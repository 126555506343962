import React from 'react';

import { FormLabel, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { useIntl } from 'react-intl';

import { useStyles } from 'app/components/shared/groups-menu-multi-select/utils';

type ParamTypes = {
  sendSelectedOptions: (filterName: string, selectedFilters: string) => void;
  label: string;
  value: string;
  placeholder?: string;
  filterName?: string;
};

const FilterTextField = ({
  sendSelectedOptions,
  label,
  value,
  placeholder,
  filterName,
}: ParamTypes) => {
  const classes = useStyles();
  const intl = useIntl();

  const displayedLabel = intl.formatMessage({ id: label });

  const handleChange = e =>
    sendSelectedOptions(filterName || label, e.target.value);

  return (
    <FormControl fullWidth className={classes.formControl}>
      <FormLabel className={classes.formLabel}>{displayedLabel}</FormLabel>

      <TextField
        placeholder={placeholder || label}
        value={value || ''}
        onChange={handleChange}
        variant="outlined"
        aria-label={label}
        InputLabelProps={{ shrink: false }}
        inputProps={{
          className: classes.textField,
        }}
      />
    </FormControl>
  );
};

export default FilterTextField;
