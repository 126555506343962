import React, { useState } from 'react';

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  makeStyles,
  Container,
  FormHelperText,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { changeCreationPassword } from 'actions';

const useStyles = makeStyles(() => ({
  form: { marginTop: 20 },
  alignItemsAndJustifyContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 100,
  },
  inputField: { width: '100%', marginBottom: 14 },
  submitButton: {
    height: '38px',
    marginTop: 21,
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#E02969',
    boxShadow: '0 1px 4px 0 rgba(125,125,125,0.5)',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#E02969',
      opacity: 0.9,
    },
  },
}));

const ChangeCreationPassword: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const isMatchPassword = password === confirmPassword;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isMatchPassword) {
      dispatch(changeCreationPassword(password));
    }
  };

  return (
    <Container
      maxWidth="md"
      className={`${classes.alignItemsAndJustifyContent} `}
    >
      <Card variant="outlined">
        <CardContent>
          <Typography align="center" variant="h5" color="secondary">
            Change Password
          </Typography>

          <Typography align="center" variant="subtitle2" color="textSecondary">
            You need to change creation password to be able to use your account
          </Typography>

          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className={classes.form}
          >
            <TextField
              label="New Password"
              variant="outlined"
              className={classes.inputField}
              value={password}
              type="password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
              }}
              autoComplete="off"
              required
              inputProps={{ minLength: '8' }}
            />

            <TextField
              label="Confirm New Password"
              variant="outlined"
              className={classes.inputField}
              value={confirmPassword}
              type="password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(e.target.value);
              }}
              error={
                !!password &&
                !!confirmPassword &&
                confirmPassword.length >= 8 &&
                !isMatchPassword
              }
              helperText={
                isMatchPassword ? '' : 'Please make sure your password match'
              }
              autoComplete="off"
              required
              inputProps={{ minLength: '8' }}
            />

            <FormHelperText variant="outlined">
              *After successful password update, you will be logged out
              automatically to login using the new password
            </FormHelperText>

            <Button
              variant="contained"
              className={classes.submitButton}
              type="submit"
            >
              Change Password
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export { ChangeCreationPassword };
