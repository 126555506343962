import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { deleteDevice, getDevice } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { useStyles } from 'app/components/shared/styles/view-entity';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import { canEditOperationsSubSystemEntity } from 'selectors';
import { Device } from 'types';

import AssignVehicle from './assign-vehicle';

export const getMenuItemStyles = (
  item: string,
  value: string[],
  theme: Theme
) => ({
  fontWeight:
    value.indexOf(item) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
});

const ViewDevice: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);

  const intl = useIntl();

  const deviceId = match.params.id;

  const device = useTypedSelector(
    state => state.devices.collection[deviceId] as Device | undefined
  );

  const prevDevice = useRef(device);

  const { locale } = useContext(LanguageContext);

  const entityName = device?.serial;

  const hasDeviceWriteAccess = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Devices', deviceId)
  );

  const parentOrganization = getTranslated(device?.organisation, locale);

  const disableDelete = !!device?.vehicle?.id;

  const renderCardActions = () => {
    if (hasDeviceWriteAccess)
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.editButton}
            component={Link}
            to={`${baseURL}/edit`}
          >
            {intl.formatMessage({ id: 'edit' })}
          </Button>

          <Button
            color="secondary"
            disabled={disableDelete}
            className={`${classes.editButton} ${classes.deleteButton} ${
              disableDelete && classes.disabledBtn
            }`}
            component={Button}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            {intl.formatMessage({ id: 'delete' })}
          </Button>
        </>
      );

    return null;
  };

  useEffect(() => {
    if (device) {
      setLoading(false);
      prevDevice.current = device;
    } else if (!prevDevice.current) {
      dispatch(getDevice(deviceId));
    }
  }, [dispatch, device, deviceId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.viewContainer}>
      <CardTitle title={intl.formatMessage({ id: 'deviceInfo' })} />

      <div className={classes.infoWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" aria-label="Device Info Table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeadCell}>
                  {device?.serial}
                </TableCell>

                <TableCell
                  style={{ textAlign: 'end' }}
                  className={classes.tableHeadCell}
                >
                  {renderCardActions()}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'code' })}</TableCell>

                {hasDeviceWriteAccess ? (
                  <AssignVehicle
                    deviceId={deviceId}
                    filled
                    onAssign={() => dispatch(getDevice(deviceId))}
                  />
                ) : (
                  <TableCell align="center">{device?.vehicle?.code}</TableCell>
                )}
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'serial' })}</TableCell>

                <TableCell align="center">{device?.serial}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'type' })}</TableCell>

                <TableCell align="center">{device?.obc_type}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'organization' })}
                </TableCell>

                <TableCell align="center">{parentOrganization}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell> {intl.formatMessage({ id: 'ip' })}</TableCell>

                <TableCell align="center">{device?.ip}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Button
        variant="outlined"
        color="default"
        type="button"
        className={classes.backButton}
        onClick={() => history.replace('/operations/devices')}
      >
        {intl.formatMessage({ id: 'backToDevicesList' })}
      </Button>

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteDevice,
          entityType: intl.formatMessage({ id: 'device' }),
          entityName: entityName || ' ',
          entityId: deviceId,
          onfinishNavigation: '/operations/devices',
        }}
      />
    </div>
  );
};

export { ViewDevice };
