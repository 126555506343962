import React, { useEffect, useState } from 'react';

import {
  Button,
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import AppliedFilters from 'app/components/shared/applied-filter';
import { mainButtonStyles } from 'app/shared/styles';

import { Filters, TripSalesFilterParams } from './filters';
import DarkSearchIcon from './grey-search-icon.svg';
import LightSearchIcon from './light-search-icon.svg';

const filterLabels = {
  [TripSalesFilterParams.driver]: 'driver',
  [TripSalesFilterParams.axis]: 'axis',
  [TripSalesFilterParams.vehicle]: 'vehicle',
  [TripSalesFilterParams.groups]: 'groups',
  [TripSalesFilterParams.minDate]: 'fromDate',
  [TripSalesFilterParams.maxDate]: 'toDate',
};

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      marginLeft: 32,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 15,
      },
    },
    searchField: {
      width: 222,
      height: 43,
    },
    searchButton: {
      ...mainButtonStyles,
      height: 30,
      width: 52,
      minWidth: 52,
      borderRadius: 30,
      marginLeft: 0,
    },
    notchedOutline: {
      borderColor: '#DFE4EA',
      borderRadius: 8,
    },
    multiActionButton: {
      ...mainButtonStyles,
      height: '',
      marginLeft: '',
      padding: '8px 20px',
      margin: '0px 11px 5px 0px',
      textAlign: 'left',
    },
    filterListCont: {
      paddingBottom: 8,
    },
  })
);

const FilterTripSale: React.FC<{
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => void;
  filterTripSales: (filters: Record<string, string>) => void;
  isSearching: boolean;
}> = ({
  searchTerm,
  setSearchTerm,
  handleSearch,
  filterTripSales,
  isSearching,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [filters, setFilters] = useState<Record<string, string[]>>({});

  const [appliedFilters, setAppliedFilters] = useState<
    Record<string, string[]>
  >({});

  const clearFilters = (id: string | null) => {
    let newFilters = {};
    if (id) {
      newFilters = { ...appliedFilters };
      delete newFilters[id];
    }
    setFilters(newFilters);
    setAppliedFilters(newFilters);
    filterTripSales(newFilters);
  };

  useEffect(() => {
    if (isSearching) {
      clearFilters(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  return (
    <div className={classes.container}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}
      >
        <TextField
          className={classes.searchField}
          variant="outlined"
          margin="dense"
          size="small"
          placeholder={intl.formatMessage({ id: 'searchByCode' })}
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleSearch();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={DarkSearchIcon} alt="search" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSearch}
                  className={classes.searchButton}
                >
                  <img src={LightSearchIcon} alt="search" />
                </Button>
              </InputAdornment>
            ),
            style: { height: 40 },
            classes: { notchedOutline: classes.notchedOutline },
          }}
        />

        <Filters
          filterTripSales={filterTripSales}
          filters={filters}
          setFilters={setFilters}
          setAppliedFilters={setAppliedFilters}
          isApplied={!!Object.keys(appliedFilters).length}
        />
      </div>

      {!!Object.keys(appliedFilters).length && (
        <div className={classes.filterListCont}>
          {Object.entries(appliedFilters)?.map(([entity, values]) => (
            <AppliedFilters
              key={entity}
              {...{
                label: filterLabels[entity],
                filterKey: entity,
                entity,
                values,
                onClick: clearFilters,
              }}
            />
          ))}

          <Button
            variant="outlined"
            color="default"
            type="button"
            className={classes.multiActionButton}
            onClick={() => clearFilters('')}
          >
            {intl.formatMessage({ id: 'clearAll' })}
          </Button>
        </div>
      )}
    </div>
  );
};

export { FilterTripSale };
