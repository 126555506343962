import { string as yupString, object as yupObject } from 'yup';

import { Organization } from 'types';

export interface StationFormState {
  en_name: string;
  ar_name: string;
  organisationId: string;
  enabled: boolean;
  organisation?: Organization;
}

const StationFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  return yupObject().shape({
    en_name: yupString().required(validationRequiredMessage),
    ar_name: yupString().required(validationRequiredMessage),
    organisationId: yupString().required(validationRequiredMessage),
  });
};

export { StationFormSchema };
