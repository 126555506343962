import {
  string as yupString,
  object as yupObject,
  number as yupNumber,
} from 'yup';

export interface VehicleClassFormState {
  en_name: string;
  ar_name: string;
  avg_speed: string;
}

const VehicleClassFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const avgSpeedNumberValidationMessage = intl.formatMessage({
    id: 'avgSpeedNumberValidation',
  });

  return yupObject().shape({
    en_name: yupString().required(validationRequiredMessage),
    ar_name: yupString().required(validationRequiredMessage),
    avg_speed: yupNumber()
      .typeError(avgSpeedNumberValidationMessage)
      .required(validationRequiredMessage),
  });
};

export { VehicleClassFormSchema };
