import React, { useMemo, useState } from 'react';

import { IntlProvider } from 'react-intl';

import { userConstants } from '_constants';
import ar from 'app/i18n-locale/ar-EG';
import en from 'app/i18n-locale/en-US';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';

const messages = { ...ar, ...en };

const local =
  localStorage.getItem(userConstants.EASY_MOBILITY_LANGUAGE) || 'en-us';

const getDirection: (locale: string) => 'ltr' | 'rtl' = (locale: string) => {
  if (locale?.toLowerCase() === LOCALES.ARABIC) {
    return 'rtl';
  }

  return 'ltr';
};

const I18nProvider = ({ children }) => {
  const [locale, setLocale] = useState(local?.toLowerCase());

  const selectLanguage = newLocale => {
    setLocale(newLocale);
    localStorage.setItem(userConstants.EASY_MOBILITY_LANGUAGE, newLocale);
  };

  const languageContextValue = useMemo(
    () => ({
      locale,
      messages: messages[locale.toLowerCase()],
      setLocale: selectLanguage,
      direction: getDirection(locale),
    }),
    [locale]
  );

  return (
    <LanguageContext.Provider value={languageContextValue}>
      <IntlProvider messages={messages[locale]} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export { I18nProvider };
