import React, { useEffect, useState } from 'react';

import {
  createStyles,
  Grid,
  makeStyles,
  TablePagination,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

type fetchActionType = {
  fetchAction: (
    page: number,
    size: number
  ) => (dispatch: Dispatch) => FluxStandardAction;
};

interface BasePaginationProps {
  rowsPerPage: number;
  page: number;
  handleChangePage: (_event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface UsePaginationReturnProps extends BasePaginationProps {
  startIndex: number;
  endIndex: number;
}

interface PaginationComponentProps extends BasePaginationProps {
  totalCount: number;
  hasData: boolean;
}

export const usePagination = ({
  fetchAction,
}: fetchActionType): UsePaginationReturnProps => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  useEffect(() => {
    dispatch(fetchAction(page, rowsPerPage));
  }, [dispatch, fetchAction, page, rowsPerPage]);

  return {
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    startIndex,
    endIndex,
  };
};

const useStyles = makeStyles(() =>
  createStyles({
    paginationContainer: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: 24,
    },
    tablePagination: {
      minWidth: 300,
    },
    jumpLabel: { marginRight: 5, color: '#9FA2B4' },
    numericInput: {
      marginBottom: 8,
      width: 55,
      height: 36,
      borderRadius: 10,
      textAlign: 'center',
      outline: 'none',
      color: '#4B506D',
      border: '1px solid #DADCE0',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  })
);

export const PaginationComponent: React.FC<
  PaginationComponentProps
> = props => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState<number | undefined>();
  const intl = useIntl();

  const {
    totalCount,
    hasData,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;

  useEffect(() => {
    setPageNumber(page + 1);
  }, [page]);

  if (!hasData) {
    return null;
  }

  const maxPageNumber = Math.ceil(totalCount / rowsPerPage);

  return (
    <Grid container className={classes.paginationContainer}>
      <Grid item className={classes.tablePagination}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={intl.formatMessage({ id: 'show' })}
          style={{
            // border: '1px solid black',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 0,
          }}
          nextIconButtonProps={{
            title: intl.formatMessage({ id: 'nextPage' }),
          }}
          backIconButtonProps={{
            title: intl.formatMessage({ id: 'previousPage' }),
          }}
        />
      </Grid>

      <Grid item style={{ alignSelf: 'flex-end' }}>
        <span className={classes.jumpLabel}>
          {intl.formatMessage({ id: 'jumpToPage' })}
        </span>

        <input
          type="number"
          value={pageNumber}
          className={classes.numericInput}
          onChange={event => {
            const value = Number(event.target.value);

            if (value) {
              if (value > 0 && value <= maxPageNumber) {
                setPageNumber(value);
              }
            } else {
              setPageNumber(undefined);
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter' && pageNumber) {
              handleChangePage(null, pageNumber - 1);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
