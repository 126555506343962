import { importDevices } from 'actions';
import { useHandleImportData } from 'hooks';

interface DeviceRecord {
  Serial: string;
  'Device ID': number;
  Type: number;
  IP: number | string;
  Organization: string;
  __rowNum__: number;
}

const DeviceMandatoryFields = [
  'Serial',
  'Device ID',
  'Type',
  'IP',
  'Organization',
];

interface Props {
  onSuccess?: () => void;
}

const useHandleImportDevicesData = ({ onSuccess }: Props) =>
  useHandleImportData<DeviceRecord>({
    entityName: 'Devices',
    mandatoryFields: DeviceMandatoryFields,
    importAction: importDevices,
    onSuccess,
  });

export { useHandleImportDevicesData };
