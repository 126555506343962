import {
  string as yupString,
  object as yupObject,
  array as yupArray,
} from 'yup';

import { Organization } from 'types';

export interface RouteFormState {
  englishName: string;
  arabicName: string;
  code: string;
  organizationId: string;
  organization?: Organization;
  groups: string[];
  status?: boolean;
}

const RouteFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const lengthValidationMessage = intl.formatMessage({
    id: 'minLengthOneValidation',
  });

  return yupObject().shape({
    englishName: yupString().required(validationRequiredMessage),
    arabicName: yupString().required(validationRequiredMessage),
    code: yupString().required(validationRequiredMessage),
    organizationId: yupString().required(validationRequiredMessage),
    groups: yupArray().min(1, lengthValidationMessage),
  });
};

export { RouteFormSchema };
