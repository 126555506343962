import { FluxStandardAction } from 'redux-promise-middleware';

import { stopConstants } from '_constants';
import { AppState, Route } from 'types';

const stops = (
  state: AppState['stops'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['stops'] => {
  switch (action.type) {
    case `${stopConstants.GET_STOPS}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${stopConstants.GET_STOPS}_FULFILLED`: {
      const stopList: AppState['stops']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: Route) => {
          stopList[item.id] = item;
        });

        return {
          collection: { ...stopList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${stopConstants.GET_STOPS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${stopConstants.GET_STOP}_FULFILLED`: {
      const stopData = action.payload.data?.response.stop;

      if (stopData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [stopData.id]: stopData,
          },
        };
      }

      return { ...state };
    }

    case `${stopConstants.ADD_STOP}_FULFILLED`: {
      const stopData = action.payload.data?.response.stop;

      return {
        ...state,
        collection: {
          ...state.collection,
          [stopData.id]: stopData,
        },
      };
    }

    case `${stopConstants.EDIT_STOP}_FULFILLED`: {
      const stopData = action.payload.data?.response.stop;

      return {
        ...state,
        collection: {
          ...state.collection,
          [stopData.id]: stopData,
        },
      };
    }

    case `${stopConstants.DELETE_STOP}_FULFILLED`: {
      const deletedRouteId = action.meta.stopId;

      const newState = { ...state };
      delete newState.collection[deletedRouteId];

      return newState;
    }

    default:
      return state;
  }
};

export { stops };
