import { FluxStandardAction } from 'redux-promise-middleware';

import { groupConstants } from '_constants';
import { AppState, Group } from 'types';

const groups = (
  state: AppState['groups'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['groups'] => {
  switch (action.type) {
    case `${groupConstants.GET_GROUPS}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${groupConstants.GET_GROUPS}_FULFILLED`: {
      const grouplList: AppState['groups']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: Group) => {
          grouplList[item.id] = item;
        });

        return {
          collection: { ...grouplList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${groupConstants.GET_GROUPS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${groupConstants.GET_GROUP}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${groupConstants.GET_GROUP}_FULFILLED`: {
      const groupData = action.payload.data?.response.group;

      if (groupData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [groupData.id]: groupData,
          },
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${groupConstants.ADD_GROUP}_FULFILLED`: {
      const groupData = action.payload.data?.response.group;

      return {
        ...state,
        collection: {
          ...state.collection,
          [groupData.id]: groupData,
        },
      };
    }

    case `${groupConstants.EDIT_GROUP}_FULFILLED`: {
      const groupData = action.payload.data?.response.group;

      return {
        ...state,
        collection: {
          ...state.collection,
          [groupData.id]: groupData,
        },
      };
    }

    case `${groupConstants.DELETE_GROUPS}_FULFILLED`: {
      const deletedGroupId = action.meta.groupId;

      const newState = { ...state };
      delete newState.collection[deletedGroupId];

      return newState;
    }

    default:
      return state;
  }
};

export { groups };
