import { importDrivers } from 'actions';
import { useHandleImportData } from 'hooks';

interface DriverRecord {
  'Driver code': number;
  'First Name (AR)': string;
  Groups: string;
  'Last Name (AR)': string;
  'License Number': number;
  'National ID': number;
  Organization: string;
  'Phone Number': number;
  __rowNum__: number;
}

const DriverMandatoryFields = [
  'First Name (AR)',
  'Last Name (AR)',
  'Organization',
  'Groups',
];

interface Props {
  onSuccess?: () => void;
}

const useHandleImportDriverData = ({ onSuccess }: Props) =>
  useHandleImportData<DriverRecord>({
    entityName: 'Drivers',
    mandatoryFields: DriverMandatoryFields,
    importAction: importDrivers,
    onSuccess,
  });

export { useHandleImportDriverData };
