import { FluxStandardAction } from 'redux-promise-middleware';

import { alertConstants } from '_constants';
import { Alert } from 'types';

const alert = (state = null, action: FluxStandardAction): Alert | null => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.payload,
      };

    case alertConstants.ERROR:
      return {
        type: 'error',
        message: action.payload,
      };

    case alertConstants.CLEAR:
      return null;

    default:
      return state;
  }
};

export { alert };
