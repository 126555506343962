import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

import { AddVehicleClass } from './add-vehicle-class';
import { EditVehicleClass } from './edit-vehicle-class';
import { VehicleClassList } from './vehicle-class-list';
import { ViewVehicleClass } from './view-vehicle-class';

const VehicleClasses: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasVehicleAddAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Vehicles')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasVehicleAddAccess ? <AddVehicleClass /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          <EditVehicleClass />
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewVehicleClass />
        </Route>

        <Route exact path={`${baseURL}`}>
          <VehicleClassList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { VehicleClasses };
