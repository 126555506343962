// @ts-nocheck
import React, { createRef, useCallback, useEffect, useState } from "react";
//import Header from "components/Header";
import EditIcon from '@mui/icons-material/Edit';
import L from "leaflet";
import {  TileLayer, FeatureGroup, Marker, Popup ,Polygon,Polyline,Tooltip, useMapEvents, useMapEvent} from "react-leaflet";
import { MapContainer, useMap } from 'react-leaflet'
import { EditControl } from "react-leaflet-draw";
import osm from "./osm-providers";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//import ExternalInfo from "components/ExternalInfo";
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import {axios} from './zonesAxios'
import { useDispatch, useSelector } from "react-redux";
import { storeAllZones, storeZones } from "actions/zoning.actions";
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import PlaceIcon from '@mui/icons-material/Place';
import Cookies from "js-cookie";
import PaidIcon from '@mui/icons-material/Paid';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Modal, Button, Form } from "react-bootstrap";

import { useIntl } from 'react-intl';
import "leaflet-polylinedecorator";
interface MyComponentProps {
  lineStringSearch: any;
  TMSRouteData: any;
  axisid: any;
  axiscode: any;
}

const MarkerToAndCreateDynamicZone: React.FC<MyComponentProps> = ({ lineStringSearch, TMSRouteData,axisid, axiscode }) => {
    const dispatch= useDispatch();
    const intl = useIntl();
    const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
    let user: any = '';
    let user2 :any= '';
    let userRefresh:any = '';
    
    const authData = localStorage.getItem("authData");
    
    if (authData !== null) {
      const parsedAuthData = JSON.parse(authData);
      user = parsedAuthData.userData;
      user2 = parsedAuthData.userToken;
      userRefresh = parsedAuthData.userToken;
    }
    const { t } = useTranslation()
  const [coord, setPosition] = useState([]);

  useMapEvents({
    dblclick: (e) => {
      setPosition([...coord, e.latlng]);
    }
  });

  useEffect(() => {
    console.log(coord);
  }, [coord]);

  const removeMarker = (pos) => {
    setPosition((prevCoord) =>
      prevCoord.filter((coord) => JSON.stringify(coord) !== JSON.stringify(pos))
    );
  };
  const [show, setShow] = useState(false);
  const [currentPos, setCurrentPos] = useState('');
  const [isFetchingAddZone, setIsFetchingAddZone] = useState(false); // State to manage button disabled state
  const handleClose = () =>{
    setShow(false);
    setIsFetchingAddZone(false);
  } 
  const handleShow = (pos) =>{
    setShow(true);
    setCurrentPos(pos)
  }
  const WithoutStopZoneCashPrice =useRef();
  const WithoutStopZoneCardPrice =useRef();
  const WithoutStopZoneName      =useRef();
  const [onFlyStopLat,setOnFlyStopLat] =  useState(0);
  const [onFlyStopLng,setOnFlyStopLng] =  useState(0);

  const addDynamicZone = () => {
  
    console.log(onFlyStopLat);
    console.log(onFlyStopLng);
                // Disable the button while the API request is being sent
                setIsFetchingAddZone(true);
    console.log(WithoutStopZoneName.current.value);
            console.log(WithoutStopZoneCashPrice.current.value);
            console.log(WithoutStopZoneCardPrice.current.value);
            if(WithoutStopZoneName.current.value.trim().length > 100){
              toast.error("Name Entered Is Very High", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                })
                setIsFetchingAddZone(false);       
              }else if(WithoutStopZoneName.current.value.trim().length==0 ){
                toast.error("Empty Fields", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  })
                  setIsFetchingAddZone(false);
              }else{
                const ExecuteAddDynamicZone =  ()=>{
                  if(WithoutStopZoneCashPrice.current.value.trim().length>5 || WithoutStopZoneCardPrice.current.value.trim().length>5){
                    toast.error("Price Entered Is Very High", {
                      position: "top-right",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                      })
                      setIsFetchingAddZone(false);
                    }else if(WithoutStopZoneCashPrice.current.value.trim().length==0 || WithoutStopZoneCardPrice.current.value.trim().length==0){
                      toast.error("Empty Fields", {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                        })
                        setIsFetchingAddZone(false);
                      }  
                  else{
                 axios.post(
                  `${process.env.REACT_APP_MID_LINK}/addZone?zoneName=${WithoutStopZoneName.current.value}&tmsRouteId=${lineStringSearch}&tmsRouteCode=${TMSRouteData?.routeCode}&fareAxisId=${axisid}&fareAxisCode=${axiscode}&stopCode=${"dynamic_stop"}&stopLatitude=${onFlyStopLat}&stopLongitude=${onFlyStopLng}
    
                  &zoneCashPrice=${WithoutStopZoneCashPrice.current.value}&zoneCardPrice=${WithoutStopZoneCardPrice.current.value}&UID=${user2.uid}`)
                .then((res)=> {  
                  if(res.data != "Request failed with status code 400"){
                    console.log(res.data);
                   // setZones(res.data.zones);
                    dispatch(storeZones((res.data.zones)));
                    toast.success("Added Successfuly", {
                      position: "top-right",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                      }) 
                      handleClose();
                      removeMarker(currentPos);
                  }else{
                    toast.error(`Sorry , ${res.data} , Code : #mtacdz5`, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined
                      }) 
                      setIsFetchingAddZone(false);
                  }
             
                  }).catch((err)=>{
                   console.log(err);
                   toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #mtacdz6`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                    }) 
                   setIsFetchingAddZone(false);
                  }).finally(() => {
                    // Re-enable the button after the API request is complete (success or error)
                    setIsFetchingAddZone(false);
                  }) }
                }
                ExecuteAddDynamicZone();
              }
 
           
  };
  return (
    <div>
        
      <Modal show={show} onHide={handleClose} style={{zIndex:"9999969"}}>
    
    <Modal.Body>
      <h5> {intl.formatMessage({ id: 'AddZone' })}</h5>
    <h6 className='head-filter'style={{margin:"4px"}}>{intl.formatMessage({ id: 'Name' })}</h6>
               <input type="text" ref={WithoutStopZoneName} required className="form-control" style={{margin:"2px"}} />
                      <h6 className='head-filter'style={{margin:"4px"}}>{intl.formatMessage({ id: 'CashPrice' })}</h6>
               <input type="number" ref={WithoutStopZoneCashPrice} required className="form-control" style={{margin:"2px"}} />
               <h6 className='head-filter'style={{margin:"4px"}}>{intl.formatMessage({ id: 'CardPrice' })}</h6>
               <input type="number" ref={WithoutStopZoneCardPrice} required className="form-control" style={{margin:"2px"}}  />

    </Modal.Body>
    <Modal.Footer>
   
      <Button variant="outline-dark" onClick={handleClose}>
      {intl.formatMessage({ id: 'Cancel' })}
      </Button>
      <Button variant="success"disabled={isFetchingAddZone} onClick={ addDynamicZone}>
      {intl.formatMessage({ id: 'Add' })}
      </Button>
    </Modal.Footer>
  </Modal>
      {coord.map((pos, idx) => (
        <Marker
          key={`marker-${idx}`}
          position={pos}
          draggable={true}
          eventHandlers={{
            click: (e) => {
             // handleShow();
              console.log(e.latlng);
              setOnFlyStopLat(e.latlng.lat);
              setOnFlyStopLng(e.latlng.lng);

            }
          }}
        >
          <Popup>
          <Button style={{margin:"5px"}} variant="danger" onClick={() => removeMarker(pos)}>
          {intl.formatMessage({ id: 'RemoveMarker'})}
      </Button>
          <Button style={{margin:"5px"}}  variant="success" onClick={() => handleShow(pos)}>
          {intl.formatMessage({ id: 'AddZone'})}
      </Button>
          </Popup>
        </Marker>
      ))}
    </div>
  );
}
export default MarkerToAndCreateDynamicZone
