import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, addDevice } from 'actions';
import { DeviceForm } from 'app/operations/devices/device-form';
import { DeviceFormState } from 'app/operations/devices/device-helpers';
import { trimTrailingSlash } from 'helpers/url-helpers';

const initialState: DeviceFormState = {
  serial: '',
  device_identifier: '',
  type: '',
  organizationId: '',
  ip: '',
};

const AddDevice: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const handleSubmit = (
    values: DeviceFormState,
    { setSubmitting }: FormikHelpers<DeviceFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        addDevice({
          serial: values.serial,
          device_identifier: values.device_identifier,
          obc_type: values.type,
          ip: values.ip,
          organisation_id: values.organizationId,
        })
      )
    )
      .then(({ action }: any) => {
        const newDeviceId = action.payload.data?.response.id;
        history.push(`${baseURL.replace('/new', '')}/${newDeviceId}`);

        dispatch(successAlert(intl.formatMessage({ id: 'successAddDevice' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <DeviceForm {...{ initialState, handleSubmit }} />;
};

export { AddDevice };
