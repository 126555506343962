import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { AddOrganization } from 'app/configuration/organizations/add-organization';
import { EditOrganization } from 'app/configuration/organizations/edit-organization';
import { OrganizationList } from 'app/configuration/organizations/organization-list';
import { ViewOrganization } from 'app/configuration/organizations/view-organization';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { hasWriteAccessToMasterConfigSubSystem } from 'selectors';

const Organizations: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasOrganizationWriteAccess = useTypedSelector(state =>
    hasWriteAccessToMasterConfigSubSystem(state, 'Organizations')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasOrganizationWriteAccess ? <AddOrganization /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          {hasOrganizationWriteAccess ? (
            <EditOrganization />
          ) : (
            <NotAuthorized />
          )}
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewOrganization />
        </Route>

        <Route exact path={`${baseURL}`}>
          <OrganizationList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { Organizations };
