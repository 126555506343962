import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  LinearProgress,
  Button,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getOrganizations } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { getInputProps } from 'app/components/shared/groups-menu-multi-select/utils';
import { ActionAlert } from 'app/components/shared/on-action-alert';
import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import {
  DeviceFormState,
  DeviceFormSchema,
} from 'app/operations/devices/device-helpers';
import { useStyles } from 'app/operations/drivers/driver-form';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, useTypedSelector } from 'helpers';
import { getOrganizationsWithWritePermission } from 'selectors';
import { Organization } from 'types';

export const DeviceTypes = [
  { id: 'OBU', name: 'OBU' },
  { id: 'POS', name: 'POS' },
  { id: 'Phone', name: 'Phone' },
];

interface DeviceForm {
  initialState: DeviceFormState;
  handleSubmit: (
    values: DeviceFormState,
    { setSubmitting }: FormikHelpers<DeviceFormState>
  ) => void;
  isEditForm?: boolean;
}

const DeviceForm: React.FC<DeviceForm> = ({
  initialState,
  handleSubmit,
  isEditForm = false,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isActionAlertOpen, setIsActionAlertOpen] = useState(false);
  const [action, setAction] = useState(undefined) as any;

  const { direction, locale } = useContext(LanguageContext);

  const isRTL = direction === DIRECTIONS.RTL;

  const organizationList = useTypedSelector(state =>
    getOrganizationsWithWritePermission(state, 'Devices')
  ) as Organization[];

  const hasWriteAccessOnOrganizations = organizationList?.length > 0;

  const organizations = useMemo(() => {
    if (hasWriteAccessOnOrganizations) return organizationList;
    if (initialState.organization) return [initialState.organization];

    return null;
  }, [initialState, organizationList, hasWriteAccessOnOrganizations]);

  const formikRef = useRef<FormikProps<DeviceFormState>>(null);

  useEffect(() => {
    dispatch(getOrganizations(0, 100));
  }, [dispatch]);

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [formikRef, direction]);

  const onSubmit = (
    values: DeviceFormState,
    { setSubmitting }: FormikHelpers<DeviceFormState>
  ) => {
    if (
      initialState.vehicleId &&
      initialState.organizationId !== values.organizationId
    ) {
      setIsActionAlertOpen(true);

      setAction(
        () => () =>
          handleSubmit(values, {
            setSubmitting,
          } as FormikHelpers<DeviceFormState>)
      );

      setSubmitting(false);
    } else {
      handleSubmit(values, { setSubmitting } as FormikHelpers<DeviceFormState>);
    }
  };

  return (
    <>
      <CardTitle
        title={intl.formatMessage({
          id: isEditForm ? 'editDevice' : 'createDevice',
        })}
      />

      <div className={classes.formWrapper}>
        <Formik
          innerRef={formikRef}
          initialValues={initialState}
          validationSchema={() => DeviceFormSchema(intl)}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div
                className={classes.fieldGroupWrapper}
                style={{ flexDirection: 'column' }}
              >
                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'serial' })}

                    <strong className={classes.requiredAstrisk}>*</strong>
                  </InputLabel>

                  <Field
                    name="serial"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                {!isEditForm && (
                  <div
                    className={classes.fieldGroupWrapper}
                    style={{ flexDirection: 'column' }}
                  >
                    <div className={classes.fieldWrapper}>
                      <InputLabel className={classes.formLabel}>
                        {intl.formatMessage({ id: 'deviceIdentifier' })}

                        <strong className={classes.requiredAstrisk}>*</strong>
                      </InputLabel>

                      <Field
                        name="device_identifier"
                        component={TextField}
                        type="text"
                        variant="outlined"
                        size="small"
                      />
                    </div>
                  </div>
                )}

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'type' })}

                    <strong className={classes.requiredAstrisk}>*</strong>
                  </InputLabel>

                  <Field
                    name="type"
                    component={TextField}
                    type="text"
                    select
                    variant="outlined"
                    size="small"
                    inputProps={getInputProps(isRTL)}
                  >
                    {DeviceTypes?.map(type => (
                      <MenuItem
                        key={type.id}
                        value={type.id}
                        className={classes.selectMenuItem}
                      >
                        {type.name}
                      </MenuItem>
                    ))}
                  </Field>
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'ip' })}

                    <strong className={classes.requiredAstrisk}>*</strong>
                  </InputLabel>

                  <Field
                    name="ip"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'organization' })}

                    <strong className={classes.requiredAstrisk}>*</strong>
                  </InputLabel>

                  <Field
                    name="organizationId"
                    component={TextField}
                    type="text"
                    select
                    variant="outlined"
                    size="small"
                    inputProps={getInputProps(isRTL)}
                  >
                    {organizations?.map(organization => (
                      <MenuItem
                        key={organization.id}
                        value={organization.id}
                        className={classes.selectMenuItem}
                      >
                        {getTranslated(organization, locale)}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({ id: isEditForm ? 'update' : 'add' })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => history.go(-1)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({ id: 'cancel' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <ActionAlert
        {...{
          isOpen: isActionAlertOpen,
          setOpen: setIsActionAlertOpen,
          title: intl.formatMessage({ id: 'changeDeviceOrgMsg' }),
          setActionCall: setAction,
          action,
        }}
      />
    </>
  );
};

export { DeviceForm };
