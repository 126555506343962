import React from 'react';

import {
  LinearProgress,
  Button,
  InputLabel,
  DialogTitle,
  Typography,
  Grid,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';

import {
  StopFormState,
  StopFormSchema,
} from 'app/operations/stations/stop-helpers';
import { mainButtonStyles } from 'app/shared/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: { padding: '0 32px', width: '100%' },
    fieldWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    fieldGroupWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 20,
      [theme.breakpoints.down('sm')]: { width: '100%' },
    },
    buttonsWrapper: {
      marginTop: 44,
    },
    actionButton: {
      textTransform: 'capitalize',
      marginRight: 10,
      borderRadius: 30,
      [theme.breakpoints.down('sm')]: {
        margin: '0 0 10px',
        width: '100%',
      },
      padding: '7px 17px',
      borderColor: '#DFE0EB',
    },
    submitButton: {
      backgroundColor: '#0FA66D',
      '&:hover': {
        backgroundColor: '#0FA66D',
        opacity: 0.9,
      },
      color: '#fff',
      borderColor: '#0FA66D',
      padding: '7px 25px',
    },
    formLabel: {
      color: '#444',
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'start',
    },
    requiredAstrisk: {
      color: 'red',
    },
    stopsSection: {
      border: '1px solid #DADCE0',
      padding: '22px 15px 8px 15px',
      marginBottom: 20,
    },
    addStopButton: {
      ...mainButtonStyles,
      height: 45,
      marginLeft: 10,
      minWidth: 113,
      padding: '5px 10px',
      marginBottom: 12,
    },
    stopContainer: {
      height: 42,
      backgroundColor: 'rgba(240, 240, 242, 1)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 5,
      paddingLeft: 8,
      borderRadius: 3,
    },
    stopName: {
      fontSize: 14,
      fontWeight: 500,
      color: 'rgba(68, 68, 68, 1)',
      marginLeft: 13,
    },
    dragLabel: {
      marginBottom: 14,
    },
  })
);

interface StopForm {
  initialState: StopFormState;
  handleSubmit: (
    values: StopFormState,
    { setSubmitting }: FormikHelpers<StopFormState>
  ) => void;
  setOpen: (newState: boolean) => void;
  isEditForm?: boolean;
}

const StopForm: React.FC<StopForm> = ({
  initialState,
  handleSubmit,
  setOpen,
  isEditForm = false,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <DialogTitle>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          {intl.formatMessage({
            id: isEditForm ? 'editStop' : 'addStop',
          })}
        </Typography>
      </DialogTitle>

      <div className={classes.formWrapper}>
        <Formik
          initialValues={initialState}
          validationSchema={() => StopFormSchema(intl)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div
                className={classes.fieldGroupWrapper}
                style={{ flexDirection: 'column' }}
              >
                <Grid container spacing={3}>
                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'englishName' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="en_name"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'arabicName' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="ar_name"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'longitude' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="lon"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'latitude' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="lat"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'code' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="code"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({ id: isEditForm ? 'update' : 'add' })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => setOpen(false)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({ id: 'cancel' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { StopForm };
