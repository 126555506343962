import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { addAdmin, successAlert } from 'actions';
import { AdminForm } from 'app/admin-management/admins/admin-form';
import { AdminFormState } from 'app/admin-management/admins/admin-helpers';
import { trimTrailingSlash } from 'helpers';

const initialState: AdminFormState = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  username: '',
  password: '',
  repeatPassword: '',
  organizationId: '',
  is_super_admin: false,
};

const AddAdmin: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const handleSubmit = (
    values: AdminFormState,
    { setSubmitting }: FormikHelpers<AdminFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        addAdmin({
          first_name: values.firstName,
          last_name: values.lastName,
          username: values.username,
          password: values.password,
          email: values.email || undefined,
          phone: values.phone,
          organisation_id: values.organizationId,
          is_super_admin: values.is_super_admin,
        })
      )
    )
      .then(({ action }: any) => {
        const newAdminId = action.payload.data?.response.admin.id;
        history.push(`${baseURL.replace('/new', '')}/${newAdminId}`);

        dispatch(successAlert(intl.formatMessage({ id: 'successAddAdmin' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <AdminForm {...{ initialState, handleSubmit }} />;
};

export { AddAdmin };
