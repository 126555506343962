import React, { useState, useCallback, useContext, useEffect } from 'react';

import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { driverConstants } from '_constants';
import {
  deleteDriver,
  getDrivers,
  deleteDrivers,
  changeDriversStatus,
  clearDriversGroups,
  changeDriversGroups,
  getGroups,
} from 'actions';
import { ExcelFileInput } from 'app/components/excel-file-input';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { GroupsMenuMultiSelect } from 'app/components/shared/groups-menu-multi-select';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { ActionAlert } from 'app/components/shared/on-action-alert';
import { GroupsCell, StatusCell, TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { TableRowActions } from 'app/components/table-row-actions';
import {
  PaginationComponent,
  usePagination,
} from 'app/components/use-pagination';
import {
  actionButtonStyles,
  basicButtonStyles,
  tableRowStyles,
} from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canAddNewEntity,
  canEditWithGroupActions,
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithReadPermission,
  getSelectedEntityOrganizationIdById,
  haveSameOrganization,
} from 'selectors';
import { Driver } from 'types';

import { ReactComponent as CheckBoxIcon } from './check_box.svg';
import { FilterDriverByName } from './filter-driver';
import { useHandleImportDriverData } from './hooks';
import { ReactComponent as UnselectIcon } from './unselect-icon.svg';

import ExportIcon from 'assets/export-icon.svg';
import ImportIcon from 'assets/import-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 1000,
      overflow: 'scroll',
      whiteSpace: 'nowrap',
    },
    basicButton: { ...basicButtonStyles },
    multiActionButton: {
      ...actionButtonStyles,
      height: 27,
      padding: '4px 18px',
    },
    row: { ...tableRowStyles },
    selectedTableHead: {
      backgroundColor: '#4b506d !important',
      width: '100%',
      padding: 6,
    },
  })
);

interface DriverListItemProps {
  driver: Driver;
  isSelected: boolean;
  toggleSelection: (id: string) => void;
}

const DriverListItem = ({
  driver,
  isSelected,
  toggleSelection,
}: DriverListItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const { locale } = useContext(LanguageContext);

  const parentOrganization = getTranslated(driver?.organisation, locale);

  const hasEditPermissionOnOrganizationDriver = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Drivers', driver.id)
  );

  const driverGroups = useTypedSelector(state =>
    getEntityGroupsWithReadPermission(state, 'Drivers', driver.id)
  );

  return (
    <TableRow
      onClick={() => {
        history.push(`${baseURL}/${driver.id}`);
      }}
      className={classes.row}
      hover
      selected={isSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            toggleSelection(driver.id);
          }}
          checkedIcon={
            <CheckBoxIcon
              fill="#0fa66d"
              style={{
                backgroundColor: '#fff',
                borderRadius: 3,
              }}
            />
          }
        />
      </TableCell>

      <TextCell
        style={{ fontFamily: 'Cairo' }}
        label={driver.first_name}
        maxWidth={150}
      />

      <TextCell
        style={{ fontFamily: 'Cairo' }}
        label={driver.last_name}
        maxWidth={150}
      />

      <TextCell label={driver.username} maxWidth={150} />

      <TextCell label={parentOrganization} maxWidth={150} />

      <StatusCell isDisabled={driver.disabled} />

      <GroupsCell groups={driverGroups} />

      <TableRowActions
        hasWriteAccess={hasEditPermissionOnOrganizationDriver}
        editPath={`${baseURL}/${driver.id}/edit`}
        entityId={driver.id}
        entityName={`${driver.first_name} ${driver.last_name}`}
        entityType="driver"
        deleteAction={deleteDriver}
      />
    </TableRow>
  );
};

const DriverList: React.FC = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const baseURL = trimTrailingSlash(match.url);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);
  const [actionCall, setActionCall] = useState(undefined);
  const [isFiltering, setFiltering] = useState(false);

  const [filterParams, setFilterParams] = useState<{ [key: string]: string }>(
    {}
  );

  const [isActionConfirmationModalOpen, setActionConfirmationModalOpen] =
    useState(false);

  const [multiActionTitle, setMultiActionTitle] = useState('');

  const intl = useIntl();

  // Remove until fix multi select group button double click to open issue
  useEffect(() => {
    dispatch(getGroups(0, 100));
  }, [dispatch]);

  const hasEditPermissionOnSelectedDrivers = useTypedSelector(state =>
    canEditWithGroupActions(state, 'Drivers', selectedItems)
  );

  const hasSameOrg = useTypedSelector(state =>
    haveSameOrganization(state, 'Drivers', selectedItems)
  );

  const selectedEntitiesOrganizationId = useTypedSelector(state =>
    getSelectedEntityOrganizationIdById(state, {
      entityName: 'Drivers',
      entityId: selectedItems?.[0],
    })
  );

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getDrivers(page, rowSize, {
        first_name_or_last_name_or_username_cont: searchTerm,
      }),
    [searchTerm]
  );

  const fetchFilterResults = useCallback(
    (page: number, rowSize: number) => {
      let showDisabledOnly: boolean | undefined;
      if (filterParams?.disabled_in?.length === 1) {
        showDisabledOnly = filterParams?.disabled_in?.includes('disabled');
      }

      return getDrivers(page, rowSize, {
        ...filterParams,
        disabled_in: [showDisabledOnly],
      });
    },
    [filterParams]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    if (isFiltering) return fetchFilterResults;

    return getDrivers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFilterResults, isFiltering, isSearching]);

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    if (selectedItems?.length) {
      setSelectedItems([]);
    }

    handleChangePage(null, 0);
  };

  const filterDrivers = filters => {
    if (Object.values(filters)?.length) {
      setFiltering(true);
      setSearching(false);
      setSearchTerm('');
    }

    resetPageState();

    setFilterParams(filters);
  };

  const hasDriverWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Drivers')
  );

  const driverListIsLoading = useTypedSelector(
    state => state.drivers.isLoading as boolean
  );

  const driverListHasError = useTypedSelector(state => state.drivers.hasError);
  const driversTotalCount = useTypedSelector(state => state.drivers.totalCount);

  const driverList = useTypedSelector(state => {
    if (
      !state.drivers.isLoading &&
      Object.values(state.drivers.collection).length
    ) {
      return Object.values(state.drivers.collection);
    }

    return [];
  });

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getDrivers(page, rowsPerPage, {
          first_name_or_last_name_or_username_cont: searchTerm,
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const toggleSelection = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems([...selectedItems.filter(item => item !== id)]);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const isAllListSelected =
    driverList.length && selectedItems.length === driverList.length;

  const toggleSelectAll = () => {
    if (isAllListSelected) {
      setSelectedItems([]);
    } else {
      const listToBeSelected = driverList.map(driver => driver.id);
      setSelectedItems(listToBeSelected);
    }
  };

  const onChangePage = (event: unknown, newPage: number) => {
    if (page !== newPage) {
      setSelectedItems([]);
    }

    return handleChangePage(event, newPage);
  };

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: driversTotalCount,
        hasData: !!driverList.length,
        rowsPerPage,
        page,
        handleChangePage: onChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );

  const RenderActionButton = ({ title, onClick }) => (
    <Button
      variant="contained"
      color="default"
      type="button"
      className={classes.multiActionButton}
      onClick={() => {
        setMultiActionTitle(title);
        setActionConfirmationModalOpen(true);
        setActionCall(onClick);
      }}
    >
      {intl.formatMessage({ id: title })}
    </Button>
  );

  const assignDriversToGroups = onClick => {
    setMultiActionTitle('assign');
    setActionConfirmationModalOpen(true);
    setActionCall(onClick);
  };

  const refetchAction = useCallback(() => {
    setSelectedItems([]);
    dispatch(fetchAction()(page, rowsPerPage));
  }, [dispatch, fetchAction, page, rowsPerPage]);

  const { readUploadFile, loading: importing } = useHandleImportDriverData({
    onSuccess: refetchAction,
  });

  const pageLoading = driverListIsLoading || importing;

  const EnhancedTableToolbar = () => {
    if (selectedItems?.length) {
      return (
        <div className={classes.selectedTableHead}>
          <Checkbox
            checkedIcon={
              isAllListSelected ? (
                <CheckBoxIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              ) : (
                <UnselectIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              )
            }
            checked={!!selectedItems?.length}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setSelectedItems([]);
            }}
          />

          {hasEditPermissionOnSelectedDrivers && (
            <>
              {hasSameOrg && (
                <GroupsMenuMultiSelect
                  entityName="Drivers"
                  onAddGroups={groups =>
                    assignDriversToGroups(() =>
                      changeDriversGroups(selectedItems, [], groups)
                    )
                  }
                  selectedOrganizationId={selectedEntitiesOrganizationId}
                />
              )}

              {hasSameOrg && (
                <RenderActionButton
                  title="clearGroups"
                  onClick={() => clearDriversGroups(selectedItems)}
                />
              )}

              <RenderActionButton
                title="enable"
                onClick={() =>
                  changeDriversStatus(selectedItems, driverConstants.ENABLED)
                }
              />

              <RenderActionButton
                title="disable"
                onClick={() =>
                  changeDriversStatus(selectedItems, driverConstants.DISABLED)
                }
              />

              <RenderActionButton
                title="delete"
                onClick={() => deleteDrivers(selectedItems)}
              />
            </>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <CardTitle title={intl.formatMessage({ id: 'drivers' })}>
        <div>
          {hasDriverWriteAccess && (
            <ExcelFileInput
              onChange={readUploadFile}
              icon={ImportIcon}
              labelKey="import"
            />
          )}

          {false && (
            <ExcelFileInput
              onChange={readUploadFile}
              icon={ExportIcon}
              labelKey="export"
            />
          )}

          {hasDriverWriteAccess && (
            <Button
              component={Link}
              to={`${baseURL}/new`}
              variant="contained"
              color="secondary"
              className={classes.basicButton}
            >
              {intl.formatMessage({ id: 'addDriver' })}
            </Button>
          )}
        </div>
      </CardTitle>

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterDriverByName
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
              showFilters: !isSearching,
              filterDrivers,
              isSearching,
            }}
          />
        </Grid>

        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <EnhancedTableToolbar />

      <ActionAlert
        {...{
          isOpen: isActionConfirmationModalOpen,
          setOpen: setActionConfirmationModalOpen,
          setMultiActionTitle,
          setActionCall,
          action: actionCall,
          entityType: intl.formatMessage({ id: 'drivers' }),
          actionName: multiActionTitle,
          onSuccess: refetchAction,
        }}
      />

      <DataTableContainer alignItems={pageLoading ? 'center' : 'flex-start'}>
        {driverListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {pageLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {driverList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Drivers Table"
                  >
                    <TableHead>
                      {!selectedItems?.length && (
                        <TableRow
                          style={{ backgroundColor: '#4b506d !important' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={!!isAllListSelected}
                              onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                                toggleSelectAll();
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'firstName' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'lastName' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'userName' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'organization' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'status' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'groups' })}
                          </TableCell>

                          <TableCell align="center">
                            {intl.formatMessage({ id: 'actions' })}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>

                    <TableBody>
                      {driverList.map(driver => (
                        <DriverListItem
                          key={driver.id}
                          {...{ driver, toggleSelection }}
                          isSelected={selectedItems.includes(driver.id)}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={hasDriverWriteAccess}
                      baseURL={baseURL}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>

      <Pagination />
    </>
  );
};

export { DriverList };
