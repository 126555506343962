import { FluxStandardAction } from 'redux-promise-middleware';

import { routeConstants } from '_constants';
import { AppState, Axis, Route } from 'types';

const routes = (
  state: AppState['routes'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['routes'] => {
  switch (action.type) {
    case `${routeConstants.GET_ROUTES}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${routeConstants.GET_ROUTES}_FULFILLED`: {
      const routeList: AppState['routes']['collection'] = {};

      if (action.payload?.data) {
        action.payload.data.response?.data.forEach((item: Route) => {
          routeList[item.id] = item;
        });

        return {
          collection: { ...routeList },
          totalCount: action.payload?.data.response?.size,
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${routeConstants.GET_ROUTES}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${routeConstants.GET_ROUTE}_FULFILLED`: {
      const routeData = action.payload.data?.response.route;

      if (routeData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [routeData.id]: routeData,
          },
        };
      }

      return { ...state };
    }

    case `${routeConstants.ADD_ROUTE}_FULFILLED`: {
      const routeData = action.payload.data?.response.route;

      return {
        ...state,
        collection: {
          ...state.collection,
          [routeData.id]: routeData,
        },
      };
    }

    case `${routeConstants.EDIT_ROUTE}_FULFILLED`: {
      const routeData = action.payload.data?.response.route;

      return {
        ...state,
        collection: {
          ...state.collection,
          [routeData.id]: routeData,
        },
      };
    }

    case `${routeConstants.DELETE_ROUTE}_FULFILLED`: {
      const deletedRouteId = action.meta.routeId;

      const newState = { ...state };
      delete newState.collection[deletedRouteId];

      return newState;
    }

    case `${routeConstants.ADD_ROUTE_AXIS}_FULFILLED`: {
      const routeAxisData = action.payload.data?.response;
      const routeId = routeAxisData?.route_id;

      return {
        ...state,
        collection: {
          ...state.collection,
          [routeId]: {
            ...state.collection[routeId],
            axes: [...state.collection[routeId].axes, routeAxisData],
          },
        },
      };
    }

    case `${routeConstants.EDIT_ROUTE_AXIS}_FULFILLED`: {
      const routeAxisData = action.payload.data?.response as Axis;
      const routeId = routeAxisData.route_id as string;

      const oldState = { ...state };
      const modifiedAxes = oldState.collection[routeId].axes as Axis[];

      const indexOfAxis = modifiedAxes.findIndex(
        (axis: Axis) => axis.id === routeAxisData.id
      );

      modifiedAxes[indexOfAxis] = routeAxisData;

      return {
        ...state,
        collection: {
          ...state.collection,
          [routeId]: {
            ...state.collection[routeId],
            axes: modifiedAxes as [],
          },
        },
      };
    }

    case `${routeConstants.DELETE_ROUTE_AXES}_FULFILLED`: {
      const deletedAxes = action.meta.axes;
      const { routeId } = action.meta;

      const oldAxes = state.collection[routeId].axes;

      const newAxes = oldAxes.filter(
        (axis: Axis) => !deletedAxes.includes(axis.id)
      );

      return {
        ...state,
        collection: {
          ...state.collection,
          [routeId]: { ...state.collection[routeId], axes: newAxes },
        },
      };
    }

    case `${routeConstants.EDIT_BASE_FARE}_FULFILLED`: {
      const { routeId, baseFareId } = action.meta;
      const newState = { ...state };

      const modifiedAxes = newState.collection[routeId].axes as Axis[];

      const indexOfAxis = modifiedAxes.findIndex(
        (axis: Axis) => axis.base_fare?.id === baseFareId
      );

      modifiedAxes[indexOfAxis].base_fare = action.payload.data?.response;

      return {
        ...state,
        collection: {
          ...state.collection,
          [routeId]: {
            ...state.collection[routeId],
            axes: modifiedAxes as [],
          },
        },
      };
    }

    case `${routeConstants.ADD_ROUTE_AXIS_STOPS}_FULFILLED`: {
      const { axisId, routeId, newStops } = action.meta;
      const newState = { ...state };

      const modifiedAxes = newState.collection[routeId].axes as Axis[];

      const indexOfAxis = modifiedAxes.findIndex(
        (axis: Axis) => axis?.id === axisId
      );

      modifiedAxes[indexOfAxis].stops = newStops;

      return {
        ...state,
        collection: {
          ...state.collection,
          [routeId]: {
            ...state.collection[routeId],
            axes: modifiedAxes as [],
          },
        },
      };
    }

    default:
      return state;
  }
};

export { routes };
