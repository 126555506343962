import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { routeConstants } from '_constants';
import { API } from 'helpers';

interface RouteFilterParams {
  en_name_or_ar_name_or_code_cont?: string;
  organisation_id_in?: [string];
  route_groups_group_id_in?: [string];
  disabled_in?: [boolean | undefined];
}

const getRoutes =
  (page: number, size: number, params?: RouteFilterParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: routeConstants.GET_ROUTES,
      payload: API.get('/v2/routes', {
        params: { page: pageNumber, page_count: size, export: 0, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getRoute =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: routeConstants.GET_ROUTE,
      payload: API.get(`/v2/routes/${id}`),
    });

interface RouteActionParams {
  en_name: string;
  ar_name: string;
  code: string;
  organisation_id: string;
  disabled?: boolean;
}

const addRoute =
  (data: RouteActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { route: data };

    return dispatch({
      type: routeConstants.ADD_ROUTE,
      payload: API.post('/v2/routes', requestData),
    });
  };

const editRoute =
  (id: string, data: RouteActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { route: data };

    return dispatch({
      type: routeConstants.EDIT_ROUTE,
      payload: API.put(`/v2/routes/${id}`, requestData),
    });
  };

const deleteRoute =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: routeConstants.DELETE_ROUTE,
      payload: API.delete(`/v2/routes/${id}`),
      meta: { routeId: id },
    });

const assignRouteGroups =
  ({
    routeIdList,
    addedGroupIdList,
    removedGroupIdList,
  }: {
    routeIdList: string[];
    addedGroupIdList: string[];
    removedGroupIdList: string[];
  }) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: routeConstants.ASSIGN_ROUTE_GROUPS,
      payload: API.post('/v2/routes/assign_routes_groups', {
        routes: routeIdList,
        groups_to_be_added: addedGroupIdList,
        groups_to_be_deleted: removedGroupIdList,
      }),
    });

const clearRoutesGroups =
  (ids: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { routes: ids };

    return dispatch({
      type: routeConstants.CLEAR_GROUPS,
      payload: API.post(
        '/v2/routes/clear_routes_groups_assignment',
        requestData
      ),
      meta: { routeIds: ids },
    });
  };

const deleteRoutes =
  (ids: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { routes: ids };

    return dispatch({
      type: routeConstants.DELETE_ROUTES,
      payload: API.post('/v2/routes/delete_multiple_routes', requestData),
      meta: { routeIds: ids },
    });
  };

const changeRoutesStatus =
  (ids: string[], status: 'disabled' | 'enabled') =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { routes: ids, status };

    return dispatch({
      type: routeConstants.CHANGE_ROUTES_STATUS,
      payload: API.post('/v2/routes/change_routes_status', requestData),
      meta: { routeIds: ids, status },
    });
  };

interface RouteAxisActionParams {
  en_name: string;
  ar_name: string;
  code: string;
  route_id: string;
  disabled?: boolean;
  en_description?: string;
  ar_description?: string;
}

const addRouteAxis =
  (data: RouteAxisActionParams) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: routeConstants.ADD_ROUTE_AXIS,
      payload: API.post('/v2/axes', { axis: data }),
    });

const editRouteAxis =
  (id: string, data: RouteAxisActionParams) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: routeConstants.EDIT_ROUTE_AXIS,
      payload: API.put(`/v2/axes/${id}`, { axis: data }),
    });

const deleteRouteAxes =
  (routeId: string, axes: string[]) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: routeConstants.DELETE_ROUTE_AXES,
      payload: API.post(`/v2/axes/delete_multiple_axes`, { axes }),
      meta: { routeId, axes },
    });

interface BaseFareActionParams {
  cash_price: string;
  card_price: string;
  axis_id?: string;
  valid_from?: string;
  fare_method?: string;
  organisation_id?: boolean;
}

const editBaseFare =
  (id: string, routeId: string, data: BaseFareActionParams) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: routeConstants.EDIT_BASE_FARE,
      payload: API.put(`/v2/base_fares/${id}`, { base_fare: data }),
      meta: { routeId, baseFareId: id },
    });

const addRouteAxisStops =
  (axisId: string, routeId: string, data: string[]) =>
  (dispatch: Dispatch, getState): FluxStandardAction => {
    const newStops = data.map((id: string) => getState().stops.collection[id]);

    return dispatch({
      type: routeConstants.ADD_ROUTE_AXIS_STOPS,
      payload: API.post('/v2/axes_stops/reset_for_axis', {
        axis_and_stops: { axis_id: axisId, stops_ids: data },
      }),
      meta: { routeId, axisId, newStops },
    });
  };

export {
  getRoutes,
  getRoute,
  addRoute,
  editRoute,
  deleteRoute,
  assignRouteGroups,
  clearRoutesGroups,
  deleteRoutes,
  changeRoutesStatus,
  addRouteAxis,
  editRouteAxis,
  deleteRouteAxes,
  editBaseFare,
  addRouteAxisStops,
};
