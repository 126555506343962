export enum permissionConstants {
  GET_ADMIN_FULL_PERMISSIONS = 'GET_ADMIN_FULL_PERMISSIONS',
  GET_ِGENERAL_PERMISSIONS = 'GET_ِGENERAL_PERMISSIONS',
  GET_LOGGED_IN_ADMIN_CATEGORIES = 'GET_LOGGED_IN_ADMIN_CATEGORIES',
  GET_LOGGED_IN_ADMIN_ORGANIZATIONS = 'GET_LOGGED_IN_ADMIN_ORGANIZATIONS',
  GET_LOGGED_IN_ADMIN_GROUPS = 'GET_LOGGED_IN_ADMIN_GROUPS',
  GET_ADMIN_AVAILABLE_PERMISSIONS = 'GET_ADMIN_AVAILABLE_PERMISSIONS',
  EDIT_ADMIN_CATEGORY_PERMISSIONS = 'EDIT_ADMIN_CATEGORY_PERMISSIONS',
  EDIT_ALL_PERMISSIONS_FOR_ORGANIZATION_OR_GROUP = 'EDIT_ALL_PERMISSIONS_FOR_ORGANIZATION_OR_GROUP',
  GRANT_GENERAL_PERMISSION = 'GRANT_GENERAL_PERMISSION',
}

export enum permissionAccessLevel {
  fullAccess = 'Full Access',
  readOnly = 'Read Only',
  noAccess = 'No Access',
}
