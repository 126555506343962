export enum stationConstants {
  GET_STATIONS = 'GET_STATIONS',
  GET_STATION = 'GET_STATION',
  ADD_STATION = 'ADD_STATION',
  EDIT_STATION = 'EDIT_STATION',
  DELETE_STATION = 'DELETE_STATION',
  CHANGE_STATION_STATUS = 'CHANGE_STATION_STATUS',
  ASSIGN_STATIONS_TO_GROUPS = 'ASSIGN_STATIONS_TO_GROUPS',
  CLEAR_STATIONS_GROUPS = 'CLEAR_STATIONS_GROUPS',
  DELETE_MULTIPLE_STATIONS = 'DELETE_MULTIPLE_STATIONS',
}
