/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
// actions/zoning.actions.ts
export const storeZones = (zones) => {
	return {
	  type: "storeZones",
	  payload: zones // Use "payload" instead of "data"
	};
  };
  
  export const storeAllZones = (zone) => {
	return {
	  type: "storeAllZones",
	  payload: zone // Use "payload" instead of "data"
	};
  };
  export const visabilityOfErrorPage = (boolValue) => {
	return {
	  type: "visabilityOfErrorPage",
	  payload: boolValue // Use "payload" instead of "data"
	};
  };
  