import {
  string as yupString,
  object as yupObject,
  number as yupNumber,
} from 'yup';

export interface StopFormState {
  en_name: string;
  ar_name: string;
  lon: string;
  lat: string;
  code: string;
}

const StopFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const lonValidateNumberMessage = intl.formatMessage({
    id: 'lonNumberValidation',
  });

  const latValidateNumberMessage = intl.formatMessage({
    id: 'latNumberValidation',
  });

  const codeValidateNumberMessage = intl.formatMessage({
    id: 'codeNumberValidation',
  });

  const codeValidateLengthMessage = intl.formatMessage({
    id: 'codeLengthValidation',
  });

  return yupObject().shape({
    en_name: yupString().required(validationRequiredMessage),
    ar_name: yupString().required(validationRequiredMessage),
    lon: yupNumber()
      .typeError(lonValidateNumberMessage)
      .required(validationRequiredMessage),
    lat: yupNumber()
      .typeError(latValidateNumberMessage)
      .required(validationRequiredMessage),
    code: yupString()
      .required(validationRequiredMessage)
      .matches(/^[0-9]+$/, codeValidateNumberMessage)
      .min(3, codeValidateLengthMessage)
      .max(3, codeValidateLengthMessage),
  });
};

export { StopFormSchema };
