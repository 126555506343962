// @ts-nocheck
import {axios} from '../components/zonesAxios'
import React, { useEffect, useState } from 'react'
import { NavLink, useHistory, useParams, Link } from "react-router-dom";
//import NavBar from '../components/NavBar';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import { Suspense } from 'react'
//import Sidebar from '../components/Sidebar';
import cookies from 'js-cookie'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FeedIcon from '@mui/icons-material/Feed';
import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,

} from '@material-ui/core';
import Loading from "../images/loading2.svg";
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { TextCell } from 'app/components/shared/table';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(() =>
createStyles({
  table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
  addButton: { ...basicButtonStyles },
  row: { ...tableRowStyles },
})
);

    
  const InfoOfRoute: React.FC = () => {
    const intl = useIntl();
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams<{ id: any }>(); 
    //const history.push = useNavigate();
    const { t } = useTranslation()
    const [routeData, getRouteData] = useState<any>({});
    const [allAxisData, getAllAxisData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    let user: any = '';
    let user2 :any= '';
    let userRefresh:any = '';
    
    const authData = localStorage.getItem("authData");
    
    if (authData !== null) {
      const parsedAuthData = JSON.parse(authData);
      user = parsedAuthData.userData;
      user2 = parsedAuthData.userToken;
      userRefresh = parsedAuthData.userToken;
    }
    const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
console.log(id);
    useEffect(()=>{

    const getRouteInfo =  () => {
      setIsLoading(true); // Set loading to true when data is being fetched
    
      //setTimeout(() => {
    axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/v2/routes/${id}`,
    { headers:{'access-token' : user2['access-token'],'uid' :user2.uid , 'client': user2.client }})
  .then((res)=> {
        getRouteData(res.data.response.route);
        console.log(JSON.stringify(res.data.response.route));
        getAllAxisData(res.data.response.route?.axes);
        setIsLoading(false); // Set loading to false when data is fetched
     
    })
    .catch((err)=>{
      toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #ior1`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
        })           
    
        setIsLoading(false); // Set loading to false when data is fetched
         
      })
   // }, 3000);
  }
   getRouteInfo();

  
    
      },[])
      const viewinfoofaxis = (routeID,axisID,axisCODE) =>{
           // Set sessionStorage
      sessionStorage.setItem('routeid', routeID);
      sessionStorage.setItem('axisid', axisID);
      sessionStorage.setItem('axiscode', axisCODE);
      const searchParams = new URLSearchParams();

      // Set query parameters
      searchParams.set('routeid', routeID);
      searchParams.set('axisid', axisID);
      searchParams.set('axiscode', axisCODE);
  
      // Update the URL with the new query parameters
      history.push({
        pathname: '/zoning/zoningRoutes/axis',
        search: `?${searchParams.toString()}`,
      });
       }
       // feature not used now
       /* 
      const viewinfoofMultiLineMap = (allAxisOfEazyRouteData) =>
      {
        const searchParams = new URLSearchParams();
        searchParams.set('allAxisOfEazyRouteData', allAxisOfEazyRouteData);
      history.push({
        pathname: '/zoning/zoningRoutes/multiLineMap',
        search: `?${searchParams.toString()}`,});
    }*/
  return (
    

   
         <div className='second xxx'style={{marginTop:"-35px",marginBottom:"-25px", flex:"1 1 auto", display:"flex", flexFlow:"column"}}>
        

   <div className='Info-all firstsec'style={{position:"relative"}}>
   <Link to={"/zoning/zoningRoutes"} style={currentLanguageCode == "en-us" ? {position:"absolute" , right:"20px",borderRadius:"15px",backgroundColor:"#dcdcdc",border:"1px solid #dcdcdc",padding:"0", top:"10px"}: {position:"absolute" , top:"10px",left:"20px",borderRadius:"15px",backgroundColor:"#dcdcdc",border:"1px solid #dcdcdc",padding:"0"}} className="btn btn-dark " >

   {currentLanguageCode == "en-us" ?<ChevronRightIcon/>  :<ChevronLeftIcon/> }
   </Link>
   <h3><FeedIcon style={{margin:"3px",marginBottom:"7px",fontSize:"30px"}}/> {intl.formatMessage({ id: 'RouteInfo' })}</h3>
   { 
        isLoading ? (
  
            <div style={{textAlign:"center",marginTop:"8px",padding:"12px"}}>
    <img src={Loading} alt="loading"  style={{width:"125px"}} />
    </div>
           
        ) : 
   routeData?.id != undefined ? <>
        <h6>{intl.formatMessage({ id: 'EnglishName'}) + " :  "} <span className='value-info'>{routeData.en_name}</span>  </h6>
        <h6>{intl.formatMessage({ id: 'ArabicName'}) + " :  "} <span className='value-info'>{ routeData.ar_name}</span> </h6>
        <h6>{intl.formatMessage({ id: 'Code'}) + " :  "} <span className='value-info'>{ routeData.code}</span> </h6>
        <h6>{intl.formatMessage({ id: 'Organization'}) + " :  "} <span className='value-info'>{  (currentLanguageCode == "ar-eg"? routeData.organisation.ar_name : routeData.organisation.en_name)}</span> </h6>
        <h6>{intl.formatMessage({ id: 'Groups'}) + " :  "} <span className='value-info'>{ routeData.groups[0]?.en_name}</span> </h6>
        <h6>{intl.formatMessage({ id: 'Axes'}) + " :  "} <span className='value-info'>{ routeData.axes?.length}</span> </h6>
        </>:<div style={{textAlign:"center",padding:"15px"}}>{intl.formatMessage({ id: 'NoDateAvailable'})}</div>
        
        }
   </div>
   <div className='Info-all secondsec' style={{position:"relative"}}>
   {/*(routeData).axes?.length>1 ? 
   <button onClick={()=>viewinfoofMultiLineMap((routeData).axes)}
   style={currentLanguageCode == "en-us" ? 
   {marginTop:"20px",position:"absolute" , right:"40px"}:
    {marginTop:"20px",position:"absolute" , top:"10px",left:"40px"}} 
   className="btn btn-dark " >
{currentLanguageCode == "en-us" ?<CompareArrowsIcon/>  :<CompareArrowsIcon/> }
</button>
:<></>*/}
   <h3><ListAltIcon style={{margin:"3px",marginBottom:"7px",fontSize:"27px"}}/> {intl.formatMessage({ id: 'Axes' })}</h3> 
   { user && user2 ?

   <div className="parentTable2">
 <Table
className={classes.table}
size="small"
aria-label="zoning Routes Table"
>
<TableHead>
  <TableRow>
    <TableCell align="center">
    {intl.formatMessage({ id: 'EnglishName' })}
    </TableCell>
    <TableCell align="center">
    {intl.formatMessage({ id: 'ArabicName' })}
    </TableCell>

    <TableCell>
    <th> {intl.formatMessage({ id: 'Code' })}</th>
    </TableCell>

    <TableCell>
    {intl.formatMessage({ id: 'CashPrice' })}
    </TableCell>

    <TableCell align="center">
    {intl.formatMessage({ id: 'CardPrice' })}
    </TableCell>
  </TableRow>
</TableHead>				
 <TableBody>
        {
            isLoading ? (
              <tr> <td colSpan={5}style={{textAlign:"center",padding:"8px"}}>
                <div style={{textAlign:"center",marginTop:"5px"}}>
        <img src={Loading} alt="loading"  style={{width:"75px"}} />
        </div>
                </td></tr>
            ) : 
          
        (routeData).axes?.length>0 ? 
		 (routeData).axes?.map((r,i) =>


     <TableRow style={{cursor:"pointer"}} key={r.id} onClick={()=>viewinfoofaxis(id, r.id,r.code)}>
          <TextCell
    label={r.en_name}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
            <TextCell
    label={r.ar_name}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
              <TextCell
    label={r.code}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
                <TextCell
    label={r.base_fare?.cash_price}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
                  <TextCell
    label={r.base_fare?.card_price}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />

      </TableRow>)
		: <tr> <td colSpan={5}style={{textAlign:"center",padding:"15px"}}>{intl.formatMessage({ id: 'NoDateAvailable'})}</td></tr>

	} </TableBody>
    </Table>
    </div> : <h2>
      <center> {intl.formatMessage({ id: 'LoginAgain' })} </center>
        </h2>}
    </div></div>

  )
}

export default InfoOfRoute