import React, { useMemo, useState } from 'react';

import {
  Button,
  Fade,
  Popper,
  makeStyles,
  createStyles,
  ClickAwayListener,
  Paper,
  Typography,
  TableCell,
  Icon,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { useIntl } from 'react-intl';

import { assignVehicleToDevice, getUnassignedVehicles } from 'actions';
import { AsyncAutoComplete } from 'app/components/async-auto-complete';
import { ActionAlert } from 'app/components/shared/on-action-alert';
import { basicButtonStyles } from 'app/shared/styles';
import { useTypedSelector } from 'helpers';

import Check from 'assets/check.svg';
import CloseIcon from 'assets/close-icon-1.svg';

const useStyles = makeStyles(() =>
  createStyles({
    assignButton: {
      padding: '7px 14px',
      height: '32px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #DADCE0',
      borderRadius: 15,
      textTransform: 'none',
      '&:hover': {
        border: '1px solid #DADCE0',
        backgroundColor: '#FFFFFF !important',
        opacity: 0.8,
      },
    },
    filled: {
      backgroundColor: '#4b506D',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#4b506D !important',
        opacity: 0.8,
      },
    },
    paperStyle: {
      width: 364,
      backgroundColor: '#F4F6FB',
      padding: '20px',
      paddingTop: '25px',
      paddingBottom: '25px',
      borderRadius: 15,
      boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.2)',
    },
    popperStyle: {
      marginTop: 6,
      zIndex: 10,
    },
    closeFilters: {
      marginRight: 10,
      cursor: 'pointer',
      '& :hover': {
        opacity: 0.7,
      },
    },
    confirmButton: { ...basicButtonStyles, marginRight: 10 },
    resetButton: {
      color: '#5572FA',
      width: 85,
      height: 37,
      fontSize: 13,
      fontWeight: 700,
    },
    title: {
      color: '#444',
      fontSize: 15,
      fontWeight: 500,
      paddingBottom: 10,
    },
    subtitle: {
      fontWeight: 700,
      color: '#252733',
    },
    subtitlePadding: {
      padding: '10px 0px 5px 0px',
    },
    actionsContainer: {
      paddingTop: 10,
    },
    inputSelect: {
      margin: '13px 0px',
    },
    checkIcon: {
      marginRight: 7,
    },
  })
);

const AssignVehicle = ({
  deviceId,
  filled,
  onAssign,
}: {
  deviceId: string;
  filled?: boolean;
  onAssign?: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [isActionAlertOpen, setIsActionAlertOpen] = useState<boolean>(false);

  const device = useTypedSelector(state => state.devices.collection[deviceId]);
  const [vehicleId, setVehicleId] = useState<string>(device?.vehicle?.id);

  const vehicle = useTypedSelector(
    state => state.vehicles.collection?.[vehicleId]
  );

  const id = open ? `simple-popper ${deviceId}` : undefined;
  const classes = useStyles();
  const intl = useIntl();

  const handleClick = event => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const handleClose = event => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleClickAway = () => setOpen(false);

  const buttonName = useMemo(() => {
    if (!device?.vehicle?.id) return intl.formatMessage({ id: 'unassigned' });

    return intl.formatMessage(
      { id: filled ? 'assignedTo_code' : 'assigned' },
      { code: device?.vehicle?.code }
    );
  }, [device, filled, intl]);

  const actionTitle = useMemo(
    () =>
      intl.formatMessage(
        { id: 'assignDeviceToVehicleMsg' },
        {
          serial: device?.serial,
          currentVehicleCode:
            device?.vehicle?.code ||
            `“ ${intl.formatMessage({ id: 'unassigned' })} ”`,
          code:
            vehicle?.code || `“ ${intl.formatMessage({ id: 'unassigned' })} ”`,
        }
      ),
    [device, intl, vehicle]
  );

  return (
    <>
      <TableCell
        align="center"
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
        }}
      >
        <Button
          variant="outlined"
          className={`${classes.assignButton} ${filled ? classes.filled : ''}`}
          onMouseUp={undefined}
          onMouseDown={handleClick}
        >
          {device?.vehicle?.id && (
            <img src={Check} alt="check" className={classes.checkIcon} />
          )}

          <Typography variant="body2">{buttonName}</Typography>

          <Icon component={open ? ArrowDropUp : ArrowDropDown} />
        </Button>
      </TableCell>

      <Popper
        id={id}
        open={open}
        placement="bottom-end"
        anchorEl={anchorEl}
        transition
        className={classes.popperStyle}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                variant="outlined"
                className={classes.paperStyle}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span className={classes.title}>
                    {intl
                      .formatMessage({ id: 'assignDeviceToVehicle' })
                      .toUpperCase()}
                  </span>

                  <span
                    onClick={handleClose}
                    onKeyDown={handleClose}
                    role="button"
                    tabIndex={0}
                    className={classes.closeFilters}
                  >
                    <img src={CloseIcon} alt="close filters" />
                  </span>
                </div>

                <div
                  className={`${classes.subtitle} ${classes.subtitlePadding}`}
                >
                  {intl.formatMessage(
                    {
                      id: device?.vehicle?.id
                        ? 'changeDevice_serial'
                        : 'assignDevice_serial',
                    },
                    { serial: device.serial }
                  )}
                </div>

                <div className={classes.subtitle}>
                  {intl.formatMessage(
                    {
                      id: device?.vehicle?.id
                        ? 'fromVehicle_Code_to'
                        : 'toVehicleCode',
                    },
                    { from: device.vehicle?.code }
                  )}
                </div>

                <div className={classes.inputSelect}>
                  <AsyncAutoComplete
                    id={id}
                    action={getUnassignedVehicles}
                    filterKey="code_cont"
                    labelParam="code"
                    placeholder={intl.formatMessage({ id: 'unassigned' })}
                    defaultValue={device?.vehicle}
                    onChange={val => setVehicleId(val)}
                    fixedFilterKey="organisation_id_in"
                    fixedFilterValue={[device.organisation.id]}
                  />
                </div>

                <div className={classes.actionsContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (device?.vehicle?.id === vehicleId) setOpen(false);
                      else setIsActionAlertOpen(true);
                    }}
                    className={classes.confirmButton}
                  >
                    {intl.formatMessage({ id: 'confirm' })}
                  </Button>

                  <Button
                    variant="text"
                    className={classes.resetButton}
                    onClick={handleClose}
                  >
                    {intl.formatMessage({ id: 'cancel' })}
                  </Button>
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>

      <ActionAlert
        {...{
          isOpen: isActionAlertOpen,
          setOpen: setIsActionAlertOpen,
          title: actionTitle,
          action: assignVehicleToDevice(deviceId, vehicleId),
          onSuccess: onAssign,
        }}
      />
    </>
  );
};

export default React.memo(AssignVehicle);
