import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { groupConstants } from '_constants';
import { API } from 'helpers';

interface GroupFilterParams {
  en_name_or_ar_name_or_code_cont?: string;
}

const getGroups =
  (page: number, size: number, params?: GroupFilterParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: groupConstants.GET_GROUPS,
      payload: API.get('/v2/groups', {
        params: { page: pageNumber, page_count: size, export: 0, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getGroup =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: groupConstants.GET_GROUP,
      payload: API.get(`/v2/groups/${id}`),
    });

interface GroupActionParams {
  en_name: string;
  ar_name: string;
  code: string;
  organisation_id: string;
}

const addGroup =
  (data: GroupActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { group: data };

    return dispatch({
      type: groupConstants.ADD_GROUP,
      payload: API.post('/v2/groups', requestData),
    });
  };

const editGroup =
  (id: string, data: GroupActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { group: data };

    return dispatch({
      type: groupConstants.EDIT_GROUP,
      payload: API.put(`/v2/groups/${id}`, requestData),
    });
  };

const deleteGroup =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: groupConstants.DELETE_GROUPS,
      payload: API.delete(`/v2/groups/${id}`),
      meta: { groupId: id },
    });

const deleteMultipleGroups =
  (ids: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { groups: ids };

    return dispatch({
      type: groupConstants.DELETE_MULTIPLE_GROUPS,
      payload: API.post('/v2/groups/delete_multiple_groups', requestData),
    });
  };

export {
  getGroups,
  getGroup,
  addGroup,
  editGroup,
  deleteGroup,
  deleteMultipleGroups,
};
