import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { organizationConstants } from '_constants';
import { API } from 'helpers';

interface OrganizationFilterParams {
  en_name_or_ar_name_or_code_cont?: string;
}

const getOrganizations =
  (page?: number, size?: number, params?: OrganizationFilterParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    // to be adjusted when working on organization list
    const pageNumber = page ? page + 1 : 0;

    return dispatch({
      type: organizationConstants.GET_ORGANIZATIONS,
      payload: API.get('/v2/organisations', {
        params: { page: pageNumber, page_count: size, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getOrganization =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: organizationConstants.GET_ORGANIZATION,
      payload: API.get(`/v2/organisations/${id}`),
    });

const addOrganization =
  (data: { en_name: string; ar_name: string }) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { organisation: data };

    return dispatch({
      type: organizationConstants.ADD_ORGANIZATION,
      payload: API.post('/v2/organisations', requestData),
    });
  };

const editOrganization =
  (
    id: string,
    data: {
      en_name: string;
      ar_name: string;
      disabled?: boolean;
    }
  ) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { organisation: data };

    return dispatch({
      type: organizationConstants.EDIT_ORGANIZATION,
      payload: API.put(`/v2/organisations/${id}`, requestData),
    });
  };

const deleteOrganization =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: organizationConstants.DELETE_ORGANIZATION,
      payload: API.delete(`/v2/organisations/${id}`),
      meta: { organizationId: id },
    });

export {
  getOrganizations,
  getOrganization,
  addOrganization,
  editOrganization,
  deleteOrganization,
};
