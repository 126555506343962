import React, { useState, useCallback, useContext } from 'react';

import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { deleteVehicleType, getVehicleTypes } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { TableRowActions } from 'app/components/table-row-actions';
import {
  PaginationComponent,
  usePagination,
} from 'app/components/use-pagination';
import {
  actionButtonStyles,
  basicButtonStyles,
  tableRowStyles,
} from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';
import { VehicleType } from 'types';

import { FilterVehicleByName } from './filter-vehicle-type';

import ExportIcon from 'assets/export-icon.svg';
import ImportIcon from 'assets/import-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 1000,
      overflow: 'scroll',
      whiteSpace: 'nowrap',
    },
    basicButton: { ...basicButtonStyles },
    multiActionButton: {
      ...actionButtonStyles,
      height: 27,
      padding: '4px 18px',
    },
    dataButton: { marginRight: 10, display: 'none' },
    row: { ...tableRowStyles },
  })
);

interface VehicleTypeListItemProps {
  vehicleType: VehicleType;
}

const VehicleListItem = ({ vehicleType }: VehicleTypeListItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const { locale } = useContext(LanguageContext);

  const hasWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Vehicles')
  );

  return (
    <TableRow
      onClick={() => {
        history.push(`${baseURL}/${vehicleType.id}`);
      }}
      className={classes.row}
      hover
    >
      <TextCell
        style={{ fontFamily: 'Cairo' }}
        label={vehicleType.en_model}
        maxWidth={150}
        align="center"
      />

      <TextCell
        style={{ fontFamily: 'Cairo' }}
        label={vehicleType.ar_model}
        maxWidth={150}
        align="center"
      />

      <TextCell
        label={getTranslated(vehicleType.vehicle_class, locale)}
        maxWidth={150}
        align="center"
      />

      <TextCell label={vehicleType.capacity} maxWidth={150} align="center" />

      <TableRowActions
        hasWriteAccess={hasWriteAccess}
        editPath={`${baseURL}/${vehicleType.id}/edit`}
        entityId={vehicleType.id}
        entityName={getTranslated(vehicleType, locale, 'model')}
        entityType="vehicleType"
        deleteAction={deleteVehicleType}
      />
    </TableRow>
  );
};

const VehicleTypeList: React.FC = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const baseURL = trimTrailingSlash(match.url);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);

  const intl = useIntl();

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getVehicleTypes(page, rowSize, {
        en_model_or_ar_model_cont: searchTerm,
      }),
    [searchTerm]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    return getVehicleTypes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  const hasVehicleWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Vehicles')
  );

  const vehicleListIsLoading = useTypedSelector(
    state => state.vehicleTypes.isLoading as boolean
  );

  const vehicleListHasError = useTypedSelector(
    state => state.vehicleTypes.hasError
  );

  const vehicleTypesTotalCount = useTypedSelector(
    state => state.vehicleTypes.totalCount
  );

  const vehicleList = useTypedSelector(state => {
    if (
      !state.vehicleTypes.isLoading &&
      Object.values(state.vehicleTypes.collection).length
    ) {
      return Object.values(state.vehicleTypes.collection);
    }

    return [];
  });

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    handleChangePage(null, 0);
  };

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getVehicleTypes(page, rowsPerPage, {
          en_model_or_ar_model_cont: searchTerm,
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const onChangePage = (event: unknown, newPage: number) =>
    handleChangePage(event, newPage);

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: vehicleTypesTotalCount,
        hasData: !!vehicleList.length,
        rowsPerPage,
        page,
        handleChangePage: onChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );

  return (
    <>
      <CardTitle title={intl.formatMessage({ id: 'vehicleTypes' })}>
        <div>
          <Button
            variant="outlined"
            className={`${classes.basicButton} ${classes.dataButton}`}
          >
            <img
              src={ImportIcon}
              alt="export vehicleTypes"
              style={{ marginRight: 10 }}
            />

            {intl.formatMessage({ id: 'import' })}
          </Button>

          <Button
            variant="outlined"
            className={`${classes.basicButton} ${classes.dataButton}`}
          >
            <img
              src={ExportIcon}
              alt="export vehicleTypes"
              style={{ marginRight: 10 }}
            />

            {intl.formatMessage({ id: 'export' })}
          </Button>

          {hasVehicleWriteAccess && (
            <Button
              component={Link}
              to={`${baseURL}/new`}
              variant="contained"
              color="secondary"
              className={classes.basicButton}
            >
              {intl.formatMessage({ id: 'addVehicleType' })}
            </Button>
          )}
        </div>
      </CardTitle>

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterVehicleByName
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
            }}
          />
        </Grid>

        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <DataTableContainer
        alignItems={vehicleListIsLoading ? 'center' : 'flex-start'}
      >
        {vehicleListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {vehicleListIsLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {vehicleList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Vehicles Table"
                  >
                    <TableHead>
                      <TableRow
                        style={{ backgroundColor: '#4b506d !important' }}
                      >
                        <TableCell align="center">
                          {intl.formatMessage({ id: 'englishName' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'arabicName' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'vehicleClass' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'capacity' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'actions' })}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {vehicleList.map(vehicleType => (
                        <VehicleListItem
                          key={vehicleType.id}
                          {...{ vehicleType }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <SystemEmptyState hasWriteAccess baseURL={baseURL} />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>

      <Pagination />
    </>
  );
};

export { VehicleTypeList };
