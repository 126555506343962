import React, { useContext, useEffect, useMemo } from 'react';

import {
  LinearProgress,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getOrganizations } from 'actions';
import {
  AdminFormState,
  AddAdminSchema,
  EditAdminSchema,
} from 'app/admin-management/admins/admin-helpers';
import { CardTitle } from 'app/components/shared/card-title';
import { getInputProps } from 'app/components/shared/groups-menu-multi-select/utils';
import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import { useStyles } from 'app/operations/drivers/driver-form';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, useTypedSelector } from 'helpers';
import {
  getIsSuperAdmin,
  getOrganizationsWithWritePermission,
} from 'selectors';
import { Organization } from 'types';

interface AdminForm {
  initialState: AdminFormState;
  handleSubmit: (
    values: AdminFormState,
    { setSubmitting }: FormikHelpers<AdminFormState>
  ) => void;
  isEditForm?: boolean;
}

const AdminForm: React.FC<AdminForm> = ({
  initialState,
  handleSubmit,
  isEditForm = false,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const isSuperAdmin = useTypedSelector(getIsSuperAdmin);

  const { direction, locale } = useContext(LanguageContext);

  const isRTL = direction === DIRECTIONS.RTL;

  useEffect(() => {
    dispatch(getOrganizations(0, 100));
  }, [dispatch]);

  const organizationList = useTypedSelector(state =>
    getOrganizationsWithWritePermission(state, 'Admins')
  ) as Organization[];

  const hasWriteAccessOnOrganizations = organizationList?.length > 0;

  const organizations = useMemo(() => {
    if (hasWriteAccessOnOrganizations) return organizationList;
    if (initialState.organization) return [initialState.organization];

    return null;
  }, [initialState, organizationList, hasWriteAccessOnOrganizations]);

  const canEditStatus =
    isEditForm && (!initialState?.is_super_admin || isSuperAdmin);

  return (
    <>
      <CardTitle
        title={intl.formatMessage({
          id: isEditForm ? 'editAdmin' : 'createAdmin',
        })}
      />

      <div className={classes.formWrapper}>
        <Formik
          initialValues={initialState}
          validationSchema={
            isEditForm
              ? () => EditAdminSchema(intl)
              : () => AddAdminSchema(intl)
          }
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={classes.fieldGroupWrapper}>
                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'firstName' })}
                  </InputLabel>

                  <Field
                    name="firstName"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'lastName' })}
                  </InputLabel>

                  <Field
                    name="lastName"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'userName' })}
                  </InputLabel>

                  <Field
                    name="username"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    {intl.formatMessage({ id: 'email' })}
                  </InputLabel>

                  <Field
                    name="email"
                    component={TextField}
                    type="email"
                    variant="outlined"
                    size="small"
                  />
                </div>

                {!isEditForm && (
                  <>
                    <div className={classes.fieldWrapper}>
                      <InputLabel
                        className={classes.formLabel}
                        style={{ flexDirection: 'row-reverse' }}
                      >
                        <strong className={classes.requiredAstrisk}>*</strong>

                        {intl.formatMessage({ id: 'password' })}
                      </InputLabel>

                      <Field
                        name="password"
                        component={TextField}
                        type="password"
                        variant="outlined"
                        size="small"
                      />
                    </div>

                    <div className={classes.fieldWrapper}>
                      <InputLabel
                        className={classes.formLabel}
                        style={{ flexDirection: 'row-reverse' }}
                      >
                        <strong className={classes.requiredAstrisk}>*</strong>

                        {intl.formatMessage({ id: 'repeatPassword' })}
                      </InputLabel>

                      <Field
                        name="repeatPassword"
                        component={TextField}
                        type="password"
                        variant="outlined"
                        size="small"
                      />
                    </div>
                  </>
                )}

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    {intl.formatMessage({ id: 'phone' })}
                  </InputLabel>

                  <Field
                    name="phone"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'organization' })}

                    <strong className={classes.requiredAstrisk}>*</strong>
                  </InputLabel>

                  <Field
                    name="organizationId"
                    component={TextField}
                    type="text"
                    select
                    variant="outlined"
                    size="small"
                    inputProps={getInputProps(isRTL)}
                  >
                    {organizations?.map(organization => (
                      <MenuItem
                        key={organization.id}
                        value={organization.id}
                        className={classes.selectMenuItem}
                      >
                        {getTranslated(organization, locale)}
                      </MenuItem>
                    ))}
                  </Field>
                </div>

                {canEditStatus && (
                  <div className={classes.fieldWrapper}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'status' })}
                    </InputLabel>

                    <FormControlLabel
                      control={
                        <Field
                          name="status"
                          type="checkbox"
                          component={Switch}
                          color="Secondary"
                        />
                      }
                      label={intl.formatMessage({
                        id: 'enabled',
                      })}
                    />
                  </div>
                )}

                {isSuperAdmin && (
                  <div className={classes.fieldWrapper}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'superAdmin' })}
                    </InputLabel>

                    <FormControlLabel
                      control={
                        <Field
                          name="is_super_admin"
                          type="checkbox"
                          component={Switch}
                          color="Secondary"
                        />
                      }
                      label={intl.formatMessage({
                        id: 'enabled',
                      })}
                    />
                  </div>
                )}
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({ id: isEditForm ? 'update' : 'create' })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => history.go(-1)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({ id: 'cancel' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { AdminForm };
