import {
  string as yupString,
  object as yupObject,
  number as yupNumber,
} from 'yup';

export interface VehicleTypeFormState {
  en_model: string;
  ar_model: string;
  capacity: string;
  vehicle_class_id: string;
}

const VehicleTypeFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const capacityValidationMessage = intl.formatMessage({
    id: 'capacityValidation',
  });

  return yupObject().shape({
    en_model: yupString().required(validationRequiredMessage),
    ar_model: yupString().required(validationRequiredMessage),
    capacity: yupNumber()
      .typeError(capacityValidationMessage)
      .required(validationRequiredMessage),
    vehicle_class_id: yupString().required(validationRequiredMessage),
  });
};

export { VehicleTypeFormSchema };
