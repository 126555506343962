// @ts-nocheck
import React from 'react';

import {
  createStyles,
  makeStyles,
  MenuItem,
  withStyles,
  Theme,
  InputBase,
  Select,
} from '@material-ui/core';

import { LOCALES, LanguageLabels } from 'app/i18n-locale/locales-constants';

import EgyptFlag from 'assets/eg-flag.svg';
import USFlag from 'assets/us-flag.svg';

const useStyles = makeStyles(() =>
  createStyles({
    enLangLogo: {
      margin: '2px',
      marginRight: '10px',
    },
    arLangLogo: {
      margin: '2px',
      marginRight: '10px',
    },
    menuSelect: {
      '& div': {
        display: 'flex',
      },
    },
  })
);

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 30,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '7px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 30,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase);

interface LanguageSelectBoxProps {
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  value: unknown;
}

const LanguageSelectBox: React.FC<LanguageSelectBoxProps> = ({
  handleChange,
  value,
}) => {
  const classes = useStyles();

  return (
    <Select
      labelId="language-simple-select-label"
      id="language-simple-select"
      value={value}
      onChange={handleChange}
      input={<BootstrapInput />}
      className={classes.menuSelect}
    >
      <MenuItem value={LOCALES.ENGLISH} style={{ width: 120 }}>
        <img
          src={USFlag}
          alt="united states flag logo"
          width="16px"
          height="16px"
          className={classes.enLangLogo}
        />

        <span>{LanguageLabels['en-us']}</span>
      </MenuItem>

      <MenuItem value={LOCALES.ARABIC} style={{ width: 120 }}>
        <img
          src={EgyptFlag}
          alt="egypt flag logo"
          width="16px"
          height="16px"
          className={classes.arLangLogo}
        />

        <span>{LanguageLabels['ar-eg']}</span>
      </MenuItem>
    </Select>
  );
};

export { LanguageSelectBox };
