import { FluxStandardAction } from 'redux-promise-middleware';

import { deviceConstants } from '_constants';
import { AppState, Device } from 'types';

const devices = (
  state: AppState['devices'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['devices'] => {
  switch (action.type) {
    case `${deviceConstants.GET_DEVICES}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${deviceConstants.GET_DEVICES}_FULFILLED`: {
      const deviceList: AppState['devices']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: Device) => {
          deviceList[item.id] = item;
        });

        return {
          collection: { ...deviceList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${deviceConstants.GET_DEVICES}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${deviceConstants.GET_DEVICE}_FULFILLED`: {
      const deviceData = action.payload.data?.response;

      if (deviceData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [deviceData.id]: deviceData,
          },
        };
      }

      return { ...state };
    }

    case `${deviceConstants.ADD_DEVICE}_FULFILLED`: {
      const deviceData = action.payload.data?.response;

      return {
        ...state,
        collection: {
          ...state.collection,
          [deviceData.id]: deviceData,
        },
      };
    }

    case `${deviceConstants.EDIT_DEVICE}_FULFILLED`: {
      const deviceData = action.payload.data?.response;

      return {
        ...state,
        collection: {
          ...state.collection,
          [deviceData.id]: deviceData,
        },
      };
    }

    case `${deviceConstants.DELETE_DEVICE}_FULFILLED`: {
      const deletedDeviceId = action.meta.deviceId;

      const newState = { ...state };
      delete newState.collection[deletedDeviceId];

      return newState;
    }

    default:
      return state;
  }
};

export { devices };
