import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, getVehicleType, editVehicleType } from 'actions';
import { NotAuthorized } from 'app/components/not-authorized';
import { VehicleTypeForm } from 'app/operations/vehicles/vehicle-types/vehicle-type-form';
import { VehicleTypeFormState } from 'app/operations/vehicles/vehicle-types/vehicle-type-helpers';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { VehicleType } from 'types';

const EditVehicleType: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const [isLoading, setLoading] = useState(true);
  const intl = useIntl();

  const vehicleTypeId = match.params.id;

  const vehicleData = useTypedSelector(
    state =>
      state.vehicleTypes.collection[vehicleTypeId] as VehicleType | undefined
  );

  useEffect(() => {
    if (vehicleData) {
      setLoading(false);
    } else {
      dispatch(getVehicleType(vehicleTypeId));
    }
  }, [dispatch, vehicleData, vehicleTypeId]);

  const initialState: VehicleTypeFormState = useMemo(
    () => ({
      en_model: vehicleData?.en_model || '',
      ar_model: vehicleData?.ar_model || '',
      capacity: vehicleData?.capacity || '',
      vehicle_class_id: vehicleData?.vehicle_class?.id || '',
    }),
    [vehicleData]
  );

  const hasWriteAccessToCurrentVehicleType = true;

  const handleSubmit = async (
    values: VehicleTypeFormState,
    { setSubmitting }: FormikHelpers<VehicleTypeFormState>
  ) => {
    setSubmitting(true);
    try {
      await Promise.resolve(
        dispatch(
          editVehicleType(vehicleTypeId, {
            en_model: values.en_model,
            ar_model: values.ar_model,
            capacity: values.capacity,
            vehicle_class_id: values.vehicle_class_id,
          })
        )
      );

      await Promise.resolve(dispatch(getVehicleType(vehicleTypeId)));

      history.push(`${baseURL}`.replace('edit', ''));

      dispatch(
        successAlert(intl.formatMessage({ id: 'successEditVehicleType' }))
      );
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!hasWriteAccessToCurrentVehicleType) {
    return <NotAuthorized />;
  }

  return (
    <VehicleTypeForm {...{ initialState, handleSubmit, isEditForm: true }} />
  );
};

export { EditVehicleType };
