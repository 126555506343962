import React, { useContext } from 'react';

import {
  AppBar,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';

import { LanguageSelectBox } from 'app/components/shared/language-selectbox.tsx';
import { UserDropdown } from 'app/components/user-dropdown';
import logo from 'assets/logo_with_name.png';
import { LanguageContext } from 'context/intl.context';
import { SideNavContext } from 'context/side-nav.context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#fff',
      height: 72,
      zIndex: theme.zIndex.drawer + 1,
      marginBottom: 23,
    },
    toolbar: { height: '100%', minHeight: 'auto' },
    menuButton: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    title: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logo: {
      width: 190,
    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();
  const { handleDrawerToggle } = useContext(SideNavContext);

  const { locale, setLocale } = useContext(LanguageContext);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) =>
    setLocale?.(event.target.value as string);

  return (
    <AppBar position="sticky" className={classes.appBar} variant="outlined">
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
          <Typography
            variant="h6"
            className={classes.title}
            component={Link}
            to="/"
          >
            <img src={logo} alt="Mwasalat Misr logo" className={classes.logo} />
          </Typography>
        </div>

        <LanguageSelectBox
          value={locale.toLowerCase()}
          handleChange={handleChange}
        />

        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: '23px 24px 17px 22px' }}
        />

        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
};

export { Header };
