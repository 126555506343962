import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import isObject from 'lodash/isObject';
import some from 'lodash/some';
import toArray from 'lodash/toArray';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { alertConstants } from '_constants';
import { successAlert } from 'actions';
import { convertFileToBase64, readExcelFile, useTypedSelector } from 'helpers';
import {
  getGroupsWithWritePermissionByName,
  getOrganizationsWithWritePermissionByName,
} from 'selectors';
import { Group, OperationsSubSystem } from 'types';

interface GenericRecordType {
  __rowNum__: number;
  Groups?: string;
  Organization?: string;
}
interface Props<RecordType> {
  entityName: OperationsSubSystem;
  mandatoryFields: string[];
  importAction?: (
    base64File: string
  ) => (dispatch: Dispatch) => FluxStandardAction;
  onSuccess?: () => void;
  validateGroups?: boolean;
  extraValidations?: ((record: RecordType) => {
    valid: boolean;
    error: string;
  })[];
}

export const useHandleImportData = <RecordType extends GenericRecordType>({
  entityName,
  mandatoryFields,
  importAction,
  onSuccess,
  validateGroups = true,
  extraValidations = [],
}: Props<RecordType>): {
  readUploadFile: (event: ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
} => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const allGroups = useTypedSelector(state => state.groups.collection);
  const allGroupsArray = useMemo(() => toArray(allGroups), [allGroups]);

  const groupsWithWriteAccess = useTypedSelector(state =>
    getGroupsWithWritePermissionByName(state, entityName)
  );

  const organizations = useTypedSelector(state =>
    getOrganizationsWithWritePermissionByName(state, entityName)
  );

  const validateFields = useCallback(
    (record: RecordType) => {
      if (!mandatoryFields?.length) return { valid: true, error: '' };

      let error = '';

      mandatoryFields.some(fieldName => {
        const isEmpty = !record[fieldName];

        if (isEmpty) {
          error = intl.formatMessage(
            { id: 'importMissingField' },
            { fieldName }
          );

          return true;
        }

        return false;
      });

      return { valid: !error, error };
    },
    [intl, mandatoryFields]
  );

  const validateSingleGroup = useCallback(
    (groupName: string, orgName: string) => {
      let currentGroup = groupsWithWriteAccess[groupName] as Group | undefined;

      if (!currentGroup) {
        currentGroup = allGroupsArray.find(group => {
          if (
            group.en_name.toLowerCase() === groupName &&
            organizations[orgName]
          ) {
            return true;
          }

          return false;
        });
      }

      const isInPassedOrg =
        currentGroup?.organisation?.en_name.toLowerCase() === orgName;

      if (!currentGroup || !isInPassedOrg) return false;

      return true;
    },
    [allGroupsArray, groupsWithWriteAccess, organizations]
  );

  const validateGroup = useCallback(
    (record: RecordType) => {
      const recordGroups = record?.Groups?.toLowerCase();
      if (!recordGroups) return { valid: false, error: '' };

      const groups = recordGroups.split(',').map(group => group.trim());

      const orgName = record?.Organization?.toLowerCase() as string;

      const notValidGroup = groups.find(
        group => !validateSingleGroup(group, orgName)
      );

      if (notValidGroup)
        return {
          valid: false,
          error: intl.formatMessage(
            { id: 'groupNotFound' },
            { groupName: notValidGroup }
          ),
        };

      return { valid: true, error: '' };
    },
    [intl, validateSingleGroup]
  );

  const validationActions = useMemo(() => {
    const actions = [validateFields];

    if (validateGroups) actions.push(validateGroup);

    actions.push(...extraValidations);

    return actions;
  }, [extraValidations, validateFields, validateGroup, validateGroups]);

  const runValidation = useCallback(
    (record: RecordType) => {
      let error = '';

      const hasError = validationActions.some(action => {
        const validationResult = action(record);

        if (!validationResult.valid) {
          error = validationResult.error;

          return true;
        }

        return false;
      });

      return { valid: !hasError, error };
    },
    [validationActions]
  );

  const validateRecords = useCallback(
    (records: RecordType[]) => {
      let error = '';
      let rowNum = 0;

      some(records, record => {
        // eslint-disable-next-line no-underscore-dangle
        rowNum = record.__rowNum__ + 1;

        const validation = runValidation(record);
        error = validation.error || '';

        return !!error;
      });

      if (error) {
        const errorMsg = intl.formatMessage(
          { id: 'importErrorAtRecord' },
          { rowNum, error }
        );

        dispatch({ type: alertConstants.ERROR, payload: errorMsg });

        return false;
      }

      return true;
    },
    [dispatch, intl, runValidation]
  );

  const uploadFile = useCallback(
    async (file: string) => {
      await dispatch(importAction?.(file));
    },
    [dispatch, importAction]
  );

  const handleError = useCallback(
    (error: AxiosError) => {
      const err = error.response?.data?.errors?.error;
      if (!err) return;

      let errorMessage = err;

      if (isObject(err)) {
        errorMessage = Object.entries(err)
          .map(([key, value]) => `${key}: ${value}`)
          .join(', ');
      }

      const rowNum = error.response?.data.errors.row;

      const errorMsg = intl.formatMessage(
        { id: 'importRemoteErrorAtRecord' },
        { rowNum, error: errorMessage }
      );

      dispatch({ type: alertConstants.ERROR, payload: errorMsg });
    },
    [dispatch, intl]
  );

  const readUploadFile = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      setLoading(true);

      try {
        const excelFile = event.target.files?.[0];
        if (!excelFile) return;

        const maxSize = 3 * 1024 * 1024; // 3MB
        if (maxSize < excelFile.size) {
          dispatch({
            type: alertConstants.ERROR,
            payload: intl.formatMessage({ id: 'importFileTooBig3MB' }),
          });

          return;
        }

        const json = (await readExcelFile(excelFile)) as RecordType[];

        const isValid = validateRecords(json);
        if (isValid) {
          const base64File = await convertFileToBase64(excelFile);
          await uploadFile(base64File);

          dispatch(
            successAlert(
              intl.formatMessage(
                { id: 'successImportMessage' },
                {
                  count: json.length,
                }
              )
            )
          );

          onSuccess?.();
        }
      } catch (error) {
        handleError(error as AxiosError);
      } finally {
        setLoading(false);
      }
    },
    [validateRecords, dispatch, intl, uploadFile, onSuccess, handleError]
  );

  return { readUploadFile, loading };
};
