// @ts-nocheck
import React from 'react'
import logo from '../images/logo.png';
import { Link, useLocation } from "react-router-dom";

import cookies from 'js-cookie'
import classNames from 'classnames'
import { useIntl } from 'react-intl';

const Error: React.FC = () => {
  const intl = useIntl();
  const { search} = useLocation();
  const code = new URLSearchParams(search).get("code") || '404';
  const message = new URLSearchParams(search).get("message") || 'PageNotFound';
  const backToLink = new URLSearchParams(search).get("backToLink") ||  "/super-dash";
  return (
    <div className="container">
    <div id="notfound" style={{overflow:"hidden"}}>
  
    <div className="notfound">
    <h3 className=" text-center"> <img src={logo} width="260px"alt="logo img" /></h3>
    {/*<div className="notfound-404">
    <h1><span>{code[0]}</span><span>{code[1]}</span><span>{code[2]}</span></h1>
    </div>*/}
    <h1 style={{textTransform:"capitalize",color:"red"}}>{intl.formatMessage({ id: message})}</h1>
    <Link to={backToLink} style={{borderRadius:"2px",backgroundColor:"#2891cd",border:"0"}} className="btn btn-dark " >
      {intl.formatMessage({ id: 'BackToHome'})}
        </Link>

   
    </div></div>
    </div>
  )
}

export default Error
