import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { importVehicles } from 'actions';
import { useTypedSelector } from 'helpers';
import { useHandleImportData } from 'hooks';

interface VehicleRecord {
  'Vehicle Type': string;
  'Vehicle Model Year': number;
  'Vehicle Code': number;
  'Plate Number': number | string;
  Organization: string;
  Groups: string;
  __rowNum__: number;
}

const VehicleMandatoryFields = [
  'Vehicle Type',
  'Vehicle Model Year',
  'Vehicle Code',
  'Plate Number',
  'Organization',
  'Groups',
];

interface Props {
  onSuccess?: () => void;
}

const useHandleImportVehiclesData = ({ onSuccess }: Props) => {
  const intl = useIntl();

  const vehicleTypeList = useTypedSelector(state => {
    if (
      !state.vehicleTypes.isLoading &&
      Object.values(state.vehicleTypes.collection).length
    ) {
      return Object.values(state.vehicleTypes.collection);
    }

    return [];
  });

  const vehicleTypes = useMemo(
    () => vehicleTypeList.map(vt => vt.en_model.toLowerCase()),
    [vehicleTypeList]
  );

  const validateVehicleType = useCallback(
    (record: VehicleRecord) => {
      const vehicleType = record['Vehicle Type'].toLowerCase();

      const hasError = !vehicleTypes.includes(vehicleType);

      return {
        valid: !hasError,
        error: hasError
          ? intl.formatMessage({ id: 'vehicleTypeNotFound' })
          : '',
      };
    },

    [intl, vehicleTypes]
  );

  return useHandleImportData<VehicleRecord>({
    entityName: 'Vehicles',
    mandatoryFields: VehicleMandatoryFields,
    extraValidations: [validateVehicleType],
    importAction: importVehicles,
    onSuccess,
  });
};

export { useHandleImportVehiclesData };
