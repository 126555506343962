import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { AddGroup } from 'app/operations/groups/add-group';
import { EditGroup } from 'app/operations/groups/edit-group';
import { GroupList } from 'app/operations/groups/group-list';
import { ViewGroup } from 'app/operations/groups/view-group';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

const Groups: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasGroupAddAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Groups')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasGroupAddAccess ? <AddGroup /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          <EditGroup />
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewGroup />
        </Route>

        <Route exact path={`${baseURL}`}>
          <GroupList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { Groups };
