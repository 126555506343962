import { Dispatch } from 'redux';
import { AsyncAction } from 'redux-promise-middleware';

import { userConstants } from '_constants';
import { API } from 'helpers';

const login =
  (username: string, password: string) =>
  (dispatch: Dispatch): AsyncAction =>
    dispatch({
      type: userConstants.USER_LOGIN,
      payload: API.post('/auth/sign_in', { username, password }),
    });

const logout =
  () =>
  (dispatch: Dispatch): AsyncAction =>
    dispatch({
      type: userConstants.USER_LOGOUT,
      payload: API.delete('/auth/sign_out'),
    });

const changeCreationPassword =
  (password: string) =>
  (dispatch: Dispatch): AsyncAction =>
    dispatch({
      type: userConstants.USER_CHANGE_CREATION_PASSWORD,
      payload: API.post('/v2/admins/change_creation_password', {
        password,
      }),
    });

const changePassword =
  (old_password: string, new_password: string) =>
  (dispatch: Dispatch): AsyncAction =>
    dispatch({
      type: userConstants.CHANGE_PASSWORD,
      payload: API.post('/v2/admins/change_password', {
        old_password,
        new_password,
      }),
    });

const refreshToken =
  () =>
  (dispatch: Dispatch): AsyncAction =>
    dispatch({
      type: userConstants.USER_REFRESH_TOKEN,
      payload: API.get('/auth/refresh_token'),
    });

export { login, changeCreationPassword, changePassword, logout, refreshToken };
