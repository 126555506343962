// @ts-nocheck
import React, { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { PageNotFound } from 'app/components/page-not-found';
import { SystemHeader, SystemIcon } from 'app/components/shared/system-header';
import { ZoningRoutes } from 'app/zoning/zoningRoutes';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canViewFaresSubSystems, canViewZoningSubSystems } from 'selectors';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie'
import { ToastContainer } from 'react-toastify';
 //Set-ExecutionPolicy Bypass -Scope Process -Force

const Zoning: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const canViewZoningRoutes = useTypedSelector(state =>
    canViewFaresSubSystems(state, 'Fares','Tickets')
  );
  let user: any = '';
  let user2 :any= '';
  let userRefresh:any = '';
  
  const authData = localStorage.getItem("authData");
  
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    user = parsedAuthData.userData;
    user2 = parsedAuthData.userToken;
    userRefresh = parsedAuthData.userToken;
  }
  

  return (
    <>
      {/*<SystemHeader
        headerTitle={intl.formatMessage({ id: 'zoning' })}
        headerIcon={SystemIcon.zoning}
  />*/}
    <SystemHeader
        headerTitle={intl.formatMessage({ id: 'zoning' })}
        headerIcon={SystemIcon.zoning}
      />
      <Switch>
        <Redirect from={baseURL} exact to={`${baseURL}/zoningRoutes`} />

<Route path={`${baseURL}/zoningRoutes`}>
          {canViewZoningRoutes ? <ZoningRoutes /> : <NotAuthorized />}
</Route>
        <Route path="/">
          <PageNotFound />
        </Route>
      </Switch>
      <ToastContainer />
    </>
  );
};

export { Zoning };
