import React from 'react';

import {
  Card,
  createStyles,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      borderRadius: 30,
      background: '#fff',
      boxShadow: 'none',
      marginBottom: theme.spacing(2),
      paddingTop: 30,
      paddingBottom: 22,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        paddingTop: 24,
        paddingBottom: 22,
      },
    },
    childrenWrapper: { display: 'flex', flexDirection: 'column', flex: 1 },
  })
);

const CardContainer: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} component={Card} variant="outlined">
      <div className={classes.childrenWrapper}>{children}</div>
    </Paper>
  );
};

export { CardContainer };
