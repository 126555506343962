import React, { useContext, useEffect, useMemo, useRef } from 'react';

import {
  LinearProgress,
  Button,
  FormControlLabel,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getGroups, getOrganizations } from 'actions';
import AsyncMultiSelect from 'app/components/shared/async-multi-select';
import { CardTitle } from 'app/components/shared/card-title';
import { getInputProps } from 'app/components/shared/groups-menu-multi-select/utils';
import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import { useStyles } from 'app/operations/drivers/driver-form';
import {
  RouteFormState,
  RouteFormSchema,
} from 'app/operations/routes/route-helpers';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, useTypedSelector } from 'helpers';
import {
  getGroupsWithWritePermission,
  getOrganizationsWithReadPermission,
} from 'selectors';
import { Organization } from 'types';

interface RouteForm {
  initialState: RouteFormState;
  handleSubmit: (
    values: RouteFormState,
    { setSubmitting }: FormikHelpers<RouteFormState>
  ) => void;
  isEditForm?: boolean;
}

const RouteForm: React.FC<RouteForm> = ({
  initialState,
  handleSubmit,
  isEditForm = false,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { direction, locale } = useContext(LanguageContext);

  const isRTL = direction === DIRECTIONS.RTL;

  const organizationList = useTypedSelector(state =>
    getOrganizationsWithReadPermission(state, 'Routes')
  ) as Organization[];

  const hasWriteAccessOnOrganizations = organizationList?.length > 0;

  const organizations = useMemo(() => {
    if (hasWriteAccessOnOrganizations) return organizationList;
    if (initialState.organization) return [initialState.organization];

    return null;
  }, [initialState, organizationList, hasWriteAccessOnOrganizations]);

  const formikRef = useRef<FormikProps<RouteFormState>>(null);

  useEffect(() => {
    dispatch(getOrganizations(0, 100));
  }, [dispatch]);

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [formikRef, direction]);

  return (
    <>
      <CardTitle
        title={intl.formatMessage({
          id: isEditForm ? 'editRoute' : 'createRoute',
        })}
      />

      <div className={classes.formWrapper}>
        <Formik
          innerRef={formikRef}
          initialValues={initialState}
          validationSchema={() => RouteFormSchema(intl)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
            <Form>
              <div
                className={classes.fieldGroupWrapper}
                style={{ flexDirection: 'column' }}
              >
                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'englishName' })}

                    <strong className={classes.requiredAstrisk}>*</strong>
                  </InputLabel>

                  <Field
                    name="englishName"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'arabicName' })}

                    <strong className={classes.requiredAstrisk}>*</strong>
                  </InputLabel>

                  <Field
                    name="arabicName"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'code' })}

                    <strong className={classes.requiredAstrisk}>*</strong>
                  </InputLabel>

                  <Field
                    name="code"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'organization' })}

                    <strong className={classes.requiredAstrisk}>*</strong>
                  </InputLabel>

                  <Field
                    name="organizationId"
                    component={TextField}
                    type="text"
                    select
                    variant="outlined"
                    size="small"
                    inputProps={getInputProps(isRTL)}
                  >
                    {organizations?.map(organization => (
                      <MenuItem
                        key={organization.id}
                        value={organization.id}
                        className={classes.selectMenuItem}
                      >
                        {getTranslated(organization, locale)}
                      </MenuItem>
                    ))}
                  </Field>
                </div>

                <div className={classes.fieldWrapper}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingBottom: 24,
                    }}
                  >
                    <FormControl size="small" fullWidth variant="outlined">
                      <Field
                        name="groups"
                        component={AsyncMultiSelect}
                        sendSelectedOptions={(_, selectedGroups) =>
                          setFieldValue('groups', selectedGroups)
                        }
                        selected={values?.groups || []}
                        label="groups"
                        entity="groups"
                        action={() => getGroups(0, 100)}
                        showPlaceholder={false}
                        filterBy={{
                          filterKey: 'organisation_id',
                          filterValue: values.organizationId,
                        }}
                        requiredAstrisk
                        error={touched.groups && errors.groups}
                        customSelector={state =>
                          getGroupsWithWritePermission(
                            state,
                            'Routes',
                            values.organizationId
                          )
                        }
                      />
                    </FormControl>
                  </div>
                </div>

                {isEditForm && (
                  <div className={classes.fieldWrapper}>
                    <FormControlLabel
                      control={
                        <Field
                          name="status"
                          type="checkbox"
                          component={Switch}
                          color="secondary"
                        />
                      }
                      label={intl.formatMessage({
                        id: 'enabled',
                      })}
                    />
                  </div>
                )}
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({ id: isEditForm ? 'update' : 'add' })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => history.go(-1)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({ id: 'cancel' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { RouteForm };
