import { string as yupString, object as yupObject } from 'yup';

export interface EditProfileFormState {
  firstName: string;
  lastName: string;
}

const EditProfileFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  return yupObject().shape({
    firstName: yupString().required(validationRequiredMessage),
    lastName: yupString().required(validationRequiredMessage),
  });
};

export { EditProfileFormSchema };
