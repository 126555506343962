import React, { useContext, useEffect, useState } from 'react';

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableHead,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { deleteOrganization, getOrganization } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { useStyles } from 'app/components/shared/styles/view-entity';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { hasWriteAccessToMasterConfigSubSystem } from 'selectors';
import { Organization } from 'types';

const ViewOrganization: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const { locale } = useContext(LanguageContext);

  const isEnglish = locale.toLowerCase() === LOCALES.ENGLISH;

  const organizationId = match.params.id;

  const organization = useTypedSelector(
    state =>
      state.organizations.collection[organizationId] as Organization | undefined
  );

  const organizationTitle = isEnglish
    ? organization?.en_name
    : organization?.ar_name;

  const hasOrganizationWriteAccess = useTypedSelector(state =>
    hasWriteAccessToMasterConfigSubSystem(state, 'Organizations')
  );

  // eslint-disable-next-line consistent-return
  const renderCardActions = () => {
    if (hasOrganizationWriteAccess)
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.editButton}
            component={Link}
            to={`${baseURL}/edit`}
          >
            {intl.formatMessage({ id: 'edit' })}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            className={`${classes.editButton} ${classes.deleteButton}`}
            component={Button}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            {intl.formatMessage({ id: 'delete' })}
          </Button>
        </>
      );
  };

  useEffect(() => {
    if (!organization) {
      dispatch(getOrganization(organizationId));
    }
  }, [dispatch, organization, organizationId]);

  return (
    <div className={classes.viewContainer}>
      <CardTitle title={intl.formatMessage({ id: 'oranizationInfo' })} />

      <div className={classes.infoWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" aria-label="Organization Info Table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeadCell}>
                  {organizationTitle}
                </TableCell>

                <TableCell
                  style={{ textAlign: 'end' }}
                  className={classes.tableHeadCell}
                >
                  {renderCardActions()}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'englishName' })}
                </TableCell>

                <TableCell align="center">{organization?.en_name}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell style={{ paddingBottom: 37 }}>
                  {intl.formatMessage({ id: 'arabicName' })}
                </TableCell>

                <TableCell align="center" style={{ paddingBottom: 37 }}>
                  {organization?.ar_name}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Button
        variant="outlined"
        color="default"
        type="button"
        className={classes.backButton}
        onClick={() => history.replace('/configuration/organizations')}
      >
        {intl.formatMessage({ id: 'backToOrganizationList' })}
      </Button>

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteOrganization,
          entityType: intl.formatMessage({ id: 'organization' }),
          entityName: organizationTitle || ' ',
          entityId: organizationId,
          onfinishNavigation: '/configuration/organizations',
        }}
      />
    </div>
  );
};

export { ViewOrganization };
