import React, { useCallback, useMemo, useState } from 'react';

import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { ChangeCreationPassword } from 'app/components/change-creation-password';
import { SideNav } from 'app/components/side-nav';
import { SideNavContext } from 'context/side-nav.context';
import { getAuthData, useTypedSelector } from 'helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageWrapper: {
      height: '100%',
      background: '#F6F7FF',
    },
    contentWrapper: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
      },
    },
    childrenWrapper: {
      padding: '20px 33px 30px 30px',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding: '24px 16px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        paddingLeft: 260,
        paddingRight: 30,
      },
    },
  })
);

const PrivateRoute: React.FC<{
  path: string;
  children: React.ReactNode;
}> = ({ path, children }) => {
  const location = useLocation();
  const isHomePath = location.pathname === '/super-dash';
  const classes = useStyles();
  const authData = getAuthData();
  const [isMobileSideNavOpen, setMobileSideNavOpen] = useState(false);
  const [showSideNav, setShowSideNav] = useState(true);

  const isUsingCreationPassword = useTypedSelector(
    state => state.authentication?.user.userData.using_creation_password
  );

  const handleDrawerToggle = useCallback(() => {
    setMobileSideNavOpen(prevIsMobileSideNavOpen => !prevIsMobileSideNavOpen);
  }, []);

  const sideNavValue = useMemo(
    () => ({
      isMobileSideNavOpen,
      handleDrawerToggle,
      showSideNav,
      setShowSideNav,
    }),
    [isMobileSideNavOpen, handleDrawerToggle, showSideNav, setShowSideNav]
  );

  return (
    <div className={classes.pageWrapper}>
      <SideNavContext.Provider value={sideNavValue}>
        <div className={classes.contentWrapper}>
          <SideNav />

          {authData ? (
            <Route path={path}>
              {isUsingCreationPassword ? (
                <ChangeCreationPassword />
              ) : (
                <div
                  className={classes.childrenWrapper}
                  style={isHomePath ? { padding: 0 } : {}}
                >
                  {children}
                </div>
              )}
            </Route>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )}
        </div>
      </SideNavContext.Provider>
    </div>
  );
};

export { PrivateRoute };
