import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { CardContainer } from 'app/components/shared/card-container';
import { TripSaleList } from 'app/financial-control/trip-sales/trip-sale-list';
import { trimTrailingSlash } from 'helpers';

const TripSales: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  return (
    <CardContainer>
      <Switch>
        <Route exact path={`${baseURL}`}>
          <TripSaleList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { TripSales };
