/* export default {
    maptiler: {
        url:
            "https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=Il5SED2eFVFLkGkxfjzw",
        attribution:
            '&copy; <a href="https://www.maptiler.com/">MapTiler</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    },
} */
// https://tile.openstreetmap.org/{z}/{x}/{y}.png
export default {
    maptiler: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    },
};
