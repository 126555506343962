// @ts-nocheck
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Modal,Button as BootstrapButton } from "react-bootstrap";
import {
 Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { isEqual } from 'lodash';
import map from 'lodash/map';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { permissionAccessLevel } from '_constants';
import {
  editAdminCategoryPermissions,
  getAdminFullPermissions,
  grantGeneralPermission,
  successAlert,
} from 'actions';
import Loading from "../../../../zoning/zoningRoutes/images/loading2.svg";
import AutoCompleteInput from 'app/components/shared/Autocomplete';
import { GroupsCell } from 'app/components/shared/table';
import { basicButtonStyles, mainButtonStyles } from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import {
  generatePermissionLevelsValues,
  getCurrentAccessLevel,
  getTranslated,
  useTypedSelector,
} from 'helpers';
import {
  getGroupsWithReadPermission,
  getHasWritePermissionOnOrganization,
  getLoggedAdminId,
  makeGetCategoryAvailablePermissions,
} from 'selectors';
import { AdminPermissionList, Group, OperationsSubSystem } from 'types';

import EditIcon from './edit-icon.svg';

import fullAccessIcon from 'assets/full-access.svg';
import openEyeIcon from 'assets/open-eye.svg';
import restrictedIcon from 'assets/restricted.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { visabilityOfErrorPage } from 'actions/zoning.actions';

const icons = {
  NoAccess: restrictedIcon,
  FullAccess: fullAccessIcon,
  ReadOnly: openEyeIcon,
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#fff',
      padding: '0px 30px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    bottomRadius: {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderBottom: 0,
    },
    permissionsTitle: { margin: '20px 0', fontSize: 18 },
    permissionTypeTitle: { marginBottom: 12 },
    subSystem: {
      margin: '16px 0',
      display: 'flex',
      alignItems: 'center',
      borderBottomLeftRadius: '30px',
    },
    subSystemName: { padding: '0 42px', whiteSpace: 'nowrap' },
    rightContent: {
      padding: 0,
      paddingRight: 30,
      [theme.breakpoints.down('md')]: {
        paddingRight: 40,
      },
    },
    accessLevelContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    FullAccess: { fontWeight: 500, fontSize: 14 },
    ReadOnly: { fontWeight: 500, fontSize: 14 },
    NoAccess: { fontWeight: 500, fontSize: 14, color: '#CE0B24' },
    icon: {
      margin: '0 10px',
      paddingTop: 2,
    },
    actionButton: {
      marginRight: 10,
      width: 80,
      [theme.breakpoints.down('md')]: {
        marginBottom: 10,
      },
    },
    submitButton: {
      backgroundColor: '#0FA66D',
      '&:hover': {
        backgroundColor: '#0FA66D',
        opacity: 0.9,
      },
    },
    radioGroupCont: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    radioGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 430,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        minWidth: 40,
      },
    },
    radioLabel: {
      fontSize: 14,
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    saveButton: {
      ...mainButtonStyles,
      borderColor: '#fff',
      height: 30,
      width: 100,
      marginLeft: 11,
    },
    cancelButton: {
      ...basicButtonStyles,
      backgroundColor: '#fff',
      height: 30,
      width: 100,
      marginLeft: 11,
      '&:hover': {
        opacity: 0.9,
        backgroundColor: '#fff',
      },
    },
    loading: {
      color: '#4B506D',
    },
  })
);

const SystemsWithoutGroupsFunctionality = ['Devices', 'Groups', 'Admins'];

const formatPermission = (permissionsIds, isOrganization?: boolean) =>
  permissionsIds.map(id => ({
    id,
    type: isOrganization ? 'Organisation' : 'Group',
  }));

const PermissionItem: React.FC<{
  category: string;
  subSystemName: string;
  onChangePermission?: (
    subSystemName: string,
    permissions: string[] | undefined
  ) => void;
  permissions:
    | AdminPermissionList['permissionsByOrganization'][0]['subSystems']
    | AdminPermissionList['master_configuration'];
  canEditSinglePermission: boolean;
  permissionLevelType: 'Group' | 'Organisation' | undefined;
  isLast?: boolean;
  isEditingMasterConfig?: boolean;
  permissionLevelId?: string;
}> = ({
  category,
  subSystemName,
  onChangePermission,
  permissions,
  canEditSinglePermission,
  permissionLevelType,
  isLast,
  isEditingMasterConfig,
  permissionLevelId,
}) =>{
  const match = useRouteMatch<{ id: string }>();
  const classes = useStyles();
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //used states from redux
  const currentSuperAdminPermissionsAndCurrentRegularAdminPermissions = useSelector(state => state.permissions.collection);//current super admin permissions and current regular admin [not super] permissions , that we are edit its permissions and info
  const currentSuperAdminAllPermissions = useSelector(state => state.permissions); //current super admin all permissions
  const currentSuperAdminGroups = useSelector(state => state.groups); //current super admin groups
  //const boolValueOfVisabilityOfErrorPage = useSelector (state => state.zoning.boolValue);

  //from current url
  const adminId = match.params.id; // current regular admin [not super] ID , that we are edit its permissions and info
  //get localStorage stored logged in admin info 
  let user: any = '';
  let user2 :any= '';
  let userRefresh:any = '';
  
  const authData = localStorage.getItem("authData");
  
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    user = parsedAuthData.userData;
    user2 = parsedAuthData.userToken;
    userRefresh = parsedAuthData.userToken;
  }
  const [readPermissionsGroups, setReadPermissionsGroups] = useState(
    [] as Group[]
  );

  const shouldHidePermissionType = useMemo(
    () => SystemsWithoutGroupsFunctionality.includes(subSystemName),
    [subSystemName]
  );

  const dispatch = useDispatch();

 

  const currentAdminHasOrgWriteAccess = useTypedSelector(state =>
    getHasWritePermissionOnOrganization(
      state,
      subSystemName as OperationsSubSystem,
      permissionLevelId as string
    )
  );

  const groupsWithReadPermission = useTypedSelector(state =>
    getGroupsWithReadPermission(state, subSystemName as OperationsSubSystem)
  );

  useEffect(() => {
    if (!isEqual(readPermissionsGroups, groupsWithReadPermission)) {
      setReadPermissionsGroups(groupsWithReadPermission);
    }
  }, [groupsWithReadPermission, readPermissionsGroups]);

  const mappedGroups = useMemo(
    () =>
      groupsWithReadPermission
        .filter(group => group.organisation?.id === permissionLevelId)
        .map(group => ({
          id: group.id,
          name: getTranslated(group, locale),
        })),
    [groupsWithReadPermission, locale, permissionLevelId]
  );

  const currentAccessLevel = useMemo(
    () =>
      getCurrentAccessLevel(
        permissions?.[subSystemName]?.permissions ||
          permissions?.[subSystemName] ||
          []
      ),
    [subSystemName, permissions]
  );

  const [selectedValue, setSelectedValue] =
    useState<permissionAccessLevel>(currentAccessLevel);

  const initialGroups = useMemo(
    () =>
      map(permissions?.[subSystemName]?.groupsWithAccess, 'id')?.filter(id =>
        readPermissionsGroups.some(g => g.id === id)
      ) || [],
    [readPermissionsGroups, permissions, subSystemName]
  );

  const [selectedGroups, setSelectedGroups] = useState<string[]>(initialGroups);
  const [
    hasInitiallyOrganizationPermission,
    setIsInitiallyOrganizationPermission,
  ] = useState<boolean>(
    !permissions?.[subSystemName]?.groupsWithAccess?.length
  );

  const [isOrganizationAccess, setIsOrganizationAccess] = useState<boolean>(
    !permissions?.[subSystemName]?.groupsWithAccess?.length &&
      currentAdminHasOrgWriteAccess
  );

  const loggedAdminId = useTypedSelector(getLoggedAdminId) as string;

  const getCategoryAvailablePermissions = useMemo(
    makeGetCategoryAvailablePermissions,
    []
  );

  const categoryAvailablePermissions = useTypedSelector(state =>
    getCategoryAvailablePermissions(state, {
      systemName: category,
      adminId: loggedAdminId,
      systemType: permissionLevelType,
      systemId: permissionLevelId,
    })
  );

  const permissionList = useMemo(
    () =>
      generatePermissionLevelsValues(
        categoryAvailablePermissions?.[subSystemName]
      ),
    [categoryAvailablePermissions, subSystemName]
  );

  useEffect(() => {
    if (!isEditing && !isEditingMasterConfig) {
      setSelectedValue(currentAccessLevel);

      setIsInitiallyOrganizationPermission(
        !permissions?.[subSystemName]?.groupsWithAccess?.length &&
          selectedValue !== permissionAccessLevel.noAccess
      );

      setSelectedGroups(initialGroups);
    }
  }, [
    isEditingMasterConfig,
    currentAccessLevel,
    initialGroups,
    isEditing,
    permissions,
    selectedValue,
    subSystemName,
  ]);

  const onChange = event => {
    const newValue = event.target.value as permissionAccessLevel;
    setSelectedValue(newValue);

    if (!permissionLevelId) {
      onChangePermission?.(subSystemName, permissionList[newValue]);
    }
  };

  const clearPermissions = async (permissionsIds, isOrganization?: boolean) => {
    const groupsToBeDeleted = initialGroups;

    const permissionsToBeDeleted = isOrganization
      ? [permissionLevelId]
      : groupsToBeDeleted;

    if (permissionsToBeDeleted.length) {
      permissionsIds.forEach(async id => {
        if (id)
          await Promise.resolve(
            dispatch(
              grantGeneralPermission(
                adminId,
                id,
                [],
                formatPermission(permissionsToBeDeleted, isOrganization)
              )
            )
          );
      });
    }
  };
/*

Senario
1-regular Senario
  A- super admin open any regular admin page that containt its info and its permissions ex: /adminManagement/admins/123456789
  B- super admin can do any regular edited permissions and info for regular admin
2-New Features Added
  A-for any Org if you want to grant Fares subSys 
    A.A.1-If Routes Is already have any control , then fares will granted successfuly
    A.B.1-If Routes Is Not have any control You Should first Grant Any Routes SubSys control 
    A.B.2-in thi case when click Fares subsys edited btn [Full Cotroll , Read only] 
    A.B.3-Modal Open,click grant routes subsys for current working org 
    A.B.4-Grant Cliked-> your given Fares Permissions will applied on Routes Also [But With ReadOnly]
    A.B.5-Cancel Clicked-> your given Fares Permissions will NOT applied and also Routes Permissions not affected
  B-for any Org if you want to Deny Routes subSys 
    B.A.1-if Fares Not Have Any contol then Routes deny Success
    B.B.1-if Fares  Have Any contol then Modal open and if clicked deny then fares will denyed and then routes will denyed
    B.B-2-Cancel Clicked->deny for routes and fares not happend 
    */
/*
notes:
permission access level -> full access OR read only OR no access
permission access level Type -> organision OR groups
 */
/* here all useState's for handle  grant routes subsys and then continue  grant Fares 
   and deny fares subsys and then continue deny Routes  using Modal */
  const [show, setShow] = useState(false);
  const [modelMessage, setModelMessage] = useState('');
  const [routeOrFareOrgID, setRouteOrFareOrgID] = useState(null); //current working routes or fares org Id
  const [type, setType] = useState(''); //grant or deny
  const [typeInside, setTypeInside] = useState(''); //full or read
  const [sendedData, setData] = useState([]); //data of second action after deny or grant
  const [watingAlert, setWatingAlert] = useState(false); //control visabilty of waiting alert
  const history = useHistory();
    //handle Errors functions
  const handleError = (message,code)=>{
          const searchParams = new URLSearchParams();
        
                    // Set query parameters
                    searchParams.set('message',message );
                    searchParams.set('code', code);
                    searchParams.set('backToLink', `/adminManagement/admins/${adminId}`);
                
                    // Update the URL with the new query parameters
                    history.push({
                      pathname: '/Error',
                      search: `?${searchParams.toString()}`,
                    });
                    toast.error( intl.formatMessage({ id: 'someThingWentWrongError'}), {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined
                      })  
        }
  const currentWorkingModuleInfoAndItsSubSysNotFound =()=>{
      toast.error(intl.formatMessage({ id: 'currentWorkingModuleInfoAndItsSubSysNotFound'}), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
        }) 
    }
    //close modal 
  const handleClose = () =>{
    setShow(false);
    setModelMessage('');
    setRouteOrFareOrgID(null)
    setType('')
    setTypeInside('')
    setData([])
    setShow(false);
    setIsEditing(false);
    setIsLoading(false);
  } 
  // open modal
  const handleShow = (m,o,type,typeInside,data) =>{
    setModelMessage(m);
    setRouteOrFareOrgID(o)
    setType(type)
    setTypeInside(typeInside)
    setData(data)
    setShow(true);
  } 
  /*
  at first  modal opened using handleShow then grantRoutesSubSysOrDenyFaresSubSysUsingModalBtn implmnted
  */
  const grantRoutesSubSysOrDenyFaresSubSysUsingModalBtn = async(id,type,typeInside,data)=>{
    //checked on important used data
    if(currentSuperAdminAllPermissions && currentSuperAdminAllPermissions.generalPermissions && currentSuperAdminGroups && adminId){
    if(type =="grant"){
      grantRoutesSubSysReadOnlyAndThenGrantFaresUsingModalBtnGrant(id,typeInside,data)
    }
    else{
      denyFaresSubSysAndThenDenyRoutesSubSysUsingModalBtnDeny(id,data)
    }

    //at last
    handleClose()
  }else{
    handleError('someThingWentWrongError','pi-i-1')
  }
  }
  const grantRoutesSubSysReadOnlyAndThenGrantFaresUsingModalBtnGrant = async(OrgId,ReadOrWrite,data)=>{
    setIsLoading(true);
  //dispatch(storeVisabilityOfLoadingAlert(true))
   try{
 
 
   //get  Routes subsys general Read ID  , to make readonly permission access level
   const routesReadId = currentSuperAdminAllPermissions.generalPermissions
   .filter(obj => obj.action === 'Routes.read')
   .map(obj => obj.id);
     // grant Routes sub Sys
await Promise.resolve(
 dispatch(
   grantGeneralPermission(
     adminId,
     routesReadId[0],  
     data[1], //its a new permissions added for Fares Sub Sys, added here also
     []
   )
 )
);
toast.warn(intl.formatMessage({ id: 'NoMessageFound'}), {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
  }) 
/*await dispatch(getAdminFullPermissions(adminId));
dispatch(
 successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
);*/
  
    //grant Fares Sub Sys 
    //in case grant readonly access
    if(ReadOrWrite == 'read'){
      await Promise.resolve(
        dispatch(
          grantGeneralPermission(
            adminId,
            data[0], //fares read general ID
            data[1], //its a new permissions added for Fares Sub Sys like [current org  or current some groups permisions level type]
            data[2] //in case any removed permissions
          )
        )
      );
      await dispatch(getAdminFullPermissions(adminId));
      
    }else if(ReadOrWrite =='full'){ //in case grant full access
 
      await Promise.resolve(
        dispatch(
          grantGeneralPermission(
            adminId,
            data[0], //fares read general ID
            data[1], //its a new permissions added for Fares Sub Sys
            data[2] //in case any removed permissions
          )
        )
      );

      await Promise.resolve(
        dispatch(
          grantGeneralPermission(
            adminId,  
            data[3], //fares write general ID
            data[4],//its a new permissions added for Fares Sub Sys
            data[5] //in case any removed permissions
          )
        )
      );  
      await dispatch(getAdminFullPermissions(adminId));
    }
   

    dispatch(
      successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
    );
  
       
  
      } finally {
      
        setIsEditing(false);
       setIsLoading(false);
   
      }
  }
  const denyFaresSubSysAndThenDenyRoutesSubSysUsingModalBtnDeny = async(id,routeDeny)=>{ 
 //deny Fares permiisons
     //get  Fares subsys general Read ID  , to make no permission access level
    const faresReadIds = currentSuperAdminAllPermissions.generalPermissions
    .filter(obj => obj.action === 'Fares.read')
    .map(obj => obj.id);
       //get  Fares subsys general write ID  , to make no permission access level
  const faresWriteIds = currentSuperAdminAllPermissions.generalPermissions
    .filter(obj => obj.action === 'Fares.write')
    .map(obj => obj.id);
    // groups of currnt working organision , to be deleted
    const groupIds =  Object.values(currentSuperAdminGroups.collection)
  .filter(group => group.organisation_id === id)
  .map(group => group.id)
  const ClearPermissionsOrganisationAccesslevelTypeForFares = async()=>{
    const groupsToBeDeleted = groupIds;
        const permissionsToBeDeleted = true
          ? [permissionLevelId]
          : groupsToBeDeleted;
    
        if (permissionsToBeDeleted.length) {
          [faresReadIds[0],faresWriteIds[0]].forEach(async id => {
            if (id)
              await Promise.resolve(
                dispatch(
                  grantGeneralPermission(
                    adminId,
                    id,
                    [],
                    formatPermission(permissionsToBeDeleted, true)
                  )
                )
              );
          });
        }
  }
  const ClearPermissionsGroupsAccesslevelTypeForFares = async()=>{
      const groupsToBeDeleted = groupIds;
          const permissionsToBeDeleted = false
            ? [permissionLevelId]
            : groupsToBeDeleted;
      
          if (permissionsToBeDeleted.length) {
            [faresReadIds[0],faresWriteIds[0]].forEach(async id => {
              if (id)
                await Promise.resolve(
                  dispatch(
                    grantGeneralPermission(
                      adminId,
                      id,
                      [],
                      formatPermission(permissionsToBeDeleted, false)
                    )
                  )
                );
            });
          }
        }
        try{
          setIsLoading(true);
        await ClearPermissionsOrganisationAccesslevelTypeForFares()
        await ClearPermissionsGroupsAccesslevelTypeForFares()
   
        await dispatch(getAdminFullPermissions(adminId));
        dispatch(
          successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
        );
      } finally {
        setIsEditing(false);
       setIsLoading(false);
      }
//deny routes permmisions
      try{
        setIsLoading(true);
      await clearPermissions([routeDeny[0],routeDeny[1]], true)
      await clearPermissions([routeDeny[0],routeDeny[1]], false);
    
      await dispatch(getAdminFullPermissions(adminId));
      dispatch(
        successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
      );
    } finally {
      setIsEditing(false);
     setIsLoading(false);
    }  
    await dispatch(getAdminFullPermissions(adminId));
  }
  //functions to handle relations between fares subsystem and routes subsystem permissions
  const checkAnySpecificSubSystemOfCurrentWorkingOrgIsExist = async (organisations_permission_level, groups_permission_level, arrayOfCurrentSelectedOrgOrGroups,groupsApiErrorCode,generalErrorCode,subSystemWantChecked) => {
     let currentOrgID =''
    if(arrayOfCurrentSelectedOrgOrGroups && arrayOfCurrentSelectedOrgOrGroups[0] && arrayOfCurrentSelectedOrgOrGroups[0].type){
        if (arrayOfCurrentSelectedOrgOrGroups[0].type === "Organisation") {
          currentOrgID=arrayOfCurrentSelectedOrgOrGroups[0].id
          for (const item of organisations_permission_level) {
            if (item.organisation.id === arrayOfCurrentSelectedOrgOrGroups[0].id) {
              const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === subSystemWantChecked);
              if (subSystem) {
                return {status :true ,currentOrgID:currentOrgID};
              }
            }
          }
          for (const item of groups_permission_level) {
            if (item.group.organisation.id === arrayOfCurrentSelectedOrgOrGroups[0].id) {
              const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === subSystemWantChecked);
              if (subSystem) {
                return {status :true ,currentOrgID:currentOrgID};
              }
            }
          }
        }
    
        if (arrayOfCurrentSelectedOrgOrGroups[0].type === "Group") {
          try{
          const groupInfoResponse = await axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/v2/groups/${arrayOfCurrentSelectedOrgOrGroups[0].id}`, {
            headers: { 'access-token': user2['access-token'], 'uid': user2.uid, 'client': user2.client }
          });
    
          const groupOrganisationId = groupInfoResponse.data.response.group.organisation_id;
          currentOrgID=groupOrganisationId
          for (const item of organisations_permission_level) {
            if (item.organisation.id === groupOrganisationId) {
              const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === subSystemWantChecked);
              if (subSystem) {
                return {status :true ,currentOrgID:currentOrgID};
              }
            }
          }
          for (const item of groups_permission_level) {
            if (item.group.organisation.id === groupOrganisationId) {
              const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === subSystemWantChecked);
              if (subSystem) {
                return {status :true ,currentOrgID:currentOrgID};
              }
            }
          }
        }catch(err){
          handleError('CantGetImportantUsedDataFromGroupsApi',groupsApiErrorCode)
       
        }}
      }else{
        handleError('someThingWentWrongError',generalErrorCode)  
      }
      return {status :false ,currentOrgID:currentOrgID};
      };
  const handleChangeAccess = async isOrganization => {
    const writePermissionId =
      categoryAvailablePermissions?.[subSystemName]?.write?.id;

    const readPermissionId =
      categoryAvailablePermissions?.[subSystemName]?.read?.id;

    const selectedEntities = isOrganization
      ? [permissionLevelId]
      : selectedGroups;

    const groupsToBeDeleted = initialGroups.filter(
      id => !selectedGroups.includes(id)
    );

    const entitiesToBeRemoved = isOrganization ? [] : groupsToBeDeleted;

    if (
      (isOrganization && initialGroups.length) ||
      (selectedGroups.length && hasInitiallyOrganizationPermission)
    ) {
      await clearPermissions(
        [readPermissionId, writePermissionId],
        !isOrganization
      );
    }

    if (
      selectedValue === permissionAccessLevel.fullAccess &&
      writePermissionId
    ) {
      const fullAccessImplemntaion = async () => {
        await Promise.resolve(
          dispatch(
            grantGeneralPermission(
              adminId,
              readPermissionId,
              formatPermission(selectedEntities, isOrganization),
              formatPermission(entitiesToBeRemoved)
            )
          )
        );
  
        await Promise.resolve(
          dispatch(
            grantGeneralPermission(
              adminId,
              writePermissionId,
              formatPermission(selectedEntities, isOrganization),
              formatPermission(entitiesToBeRemoved)
            )
          )
        );
        dispatch(
          successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
        );   
      }
      const isRoutesOfOrgIsExistAndIfNoThenModalOpen = async () => {
        const isRoutesSubSysOfCurrentWorkingExist = await checkAnySpecificSubSystemOfCurrentWorkingOrgIsExist(
          currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].organisations_permission_level,
          currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].groups_permission_level,
          formatPermission(selectedEntities, isOrganization),'pi-i-b-1','pi-i-2','Routes'
        );
        if (!isRoutesSubSysOfCurrentWorkingExist.status) {
             //open Model
          handleShow('ThisActionNeedToGrantControlForRoutesOfTheOrganisationFirst',isRoutesSubSysOfCurrentWorkingExist.currentOrgID,"grant","full",[readPermissionId,
            formatPermission(selectedEntities, isOrganization),
            formatPermission(entitiesToBeRemoved),writePermissionId,
            formatPermission(selectedEntities, isOrganization),
            formatPermission(entitiesToBeRemoved)]);
          return false;
        } else {
          return true;
        }
      };
     //to get current working parent system module and subsys info 
     const currentWorkingModuleInfoAndItsSubSys = currentSuperAdminAllPermissions.generalPermissions.find(obj => obj.id === readPermissionId);
     //check if currentWorkingModuleInfoAndItsSubSys is exist
     if (currentWorkingModuleInfoAndItsSubSys) {
       const currentModuleWorkingName = currentWorkingModuleInfoAndItsSubSys.category; //to get current working parent system module
       const action = currentWorkingModuleInfoAndItsSubSys.action; 
       const currentSubSystemName = action.split('.')[0] //to get current working subsystem 
 //if current working parent system is fares module
     if(currentModuleWorkingName == 'fares'){
       //if current working subsystem is Fares 
       if(currentSubSystemName == "Fares"){
         // if true exec fullAccess implmention , false modal open
       if(await isRoutesOfOrgIsExistAndIfNoThenModalOpen()){
    /*      console.log("Read : ",formatPermission(selectedEntities, isOrganization));
          const routesReadId = currentSuperAdminAllPermissions.generalPermissions
          .filter(obj => obj.action === 'Routes.read')
          .map(obj => obj.id);
      console.log("routesReadId : ",routesReadId);
      
           await Promise.resolve(
             dispatch(
               grantGeneralPermission(
                 adminId,
                 routesReadId[0],
                 formatPermission(selectedEntities, isOrganization),
                 formatPermission(entitiesToBeRemoved)
               )
             )
           );
              await dispatch(getAdminFullPermissions(adminId));*/
              await fullAccessImplemntaion()  
              }}else{
                await fullAccessImplemntaion()  
              }
      }else if(currentModuleWorkingName == 'operations'){
        if(currentSubSystemName == "Routes"){
        
            const isFaresSubSysOfCurrentWorkingExist = await checkAnySpecificSubSystemOfCurrentWorkingOrgIsExist(
              currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].organisations_permission_level,
              currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].groups_permission_level,
              formatPermission(selectedEntities, isOrganization),'xxx1','xxxx2','Fares'
            );
            if (isFaresSubSysOfCurrentWorkingExist.status) {
     
          await fullAccessImplemntaion()
          toast.warn(intl.formatMessage({ id: 'noteFaresSybPermissonsChangedToBeLikeRoutesPermissions'}), {
            position: "top-center",
            autoClose: 50000000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
            }) 
              }else{
                await fullAccessImplemntaion() 
              }
      
        }else{
          await fullAccessImplemntaion()
      }
      }else{
      
          await fullAccessImplemntaion()  
      } 
      } else {
        currentWorkingModuleInfoAndItsSubSysNotFound()
  
      }
    }
    if (selectedValue === permissionAccessLevel.readOnly && readPermissionId) {
      clearPermissions([writePermissionId], isOrganization);
      const readOnlyImplemntaion = async () => {
        await Promise.resolve(
          dispatch(
            grantGeneralPermission(
              adminId,
              readPermissionId,
              formatPermission(selectedEntities, isOrganization),
              formatPermission(entitiesToBeRemoved)
            )
          )
        );
   
        dispatch(
          successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
        );
      }
     const isRoutesOfOrgIsExistAndIfNoThenModalOpen = async () => {
      const isRoutesSubSysOfCurrentWorkingExist = await checkAnySpecificSubSystemOfCurrentWorkingOrgIsExist(
        currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].organisations_permission_level,
        currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].groups_permission_level,
        formatPermission(selectedEntities, isOrganization),'pi-i-b-2','pi-i-3','Routes'
      );
      if (!isRoutesSubSysOfCurrentWorkingExist.status) {
        //open Model
        handleShow('ThisActionNeedToGrantControlForRoutesOfTheOrganisationFirst',isRoutesSubSysOfCurrentWorkingExist.currentOrgID,"grant",'read',[readPermissionId,formatPermission(selectedEntities, isOrganization),formatPermission(entitiesToBeRemoved)]);
        return false;
      } else {
        return true;
      }
    };
    //to get current working parent system module and subsys info 
  const currentWorkingModuleInfoAndItsSubSys = currentSuperAdminAllPermissions.generalPermissions.find(obj => obj.id === readPermissionId);
    //check if currentWorkingModuleInfoAndItsSubSys is exist
    if (currentWorkingModuleInfoAndItsSubSys) {
      const currentModuleWorkingName = currentWorkingModuleInfoAndItsSubSys.category; //to get current working parent system module
      const action = currentWorkingModuleInfoAndItsSubSys.action; 
      const currentSubSystemName = action.split('.')[0] //to get current working subsystem 
//if current working parent system is fares module
    if(currentModuleWorkingName == 'fares'){
      //if current working subsystem is Fares 
      if(currentSubSystemName == "Fares"){
        // if true exec readonly implmention , false modal open
      if(await isRoutesOfOrgIsExistAndIfNoThenModalOpen()){
     /* 
        console.log("Read : ",formatPermission(selectedEntities, isOrganization));
        const routesReadId = currentSuperAdminAllPermissions.generalPermissions
        .filter(obj => obj.action === 'Routes.read')
        .map(obj => obj.id);
    console.log("routesReadId : ",routesReadId);
    
         await Promise.resolve(
           dispatch(
             grantGeneralPermission(
               adminId,
               routesReadId[0],
               formatPermission(selectedEntities, isOrganization),
               formatPermission(entitiesToBeRemoved)
             )
           )
         );
         await dispatch(getAdminFullPermissions(adminId));*/
        await readOnlyImplemntaion()
        
      }
            }else{
              await readOnlyImplemntaion()
            }
    }else if(currentModuleWorkingName == 'operations'){
      if(currentSubSystemName == "Routes"){
      
          const isFaresSubSysOfCurrentWorkingExist = await checkAnySpecificSubSystemOfCurrentWorkingOrgIsExist(
            currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].organisations_permission_level,
            currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].groups_permission_level,
            formatPermission(selectedEntities, isOrganization),'xxx3','xxxx4','Fares'
          );
          if (isFaresSubSysOfCurrentWorkingExist.status) {

        await readOnlyImplemntaion()
        toast.warn(intl.formatMessage({ id: 'noteFaresSybPermissonsChangedToBeLikeRoutesPermissions'}), {
          position: "top-center",
          autoClose: 50000000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          }) 
            }else{
              await readOnlyImplemntaion() 
            }
    
      }else{
        await readOnlyImplemntaion()
    }
    }else{
    
        await readOnlyImplemntaion()
    }
 
    } else {
      currentWorkingModuleInfoAndItsSubSysNotFound()
    }
    }
    if (selectedValue === permissionAccessLevel.noAccess) {
      const noAccessImplemntaion = async () => {
        await clearPermissions([readPermissionId, writePermissionId], true);
        await clearPermissions([readPermissionId, writePermissionId], false);
        await dispatch(getAdminFullPermissions(adminId));  
        dispatch(
          successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
        );
      }
      const isFaresOfOrgIsExistAndIfYesThenModalOpenIfNoNoAccessImplmntionIsWillExecuted = async () => {
        const isFaresSubSysOfCurrentWorkingExist = await checkAnySpecificSubSystemOfCurrentWorkingOrgIsExist(
          currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].organisations_permission_level,
          currentSuperAdminPermissionsAndCurrentRegularAdminPermissions[adminId].groups_permission_level,
          formatPermission(selectedEntities, isOrganization),'pi-i-b-3','pi-i-4','Fares'
        ); 
        if (isFaresSubSysOfCurrentWorkingExist.status) {    
          return {status:true,currentOrgID:isFaresSubSysOfCurrentWorkingExist.currentOrgID};
        } else {
          return {status:false,currentOrgID:isFaresSubSysOfCurrentWorkingExist.currentOrgID};
        }
      };
    //to get current working parent system module and subsys info 
    const currentWorkingModuleInfoAndItsSubSys = currentSuperAdminAllPermissions.generalPermissions.find(obj => obj.id === readPermissionId);
    //check if currentWorkingModuleInfoAndItsSubSys is exist
    if (currentWorkingModuleInfoAndItsSubSys) {
      const currentModuleWorkingName = currentWorkingModuleInfoAndItsSubSys.category; //to get current working parent system module
      const action = currentWorkingModuleInfoAndItsSubSys.action; 
      const currentSubSystemName = action.split('.')[0] //to get current working subsystem 
//if current working parent system is operations module
      if(currentModuleWorkingName == "operations"){
           //if current working subsystem is Routes 
        if(currentSubSystemName == "Routes"){
          let res=  await isFaresOfOrgIsExistAndIfYesThenModalOpenIfNoNoAccessImplmntionIsWillExecuted()
        if(res.status){
   //open Model
    handleShow('ThisActionNeedToDenyControlForFaresOfTheOrganisationFirst', res.currentOrgID,"deny","no",[readPermissionId, writePermissionId]);
        }else{
          await noAccessImplemntaion()
        }
      }else{
        await noAccessImplemntaion()
      }
    }else{
      await noAccessImplemntaion()
    }
    }else {
      currentWorkingModuleInfoAndItsSubSysNotFound()
    }
  }
  };

  const handleSubmit = async () => {
   // alert("SUBMIT")
    setIsLoading(true);
    try {
      await handleChangeAccess(isOrganizationAccess);
      
console.log(isOrganizationAccess);

      await dispatch(getAdminFullPermissions(adminId));

  /*dispatch(
    successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
  );*/


    } finally {
      setIsEditing(false);
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    setIsEditing(false);

    setSelectedValue(currentAccessLevel);
    setSelectedGroups(initialGroups);

    setIsInitiallyOrganizationPermission(
      !permissions?.[subSystemName]?.groupsWithAccess?.length
    );

    setIsOrganizationAccess(
      !permissions?.[subSystemName]?.groupsWithAccess?.length &&
        currentAdminHasOrgWriteAccess
    );
  };

  const selectedAdminHasHigherPermission =
    (!currentAdminHasOrgWriteAccess &&
      hasInitiallyOrganizationPermission &&
      permissionList?.['Full Access']?.length === 2) ||
    (permissionList?.['Full Access']?.length === 1 &&
      currentAccessLevel === permissionAccessLevel.fullAccess);

  const disabled =
    !isOrganizationAccess &&
    !selectedGroups.length &&
    !!mappedGroups.length &&
    selectedValue !== permissionAccessLevel.noAccess;

  const canShowFullAccess =
    permissionList?.['Full Access'] && permissionList['Full Access'].length > 1;

  return (
    <>
   {/* loadingWhenGrant && <div style={{textAlign:"center",marginTop:"20px"}}>
<img src={Loading} alt="loading"  style={{width:"400px"}} />
</div> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{direction:"ltr"}}>
          <Modal.Title> {intl.formatMessage({ id:'Atten'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <h6 style={{padding:"20px 10px 10px 10px",textAlign:"center",color:"red"}}>{intl.formatMessage({ id: modelMessage||'NoMessageFound'})}</h6>
        </Modal.Body>
        <Modal.Footer>
        
          <BootstrapButton    variant="success" onClick={()=>grantRoutesSubSysOrDenyFaresSubSysUsingModalBtn(routeOrFareOrgID,type,typeInside,sendedData)}>
             {type == "grant" ? intl.formatMessage({ id:'Grant'}) : intl.formatMessage({ id:'Deny'}) }
          </BootstrapButton>
          <BootstrapButton variant="outline-secondary" onClick={handleClose}>
             {intl.formatMessage({ id:'Cancel'})}
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    <TableRow className={`${classes.container} ${classes.bottomRadius}`}>
      <TableCell
        className={`${classes.subSystemName} ${isLast && classes.bottomRadius}`}
      >
        {subSystemName}
      </TableCell>

      {isLoading && (
        <TableCell colSpan={3} className={classes.rightContent} align="right">
          <CircularProgress size={25} className={classes.loading} />
        </TableCell>
      )}

      {!isLoading && (
        <>
          {selectedValue !== permissionAccessLevel.noAccess &&
          permissionLevelId &&
          !isEditing ? (
            <GroupsCell
              groups={
                permissions?.[subSystemName]?.groupsWithAccess || [
                  { id: 'all', en_name: 'All', ar_name: 'الكل' },
                ]
              }
            />
          ) : (
            <TableCell className={`${classes.subSystemName}`}>
              {isEditing &&
                selectedValue !== permissionAccessLevel.noAccess &&
                !shouldHidePermissionType && (
                  <div style={{ display: 'flex' }}>
                    {currentAdminHasOrgWriteAccess && (
                      <FormControlLabel
                        checked={isOrganizationAccess || !mappedGroups.length}
                        onChange={() => {
                          if (mappedGroups.length)
                            setIsOrganizationAccess(prev => !prev);
                        }}
                        control={<Checkbox />}
                        label={intl.formatMessage({ id: 'organizationAccess' })}
                        classes={{ label: classes.radioLabel }}
                        disabled={!mappedGroups.length}
                      />
                    )}

                    {!isOrganizationAccess && !!mappedGroups.length && (
                      <AutoCompleteInput
                        sendSelectedValues={(_, selected) => {
                          setSelectedGroups(selected);
                        }}
                        selected={selectedGroups || []}
                        values={mappedGroups}
                        label=""
                        placeholderText="selectAGroup"
                        width={350}
                        showSelectAll
                      />
                    )}
                  </div>
                )}
            </TableCell>
          )}

          <TableCell
            className={`${classes.rightContent} ${
              isLast && classes.bottomRadius
            }`}
            align="right"
          >
            {isEditing || isEditingMasterConfig ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className={classes.radioGroupCont}>
                  <RadioGroup
                    row
                    className={classes.radioGroup}
                    value={selectedValue}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value={permissionAccessLevel.fullAccess}
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'fullAccess' })}
                      classes={{ label: classes.radioLabel }}
                      style={{
                        display: canShowFullAccess ? 'flex' : 'none',
                      }}
                    />

                    <FormControlLabel
                      value={permissionAccessLevel.readOnly}
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'readOnly' })}
                      classes={{ label: classes.radioLabel }}
                    />

                    <FormControlLabel
                      value={permissionAccessLevel.noAccess}
                      control={<Radio />}
                      label={intl.formatMessage({ id: 'noAccess' })}
                      classes={{ label: classes.radioLabel }}
                    />
                  </RadioGroup>
                </div>

                {permissionLevelId && (
                  <div className={classes.buttonGroup}>
                    <Button
                      variant="outlined"
                      color="default"
                      type="button"
                      className={classes.saveButton}
                      onClick={handleSubmit}
                      disabled={disabled}
                      style={{
                        opacity: !disabled ? 1 : 0.7,
                        color: '#fff',
                      }}
                    >
                      {intl.formatMessage({ id: 'save' })}
                    </Button>

                    <Button
                      variant="outlined"
                      color="default"
                      type="button"
                      className={classes.cancelButton}
                      onClick={onCancel}
                    >
                      {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className={classes.accessLevelContainer}>
                  <img
                    src={icons[currentAccessLevel.replace(' ', '')]}
                    alt={selectedValue}
                    className={classes.icon}
                  />

                  <Typography
                    className={classes[currentAccessLevel.replace(' ', '')]}
                  >
                    {intl.formatMessage({
                      id: currentAccessLevel.replace(' ', ''),
                    })}
                  </Typography>
                </div>

                {permissionLevelId &&
                  canEditSinglePermission &&
                  !selectedAdminHasHigherPermission && (
                    <IconButton
                      onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        setIsEditing(true);
                      }}
                    >
                      <img src={EditIcon} alt="Edit" />
                    </IconButton>
                  )}
              </div>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
    </>
  );
};

export { PermissionItem };
