import { useContext, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { getGroups } from 'actions';
import { Values } from 'app/components/shared/multi-select';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, useTypedSelector } from 'helpers';
import { getGroupsWithReadPermission } from 'selectors';
import { OperationsSubSystem } from 'types';

interface Props {
  entity: OperationsSubSystem;
}

const useGetFilterGroups = ({ entity }: Props) => {
  const dispatch = useDispatch();
  const { locale } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(getGroups(0, 100));
  }, [dispatch]);

  const groups = useTypedSelector(state =>
    getGroupsWithReadPermission(state, entity)
  );

  const mappedGroups: Values[] = groups.map(org => ({
    id: org.id,
    name: getTranslated(org, locale),
  }));

  return mappedGroups;
};

export { useGetFilterGroups };
