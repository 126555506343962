import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { changePassword, successAlert } from 'actions';
import { CardContainer } from 'app/components/shared/card-container';
import { CardTitle } from 'app/components/shared/card-title';

import { ChangePasswordForm } from './change-password-form';
import { ChangePasswordFormState } from './change-password-helpers';

const initialState: ChangePasswordFormState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePassword: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (
    values: ChangePasswordFormState,
    { setSubmitting }: FormikHelpers<ChangePasswordFormState>
  ) => {
    // TODO call edit profile api
    setSubmitting(true);

    Promise.resolve(
      dispatch(changePassword(values.currentPassword, values.newPassword))
    )
      .then(() => {
        history.go(-1);

        dispatch(
          successAlert(intl.formatMessage({ id: 'successChangePassword' }))
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <CardContainer>
      <CardTitle title={intl.formatMessage({ id: 'changePassword' })} />

      <ChangePasswordForm {...{ initialState, handleSubmit }} />
    </CardContainer>
  );
};

export { ChangePassword };
