import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { tripSaleConstants } from '_constants';
import { API } from 'helpers';

interface TripSaleQueryParams {
  group_ids?: string[];
  q: {
    ticket_serial_cont?: string;
    driver_username_or_driver_first_name_or_driver_last_name_cont_any?: string;
    axis_code_cont_any?: string;
    vehicle_code_cont_any?: string;
    groups?: string;
    created_at_gteq?: string;
    created_at_lteq?: string;
  };
}

const getTripSales =
  (page: number, size: number, queryParams?: TripSaleQueryParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: tripSaleConstants.GET_TRIP_SALES,
      payload: API.get('/v2/trip_payment_operations', {
        params: {
          page: pageNumber,
          page_count: size,
          export: 0,
          ...queryParams,
        },
      }),
      meta: { page: pageNumber },
    });
  };

export { getTripSales };
