import {
  string as yupString,
  number as yupNumber,
  object as yupObject,
  ref as yupRef,
} from 'yup';

import { Organization } from 'types';

export interface AdminFormState {
  firstName: string;
  lastName: string;
  username: string;
  password?: string;
  repeatPassword?: string;
  status?: boolean;
  email: string;
  phone: string;
  organizationId: string;
  organization?: Organization;
  is_super_admin?: boolean;
}

export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})/;

const AddAdminSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const passwordRulesMessage = intl.formatMessage({
    id: 'passwordRulesNotMatchMessage',
  });

  const passwordMatchValidation = intl.formatMessage({
    id: 'passwordMatchValidation',
  });

  return yupObject().shape({
    firstName: yupString().required(validationRequiredMessage),
    lastName: yupString().required(validationRequiredMessage),
    username: yupString().required(validationRequiredMessage),
    password: yupString()
      .required(validationRequiredMessage)
      .matches(passwordRegex, passwordRulesMessage),
    repeatPassword: yupString()
      .required(validationRequiredMessage)
      .oneOf([yupRef('password')], passwordMatchValidation),
    phone: yupNumber(),
    email: yupString().email(validationRequiredMessage),
    organizationId: yupString().required(validationRequiredMessage),
  });
};

const EditAdminSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const validEmailValidation = intl.formatMessage({
    id: 'validEmailValidation',
  });

  return yupObject().shape({
    firstName: yupString().required(validationRequiredMessage),
    lastName: yupString().required(validationRequiredMessage),
    username: yupString().required(validationRequiredMessage),
    phone: yupNumber(),
    email: yupString().email(validEmailValidation),
    organizationId: yupString().required(validationRequiredMessage),
  });
};

const AdminPasswordSchema = () =>
  yupObject().shape({
    password: yupString().required().matches(passwordRegex),
  });

export { AddAdminSchema, EditAdminSchema, AdminPasswordSchema };
