import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { basicButtonStyles } from 'app/shared/styles';
import { mainButtonStyles } from 'app/shared/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewContainer: { position: 'relative' },
    noDataWrapper: {
      height: 150,
      display: 'flex',
      alignItems: 'center',
    },
    editButton: {
      padding: '8px 25px',
      minWidth: 78,
      height: 40,
      borderRadius: 30,
      backgroundColor: '#0FA66D',
      boxShadow: '0 1px 4px 0 rgba(125, 125, 125, 0.5)',
      fontSize: 15,
      color: '#FFFFFF',
      textTransform: 'capitalize',
      border: '0',
      '&:hover': {
        backgroundColor: '#0FA66D',
        opacity: 0.9,
        border: '0',
      },
      borderColor: '#0000',
    },
    deleteButton: {
      backgroundColor: '#D0394D',
      marginLeft: 8,
      width: 125,
      '&:hover': {
        opacity: 0.9,
        backgroundColor: '#D0394D',
        border: '0',
      },
    },
    disabledBtn: {
      backgroundColor: 'rgba(75, 80, 109, 0.35)',
      border: '0',
      color: '#fff',
      boxShadow: 'none',
    },

    actionButtonIcon: {
      marginRight: 10,
    },
    infoWrapper: {
      margin: '13px 22px',
      background: '#F7F7F8',
      padding: 0,
      [theme.breakpoints.up('lg')]: {
        maxWidth: 634,
      },
    },
    customInfoWrapperMaxWidth: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1200,
      },
    },
    tableContainer: {
      border: '1px solid #DFE0EB',
      borderRadius: 10,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '100%',
      },
      paddingBottom: 30,
    },
    tableHead: {
      display: 'table-row-group',
      backgroundColor: '#fff',
    },
    tableHeadCell: {
      padding: '13px 25px',
      fontFamily: 'Manrope',
      fontSize: 18,
      letterSpacing: 0,
    },
    row: {
      height: 50,
      '& td': {
        textAlign: 'start',
        padding: '0px 30px 0 0;',
        border: 'none',
        color: '#252733',
        paddingTop: 30,
      },
      '& td:nth-of-type(1)': {
        fontWeight: 'bold',
        padding: '0 0 0 46px;',
        width: '35%',
        paddingTop: 30,
      },
    },
    backButton: {
      marginTop: 20,
      marginBottom: 60,
      color: '#4B506D',
      backgroundColor: '#fff',
      borderRadius: 30,
      borderColor: '#DFE0EB',
      marginLeft: 22,
      textTransform: 'capitalize',
      padding: '8px 20px',
    },
    groupNameStyle: {
      marginRight: 4,
      backgroundColor: '#fff',
      marginBottom: 4,
    },
    groupsContainer: {
      maxWidth: 500,
      textOverflow: 'ellipsis',
    },
    regeneratePasswordBtn: {
      backgroundColor: '#4B506D',
      color: '#fff',
      borderRadius: 31,
      height: 33,
      fontSize: 14,
      alignSelf: 'center',
      margin: 0,
      width: 178,
      textTransform: 'none',
      padding: '0px 15px',
      '&:hover': {
        opacity: 0.8,
        backgroundColor: '#4b506D !important',
      },
    },
    cancelButton: {
      ...basicButtonStyles,
      fontWeight: 400,
      height: 33,
      padding: '0px 15px',
    },
    eyeIcon: {
      height: 20,
      padding: 0,
      paddingRight: 10,
    },
    input: {
      backgroundColor: '#fff',
      maxWidth: 172,
    },
    list: {
      padding: '0 24px',
      margin: 0,
      '& li': {
        fontSize: 13,
        fontFamily: 'Manrope',
        fontWeight: 400,
      },
    },
    addAxis: {
      ...mainButtonStyles,
      marginLeft: 0,
      padding: '8px 20px',
    },
  })
);
