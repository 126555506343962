import React, { useContext, useEffect, useMemo, useRef } from 'react';

import {
  LinearProgress,
  Button,
  makeStyles,
  Theme,
  createStyles,
  FormControlLabel,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getGroups, getOrganizations } from 'actions';
import AsyncMultiSelect from 'app/components/shared/async-multi-select';
import { CardTitle } from 'app/components/shared/card-title';
import { getInputProps } from 'app/components/shared/groups-menu-multi-select/utils';
import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import {
  DriverFormState,
  DriverFormSchema,
} from 'app/operations/drivers/driver-helpers';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, useTypedSelector } from 'helpers';
import {
  getGroupsWithWritePermission,
  getOrganizationsWithReadPermission,
} from 'selectors';
import { Organization } from 'types';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: { padding: '0 32px' },
    fieldWrapper: {
      width: 347,
      marginRight: 24,
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      margin: '0 0 15px',
      [theme.breakpoints.down('sm')]: { margin: '0 0 15px' },
    },
    fieldGroupWrapper: {
      display: 'flex',
      width: '70%',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: { width: '100%' },
    },
    buttonsWrapper: {
      marginTop: 10,
    },
    actionButton: {
      textTransform: 'capitalize',
      marginRight: 10,
      borderRadius: 30,
      [theme.breakpoints.down('sm')]: {
        margin: '0 0 10px',
        width: '100%',
      },
      padding: '7px 17px',
      borderColor: '#DFE0EB',
    },
    submitButton: {
      backgroundColor: '#0FA66D',
      '&:hover': {
        backgroundColor: '#0FA66D',
        opacity: 0.9,
      },
      color: '#fff',
      borderColor: '#0FA66D',
      padding: '7px 25px',
    },
    formLabel: {
      color: '#444',
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'start',
    },
    requiredAstrisk: {
      color: 'red',
    },
    selectMenuItem: {
      borderBottom: '1px solid #DFE0EB',
      padding: '15px 30px',
      backgroundColor: '#FFFFFF',
      '&:hover': {
        opacity: 0.8,
      },
    },
  })
);

interface DriverForm {
  initialState: DriverFormState;
  handleSubmit: (
    values: DriverFormState,
    { setSubmitting }: FormikHelpers<DriverFormState>
  ) => void;
  isEditForm?: boolean;
}

const DriverForm: React.FC<DriverForm> = ({
  initialState,
  handleSubmit,
  isEditForm = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const { direction, locale } = useContext(LanguageContext);

  const isRTL = direction === DIRECTIONS.RTL;

  const organizationList = useTypedSelector(state =>
    getOrganizationsWithReadPermission(state, 'Drivers')
  ) as Organization[];

  const hasWriteAccessOnOrganizations = organizationList?.length > 0;

  const organizations = useMemo(() => {
    if (hasWriteAccessOnOrganizations) return organizationList;
    if (initialState.organization) return [initialState.organization];

    return null;
  }, [initialState, organizationList, hasWriteAccessOnOrganizations]);

  const formikRef = useRef<FormikProps<DriverFormState>>(null);

  useEffect(() => {
    dispatch(getOrganizations(0, 100));
  }, [dispatch]);

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [formikRef, direction]);

  return (
    <>
      <CardTitle
        title={intl.formatMessage({
          id: isEditForm ? 'editDriver' : 'addDriver',
        })}
      />

      <div className={classes.formWrapper}>
        <Formik
          innerRef={formikRef}
          initialValues={initialState}
          validationSchema={() => DriverFormSchema(intl)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, values, touched, errors }) => (
            <Form>
              <div className={classes.fieldGroupWrapper}>
                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: isRTL ? 'row' : 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'firstName' })}
                  </InputLabel>

                  <Field
                    name="firstName"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: isRTL ? 'row' : 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    <span>{intl.formatMessage({ id: 'lastName' })}</span>
                  </InputLabel>

                  <Field
                    name="lastName"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'phone' })}
                  </InputLabel>

                  <Field
                    name="phone"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'licenseNumber' })}
                  </InputLabel>

                  <Field
                    name="licenseNumber"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'nationalId' })}
                  </InputLabel>

                  <Field
                    name="nationalId"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: isRTL ? 'row' : 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'organization' })}
                  </InputLabel>

                  <Field
                    name="organizationId"
                    component={TextField}
                    type="text"
                    select
                    variant="outlined"
                    size="small"
                    inputProps={getInputProps(isRTL)}
                  >
                    {organizations?.map(organization => (
                      <MenuItem
                        key={organization.id}
                        value={organization.id}
                        className={classes.selectMenuItem}
                      >
                        {getTranslated(organization, locale)}
                      </MenuItem>
                    ))}
                  </Field>
                </div>

                <div className={classes.fieldWrapper}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingBottom: 24,
                    }}
                  >
                    <FormControl size="small" fullWidth variant="outlined">
                      <Field
                        name="groups"
                        component={AsyncMultiSelect}
                        sendSelectedOptions={(_, selectedGroups) =>
                          setFieldValue('groups', selectedGroups)
                        }
                        selected={values?.groups || []}
                        label="groups"
                        entity="groups"
                        action={() => getGroups(0, 100)}
                        showPlaceholder={false}
                        filterBy={{
                          filterKey: 'organisation_id',
                          filterValue: values.organizationId,
                        }}
                        requiredAstrisk
                        error={touched.groups && errors.groups}
                        customSelector={state =>
                          getGroupsWithWritePermission(
                            state,
                            'Drivers',
                            values.organizationId
                          )
                        }
                      />
                    </FormControl>
                  </div>
                </div>

                {isEditForm && (
                  <div
                    className={classes.fieldWrapper}
                    style={{ width: '100%' }}
                  >
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'status' })}
                    </InputLabel>

                    <FormControlLabel
                      control={
                        <Field
                          name="status"
                          type="checkbox"
                          component={Switch}
                          color="Secondary"
                        />
                      }
                      label={intl.formatMessage({
                        id: 'enabled',
                      })}
                    />
                  </div>
                )}
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({
                    id: isEditForm ? 'update' : 'addDriver',
                  })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => history.go(-1)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({
                    id: 'cancel',
                  })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { DriverForm };
