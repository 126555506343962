import React from 'react';

import {
  createStyles,
  makeStyles,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import { AdminMasterPermissions } from 'app/admin-management/admins/permissions/master-config-permissions';
import { OperationsPermissions } from 'app/admin-management/admins/permissions/operations-permissions';

const useStyles = makeStyles(theme =>
  createStyles({
    permissionsTitle: { margin: '20px 0', fontSize: 18 },
    infoWrapper: {
      margin: '35px 22px 14px 22px',
      background: '#F7F7F8',
      padding: 0,
    },
    tableContainer: {
      border: '1px solid #DFE0EB',
      borderRadius: 10,
      [theme.breakpoints.up('lg')]: {
        maxWidth: '100%',
      },
      paddingBottom: 30,
    },
    tableHead: {
      backgroundColor: '#fff',
    },
    tableHeadCell: {
      padding: '20px 25px',
      fontFamily: 'Manrope',
      fontSize: 18,
      letterSpacing: 0,
      width: '100%',
      fontWeight: 700,
    },
    body: {
      padding: '30px 30px 5px 30px',
    },
  })
);

const AdminPermissions: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <div className={classes.infoWrapper}>
        <TableContainer className={classes.tableContainer}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>
                {intl.formatMessage({ id: 'adminPermissions' })}
              </TableCell>

              <TableCell />
            </TableRow>
          </TableHead>

          <div className={classes.body}>
            <AdminMasterPermissions />

            <OperationsPermissions />
          </div>
        </TableContainer>
      </div>
    </>
  );
};

export { AdminPermissions };
