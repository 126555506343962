import { IntlShape } from 'react-intl';

export interface OrganizationFormState {
  englishName: string;
  arabicName: string;
  status?: boolean;
}

interface Errors {
  [key: string]: string;
}

const validateOrganizationForm = (
  values: OrganizationFormState,
  intl: IntlShape
): Errors => {
  const errors: Errors = {};

  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  if (!values.arabicName) {
    errors.arabicName = validationRequiredMessage;
  }

  if (!values.englishName) {
    errors.englishName = validationRequiredMessage;
  }

  return errors;
};

export { validateOrganizationForm };
