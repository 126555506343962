import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, getGroup, editGroup } from 'actions';
import { NotAuthorized } from 'app/components/not-authorized';
import { GroupForm } from 'app/operations/groups/group-form';
import { GroupFormState } from 'app/operations/groups/group-helpers';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canEditOperationsSubSystemEntity } from 'selectors';
import { Group } from 'types';

const EditGroup: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const [isLoading, setLoading] = useState(true);
  const intl = useIntl();

  const groupId = match.params.id;

  const groupData = useTypedSelector(
    state => state.groups.collection[groupId] as Group | undefined
  );

  useEffect(() => {
    if (groupData) {
      setLoading(false);
    } else {
      dispatch(getGroup(groupId));
    }
  }, [dispatch, groupData, groupId]);

  const initialState: GroupFormState = useMemo(
    () => ({
      en_name: groupData?.en_name || '',
      ar_name: groupData?.ar_name || '',
      code: groupData?.code || '',
      organizationId: groupData?.organisation_id || '',
      organization: groupData?.organisation || undefined,
    }),
    [groupData]
  );

  const hasWriteAccessToCurrentGroup = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Groups', groupId)
  );

  const handleSubmit = (
    values: GroupFormState,
    { setSubmitting }: FormikHelpers<GroupFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        editGroup(groupId, {
          en_name: values.en_name,
          ar_name: values.ar_name,
          code: values.code,
          organisation_id: values.organizationId,
        })
      )
    )
      .then(() => {
        history.push(`${baseURL}`.replace('edit', ''));

        dispatch(successAlert(intl.formatMessage({ id: 'successEditGroup' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!hasWriteAccessToCurrentGroup) {
    return <NotAuthorized />;
  }

  return <GroupForm {...{ initialState, handleSubmit, isEditForm: true }} />;
};

export { EditGroup };
