import { FluxStandardAction } from 'redux-promise-middleware';

import { adminConstants, userConstants } from '_constants';
import { setAuthData, clearAuthData } from 'helpers';
import { User, Authentication } from 'types';

const storedAuthData = localStorage.getItem('authData');
const user: User | null = storedAuthData ? JSON.parse(storedAuthData) : null;

const initialState: Authentication | null = user
  ? { loggedIn: true, user }
  : null;

const authentication = (
  state = initialState,
  action: FluxStandardAction
): Authentication | null => {
  switch (action.type) {
    case `${userConstants.USER_LOGIN}_FULFILLED`: {
      const {
        payload: {
          headers: { 'access-token': accessToken, uid, client, expiry },
          data: { data },
        },
      } = action;

      const userToken = { 'access-token': accessToken, uid, client, expiry };
      const authData = { userData: data, userToken };

      setAuthData(authData);

      return { loggedIn: true, user: authData };
    }

    case `${userConstants.USER_REFRESH_TOKEN}_FULFILLED`: {
      const {
        payload: {
          headers: { 'access-token': accessToken, client },
          data: { data },
        },
      } = action;

      const userToken = { 'access-token': accessToken, uid: data?.uid, client };
      const authData = { userData: data, userToken };

      setAuthData(authData);

      return { loggedIn: true, user: authData };
    }
    case `${adminConstants.GET_ADMIN}_FULFILLED`: {
      const { userData } = state?.user || {};

      const isCurrentAdmin =
        action.payload.data?.response.admin?.id === userData?.id;

      if (isCurrentAdmin && state?.user) {
        const { userToken } = state.user;

        const newUserData = action.payload.data.response.admin;
        const authData = { userData: newUserData, userToken };
        setAuthData(authData);

        return { loggedIn: true, user: authData };
      }

      return state;
    }

    case `${userConstants.USER_LOGIN}_REJECTED`:
    case `${userConstants.USER_LOGOUT}_FULFILLED`:
    case `${userConstants.USER_CHANGE_CREATION_PASSWORD}_FULFILLED`:
    case userConstants.USER_LOGOUT_INVALID_TOKEN: {
      clearAuthData();

      return null;
    }

    default:
      return state;
  }
};

export { authentication };
