import React, { useContext, useMemo, useState } from 'react';

import {
  Checkbox,
  ListItemText,
  MenuItem,
  Button,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import { LanguageContext } from 'context/intl.context';
import { getTranslated, useTypedSelector } from 'helpers';
import { getGroupsWithWritePermission } from 'selectors';
import { Group } from 'types';


import CloseIcon from './close-icon.svg';
import { useStyles } from './utils';
import { LoadingSpinner } from '../loading-spinner';

const GroupsMenuMultiSelect = ({
  buttonLabel = 'addToGroups',
  onAddGroups,
  selectedOrganizationId,
  entityName,
}) => {
  const [groupNames, setgroupNames] = React.useState<string[]>([]);
  const classes = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const id = open ? 'simple-popper' : undefined;
  const { locale } = useContext(LanguageContext);

  const groupList = useTypedSelector(state =>
    getGroupsWithWritePermission(state, entityName, selectedOrganizationId)
  );

  const filteredGroups = useMemo(() => {
    if (!groupList) return [];

    return groupList.filter(
      group => group.organisation?.id === selectedOrganizationId
    );
  }, [groupList, selectedOrganizationId]);

  const groupListIsLoading = useTypedSelector(
    state => state.groups.isLoading as boolean
  );

  const handleClick = event => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const handleClickAway = () => null;

  const handleCheckedGroup = (groupId: string) => {
    if (groupNames.includes(groupId)) {
      const newgroupNames = groupNames.filter(gid => gid !== groupId);

      setgroupNames(newgroupNames);
    } else {
      setgroupNames([...groupNames, groupId]);
    }
  };

  return filteredGroups?.length ? (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        color="default"
        type="button"
        className={classes.filterButton}
        onClick={handleClick}
        style={{ height: 27, padding: '4px 18px' }}
      >
        {intl.formatMessage({ id: buttonLabel })}
      </Button>

      <Popper
        id={id}
        open={open}
        placement="bottom-start"
        anchorEl={anchorEl}
        transition
        className={classes.popperStyle}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper variant="outlined" className={classes.paperStyle}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span
                    style={{
                      color: '#444',
                      fontSize: 15,
                      fontWeight: 500,
                      textTransform: 'uppercase',
                    }}
                  >
                    {intl.formatMessage({ id: 'addToGroups' })}
                  </span>

                  <span
                    onClick={() => setOpen(false)}
                    onKeyDown={() => setOpen(false)}
                    role="button"
                    tabIndex={0}
                    className={classes.closeFilters}
                  >
                    <img src={CloseIcon} alt="close filters" />
                  </span>
                </div>

                {groupListIsLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <>
                      <div style={{ marginTop: 17, marginBottom: 16 }}>
                        {filteredGroups.map((group: Group) => (
                          <MenuItem
                            key={group.id}
                            value={group.id}
                            onClick={() => handleCheckedGroup(group.id)}
                            className={classes.menuItem}
                          >
                            <Checkbox
                              checked={groupNames.includes(group.id)}
                              className={classes.checkbox}
                            />

                            <ListItemText
                              primary={getTranslated(group, locale)}
                            />
                          </MenuItem>
                        ))}
                      </div>

                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={event => {
                            event.stopPropagation();
                            onAddGroups(groupNames);
                          }}
                          className={classes.basicButton}
                          disabled={!groupNames.length}
                        >
                          {intl.formatMessage({ id: 'add' })}
                        </Button>

                        <Button
                          variant="text"
                          className={classes.resetButton}
                          onClick={event => {
                            event.stopPropagation();
                            setgroupNames([]);
                          }}
                        >
                          {intl.formatMessage({ id: 'reset' })}
                        </Button>
                      </div>
                    </>
                  </>
                )}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  ) : null;
};

export { GroupsMenuMultiSelect };
