import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, addRoute, assignRouteGroups } from 'actions';
import { RouteForm } from 'app/operations/routes/route-form';
import { RouteFormState } from 'app/operations/routes/route-helpers';
import { trimTrailingSlash } from 'helpers/url-helpers';

const initialState: RouteFormState = {
  englishName: '',
  arabicName: '',
  organizationId: '',
  groups: [],
  code: '',
};

const AddRoute: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const handleSaveGroups = async (routeId, currentValues) => {
    const groupsToBeAdded = currentValues.filter(
      (id: string) => !initialState.groups.includes(id)
    );

    await Promise.resolve(
      dispatch(
        assignRouteGroups({
          routeIdList: [routeId],
          addedGroupIdList: groupsToBeAdded,
          removedGroupIdList: [],
        })
      )
    );
  };

  const handleSubmit = (
    values: RouteFormState,
    { setSubmitting }: FormikHelpers<RouteFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        addRoute({
          en_name: values.englishName,
          ar_name: values.arabicName,
          code: values.code,
          organisation_id: values.organizationId,
        })
      )
    )
      .then(async ({ action }: any) => {
        const newRouteId = action.payload.data?.response.route.id;
        await handleSaveGroups(newRouteId, values.groups);
        history.push(`${baseURL.replace('/new', '')}/${newRouteId}`);

        dispatch(successAlert(intl.formatMessage({ id: 'successAddRoute' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <RouteForm {...{ initialState, handleSubmit }} />;
};

export { AddRoute };
