import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const basicButtonStyles: CSSProperties = {
  height: 40,
  borderRadius: 30,
  boxShadow: 'none',
  padding: '10px 25px',
  textTransform: 'capitalize',
};

export const actionButtonStyles: CSSProperties = {
  color: '#4B506D',
  backgroundColor: '#fff',
  borderRadius: 30,
  borderColor: '#F7F7F8',
  marginLeft: 22,
  height: 36,
  '& > span': {
    textTransform: 'capitalize',
  },
};

export const mainButtonStyles: CSSProperties = {
  backgroundColor: '#4B506D',
  color: '#fff',
  borderRadius: 30,
  borderColor: '#4B506D',
  marginLeft: 22,
  height: 36,
  '& > span': {
    textTransform: 'capitalize',
  },
  '&:hover': {
    opacity: 0.8,
    backgroundColor: '#4b506D !important',
  },
};

export const tableRowStyles: CSSProperties = {
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#E6E6F1 !important',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#F7F7F8',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#fff',
  },
};
