import React, { useContext, useState } from 'react';

import {
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { deleteStop, getStation } from 'actions';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';
import { useTypedSelector } from 'helpers';
import { canEditOperationsSubSystemEntity } from 'selectors';

import DeleteIcon from './delete-icon.svg';
import EditPenIcon from './edit-icon.svg';
import { StopModal } from './stop-modal';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      margin: '5px 15px 5px 45px',
      alignItems: 'center',
      padding: '5px 7px 5px 5px',
      minWidth: '500px',
      minHeight: '53px',
    },
    item: {
      flex: 1,
      padding: '0px 10px',
      whiteSpace: 'nowrap',
    },
    maxWidth: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

const StopRow = ({ id, en_name, ar_name, code }) => {
  const [showAddStopModal, setShowAddStopModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const match = useRouteMatch<{ id: string }>();

  const dispatch = useDispatch();

  const stationId = match.params.id;

  const classes = useStyles();
  const { locale } = useContext(LanguageContext);
  const isEnglish = locale.toLowerCase() === LOCALES.ENGLISH;

  const entityName = isEnglish ? en_name : ar_name;

  const hasStationWriteAccess = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Stations', stationId)
  );

  return (
    <div className={classes.container}>
      <Typography className={[classes.item, classes.maxWidth].join(' ')}>
        {en_name}
      </Typography>

      <Typography className={[classes.item, classes.maxWidth].join(' ')}>
        {code}
      </Typography>

      <Typography className={[classes.item, classes.maxWidth].join(' ')}>
        {ar_name}
      </Typography>

      {hasStationWriteAccess && (
        <div
          className={classes.item}
          style={{
            flex: 0.8,
            display: 'flex',
            // justifyContent: 'flex-end',
            padding: 0,
          }}
        >
          <IconButton
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setShowAddStopModal(true);
            }}
          >
            <img src={EditPenIcon} alt="Edit" />
          </IconButton>

          <IconButton
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            <img src={DeleteIcon} alt="Delete" />
          </IconButton>
        </div>
      )}

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteStop,
          entityType: 'stop',
          entityName: entityName || ' ',
          entityId: id,
          onSuccess: () => dispatch(getStation(stationId)),
        }}
      />

      <StopModal
        {...{
          isOpen: showAddStopModal,
          setOpen: setShowAddStopModal,
          isEditing: true,
          stopId: id,
        }}
      />
    </div>
  );
};

export default StopRow;
