import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { addRouteAxis, addRouteAxisStops, successAlert } from 'actions';
import { AxisFormState } from 'app/operations/routes/axis-helpers';

import { AxisForm } from './axis-form';

const initialState: AxisFormState = {
  ar_name: '',
  en_name: '',
  cash_price: '',
  card_price: '',
  code: '',
  stops: [],
};

interface AddAxisProps {
  setOpen: (newState: boolean) => void;
}

const AddAxis: React.FC<AddAxisProps> = ({ setOpen }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const match = useRouteMatch<{ id: string }>();

  const routeId = match.params.id;

  const handleSubmit = async (
    values: AxisFormState,
    { setSubmitting }: FormikHelpers<AxisFormState>
  ) => {
    setSubmitting(true);
    try {
      const res: any = await Promise.resolve(
        dispatch(
          addRouteAxis({
            ...values,
            route_id: routeId,
          })
        )
      );

      const axisId = res.value.data.response.id;

      await Promise.resolve(
        dispatch(addRouteAxisStops(axisId, routeId, values.stops))
      );

      setOpen(false);

      dispatch(successAlert(intl.formatMessage({ id: 'successAddAxis' })));
    } finally {
      setSubmitting(false);
    }
  };

  return <AxisForm {...{ initialState, handleSubmit, setOpen }} />;
};

export { AddAxis };
