import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { AddDevice } from 'app/operations/devices/add-device';
import { DeviceList } from 'app/operations/devices/device-list';
import { EditDevice } from 'app/operations/devices/edit-device';
import { ViewDevice } from 'app/operations/devices/view-device';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

const Devices: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasDeviceWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Devices')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasDeviceWriteAccess ? <AddDevice /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          {hasDeviceWriteAccess ? <EditDevice /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewDevice />
        </Route>

        <Route exact path={`${baseURL}`}>
          <DeviceList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { Devices };
