import React, { useState, useContext } from 'react';

import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { LOCALES } from 'app/i18n-locale/locales-constants';
import { mainButtonStyles } from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';

import CheckWhite from 'assets/checkWhite.svg';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContainer: {
      width: 715,
      maxWidth: 715,
      minHeight: 196,
      borderRadius: 10,
      background: '#F7F7F8',
    },
    dialogTitle: {
      fontWeight: 700,
      marginTop: 15,
      marginLeft: 10,
      paddingRight: 250,
      lineHeight: 2,
    },
    dialogActions: {
      padding: '13px 22px',
      background: '#fff',
      borderTop: '1px solid #DFE0EB',
      height: 66,
    },
    spinnerWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    confirmBtn: {
      ...mainButtonStyles,
    },
    deleteBtn: {
      borderColor: '#CE1126',
      backgroundColor: '#CE1126',
      '&:hover': {
        backgroundColor: '#CE1126 !important',
        opacity: 0.8,
      },
    },
    cancelBtn: {
      borderRadius: 30,
      height: 40,
      boxShadow: 'none',
      border: '1px solid #DADCE0',
      color: '#4B506D',
      background: '#fff',
    },
    check: {
      marginRight: 10,
    },
  })
);

interface ActionAlertProps {
  isOpen: boolean;
  setOpen: (newState: boolean) => void;
  title?: string;
  setMultiActionTitle?: (newState: string) => void;
  entityType?: string;
  actionName?: string;
  setActionCall?: (newState: undefined | any) => void;
  onSuccess?: () => void;
  action?: any;
  isDelete?: boolean;
}

const ActionAlert: React.FC<ActionAlertProps> = ({
  isOpen,
  setOpen,
  title,
  setActionCall,
  setMultiActionTitle,
  action,
  entityType,
  actionName,
  onSuccess,
  isDelete,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const { locale } = useContext(LanguageContext);
  const isEnglish = locale.toLowerCase() === LOCALES.ENGLISH;

  const handleRequestFinish = () => {
    setOpen(false);
    setActionCall?.(undefined);
    setMultiActionTitle?.('');
    setLoading(false);
  };

  const renderMultiActionConfirmation = () => (
    <span>
      {`${intl.formatMessage({
        id: `confirm${actionName}Message`,
      })} ${entityType} `}

      <span> {isEnglish ? ' ?' : '؟ '}</span>
    </span>
  );

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="multi-action-alert"
      classes={{ paper: classes.dialogContainer }}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
      }}
    >
      <DialogTitle>
        <Typography className={classes.dialogTitle}>
          {title || (!!actionName && renderMultiActionConfirmation())}
        </Typography>
      </DialogTitle>

      <DialogContent />

      <DialogActions className={classes.dialogActions}>
        {isLoading ? (
          <div className={classes.spinnerWrapper}>
            <CircularProgress size={28} />
          </div>
        ) : (
          <>
            <Button
              onClick={e => {
                e.stopPropagation();
                if (action) {
                  setLoading(true);

                  dispatch(action)
                    .then(() => {
                      onSuccess?.();
                    })
                    .finally(handleRequestFinish);
                }
              }}
              variant="outlined"
              className={`${classes.confirmBtn} ${
                isDelete && classes.deleteBtn
              }`}
            >
              <img src={CheckWhite} alt="check" className={classes.check} />

              {intl.formatMessage({ id: 'confirm' })}
            </Button>

            <Button
              onClick={e => {
                e.stopPropagation();
                setOpen(false);
              }}
              variant="outlined"
              className={classes.cancelBtn}
            >
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { ActionAlert };
