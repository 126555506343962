import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';

import { CardContainer } from 'app/components/shared/card-container';
import { CardTitle } from 'app/components/shared/card-title';

import { EditProfileForm } from './edit-profile-form';
import { EditProfileFormState } from './edit-profile-helpers';

// import { useStyles } from 'app/operations/routes/view-route';

// TODO shall get from getuserinfo api
const initialState: EditProfileFormState = {
  firstName: 'Heba',
  lastName: 'Saleh',
};

const handleSubmit = (
  values: EditProfileFormState,
  { setSubmitting }: FormikHelpers<EditProfileFormState>
) => {
  setSubmitting(true);
  // TODO call edit profile api
  console.log({ values });
};

const EditProfile: React.FC = () => {
  // const classes = useStyles();
  const intl = useIntl();

  return (
    <CardContainer>
      <CardTitle title={intl.formatMessage({ id: 'editMyProfile' })} />

      <EditProfileForm {...{ initialState, handleSubmit }} />
    </CardContainer>
  );
};

export { EditProfile };
