import React, { useCallback, useMemo } from 'react';

import {
  Chip,
  createStyles,
  FormLabel,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FilterOptionsState } from '@material-ui/lab';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import map from 'lodash/map';
import { useIntl } from 'react-intl';

import { useStyles } from 'app/components/shared/groups-menu-multi-select/utils';

export type Value = {
  id: string;
  name: string;
};

const useCustomStyles = makeStyles(() =>
  createStyles({
    autoCompleteOption: {
      padding: '0px 0px',
      margin: '0px 0px',
    },
  })
);

const filter: (options: Value[], params: FilterOptionsState<Value>) => Value[] =
  createFilterOptions();

type ParamTypes = {
  sendSelectedValues: (filterName: string, selectedFilters: string[]) => void;
  values: Value[];
  selected: string[];
  filterName?: string;
  label?: string;
  placeholderText?: string;
  width?: number | null;
  showSelectAll?: boolean;
};

function AutoCompleteInput({
  sendSelectedValues,
  values,
  selected,
  filterName = '',
  label,
  placeholderText = 'all',
  width = null,
  showSelectAll,
}: ParamTypes) {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const intl = useIntl();

  const allSelected = values.length === selected.length;

  const displayLabel = label ? intl.formatMessage({ id: label }) : '';

  const selectedValue = useMemo(
    () => selected.map(id => values.find(value => value.id === id)),
    [selected, values]
  ) as Value[];

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    if (showSelectAll) {
      filtered.unshift({
        id: 'select-all',
        name: intl.formatMessage({ id: 'all' }),
      });
    }

    return [...filtered];
  };

  const handleSelectAll = isSelected => {
    if (isSelected) {
      sendSelectedValues(filterName, map(values, 'id'));
    } else {
      sendSelectedValues(filterName, []);
    }
  };

  const handleChange = (selectedValues: Value[], reason: string) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      if (selectedValues.find(option => option.id === 'select-all')) {
        handleSelectAll(!allSelected);
      } else {
        return sendSelectedValues(filterName, map(selectedValues, 'id'));
      }
    } else if (reason === 'clear') {
      return sendSelectedValues(filterName, []);
    }

    return null;
  };

  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        placeholder={intl.formatMessage({ id: placeholderText })}
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          style: {
            width,
          },
        }}
      />
    ),
    [intl, placeholderText, width]
  );

  const renderTags = useCallback(
    (value, getTagProps) =>
      value?.map((option, index) => (
        <Chip
          key={option?.id}
          variant="outlined"
          label={option?.name}
          classes={{
            deleteIcon: classes.deleteIcon,
            root: classes.chipRoot,
            label: classes.chipLabel,
          }}
          {...getTagProps({ index })}
        />
      )),
    [classes]
  );

  const renderOption = useCallback(
    (value: Value) => {
      const allChecked = allSelected && value?.id === 'select-all'; // To control the state of 'select-all' checkbox

      return (
        <MenuItem divider style={{ width: '100%' }}>
          <ListItemIcon>
            <Checkbox checked={allChecked || selectedValue.includes(value)} />
          </ListItemIcon>

          <ListItemText primary={value?.name} />
        </MenuItem>
      );
    },
    [allSelected, selectedValue]
  );

  return (
    <FormControl fullWidth style={{ marginBottom: 10 }}>
      <FormLabel className={classes.formLabel}>{displayLabel}</FormLabel>

      <Autocomplete
        multiple
        id="auto-complete-input"
        options={values}
        getOptionLabel={option => option?.name || ''}
        disableCloseOnSelect
        value={selectedValue}
        onChange={(__, newValue, reason) => {
          handleChange(newValue, reason);
        }}
        classes={{
          option: customClasses.autoCompleteOption,
          noOptions: classes.whiteBackground,
        }}
        size="small"
        ListboxProps={{
          style: { maxHeight: 224, backgroundColor: '#ffffff', padding: 0 },
        }}
        renderTags={renderTags}
        filterOptions={filterOptions}
        renderInput={renderInput}
        renderOption={renderOption}
      />
    </FormControl>
  );
}

export default AutoCompleteInput;
