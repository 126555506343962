import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, addVehicle } from 'actions';
import { VehicleForm } from 'app/operations/vehicles/vehicle-list/vehicle-form';
import { VehicleFormState } from 'app/operations/vehicles/vehicle-list/vehicle-helpers';
import { trimTrailingSlash } from 'helpers/url-helpers';

const initialState: VehicleFormState = {
  vehicle_type_id: '',
  plateNumber: '',
  code: '',
  modelYear: '',
  groups: [],
  color: '',
  organisationId: '',
  enabled: true,
};

const AddVehicle: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const handleSubmit = (
    values: VehicleFormState,
    { setSubmitting }: FormikHelpers<VehicleFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        addVehicle({
          vehicle_type_id: values.vehicle_type_id,
          plate_number: values.plateNumber,
          code: values.code,
          model_year: values.modelYear,
          group_ids: values.groups,
          color: values.color,
          organisation_id: values.organisationId,
          disabled: !values.enabled,
        })
      )
    )
      .then(({ action }: any) => {
        const newVehicleId = action.payload.data?.response.id;
        history.push(`${baseURL.replace('/new', '')}/${newVehicleId}`);

        dispatch(successAlert(intl.formatMessage({ id: 'successAddVehicle' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <VehicleForm {...{ initialState, handleSubmit }} />;
};

export { AddVehicle };
