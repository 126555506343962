import React, { useState } from 'react';

import {
  Button,
  Fade,
  Popper,
  makeStyles,
  createStyles,
  ClickAwayListener,
  Paper,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import MultiSelect from 'app/components/shared/multi-select';
import { basicButtonStyles, mainButtonStyles } from 'app/shared/styles';
import { useGetFilterOrganizations } from 'hooks';

import { DeviceTypes } from '../device-form';

import CloseIcon from 'assets/close-icon-1.svg';
import FilterIcon from 'assets/filter_alt_black.svg';
import lightFilterIcon from 'assets/filter_alt_white.svg';

export const DevicesFilterParams = {
  organizations: 'organisation_id_in',
  device_type: 'obc_type_in',
};

const useStyles = makeStyles(() =>
  createStyles({
    multiActionButton: {
      ...mainButtonStyles,
      height: 40,
      padding: '4px 18px',
      borderColor: '#DFE4EA',
      '&:hover': {
        opacity: 1,
      },
    },
    paperStyle: {
      width: 364,
      backgroundColor: '#F4F6FB',
      padding: '20px',
      paddingTop: '25px',
      paddingBottom: '25px',
      borderRadius: 15,
      boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.2)',
    },
    popperStyle: {
      marginTop: 6,
      zIndex: 10,
    },
    formLabel: {
      color: '#444',
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 700,
    },
    formControl: {
      marginBottom: 22,
    },
    closeFilters: {
      marginRight: 10,
      cursor: 'pointer',
      '& :hover': {
        opacity: 0.7,
      },
    },
    basicButton: { ...basicButtonStyles },
    filterIcon: {
      marginRight: 10,
    },
    resetButton: {
      color: '#5572FA',
      width: 85,
      height: 37,
      fontSize: 13,
      fontWeight: 700,
    },
  })
);

function Filters({
  filterDevices,
  filters,
  setFilters,
  setAppliedFilters,
  isApplied,
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const id = open ? 'simple-popper' : undefined;
  const classes = useStyles();
  const intl = useIntl();

  const handleClick = event => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const handleClickAway = () => setOpen(false);

  const handleAddedFilters = (
    filterName: string,
    selectedFilters: string[]
  ) => {
    let updatedFilters = { ...filters };
    if (!selectedFilters?.length) {
      delete updatedFilters[filterName];
    } else {
      updatedFilters[filterName] = selectedFilters;
    }

    return setFilters(updatedFilters);
  };

  const organizations = useGetFilterOrganizations({ entity: 'Vehicles' });

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        color="default"
        type="button"
        className={classes.multiActionButton}
        onMouseUp={undefined}
        onMouseDown={handleClick}
        style={{
          backgroundColor: isApplied ? '#4b506D ' : '#fff',
          color: isApplied ? '#fff' : '#4b506D',
        }}
      >
        <img
          src={isApplied ? lightFilterIcon : FilterIcon}
          alt="filter devices"
          className={classes.filterIcon}
        />

        {intl.formatMessage({ id: 'filter' })}
      </Button>

      <Popper
        id={id}
        open={open}
        placement="bottom-start"
        anchorEl={anchorEl}
        transition
        className={classes.popperStyle}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper variant="outlined" className={classes.paperStyle}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span
                    style={{
                      color: '#444',
                      fontSize: 15,
                      fontWeight: 500,
                      paddingBottom: 10,
                    }}
                  >
                    {intl.formatMessage({ id: 'filters' }).toUpperCase()}
                  </span>

                  <span
                    onClick={() => setOpen(false)}
                    onKeyDown={() => setOpen(false)}
                    role="button"
                    tabIndex={0}
                    className={classes.closeFilters}
                  >
                    <img src={CloseIcon} alt="close filters" />
                  </span>
                </div>

                <div style={{ marginTop: 15 }}>
                  <MultiSelect
                    sendSelectedValues={(filterName, selectedFilters) =>
                      handleAddedFilters(filterName, selectedFilters)
                    }
                    selected={
                      filters?.[DevicesFilterParams.organizations] || []
                    }
                    values={organizations}
                    label="organization"
                    filterName={DevicesFilterParams.organizations}
                    addBottomMargin
                  />

                  <MultiSelect
                    sendSelectedValues={(filterName, selectedFilters) =>
                      handleAddedFilters(filterName, selectedFilters)
                    }
                    selected={filters?.[DevicesFilterParams.device_type] || []}
                    values={DeviceTypes}
                    label="type"
                    filterName={DevicesFilterParams.device_type}
                    addBottomMargin
                  />

                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.basicButton}
                      onClick={event => {
                        // call filter api with values in state
                        filterDevices(filters);
                        setAppliedFilters(filters);
                        setOpen(false);
                        event.stopPropagation();
                      }}
                    >
                      {intl.formatMessage({ id: 'apply' })}
                    </Button>

                    {!!Object.values(filters).length && (
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={event => {
                          event.stopPropagation();
                          setFilters({});
                        }}
                        className={classes.resetButton}
                      >
                        {intl.formatMessage({ id: 'reset' })}
                      </Button>
                    )}
                  </div>
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
}

export { Filters };
