import { string as yupString, object as yupObject } from 'yup';

import { Organization } from 'types';

export interface GroupFormState {
  en_name: string;
  ar_name: string;
  code: string;
  organizationId: string;
  organization?: Organization;
}

const GroupFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  return yupObject().shape({
    en_name: yupString().required(validationRequiredMessage),
    ar_name: yupString().required(validationRequiredMessage),
    organizationId: yupString().required(validationRequiredMessage),
    code: yupString().required(validationRequiredMessage),
  });
};

export { GroupFormSchema };
