import {
  string as yupString,
  object as yupObject,
  array as yupArray,
} from 'yup';

import { Organization } from 'types';

export interface VehicleFormState {
  vehicle_type_id: string;
  plateNumber: string;
  code: string;
  modelYear: string;
  groups: string[];
  color: string;
  organisationId: string;
  organization?: Organization;
  enabled: boolean;
  deviceId?: string;
}

const VehicleClassFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const lengthValidationMessage = intl.formatMessage({
    id: 'minLengthOneValidation',
  });

  return yupObject().shape({
    vehicle_type_id: yupString().required(validationRequiredMessage),
    plateNumber: yupString().required(validationRequiredMessage),
    code: yupString().required(validationRequiredMessage),
    modelYear: yupString().required(validationRequiredMessage),
    organisationId: yupString().required(validationRequiredMessage),
    groups: yupArray().min(1, lengthValidationMessage),
  });
};

export { VehicleClassFormSchema };
