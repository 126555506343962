import React, { useContext } from 'react';

import { LinearProgress, Button, InputLabel } from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { CardTitle } from 'app/components/shared/card-title';
import {
  OrganizationFormState,
  validateOrganizationForm,
} from 'app/configuration/organizations/organization-helpers';
import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import { useStyles } from 'app/operations/drivers/driver-form';
import { LanguageContext } from 'context/intl.context';

interface OrganizationForm {
  initialState: OrganizationFormState;
  handleSubmit: (
    values: OrganizationFormState,
    { setSubmitting }: FormikHelpers<OrganizationFormState>
  ) => void;
  isEditForm?: boolean;
}

const OrganizationForm: React.FC<OrganizationForm> = ({
  initialState,
  handleSubmit,
  isEditForm = false,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();

  const { direction } = useContext(LanguageContext);

  const isRTL = direction === DIRECTIONS.RTL;

  return (
    <>
      <CardTitle
        title={intl.formatMessage({
          id: isEditForm ? 'editOrganization' : 'createOrganization',
        })}
      />

      <div className={classes.formWrapper}>
        <Formik
          initialValues={initialState}
          validate={values => validateOrganizationForm(values, intl)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div
                className={classes.fieldGroupWrapper}
                style={{ flexDirection: 'column' }}
              >
                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: isRTL ? 'row' : 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'englishName' })}
                  </InputLabel>

                  <Field
                    name="englishName"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: isRTL ? 'row' : 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'arabicName' })}
                  </InputLabel>

                  <Field
                    name="arabicName"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({ id: 'save' })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => history.go(-1)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({ id: 'cancel' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { OrganizationForm };
