/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-newline */
// @ts-nocheck
import React, { useState } from 'react';

import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useIntl } from 'react-intl';

const StyledAccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, .125)',
    minHeight: 70,
    '&$expanded': {
      minHeight: 70,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      height: 35,
      backgroundColor: '#E6E7F2',
      borderColor: '#E6E7F2',
      color: '#4B506D',
      fontWeight: 600,
      margin: 5,
      borderRadius: 20,
      textTransform: 'none',
      minWidth: 150,
    },
    titleCont: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: 16,
      fontWeight: 700,
      fontStyle: 'Manrope',
      padding: '0px 10px',
    },
    regular: {
      fontWeight: 400,
    },
    loading: {
      color: '#4B506D',
      marginRight: 40,
    },
  })
);

const AccordionSummary: React.FC<{
  title?: string;
  icon?: string;
  ariaControls: string;
  entityName?: string;
  onDenyAllAccess?: () => void;
  onGrantFullControl?: () => void;
  showActions?: boolean;
  variant?: 'primary' | 'secondary';
}> = ({
  title,
  icon,
  ariaControls,
  entityName,
  onDenyAllAccess,
  onGrantFullControl,
  showActions,
  variant,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(false);

  const handleGrantFullControl = async () => {
    setIsLoading(true);
    try {
      await onGrantFullControl?.();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDenyAllAccess = async () => {
    setIsLoading(true);
    try {
      await onDenyAllAccess?.();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledAccordionSummary
      expandIcon={<ExpandMoreIcon style={{ color: 'rgba(75, 80, 109, 1)' }} />}
      aria-controls={ariaControls}
      style={{
        backgroundColor: variant === 'secondary' ? '#F0F0FB' : '#fff',
        borderRadius: variant === 'secondary' ? '10px' : '0px',
      }}
    >
      <div className={classes.titleCont}>
        {icon && <img src={icon} alt="configuration" />}

        <Typography color="textPrimary" className={classes.title}>
          {title}{' '}
          {entityName && <span className={classes.regular}>{entityName}</span>}
        </Typography>
      </div>

      {showActions &&
        (isLoading ? (
          <CircularProgress size={25} className={classes.loading} />
        ) : (
          <div onClick={event => event.stopPropagation()}>
            <Button
              variant="outlined"
              className={classes.chip}
              onClick={handleGrantFullControl}
              onMouseDown={event => {
                event.stopPropagation();
              }}
              disabled={isLoading}
              style={{
                backgroundColor: variant === 'secondary' ? '#fff' : '#E6E7F2',
              }}
            >
              {isLoading ? (
                <CircularProgress size={14} style={{ color: '#4B506D' }} />
              ) : (
                intl.formatMessage({ id: 'grantFullControl' })
              )}
            </Button>

            <Button
              variant="outlined"
              className={classes.chip}
              onMouseDown={event => {
                event.stopPropagation();
              }}
              onClick={handleDenyAllAccess}
              disabled={isLoading}
              style={{
                backgroundColor: variant === 'secondary' ? '#fff' : '#E6E7F2',
              }}
            >
              {isLoading ? (
                <CircularProgress size={14} style={{ color: '#4B506D' }} />
              ) : (
                intl.formatMessage({ id: 'denyAllAccess' })
              )}
            </Button>
          </div>
        ))}
    </StyledAccordionSummary>
  );
};

export { AccordionSummary };
