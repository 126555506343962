export enum driverConstants {
  GET_DRIVERS = 'GET_DRIVERS',
  GET_DRIVER = 'GET_DRIVER',
  ADD_DRIVER = 'ADD_DRIVER',
  EDIT_DRIVER = 'EDIT_DRIVER',
  DELETE_DRIVER = 'DELETE_DRIVER',
  DELETE_DRIVERS = 'DELETE_DRIVERS',
  CHANGE_DRIVERS_STATUS = 'CHANGE_DRIVERS_STATUS',
  ADD_TO_GROUPS = 'ADD_TO_GROUPS',
  CLEAR_GROUPS = 'CLEAR_GROUPS',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  REGENERATE_DRIVER_PASSWORD = 'REGENERATE_DRIVER_PASSWORD',
  IMPORT_DRIVERS = 'IMPORT_DRIVERS',
}
