import React, { useEffect, useContext, useState, useCallback } from 'react';

import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { deleteAdmin, getAdmins } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { StatusCell, TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { TableRowActions } from 'app/components/table-row-actions';
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { formatDateTime, trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

import { FilterAdmins } from './filter-admins';

const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 650, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
  })
);

const AdminList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);
  const [isFiltering, setFiltering] = useState(false);
  const { direction } = useContext(LanguageContext);

  const [filterParams, setFilterParams] = useState<{ [key: string]: string }>(
    {}
  );

  const fetchFilterResults = useCallback(() => {
    let showDisabledOnly: boolean | undefined;
    if (filterParams?.statuses?.length === 1) {
      showDisabledOnly = filterParams?.statuses?.includes('disabled');
    }

    return getAdmins({
      disabled_in: [showDisabledOnly],
      is_super_admin_eq:
        filterParams?.is_super_admin?.length === 1 ? true : undefined,
    });
  }, [filterParams]);

  const fetchAction = useCallback(() => {
    if (isFiltering) return fetchFilterResults;

    return getAdmins;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdmins, isFiltering, filterParams]);

  const filterAdmins = filters => {
    if (Object.values(filters)?.length) {
      setFiltering(true);
      setSearching(false);
      setSearchTerm('');
    }
    // eslint-disable-next-line no-restricted-syntax
    if (!isEqual(filters, filterParams)) {
      setFilterParams(filters);
    }
  };

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getAdmins({
          full_name_or_username_or_phone_or_email_cont: searchTerm,
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }
  };

  const adminListIsLoading = useTypedSelector(
    state => state.admins.isLoading as boolean
  );

  const adminListHasError = useTypedSelector(state => state.admins.hasError);

  const adminList = useTypedSelector(state => {
    if (!state.admins.isLoading && Object.values(state.admins).length) {
      return Object.values(state.admins.collection).sort((a, b) =>
        a.username.localeCompare(b.username)
      );
    }

    return [];
  });

  const hasAdminsWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Admins')
  );

  useEffect(() => {
    if (!isSearching) {
      const action = fetchAction();
      dispatch(action());
    }
  }, [dispatch, fetchAction, isSearching]);

  return (
    <div dir={direction}>
      <CardTitle title={intl.formatMessage({ id: 'admins' })}>
        {hasAdminsWriteAccess && (
          <Button
            component={Link}
            to={`${baseURL}/new`}
            variant="contained"
            className={classes.addButton}
            color="secondary"
          >
            {intl.formatMessage({ id: 'addAdmin' })}
          </Button>
        )}
      </CardTitle>

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterAdmins
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
              showFilters: !!adminList.length && !isSearching,
              filterAdmins,
              isSearching,
            }}
          />
        </Grid>
      </Grid>

      <DataTableContainer
        alignItems={adminListIsLoading ? 'center' : 'flex-start'}
      >
        {adminListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            Sorry, something went wrong!
          </Typography>
        ) : (
          <>
            {adminListIsLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {adminList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Admins Table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {intl.formatMessage({ id: 'name' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'userName' })}
                        </TableCell>

                        <TableCell>
                          {intl.formatMessage({ id: 'status' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'lastLogin' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'actions' })}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {adminList.map(admin => (
                        <TableRow
                          onClick={() => {
                            history.push(`${baseURL}/${admin.id}`);
                          }}
                          key={admin.id}
                          className={classes.row}
                          hover
                        >
                          <TextCell
                            align="center"
                            label={`${admin.first_name} ${admin.last_name}`}
                            maxWidth={200}
                          />

                          <TextCell
                            align="center"
                            label={admin.username}
                            maxWidth={200}
                          />

                          <StatusCell isDisabled={admin.disabled} />

                          <TextCell
                            align="center"
                            label={
                              admin.last_sign_in_at
                                ? formatDateTime(admin.last_sign_in_at)
                                : ''
                            }
                            maxWidth={100}
                          />

                          <TableRowActions
                            hasWriteAccess={hasAdminsWriteAccess}
                            editPath={`${baseURL}/${admin.id}/edit`}
                            entityId={admin.id}
                            entityName={`${admin.first_name} ${admin.last_name}`}
                            entityType="admin"
                            deleteAction={deleteAdmin}
                          />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={hasAdminsWriteAccess}
                      baseURL={baseURL}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>
    </div>
  );
};

export { AdminList };
