import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, addDriver } from 'actions';
import { DriverForm } from 'app/operations/drivers/driver-form';
import { DriverFormState } from 'app/operations/drivers/driver-helpers';
import { trimTrailingSlash } from 'helpers/url-helpers';

const initialState: DriverFormState = {
  firstName: '',
  lastName: '',
  phone: '',
  licenseNumber: '',
  nationalId: '',
  organizationId: '',
  groups: [],
  status: false,
};

const AddDriver: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const handleSubmit = (
    values: DriverFormState,
    { setSubmitting }: FormikHelpers<DriverFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        addDriver({
          first_name: values.firstName,
          last_name: values.lastName,
          phone: values.phone,
          license_number: values.licenseNumber,
          national_id: values.nationalId,
          organisation_id: values.organizationId,
          group_ids: values.groups,
        })
      )
    )
      .then(({ action }: any) => {
        const newDriverId = action.payload.data?.response.driver.id;
        history.push(`${baseURL.replace('/new', '')}/${newDriverId}`);

        dispatch(successAlert(intl.formatMessage({ id: 'successAddDriver' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <DriverForm {...{ initialState, handleSubmit }} />;
};

export { AddDriver };
