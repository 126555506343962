import React, { useMemo } from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  addRouteAxisStops,
  editBaseFare,
  editRouteAxis,
  successAlert,
} from 'actions';
import { AxisFormState } from 'app/operations/routes/axis-helpers';
import { useTypedSelector } from 'helpers';
import { getRouteAxis } from 'selectors';
import { Axis } from 'types';

import { AxisForm } from './axis-form';

interface EditAxisProps {
  setOpen: (newState: boolean) => void;
  axisId: string;
}

const EditAxis: React.FC<EditAxisProps> = ({ setOpen, axisId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const match = useRouteMatch<{ id: string }>();

  const routeId = match.params.id;

  const axis = useTypedSelector(
    state => getRouteAxis(state, { routeId, axisId }) as Axis | undefined
  );

  const baseFareId = axis?.base_fare?.id || '';

  const initialState: AxisFormState = useMemo(
    () => ({
      ar_name: axis?.ar_name || '',
      en_name: axis?.en_name || '',
      cash_price: axis?.base_fare?.cash_price || '',
      card_price: axis?.base_fare?.card_price || '',
      code: axis?.code || '',
      stops: axis?.stops?.map((stop: any) => stop.id) || [],
    }),
    [axis]
  );

  const handleSubmit = async (
    values: AxisFormState,
    { setSubmitting }: FormikHelpers<AxisFormState>
  ) => {
    setSubmitting(true);
    try {
      await Promise.resolve(
        dispatch(
          editRouteAxis(axisId, {
            ...values,
            route_id: routeId,
          })
        )
      );

      await Promise.resolve(
        dispatch(
          editBaseFare(baseFareId, routeId, {
            cash_price: values.cash_price,
            card_price: values.card_price,
          })
        )
      );

      await Promise.resolve(
        dispatch(addRouteAxisStops(axisId, routeId, values.stops))
      );

      setOpen(false);

      dispatch(successAlert(intl.formatMessage({ id: 'successEditAxis' })));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AxisForm {...{ initialState, handleSubmit, setOpen, isEditForm: true }} />
  );
};

export { EditAxis };
