import React, { useRef, useState, useContext } from 'react';

import {
  Button,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logout } from 'actions';
import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';
import { useTypedSelector } from 'helpers';

import DropdownIcon from './dropdown-icon.svg';
import LockIcon from './lock-icon.svg';
import LogoutIcon from './logout-icon.svg';
// import ProfileIcon from './profile-icon.svg';

import userAvatar from 'assets/user-avatar-placeholder.svg';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: { display: 'flex', alignItems: 'center' },
    button: { fontSize: 14, fontWeight: 600, lineHeight: '16px' },
    avatar: { marginLeft: 8, marginRight: 12 },
    paper: {
      width: '100%',
      borderRadius: 10,
      background: '#fff',
      border: '1px solid #CED6E0',
    },
    list: { padding: '23px 21px' },
    menuItem: {
      width: '100%',
      fontSize: 14,
      fontWeight: 400,
      paddingLeft: 4,
    },
    menuItemIcon: { marginRight: 11 },
  })
);

const UserDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const { direction } = useContext(LanguageContext);
  const history = useHistory();

  const anchorEl = useRef<null | HTMLDivElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const username = useTypedSelector(
    state =>
      `${state.authentication?.user.userData.first_name} ${state.authentication?.user.userData.last_name}`
  );

  return (
    <div ref={anchorEl} className={classes.wrapper}>
      <Button
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!isDropdownOpen)}
        className={classes.button}
      >
        {username}

        <img src={DropdownIcon} alt="icon" className={classes.avatar} />

        <img src={userAvatar} alt="Avater placeholder" />
      </Button>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl?.current}
        keepMounted
        open={isDropdownOpen}
        onClose={() => setDropdownOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === DIRECTIONS.RTL ? 'right' : 'left',
        }}
        getContentAnchorEl={null}
        style={{
          width: 220,
          right: direction === DIRECTIONS.RTL ? '0px !important' : 'initial',
        }}
        classes={{ paper: classes.paper, list: classes.list }}
      >
        {/* <MenuItem
          onClick={() => history.push('/operations/edit-profile')}
          className={classes.menuItem}
        >
          <img
            src={ProfileIcon}
            alt="editProfile"
            className={classes.menuItemIcon}
          />

          {intl.formatMessage({ id: 'editProfile' })}
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            setDropdownOpen(false);
            history.push('/operations/change-password');
          }}
          className={classes.menuItem}
        >
          <img
            src={LockIcon}
            alt="changePassword"
            className={classes.menuItemIcon}
          />

          {intl.formatMessage({ id: 'changePassword' })}
        </MenuItem>

        <MenuItem
          onClick={() => dispatch(logout())}
          className={classes.menuItem}
        >
          <img src={LogoutIcon} alt="logout" className={classes.menuItemIcon} />

          {intl.formatMessage({ id: 'logout' })}
        </MenuItem>
      </Menu>
    </div>
  );
};

export { UserDropdown };
