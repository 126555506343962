import React from 'react';

import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { Admins } from 'app/admin-management/admins';
import { NotAuthorized } from 'app/components/not-authorized';
import { PageNotFound } from 'app/components/page-not-found';
import { SystemHeader, SystemIcon } from 'app/components/shared/system-header';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canViewAdminsSubSystems } from 'selectors';

const AdminManagement: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const intl = useIntl();

  const canViewAdmins = useTypedSelector(state =>
    canViewAdminsSubSystems(state, 'Admins')
  );

  return (
    <>
      <SystemHeader
        headerTitle={intl.formatMessage({ id: 'adminManagement' })}
        headerIcon={SystemIcon.Configurations}
      />

      <Switch>
        <Redirect from={baseURL} exact to={`${baseURL}/admins`} />

        <Route path={`${baseURL}/admins`}>
          {canViewAdmins ? <Admins /> : <NotAuthorized />}
        </Route>

        <Route path="/">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};

export { AdminManagement };
