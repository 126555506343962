const LOCALES = {
  ENGLISH: 'en-us',
  ARABIC: 'ar-eg',
};

const LanguageLabels = {
  'en-us': 'English',
  'ar-eg': 'عربي',
};

const DIRECTIONS = {
  RTL: 'rtl',
  LTR: 'ltr',
};

export { LOCALES, LanguageLabels, DIRECTIONS };
