import React, { useState, useCallback, useContext } from 'react';

import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { deleteGroup, getGroups, deleteMultipleGroups } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { ActionAlert } from 'app/components/shared/on-action-alert';
import { TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { TableRowActions } from 'app/components/table-row-actions';
import {
  PaginationComponent,
  usePagination,
} from 'app/components/use-pagination';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import {
  actionButtonStyles,
  basicButtonStyles,
  tableRowStyles,
} from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canAddNewEntity,
  canEditOperationsSubSystemEntity,
  canEditOperationsSubSystemEntities,
} from 'selectors';
import { Group } from 'types';

import { ReactComponent as CheckBoxIcon } from './check_box.svg';
import { FilterGroupByName } from './filter-group';
import { ReactComponent as UnselectIcon } from './unselect-icon.svg';

import ExportIcon from 'assets/export-icon.svg';
import ImportIcon from 'assets/import-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 1000,
      overflow: 'scroll',
      whiteSpace: 'nowrap',
    },
    basicButton: { ...basicButtonStyles },
    multiActionButton: {
      ...actionButtonStyles,
      height: 27,
      padding: '4px 18px',
    },
    dataButton: { marginRight: 10, display: 'none' },
    row: { ...tableRowStyles },
    selectedTableHead: {
      backgroundColor: '#4b506d !important',
      width: '100%',
      padding: 6,
    },
  })
);

interface GroupListItemProps {
  group: Group;
  isSelected: boolean;
  toggleSelection: (id: string) => void;
}

const GroupListItem = ({
  group,
  isSelected,
  toggleSelection,
}: GroupListItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const { locale } = useContext(LanguageContext);

  const isArabic = locale?.toLowerCase() === LOCALES.ARABIC;

  const parentOrganization = getTranslated(group?.organisation, locale);

  const hasEditPermissionOnOrganizationGroup = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Groups', group.id)
  );

  return (
    <TableRow
      onClick={() => {
        history.push(`${baseURL}/${group.id}`);
      }}
      className={classes.row}
      hover
      selected={isSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            toggleSelection(group.id);
          }}
          checkedIcon={
            <CheckBoxIcon
              fill="#0fa66d"
              style={{
                backgroundColor: '#fff',
                borderRadius: 3,
              }}
            />
          }
        />
      </TableCell>

      <TextCell label={group.en_name} maxWidth={150} />

      <TextCell
        style={{ fontFamily: 'Cairo' }}
        label={group.ar_name}
        maxWidth={150}
      />

      <TextCell label={group?.code || ''} maxWidth={150} />

      <TextCell label={parentOrganization} maxWidth={150} />

      <TableRowActions
        hasWriteAccess={hasEditPermissionOnOrganizationGroup}
        editPath={`${baseURL}/${group.id}/edit`}
        entityId={group.id}
        entityName={isArabic ? group.ar_name : group.en_name}
        entityType="group"
        deleteAction={deleteGroup}
      />
    </TableRow>
  );
};

const GroupList: React.FC = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const baseURL = trimTrailingSlash(match.url);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);
  const [actionCall, setActionCall] = useState(undefined);

  const [isActionConfirmationModalOpen, setActionConfirmationModalOpen] =
    useState(false);

  const [multiActionTitle, setMultiActionTitle] = useState('');

  const intl = useIntl();

  const hasEditPermissionOnSelectedGroups = useTypedSelector(state =>
    canEditOperationsSubSystemEntities(state, 'Groups', selectedItems)
  );

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getGroups(page, rowSize, {
        en_name_or_ar_name_or_code_cont: searchTerm,
      }),
    [searchTerm]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    return getGroups;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  const hasGroupWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Groups')
  );

  const groupListIsLoading = useTypedSelector(
    state => state.groups.isLoading as boolean
  );

  const groupListHasError = useTypedSelector(state => state.groups.hasError);
  const groupsTotalCount = useTypedSelector(state => state.groups.totalCount);

  const groupList = useTypedSelector(state => {
    if (
      !state.groups.isLoading &&
      Object.values(state.groups.collection).length
    ) {
      return Object.values(state.groups.collection);
    }

    return [];
  });

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    if (selectedItems?.length) {
      setSelectedItems([]);
    }

    handleChangePage(null, 0);
  };

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getGroups(page, rowsPerPage, {
          en_name_or_ar_name_or_code_cont: searchTerm,
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const toggleSelection = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems([...selectedItems.filter(item => item !== id)]);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const isAllListSelected =
    groupList.length && selectedItems.length === groupList.length;

  const toggleSelectAll = () => {
    if (isAllListSelected) {
      setSelectedItems([]);
    } else {
      const listToBeSelected = groupList.map(group => group.id);
      setSelectedItems(listToBeSelected);
    }
  };

  const onChangePage = (event: unknown, newPage: number) => {
    if (page !== newPage) {
      setSelectedItems([]);
    }

    return handleChangePage(event, newPage);
  };

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: groupsTotalCount,
        hasData: !!groupList.length,
        rowsPerPage,
        page,
        handleChangePage: onChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );

  const RenderActionButton = ({ title, onClick }) => (
    <Button
      variant="contained"
      color="default"
      type="button"
      className={classes.multiActionButton}
      onClick={() => {
        setMultiActionTitle(title);
        setActionConfirmationModalOpen(true);
        setActionCall(onClick);
      }}
    >
      {intl.formatMessage({ id: title })}
    </Button>
  );

  const EnhancedTableToolbar = () => {
    if (selectedItems?.length) {
      return (
        <div className={classes.selectedTableHead}>
          <Checkbox
            checkedIcon={
              isAllListSelected ? (
                <CheckBoxIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              ) : (
                <UnselectIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              )
            }
            checked={!!selectedItems?.length}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setSelectedItems([]);
            }}
          />

          {hasEditPermissionOnSelectedGroups && (
            <RenderActionButton
              title="delete"
              onClick={() => deleteMultipleGroups(selectedItems)}
            />
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <CardTitle title={intl.formatMessage({ id: 'groups' })}>
        <div>
          <Button
            variant="outlined"
            className={`${classes.basicButton} ${classes.dataButton}`}
          >
            <img
              src={ImportIcon}
              alt="export groups"
              style={{ marginRight: 10 }}
            />

            {intl.formatMessage({ id: 'import' })}
          </Button>

          <Button
            variant="outlined"
            className={`${classes.basicButton} ${classes.dataButton}`}
          >
            <img
              src={ExportIcon}
              alt="export groups"
              style={{ marginRight: 10 }}
            />

            {intl.formatMessage({ id: 'export' })}
          </Button>

          {hasGroupWriteAccess && (
            <Button
              component={Link}
              to={`${baseURL}/new`}
              variant="contained"
              color="secondary"
              className={classes.basicButton}
            >
              {intl.formatMessage({ id: 'addGroup' })}
            </Button>
          )}
        </div>
      </CardTitle>

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterGroupByName
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
            }}
          />
        </Grid>

        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <EnhancedTableToolbar />

      <ActionAlert
        {...{
          isOpen: isActionConfirmationModalOpen,
          setOpen: setActionConfirmationModalOpen,
          setMultiActionTitle,
          setActionCall,
          action: actionCall,
          entityType: 'groups',
          actionName: multiActionTitle,
          onSuccess: () => {
            setSelectedItems([]);
            dispatch(fetchAction()(page, rowsPerPage));
          },
        }}
      />

      <DataTableContainer
        alignItems={groupListIsLoading ? 'center' : 'flex-start'}
      >
        {groupListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {groupListIsLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {groupList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Groups Table"
                  >
                    <TableHead>
                      {!selectedItems?.length && (
                        <TableRow
                          style={{ backgroundColor: '#4b506d !important' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={!!isAllListSelected}
                              onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                                toggleSelectAll();
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'englishName' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'arabicName' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'code' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'organization' })}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>

                    <TableBody>
                      {groupList.map(group => (
                        <GroupListItem
                          key={group.id}
                          {...{ group, toggleSelection }}
                          isSelected={selectedItems.includes(group.id)}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={hasGroupWriteAccess}
                      baseURL={baseURL}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>

      <Pagination />
    </>
  );
};

export { GroupList };
