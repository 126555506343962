import React, { ReactNode } from 'react';

import { createStyles, makeStyles, TableContainer } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 22,
    },
  })
);

export const DataTableContainer: React.FC<{
  children: ReactNode;
  alignItems?: string;
}> = ({ children, alignItems }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} style={{ alignItems }}>
      {children}
    </TableContainer>
  );
};
