import React, { useContext, useEffect, useRef } from 'react';

import { LinearProgress, Button, InputLabel } from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { CardTitle } from 'app/components/shared/card-title';
import { useStyles } from 'app/operations/drivers/driver-form';
import {
  VehicleClassFormState,
  VehicleClassFormSchema,
} from 'app/operations/vehicles/vehicle-classes/vehicle-class-helpers';
import { LanguageContext } from 'context/intl.context';

interface VehicleClassForm {
  initialState: VehicleClassFormState;
  handleSubmit: (
    values: VehicleClassFormState,
    { setSubmitting }: FormikHelpers<VehicleClassFormState>
  ) => void;
  isEditForm?: boolean;
}

const VehicleClassForm: React.FC<VehicleClassForm> = ({
  initialState,
  handleSubmit,
  isEditForm = false,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const { direction } = useContext(LanguageContext);

  const formikRef = useRef<FormikProps<VehicleClassFormState>>(null);

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [formikRef, direction]);

  return (
    <>
      <CardTitle
        title={intl.formatMessage({
          id: isEditForm ? 'editVehicleClass' : 'addVehicleClass',
        })}
      />

      <div className={classes.formWrapper}>
        <Formik
          innerRef={formikRef}
          initialValues={initialState}
          validationSchema={() => VehicleClassFormSchema(intl)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={classes.fieldGroupWrapper}>
                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    <span>{intl.formatMessage({ id: 'englishName' })}</span>
                  </InputLabel>

                  <Field
                    name="en_name"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    <span>{intl.formatMessage({ id: 'arabicName' })}</span>
                  </InputLabel>

                  <Field
                    name="ar_name"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'averageSpeed' })}
                  </InputLabel>

                  <Field
                    name="avg_speed"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({
                    id: isEditForm ? 'update' : 'addVehicleClass',
                  })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => history.go(-1)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({
                    id: 'cancel',
                  })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { VehicleClassForm };
