import React, { useState } from 'react';

import { IconButton, TableCell } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { DeleteAlert } from 'app/components/shared/delete-alert';

import DeleteIcon from './delete-icon.svg';
import EditIcon from './edit-icon.svg';

interface TablerowActionsProps {
  hasWriteAccess: boolean;
  editPath: string;
  entityId: string;
  entityName: string;
  entityType: string;
  deleteAction: (id: string) => (dispatch: Dispatch) => FluxStandardAction;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
}

export const TableRowActions: React.FC<TablerowActionsProps> = ({
  hasWriteAccess,
  editPath,
  entityId,
  entityName,
  entityType,
  deleteAction,
  align,
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <TableCell
      align={align || 'center'}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
      }}
    >
      {hasWriteAccess && (
        <>
          <IconButton
            component={Link}
            onClick={(event: React.MouseEvent) => event.stopPropagation()}
            to={editPath}
          >
            <img src={EditIcon} alt="Edit" />
          </IconButton>

          <IconButton
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            <img src={DeleteIcon} alt="Delete" />
          </IconButton>

          <DeleteAlert
            {...{
              isOpen: isDeleteModalOpen,
              setOpen: setDeleteModalOpen,
              deleteAction,
              entityType,
              entityName,
              entityId,
            }}
          />
        </>
      )}
    </TableCell>
  );
};
