import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, getVehicleClass, editVehicleClass } from 'actions';
import { NotAuthorized } from 'app/components/not-authorized';
import { VehicleClassForm } from 'app/operations/vehicles/vehicle-classes/vehicle-class-form';
import { VehicleClassFormState } from 'app/operations/vehicles/vehicle-classes/vehicle-class-helpers';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { VehicleClass } from 'types';

const EditVehicleClass: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const [isLoading, setLoading] = useState(true);
  const intl = useIntl();

  const vehicleClassId = match.params.id;

  const vehicleData = useTypedSelector(
    state =>
      state.vehicleClasses.collection[vehicleClassId] as
        | VehicleClass
        | undefined
  );

  useEffect(() => {
    if (vehicleData) {
      setLoading(false);
    } else {
      dispatch(getVehicleClass(vehicleClassId));
    }
  }, [dispatch, vehicleData, vehicleClassId]);

  const initialState: VehicleClassFormState = useMemo(
    () => ({
      en_name: vehicleData?.en_name || '',
      ar_name: vehicleData?.ar_name || '',
      avg_speed: vehicleData?.avg_speed || '',
    }),
    [vehicleData]
  );

  const hasWriteAccessToCurrentVehicleClass = true;

  const handleSubmit = async (
    values: VehicleClassFormState,
    { setSubmitting }: FormikHelpers<VehicleClassFormState>
  ) => {
    setSubmitting(true);
    try {
      await Promise.resolve(
        dispatch(
          editVehicleClass(vehicleClassId, {
            en_name: values.en_name,
            ar_name: values.ar_name,
            avg_speed: values.avg_speed,
          })
        )
      );

      await Promise.resolve(dispatch(getVehicleClass(vehicleClassId)));

      history.push(`${baseURL}`.replace('edit', ''));

      dispatch(
        successAlert(intl.formatMessage({ id: 'successEditVehicleClass' }))
      );
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!hasWriteAccessToCurrentVehicleClass) {
    return <NotAuthorized />;
  }

  return (
    <VehicleClassForm {...{ initialState, handleSubmit, isEditForm: true }} />
  );
};

export { EditVehicleClass };
