import React, { useState } from 'react';

import {
  Button,
  Fade,
  Popper,
  makeStyles,
  createStyles,
  ClickAwayListener,
  Paper,
} from '@material-ui/core';
import { Moment } from 'moment';
import { useIntl } from 'react-intl';

import { getGroups } from 'actions';
import AsyncMultiSelect from 'app/components/shared/async-multi-select';
import { FiltersDateRangeSelector } from 'app/components/shared/filter-date-range-selectors';
import FilterTextField from 'app/components/shared/filter-text-field';
import { basicButtonStyles, mainButtonStyles } from 'app/shared/styles';

import CloseIcon from './close-icon.svg';
import FilterIcon from './filter_alt_black.svg';
import lightFilterIcon from './filter_alt_white.svg';

export const TripSalesFilterParams = {
  driver: 'driver_username_or_driver_first_name_or_driver_last_name_cont_any',
  axis: 'axis_code_cont_any',
  vehicle: 'vehicle_code_cont_any',
  groups: 'groups',
  minDate: 'created_at_gteq',
  maxDate: 'created_at_lteq',
};

const useStyles = makeStyles(() =>
  createStyles({
    multiActionButton: {
      ...mainButtonStyles,
      height: 40,
      padding: '4px 18px',
      borderColor: '#DFE4EA',
      '&:hover': {
        opacity: 1,
      },
    },
    paperStyle: {
      width: 364,
      backgroundColor: '#F4F6FB',
      padding: '20px',
      paddingTop: '25px',
      paddingBottom: '25px',
      borderRadius: 15,
      boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.2)',
    },
    popperStyle: {
      marginTop: 6,
      zIndex: 10,
    },
    closeFilters: {
      marginRight: 10,
      cursor: 'pointer',
      '& :hover': {
        opacity: 0.7,
      },
    },
    basicButton: { ...basicButtonStyles },
    filterIcon: {
      marginRight: 10,
    },
    resetButton: {
      color: '#5572FA',
      width: 85,
      height: 37,
      fontSize: 13,
      fontWeight: 700,
    },
  })
);

function Filters({
  filterTripSales,
  filters,
  setFilters,
  setAppliedFilters,
  isApplied,
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const id = open ? 'simple-popper' : undefined;
  const classes = useStyles();
  const intl = useIntl();

  const handleClick = event => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const handleClickAway = () => setOpen(false);

  const handleAddedFilters = (
    filterName: string,
    selectedFilters: string[] | string | undefined | Moment
  ) => {
    let updatedFilters = { ...filters };
    if (
      (Array.isArray(selectedFilters) && !selectedFilters?.length) ||
      !selectedFilters
    ) {
      delete updatedFilters[filterName];
    } else {
      updatedFilters[filterName] = selectedFilters;
    }

    return setFilters(updatedFilters);
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        color="default"
        type="button"
        className={classes.multiActionButton}
        onMouseUp={undefined}
        onMouseDown={handleClick}
        style={{
          backgroundColor: isApplied ? '#4b506D ' : '#fff',
          color: isApplied ? '#fff' : '#4b506D',
        }}
      >
        <img
          src={isApplied ? lightFilterIcon : FilterIcon}
          alt="filter drivers"
          className={classes.filterIcon}
        />

        {intl.formatMessage({ id: 'filter' })}
      </Button>

      <Popper
        id={id}
        open={open}
        placement="bottom-start"
        anchorEl={anchorEl}
        transition
        className={classes.popperStyle}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent="onMouseDown"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper variant="outlined" className={classes.paperStyle}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span
                    style={{
                      color: '#444',
                      fontSize: 15,
                      fontWeight: 500,
                      paddingBottom: 10,
                    }}
                  >
                    {intl.formatMessage({ id: 'filters' }).toUpperCase()}
                  </span>

                  <span
                    onClick={() => setOpen(false)}
                    onKeyDown={() => setOpen(false)}
                    role="button"
                    tabIndex={0}
                    className={classes.closeFilters}
                  >
                    <img src={CloseIcon} alt="close filters" />
                  </span>
                </div>

                <div style={{ marginTop: 15 }}>
                  <FilterTextField
                    placeholder="أحمد توفيق 5217, عبدالعزيز محمود 2219"
                    label="driver"
                    sendSelectedOptions={(filterName, selectedValue) =>
                      handleAddedFilters(filterName, selectedValue)
                    }
                    filterName={TripSalesFilterParams.driver}
                    value={filters[TripSalesFilterParams.driver]}
                  />

                  <FilterTextField
                    placeholder="NC1, NC2"
                    label="axis"
                    sendSelectedOptions={(filterName, selectedValue) =>
                      handleAddedFilters(filterName, selectedValue)
                    }
                    filterName={TripSalesFilterParams.axis}
                    value={filters[TripSalesFilterParams.axis]}
                  />

                  <FilterTextField
                    placeholder="347, 546"
                    label="vehicle"
                    sendSelectedOptions={(filterName, selectedValue) =>
                      handleAddedFilters(filterName, selectedValue)
                    }
                    filterName={TripSalesFilterParams.vehicle}
                    value={filters[TripSalesFilterParams.vehicle]}
                  />

                  <AsyncMultiSelect
                    sendSelectedOptions={(filterName, selectedFilters) =>
                      handleAddedFilters(filterName, selectedFilters)
                    }
                    selected={filters?.[TripSalesFilterParams.groups] || []}
                    label="group"
                    entity={TripSalesFilterParams.groups}
                    action={() => getGroups(0, 100)}
                    addBottomMargin
                  />

                  <FiltersDateRangeSelector
                    minDateValue={filters?.[TripSalesFilterParams.minDate]}
                    maxDateValue={filters?.[TripSalesFilterParams.maxDate]}
                    minDateFilterName={TripSalesFilterParams.minDate}
                    maxDateFilterName={TripSalesFilterParams.maxDate}
                    onChangeMinDate={(filterName, date) =>
                      handleAddedFilters(filterName, date)
                    }
                    onChangeMaxDate={(filterName, date) =>
                      handleAddedFilters(filterName, date)
                    }
                  />

                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.basicButton}
                      onClick={event => {
                        // call filter api with values in state
                        filterTripSales(filters);
                        setAppliedFilters(filters);
                        setOpen(false);
                        event.stopPropagation();
                      }}
                    >
                      {intl.formatMessage({ id: 'apply' })}
                    </Button>

                    {!!Object.values(filters).length && (
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={event => {
                          event.stopPropagation();
                          setFilters({});
                        }}
                        className={classes.resetButton}
                      >
                        {intl.formatMessage({ id: 'reset' })}
                      </Button>
                    )}
                  </div>
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
}

export { Filters };
