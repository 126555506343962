import React, { useContext, useEffect, useState } from 'react';

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TableHead,
  Chip,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { getStation, deleteStation } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { useStyles } from 'app/components/shared/styles/view-entity';
import { StatusCell } from 'app/components/shared/table';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithReadPermission,
} from 'selectors';
import { Station, Stop } from 'types';

import { StopModal } from './stop-modal';
import StopRow from './stop-row';

import DeleteIcon from 'assets/delete-icon.svg';
import EditIcon from 'assets/edit-icon.svg';

const ViewStation: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showAddStopModal, setShowAddStopModal] = useState(false);

  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);

  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const stationId = match.params.id;

  const station = useTypedSelector(
    state => state.stations.collection[stationId] as Station | undefined
  );

  const fullName = getTranslated(station, locale);

  const hasStationWriteAccess = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Stations', stationId)
  );

  const parentOrganization = getTranslated(station?.organisation, locale);

  const groups = useTypedSelector(state =>
    getEntityGroupsWithReadPermission(state, 'Stations', stationId)
  );

  // eslint-disable-next-line consistent-return
  const renderCardActions = () => {
    if (hasStationWriteAccess)
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.editButton}
            component={Link}
            to={`${baseURL}/edit`}
          >
            <img
              src={EditIcon}
              alt="Edit"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'edit' })}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            className={`${classes.editButton} ${classes.deleteButton}`}
            component={Button}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'delete' })}
          </Button>
        </>
      );
  };

  useEffect(() => {
    (async () => {
      await dispatch(getStation(stationId));
      setLoading(false);
    })();
  }, [dispatch, stationId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.viewContainer}>
      <CardTitle title={intl.formatMessage({ id: 'stationInfo' })} />

      <div className={classes.infoWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" aria-label="Station Info Table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeadCell}>
                  {fullName}
                </TableCell>

                <TableCell
                  style={{ textAlign: 'end' }}
                  className={classes.tableHeadCell}
                >
                  {renderCardActions()}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'englishName' })}
                </TableCell>

                <TableCell>{station?.en_name}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'arabicName' })}
                </TableCell>

                <TableCell>{station?.ar_name}</TableCell>
              </TableRow>

              <TableRow className={classes.row} style={{ paddingTop: 10 }}>
                <TableCell>
                  {intl.formatMessage({ id: 'organization' })}
                </TableCell>

                <TableCell>{parentOrganization}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'status' })}</TableCell>

                <StatusCell isDisabled={station?.disabled} showLabel />
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'groups' })}</TableCell>

                <TableCell align="center" className={classes.groupsContainer}>
                  {groups?.length
                    ? groups.map(elem => {
                        if (elem.en_name) {
                          return (
                            <Chip
                              label={getTranslated(elem, locale)}
                              variant="outlined"
                              key={elem.id}
                              className={classes.groupNameStyle}
                            />
                          );
                        }

                        return null;
                      })
                    : null}
                </TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'stops' })}</TableCell>

                <TableCell align="center">
                  {hasStationWriteAccess && (
                    <Button
                      variant="outlined"
                      className={classes.addAxis}
                      onClick={() => setShowAddStopModal(true)}
                    >
                      {intl.formatMessage({ id: 'addStop' })}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {!!station?.stops?.length && (
            <div
              style={{
                margin: '15px 0',
                maxHeight: 230,
                overflowY: 'scroll',
              }}
            >
              {station?.stops.map((stop: Stop) => (
                <StopRow key={stop.id} {...stop} />
              ))}
            </div>
          )}
        </TableContainer>
      </div>

      <Button
        variant="outlined"
        color="default"
        type="button"
        className={classes.backButton}
        onClick={() => history.replace('/operations/stations')}
      >
        {intl.formatMessage({ id: 'backToStationsList' })}
      </Button>

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteStation,
          entityType: 'station',
          entityName: fullName || ' ',
          entityId: stationId,
          onfinishNavigation: '/operations/stations',
        }}
      />

      <StopModal
        {...{
          isOpen: showAddStopModal,
          setOpen: setShowAddStopModal,
        }}
      />
    </div>
  );
};

export { ViewStation };
