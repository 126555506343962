import React, { useContext, useEffect, useRef, useState } from 'react';

import { LinearProgress, Button, InputLabel } from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getVehicleClass, getVehicleClasses } from 'actions';
import { AsyncAutoComplete } from 'app/components/async-auto-complete';
import { CardTitle } from 'app/components/shared/card-title';
import { useStyles } from 'app/operations/drivers/driver-form';
import {
  VehicleTypeFormState,
  VehicleTypeFormSchema,
} from 'app/operations/vehicles/vehicle-types/vehicle-type-helpers';
import { LanguageContext } from 'context/intl.context';

interface VehicleTypeForm {
  initialState: VehicleTypeFormState;
  handleSubmit: (
    values: VehicleTypeFormState,
    { setSubmitting }: FormikHelpers<VehicleTypeFormState>
  ) => void;
  isEditForm?: boolean;
}

const VehicleTypeForm: React.FC<VehicleTypeForm> = ({
  initialState,
  handleSubmit,
  isEditForm = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const { direction } = useContext(LanguageContext);
  const [initialVehicleClass, setInitialVehicleClass] = useState();

  const formikRef = useRef<FormikProps<VehicleTypeFormState>>(null);

  const initialVehicleClassId = initialState.vehicle_class_id;

  const getCurrentVehicleClass = async () => {
    const res: any = await dispatch(getVehicleClass(initialVehicleClassId));
    setInitialVehicleClass(res?.value?.data.response);
  };

  useEffect(() => {
    if (initialVehicleClassId) {
      getCurrentVehicleClass();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialVehicleClassId]);

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [formikRef, direction]);

  return (
    <>
      <CardTitle
        title={intl.formatMessage({
          id: isEditForm ? 'editVehicleType' : 'addVehicleType',
        })}
      />

      <div className={classes.formWrapper}>
        <Formik
          innerRef={formikRef}
          initialValues={initialState}
          validationSchema={() => VehicleTypeFormSchema(intl)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, errors, touched }) => (
            <Form>
              <div className={classes.fieldGroupWrapper}>
                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    <span>{intl.formatMessage({ id: 'englishName' })}</span>
                  </InputLabel>

                  <Field
                    name="en_model"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    <span>{intl.formatMessage({ id: 'arabicName' })}</span>
                  </InputLabel>

                  <Field
                    name="ar_model"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'capacity' })}
                  </InputLabel>

                  <Field
                    name="capacity"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'vehicleClass' })}
                  </InputLabel>

                  <Field
                    id="vehicleClass"
                    name="vehicle_class_id"
                    component={AsyncAutoComplete}
                    action={getVehicleClasses}
                    onChange={_id =>
                      setFieldValue('vehicle_class_id', _id || '')
                    }
                    defaultValue={initialVehicleClass}
                    filterKey="en_name_or_ar_name_cont"
                    error={touched.vehicle_class_id && errors.vehicle_class_id}
                  />
                </div>
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({
                    id: isEditForm ? 'update' : 'addVehicleType',
                  })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => history.go(-1)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({
                    id: 'cancel',
                  })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { VehicleTypeForm };
