// @ts-nocheck
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Box, Chip, FormHelperText, FormLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  useStyles,
  MenuProps,
} from 'app/components/shared/groups-menu-multi-select/utils';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';
import {
  getTranslated,
  capitalizeFirstLetter,
  useTypedSelector,
} from 'helpers';
import { makeGetCollectionValues, makeGetEntitiesByIds } from 'selectors';
import { AppState, subSystemEntity } from 'types';

import closeIcon from 'assets/close-icon-grey.svg';

type ParamTypes = {
  sendSelectedOptions: (filterName: string, selectedFilters: string[]) => void;
  selected: string[];
  label: string;
  entity: string;
  action: () => void;
  filterName?: string;
  filterBy?: { filterKey: string; filterValue: string };
  showPlaceholder?: boolean;
  nameParam?: string;
  error?: string;
  requiredAstrisk?: boolean;
  addBottomMargin?: boolean;
  customSelector?: (state: AppState) => subSystemEntity[];
};

const AsyncMultiSelect = ({
  sendSelectedOptions,
  selected,
  label,
  entity,
  action,
  filterName,
  filterBy = { filterKey: '', filterValue: '' },
  showPlaceholder = true,
  nameParam,
  error,
  requiredAstrisk,
  addBottomMargin,
  customSelector,
}: ParamTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);
  const isRtl = locale === LOCALES.ARABIC;

  const [ready, setReady] = useState(false);

  const getEntity = useMemo(makeGetEntitiesByIds, []);
  const getCollection = useMemo(makeGetCollectionValues, []);

  const selectedEntities = useTypedSelector(state =>
    getEntity(state, { entityName: entity, ids: selected })
  );

  const collection = useTypedSelector(state =>
    customSelector
      ? customSelector(state)
      : getCollection(state, { entityName: entity })
  );

  const { filterKey, filterValue } = filterBy;

  useEffect(() => {
    if (!ready) {
      setReady(true);
    } else if (filterKey) {
      sendSelectedOptions(filterName || entity, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, filterKey, filterName, filterValue]);

  const filterCollection = useMemo(() => {
    if (!filterKey) return collection;

    return collection.filter((item: any) => item[filterKey] === filterValue);
  }, [collection, filterKey, filterValue]);

  const displayedLabel = intl.formatMessage({ id: label });

  const isLoading = useTypedSelector(
    state => state[entity].isLoading as boolean
  );

  useEffect(() => {
    dispatch(action());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChange = selectedOptions => {
    if (selected.includes(selectedOptions)) {
      const newOptionList: string[] | [] = selected?.filter(
        id => id !== selectedOptions
      );

      return sendSelectedOptions(filterName || entity, newOptionList);
    }

    return sendSelectedOptions(filterName || entity, [
      ...selected,
      selectedOptions,
    ]);
  };

  const selectedLabels = useMemo(
    () =>
      Object.values(selectedEntities)?.map((org: any) =>
        getTranslated(org, locale, nameParam)
      ),
    [selectedEntities, locale, nameParam]
  );

  return (
    <FormControl fullWidth style={{ marginBottom: addBottomMargin ? 22 : 0 }}>
      <FormLabel className={classes.formLabel}>
        {displayedLabel}

        {requiredAstrisk && <strong style={{ color: 'red' }}>*</strong>}
      </FormLabel>

      <Select
        id={displayedLabel}
        multiple
        value={selected}
        error={!!error}
        renderValue={(val: any) =>
          selectedLabels?.length ? (
            <Box className={classes.box}>
              {selectedLabels?.map((value, index) => (
                <>
                  {value && (
                    <Chip
                      key={value}
                      label={value}
                      style={{
                        margin: 4,
                      }}
                      onDelete={() => handleChange(val[index])}
                      onMouseDown={event => {
                        event.stopPropagation();
                      }}
                      deleteIcon={<img src={closeIcon} alt="close" />}
                      classes={{
                        deleteIcon: classes.deleteIcon,
                        root: classes.chipRoot,
                        label: classes.chipLabel,
                      }}
                    />
                  )}
                </>
              ))}
            </Box>
          ) : (
            <div className={classes.placeholder}>
              {showPlaceholder && intl.formatMessage({ id: 'all' })}
            </div>
          )
        }
        MenuProps={MenuProps(isRtl)}
        variant="outlined"
        SelectDisplayProps={{
          style: {
            backgroundColor: 'white',
            padding: '5px 5px',
            minHeight: 33,
          },
        }}
        displayEmpty
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            {filterCollection.length ? (
              <div>
                {filterCollection.map((option: any) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    onClick={() => handleChange(option.id)}
                    divider
                  >
                    <ListItemIcon>
                      <Checkbox checked={selected.includes(option.id)} />
                    </ListItemIcon>

                    <ListItemText
                      primary={getTranslated(option, locale, nameParam)}
                    />
                  </MenuItem>
                ))}
              </div>
            ) : (
              <div style={{ textAlign: 'center', padding: '20px 0' }}>
                {intl.formatMessage({
                  id: `no${capitalizeFirstLetter(label)}Found`,
                })}
              </div>
            )}
          </div>
        )}
      </Select>

      {error && (
        <FormHelperText className={classes.errorText}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default AsyncMultiSelect;
