import {
  string as yupString,
  object as yupObject,
  array as yupArray,
} from 'yup';

import { Organization } from 'types';

export interface DriverFormState {
  firstName: string;
  lastName: string;
  phone: string;
  licenseNumber: string;
  nationalId: string;
  organizationId: string;
  organization?: Organization;
  groups: string[];
  status?: boolean;
}

const DriverFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const lengthValidationMessage = intl.formatMessage({
    id: 'minLengthOneValidation',
  });

  return yupObject().shape({
    firstName: yupString().required(validationRequiredMessage),
    lastName: yupString().required(validationRequiredMessage),
    organizationId: yupString().required(validationRequiredMessage),
    nationalId: yupString()
      .matches(/^[0-9]+$/, intl.formatMessage({ id: 'digitsOnly' }))
      .max(15, intl.formatMessage({ id: 'nationalIdMaxValidationMsg' })),
    phone: yupString()
      .matches(/^[0-9]+$/, intl.formatMessage({ id: 'digitsOnly' }))
      .max(11, intl.formatMessage({ id: 'phoneMaxValidationMsg' })),
    groups: yupArray().min(1, lengthValidationMessage),
  });
};

export { DriverFormSchema };
