import React, { useState, useContext } from 'react';

import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { successAlert } from 'actions';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';

import DeleteIcon from './delete-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContainer: {
      width: 715,
      maxWidth: 715,
      minHeight: 196,
      borderRadius: 10,
      background: '#F7F7F8',
    },
    dialogTitle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      marginTop: 20,
    },
    dialogContent: { padding: '0 24px 22px' },
    contentText: { margin: 0, color: '#252733' },
    dialogActions: {
      padding: '13px 22px',
      background: '#fff',
      borderTop: '1px solid #DFE0EB',
      height: 66,
    },
    spinnerWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

interface DeleteAlertProps {
  isOpen: boolean;
  setOpen: (newState: boolean) => void;
  deleteAction: (id: string) => (dispatch: Dispatch) => FluxStandardAction;
  entityName: string;
  entityType: string;
  entityId: string | null;
  onfinishNavigation?: string;
  onSuccess?: () => void;
}

const DeleteAlert: React.FC<DeleteAlertProps> = ({
  isOpen,
  setOpen,
  deleteAction,
  entityName,
  entityType,
  entityId,
  onfinishNavigation,
  onSuccess,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { locale } = useContext(LanguageContext);
  const history = useHistory();

  const isEnglish = locale.toLowerCase() === LOCALES.ENGLISH;

  const handleRequestFinish = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleSuccessDeletion = () => {
    if (onfinishNavigation) {
      return history.push(onfinishNavigation);
    }

    onSuccess?.();

    return dispatch(
      successAlert(intl.formatMessage({ id: `successdelete${entityType}` }))
    );
  };

  const renderDeleteConfirmation = () => (
    <span>
      {intl.formatMessage({
        id: 'confirmDeleteMessage',
      })}

      <span>
        <b>&#32;{entityName}</b>

        <span> {isEnglish ? ' ?' : '؟ '}</span>
      </span>
    </span>
  );

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="delete-alert"
      classes={{ paper: classes.dialogContainer }}
    >
      <DialogTitle>
        <Typography variant="h5" className={classes.dialogTitle}>
          {isEnglish
            ? `${intl.formatMessage({
                id: 'delete',
              })} "${entityName}" ${intl.formatMessage({
                id: entityType.toLowerCase(),
              })}`
            : `${intl.formatMessage({
                id: 'delete',
              })} ${intl.formatMessage({
                id: entityType.toLowerCase(),
              })} "${entityName}" `}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.contentText}>
          {renderDeleteConfirmation()}
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        {isLoading ? (
          <div className={classes.spinnerWrapper}>
            <CircularProgress size={28} />
          </div>
        ) : (
          <>
            <Button
              onClick={() => {
                if (entityId) {
                  setLoading(true);

                  Promise.resolve(dispatch(deleteAction(entityId)))
                    .then(handleSuccessDeletion)
                    .finally(handleRequestFinish);
                }
              }}
              variant="contained"
              color="secondary"
              style={{
                borderRadius: 30,
                height: 40,
                boxShadow: 'none',
                marginRight: 12,
                background: '#CE1126',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                width: 120,
              }}
            >
              <img
                src={DeleteIcon}
                alt="Delete"
                style={{
                  margin: isEnglish ? '0 12PX 0 0' : '0 0 0 12PX',
                }}
              />

              {intl.formatMessage({ id: 'delete' })}
            </Button>

            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              style={{
                background: '#fff',
                borderRadius: 30,
                height: 40,
                boxShadow: 'none',
                border: '1px solid #DADCE0',
                color: '#4B506D',
              }}
            >
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { DeleteAlert };
