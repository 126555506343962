import React, { useState, useCallback, useContext, useEffect } from 'react';

import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import {
  deleteStation,
  getStations,
  deleteMultipleStations,
  assignStationsToGroups,
  clearStationsGroups,
  getGroups,
} from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { GroupsMenuMultiSelect } from 'app/components/shared/groups-menu-multi-select';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { ActionAlert } from 'app/components/shared/on-action-alert';
import { GroupsCell, TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { TableRowActions } from 'app/components/table-row-actions';
import {
  PaginationComponent,
  usePagination,
} from 'app/components/use-pagination';
import {
  actionButtonStyles,
  basicButtonStyles,
  tableRowStyles,
} from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canAddNewEntity,
  canEditOperationsSubSystemEntities,
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithReadPermission,
  getSelectedEntityOrganizationIdById,
  haveSameOrganization,
} from 'selectors';
import { Station } from 'types';

import { ReactComponent as CheckBoxIcon } from './check_box.svg';
import { FilterStationByName } from './filter-station';
import { ReactComponent as UnselectIcon } from './unselect-icon.svg';

import ExportIcon from 'assets/export-icon.svg';
import ImportIcon from 'assets/import-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 1000,
      overflow: 'scroll',
      whiteSpace: 'nowrap',
    },
    basicButton: { ...basicButtonStyles },
    multiActionButton: {
      ...actionButtonStyles,
      height: 27,
      padding: '4px 18px',
    },
    dataButton: { marginRight: 10, display: 'none' },
    row: { ...tableRowStyles },
    searchField: { marginLeft: 32, width: 222, height: 36 },
    searchButton: { height: 30, width: 52, minWidth: 52, borderRadius: 30 },
    selectedTableHead: {
      backgroundColor: '#4b506d !important',
      width: '100%',
      padding: 6,
    },
    backButton: {
      color: '#4B506D',
      backgroundColor: '#fff',
      borderRadius: 30,
      borderColor: '#F7F7F8',
      marginLeft: 22,
    },
  })
);

interface StationListItemProps {
  station: Station;
  isSelected: boolean;
  toggleSelection: (id: string) => void;
}

const StationListItem = ({
  station,
  isSelected,
  toggleSelection,
}: StationListItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const { locale } = useContext(LanguageContext);

  const parentOrganization = getTranslated(station?.organisation, locale);

  const hasEditPermissionOnOrganizationStation = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Stations', station.id)
  );

  const stationGroups = useTypedSelector(state =>
    getEntityGroupsWithReadPermission(state, 'Stations', station.id)
  );

  return (
    <TableRow
      onClick={() => {
        history.push(`${baseURL}/${station.id}`);
      }}
      className={classes.row}
      hover
      selected={isSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            toggleSelection(station.id);
          }}
          checkedIcon={
            <CheckBoxIcon
              fill="#0fa66d"
              style={{
                backgroundColor: '#fff',
                borderRadius: 3,
              }}
            />
          }
        />
      </TableCell>

      <TextCell
        style={{ fontFamily: 'Cairo' }}
        label={station.en_name}
        maxWidth={150}
      />

      <TextCell
        style={{ fontFamily: 'Cairo' }}
        label={station.ar_name}
        maxWidth={150}
      />

      <TextCell label={parentOrganization} maxWidth={150} />

      <GroupsCell groups={stationGroups} />

      <TableRowActions
        hasWriteAccess={hasEditPermissionOnOrganizationStation}
        editPath={`${baseURL}/${station.id}/edit`}
        entityId={station.id}
        entityName={getTranslated(station, locale)}
        entityType="station"
        deleteAction={deleteStation}
      />
    </TableRow>
  );
};

const StationList: React.FC = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const baseURL = trimTrailingSlash(match.url);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);
  const [actionCall, setActionCall] = useState(undefined);
  const [isFiltering, setFiltering] = useState(false);

  const [filterParams, setFilterParams] = useState<{ [key: string]: string }>(
    {}
  );

  const [isActionConfirmationModalOpen, setActionConfirmationModalOpen] =
    useState(false);

  const [multiActionTitle, setMultiActionTitle] = useState('');

  const intl = useIntl();

  // Remove until fix multi select group button double click to open issue
  useEffect(() => {
    dispatch(getGroups(0, 100));
  }, [dispatch]);

  const hasEditPermissionOnSelectedStations = useTypedSelector(state =>
    canEditOperationsSubSystemEntities(state, 'Stations', selectedItems)
  );

  const hasSameOrg = useTypedSelector(state =>
    haveSameOrganization(state, 'Stations', selectedItems)
  );

  const selectedEntitiesOrganizationId = useTypedSelector(state =>
    getSelectedEntityOrganizationIdById(state, {
      entityName: 'Stations',
      entityId: selectedItems?.[0],
    })
  );

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getStations(page, rowSize, {
        en_name_or_ar_name_cont: searchTerm,
      }),
    [searchTerm]
  );

  const fetchFilterResults = useCallback(
    (page: number, rowSize: number) => getStations(page, rowSize, filterParams),
    [filterParams]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    if (isFiltering) return fetchFilterResults;

    return getStations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFilterResults, isFiltering, isSearching]);

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    if (selectedItems?.length) {
      setSelectedItems([]);
    }

    handleChangePage(null, 0);
  };

  const filterStations = filters => {
    if (Object.values(filters)?.length) {
      setFiltering(true);
      setSearching(false);
      setSearchTerm('');
    }

    resetPageState();

    setFilterParams(filters);
  };

  const hasStationWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Stations')
  );

  const stationListIsLoading = useTypedSelector(
    state => state.stations.isLoading as boolean
  );

  const stationListHasError = useTypedSelector(
    state => state.stations.hasError
  );

  const stationsTotalCount = useTypedSelector(
    state => state.stations.totalCount
  );

  const stationList = useTypedSelector(state => {
    if (
      !state.stations.isLoading &&
      Object.values(state.stations.collection).length
    ) {
      return Object.values(state.stations.collection);
    }

    return [];
  });

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getStations(page, rowsPerPage, {
          en_name_or_ar_name_cont: searchTerm,
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const toggleSelection = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems([...selectedItems.filter(item => item !== id)]);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const isAllListSelected =
    stationList.length && selectedItems.length === stationList.length;

  const toggleSelectAll = () => {
    if (isAllListSelected) {
      setSelectedItems([]);
    } else {
      const listToBeSelected = stationList.map(station => station.id);
      setSelectedItems(listToBeSelected);
    }
  };

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: stationsTotalCount,
        hasData: !!stationList.length,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );

  const RenderActionButton = ({ title, onClick }) => (
    <Button
      variant="contained"
      color="default"
      type="button"
      className={classes.multiActionButton}
      onClick={() => {
        setMultiActionTitle(title);
        setActionConfirmationModalOpen(true);
        setActionCall(onClick);
      }}
    >
      {intl.formatMessage({ id: title })}
    </Button>
  );

  const assignStationsGroups = onClick => {
    setMultiActionTitle('assign');
    setActionConfirmationModalOpen(true);
    setActionCall(onClick);
  };

  const EnhancedTableToolbar = () => {
    if (selectedItems?.length) {
      return (
        <div className={classes.selectedTableHead}>
          <Checkbox
            checkedIcon={
              isAllListSelected ? (
                <CheckBoxIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              ) : (
                <UnselectIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              )
            }
            checked={!!selectedItems?.length}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setSelectedItems([]);
            }}
          />

          {hasEditPermissionOnSelectedStations && (
            <>
              <RenderActionButton
                title="delete"
                onClick={() => deleteMultipleStations(selectedItems)}
              />

              {hasSameOrg && (
                <GroupsMenuMultiSelect
                  entityName="Stations"
                  buttonLabel="assignToGroup"
                  onAddGroups={group_ids =>
                    assignStationsGroups(() =>
                      assignStationsToGroups(selectedItems, group_ids)
                    )
                  }
                  selectedOrganizationId={selectedEntitiesOrganizationId}
                />
              )}

              {hasSameOrg && (
                <RenderActionButton
                  title="unAssignFromGroups"
                  onClick={() => clearStationsGroups(selectedItems)}
                />
              )}
            </>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <CardTitle title={intl.formatMessage({ id: 'stationList' })}>
        <div>
          <Button
            variant="outlined"
            className={`${classes.basicButton} ${classes.dataButton}`}
          >
            <img
              src={ImportIcon}
              alt="export stations"
              style={{ marginRight: 10 }}
            />

            {intl.formatMessage({ id: 'import' })}
          </Button>

          <Button
            variant="outlined"
            className={`${classes.basicButton} ${classes.dataButton}`}
          >
            <img
              src={ExportIcon}
              alt="export stations"
              style={{ marginRight: 10 }}
            />

            {intl.formatMessage({ id: 'export' })}
          </Button>

          {hasStationWriteAccess && (
            <Button
              component={Link}
              to={`${baseURL}/new`}
              variant="contained"
              color="secondary"
              className={classes.basicButton}
            >
              {intl.formatMessage({ id: 'addStation' })}
            </Button>
          )}
        </div>
      </CardTitle>

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterStationByName
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
              showFilters: !isSearching,
              filterStations,
              isSearching,
            }}
          />
        </Grid>

        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <EnhancedTableToolbar />

      <ActionAlert
        {...{
          isOpen: isActionConfirmationModalOpen,
          setOpen: setActionConfirmationModalOpen,
          setMultiActionTitle,
          setActionCall,
          action: actionCall,
          entityType: intl.formatMessage({ id: 'stations' }),
          actionName: multiActionTitle,
          onSuccess: () => {
            setSelectedItems([]);
            dispatch(fetchAction()(page, rowsPerPage));
          },
        }}
      />

      <DataTableContainer
        alignItems={stationListIsLoading ? 'center' : 'flex-start'}
      >
        {stationListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {stationListIsLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {stationList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Stations Table"
                  >
                    <TableHead>
                      {!selectedItems?.length && (
                        <TableRow
                          style={{ backgroundColor: '#4b506d !important' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={!!isAllListSelected}
                              onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                                toggleSelectAll();
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'englishName' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'arabicName' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'organization' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'groups' })}
                          </TableCell>

                          <TableCell align="center">
                            {intl.formatMessage({ id: 'actions' })}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>

                    <TableBody>
                      {stationList.map(station => (
                        <StationListItem
                          key={station.id}
                          {...{ station, toggleSelection }}
                          isSelected={selectedItems.includes(station.id)}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={hasStationWriteAccess}
                      baseURL={baseURL}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>

      <Pagination />
    </>
  );
};

export { StationList };
