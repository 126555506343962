import { createContext } from 'react';

interface SideNav {
  isMobileSideNavOpen: boolean;
  handleDrawerToggle?: () => void;
  showSideNav: boolean;
  setShowSideNav?: (show: boolean) => void;
}

const SideNavContext = createContext<SideNav>({
  isMobileSideNavOpen: false,
  showSideNav: true,
});

export { SideNavContext };
