import React from 'react';

import {
  createStyles,
  Dialog,
  DialogContent,
  makeStyles,
} from '@material-ui/core';

import { AddStop } from './add-stop';
import { EditStop } from './edit-stop';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContainer: {
      width: 715,
      maxWidth: 715,
      borderRadius: 20,
      background: '#ffffff',
      paddingBottom: 20,
    },
    dialogTitle: {
      textTransform: 'capitalize',
      fontWeight: 700,
      marginTop: 20,
    },
    dialogContent: { padding: '0 24px 22px' },
    contentText: { margin: 0, color: '#252733' },
    dialogActions: {
      padding: '13px 22px',
      background: '#fff',
      borderTop: '1px solid #DFE0EB',
      height: 66,
    },
    spinnerWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

interface StopModalProps {
  isOpen: boolean;
  setOpen: (newState: boolean) => void;
  isEditing?: boolean;
  stopId?: string;
}

const StopModal: React.FC<StopModalProps> = ({
  isOpen,
  setOpen,
  isEditing = false,
  stopId = '',
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="delete-alert"
      classes={{ paper: classes.dialogContainer }}
    >
      <DialogContent className={classes.dialogContent}>
        {isEditing ? (
          <EditStop {...{ setOpen, stopId }} />
        ) : (
          <AddStop {...{ setOpen }} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export { StopModal };
