import { MenuProps as MenuPropsType } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { basicButtonStyles, actionButtonStyles } from 'app/shared/styles';

const useStyles = makeStyles(() =>
  createStyles({
    filterButton: { ...actionButtonStyles },
    paperStyle: {
      width: 278,
      backgroundColor: '#F4F6FB',
      padding: '20px',
      borderRadius: 15,
    },
    popperStyle: {
      marginTop: 6,
    },
    formLabel: {
      color: '#444',
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 700,
    },
    formControl: {
      marginBottom: 22,
    },
    closeFilters: {
      marginRight: 10,
      cursor: 'pointer',
      '& :hover': {
        opacity: 0.7,
      },
    },
    basicButton: {
      ...basicButtonStyles,
      marginRight: 20,
      padding: '8px 28px',
      width: 85,
      height: 37,
    },
    resetButton: { color: '#5572FA', width: 85, height: 37 },
    indeterminateColor: {
      color: '#f50057',
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    box: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: 5,
    },
    deleteIcon: {
      height: 16,
      width: 21,
    },
    chipRoot: {
      backgroundColor: '#DCE1ED',
      height: 30,
      border: '0px',
    },
    chipLabel: {
      fontWeight: 500,
    },
    placeholder: {
      color: '#9B9B9B',
      padding: 10,
    },
    menuItem: {
      padding: 0,
      margin: 0,
      borderWidth: 1,
    },
    checkbox: {
      padding: '6px 22px 6px 0px',
    },
    textField: {
      backgroundColor: '#fff',
      height: 13,
      width: '100%',
    },
    errorText: {
      color: '#FC0D1C',
      paddingLeft: 14,
    },
    autoCompleteOption: {
      padding: '0px 0px',
      margin: '0px 0px',
    },
    whiteBackground: {
      backgroundColor: '#fff',
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = isRtl =>
  ({
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: isRtl ? 'right' : 'left',
    },
    getContentAnchorEl: null,
  } as Partial<MenuPropsType>);

const getInputProps = (isRTL: boolean) => ({
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: isRTL ? 'right' : 'left',
    },
    getContentAnchorEl: null,
  },
});

export { useStyles, MenuProps, getInputProps };
