import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { AddStation } from 'app/operations/stations/add-station';
import { EditStation } from 'app/operations/stations/edit-station';
import { StationList } from 'app/operations/stations/station-list';
import { ViewStation } from 'app/operations/stations/view-station';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

const Stations: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasStationAddAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Stations')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasStationAddAccess ? <AddStation /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          <EditStation />
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewStation />
        </Route>

        <Route exact path={`${baseURL}`}>
          <StationList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { Stations };
