import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AddAdmin } from 'app/admin-management/admins/add-admin';
import { AdminList } from 'app/admin-management/admins/admin-list';
import { EditAdmin } from 'app/admin-management/admins/edit-admin';
import { ViewAdmin } from 'app/admin-management/admins/view-admin';
import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

const Admins: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasAdminsWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Admins')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasAdminsWriteAccess ? <AddAdmin /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          <EditAdmin />
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewAdmin />
        </Route>

        <Route exact path={`${baseURL}`}>
          <AdminList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { Admins };
