import React from 'react';

import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { PageNotFound } from 'app/components/page-not-found';
import { SystemHeader, SystemIcon } from 'app/components/shared/system-header';
import { Devices } from 'app/operations/devices';
import { Drivers } from 'app/operations/drivers';
import { Groups } from 'app/operations/groups';
import { ChangePassword } from 'app/operations/profile/change-password';
import { EditProfile } from 'app/operations/profile/edit-profile';
import { Routes } from 'app/operations/routes';
import { Stations } from 'app/operations/stations';
import { Vehicles } from 'app/operations/vehicles/vehicle-list';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canViewOperationsSubSystems } from 'selectors';

import { VehicleClasses } from './vehicles/vehicle-classes';
import { VehicleTypes } from './vehicles/vehicle-types';

const Operations: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const canViewRoutes = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Routes')
  );

  const canViewDrivers = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Drivers')
  );

  const canViewGroups = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Groups')
  );

  const canViewVehicles = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Vehicles')
  );

  const canViewStations = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Stations')
  );

  const canViewDevices = useTypedSelector(state =>
    canViewOperationsSubSystems(state, 'Devices')
  );

  const getInitialRoute = () => {
    if (canViewRoutes) {
      return `${baseURL}/routes`;
    }
    if (canViewDrivers) {
      return `${baseURL}/drivers`;
    }
    if (canViewDevices) {
      return `${baseURL}/devices`;
    }
    if (canViewGroups) {
      return `${baseURL}/groups`;
    }
    if (canViewVehicles) {
      return `${baseURL}/vehicles`;
    }
    if (canViewStations) {
      return `${baseURL}/stations`;
    }

    return `${baseURL}/routes`;
  };

  return (
    <>
      <SystemHeader
        headerTitle={intl.formatMessage({ id: 'operations' })}
        headerIcon={SystemIcon.Operations}
      />

      <Switch>
        <Redirect from={baseURL} exact to={getInitialRoute()} />

        <Route path={`${baseURL}/routes`}>
          {canViewRoutes ? <Routes /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/drivers`}>
          {canViewDrivers ? <Drivers /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/devices`}>
          {canViewDevices ? <Devices /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/groups`}>
          {canViewGroups ? <Groups /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/vehicles`}>
          {canViewVehicles ? <Vehicles /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/vehicle-types`}>
          {canViewVehicles ? <VehicleTypes /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/vehicle-classes`}>
          {canViewVehicles ? <VehicleClasses /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/stations`}>
          {canViewStations ? <Stations /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/edit-profile`}>
          <EditProfile />
        </Route>

        <Route path={`${baseURL}/change-password`}>
          <ChangePassword />
        </Route>

        <Route path="/">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};

export { Operations };
