import React, { useEffect, useState, useCallback, useContext } from 'react';

import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import {
  deleteVehicle,
  getVehicles,
  editMultipleVehicles,
  deleteMultipleVehicles,
  getGroups,
  getVehicleTypes,
} from 'actions';
import { ExcelFileInput } from 'app/components/excel-file-input';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { GroupsMenuMultiSelect } from 'app/components/shared/groups-menu-multi-select';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { ActionAlert } from 'app/components/shared/on-action-alert';
import { GroupsCell, StatusCell, TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { TableRowActions } from 'app/components/table-row-actions';
import {
  PaginationComponent,
  usePagination,
} from 'app/components/use-pagination';
import {
  actionButtonStyles,
  basicButtonStyles,
  tableRowStyles,
} from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithReadPermission,
  getSelectedEntityOrganizationIdById,
  canEditOperationsSubSystemEntities,
  haveSameOrganization,
  canAddNewEntity,
} from 'selectors';
import { Vehicle } from 'types';

import { ReactComponent as CheckBoxIcon } from './check_box.svg';
import { FilterVehicleByName } from './filter-vehicle';
import { VehiclesFilterParams } from './filters';
import { useHandleImportVehiclesData } from './hooks';
import { ReactComponent as UnselectIcon } from './unselect-icon.svg';

import ExportIcon from 'assets/export-icon.svg';
import ImportIcon from 'assets/import-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 1000,
      overflow: 'scroll',
      whiteSpace: 'nowrap',
    },
    basicButton: { ...basicButtonStyles },
    multiActionButton: {
      ...actionButtonStyles,
      height: 27,
      padding: '4px 18px',
    },
    dataButton: { marginRight: 10, display: 'none' },
    row: { ...tableRowStyles },
    searchField: { marginLeft: 32, width: 222, height: 36 },
    searchButton: { height: 30, width: 52, minWidth: 52, borderRadius: 30 },
    selectedTableHead: {
      backgroundColor: '#4b506d !important',
      width: '100%',
      padding: 6,
    },
    backButton: {
      color: '#4B506D',
      backgroundColor: '#fff',
      borderRadius: 30,
      borderColor: '#F7F7F8',
      marginLeft: 22,
    },
  })
);

interface VehicleListItemProps {
  vehicle: Vehicle;
  isSelected: boolean;
  toggleSelection: (id: string) => void;
}

const VehicleListItem = ({
  vehicle,
  isSelected,
  toggleSelection,
}: VehicleListItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const { locale } = useContext(LanguageContext);

  const parentOrganization = getTranslated(vehicle?.organisation, locale);

  const hasEditPermissionOnOrganizationVehicle = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Vehicles', vehicle.id)
  );

  const vehicleGroups = useTypedSelector(state =>
    getEntityGroupsWithReadPermission(state, 'Vehicles', vehicle.id)
  );

  return (
    <TableRow
      onClick={() => {
        history.push(`${baseURL}/${vehicle.id}`);
      }}
      className={classes.row}
      hover
      selected={isSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            toggleSelection(vehicle.id);
          }}
          checkedIcon={
            <CheckBoxIcon
              fill="#0fa66d"
              style={{
                backgroundColor: '#fff',
                borderRadius: 3,
              }}
            />
          }
        />
      </TableCell>

      <TextCell
        style={{ fontFamily: 'Cairo' }}
        label={getTranslated(vehicle?.vehicle_type, locale, 'model')}
        maxWidth={150}
      />

      <TextCell
        style={{ fontFamily: 'Cairo' }}
        label={vehicle.code}
        maxWidth={150}
      />

      <TextCell label={vehicle.plate_number} maxWidth={150} />

      <TextCell label={parentOrganization} maxWidth={150} />

      <StatusCell isDisabled={vehicle.disabled} />

      <GroupsCell groups={vehicleGroups} />

      <TableRowActions
        hasWriteAccess={hasEditPermissionOnOrganizationVehicle}
        editPath={`${baseURL}/${vehicle.id}/edit`}
        entityId={vehicle.id}
        entityName={vehicle.plate_number}
        entityType="vehicle"
        deleteAction={deleteVehicle}
      />
    </TableRow>
  );
};

const VehicleList: React.FC = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const baseURL = trimTrailingSlash(match.url);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);
  const [actionCall, setActionCall] = useState(undefined);
  const [isFiltering, setFiltering] = useState(false);

  const [filterParams, setFilterParams] = useState<{ [key: string]: string }>(
    {}
  );

  const [isActionConfirmationModalOpen, setActionConfirmationModalOpen] =
    useState(false);

  const [multiActionTitle, setMultiActionTitle] = useState('');

  const intl = useIntl();

  useEffect(() => {
    dispatch(getVehicleTypes(0, 500));
    dispatch(getGroups(0, 500));
  }, [dispatch]);

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getVehicles(page, rowSize, {
        code_or_plate_number_cont: searchTerm,
      }),
    [searchTerm]
  );

  const fetchFilterResults = useCallback(
    (page: number, rowSize: number) => {
      let showDisabledOnly: boolean | undefined;
      if (filterParams?.[VehiclesFilterParams.status]?.length === 1) {
        showDisabledOnly =
          filterParams?.[VehiclesFilterParams.status]?.includes('disabled');
      }

      return getVehicles(page, rowSize, {
        ...filterParams,
        disabled_in: [showDisabledOnly],
      });
    },
    [filterParams]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    if (isFiltering) return fetchFilterResults;

    return getVehicles;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFilterResults, isFiltering, isSearching]);

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    if (selectedItems?.length) {
      setSelectedItems([]);
    }

    handleChangePage(null, 0);
  };

  const filterVehicles = filters => {
    if (Object.values(filters)?.length) {
      setFiltering(true);
      setSearching(false);
      setSearchTerm('');
    }

    resetPageState();

    setFilterParams(filters);
  };

  const hasVehicleWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Vehicles')
  );

  const vehicleListIsLoading = useTypedSelector(
    state => state.vehicles.isLoading as boolean
  );

  const vehicleListHasError = useTypedSelector(
    state => state.vehicles.hasError
  );

  const vehiclesTotalCount = useTypedSelector(
    state => state.vehicles.totalCount
  );

  const vehicleList = useTypedSelector(state => {
    if (
      !state.vehicles.isLoading &&
      Object.values(state.vehicles.collection).length
    ) {
      return Object.values(state.vehicles.collection);
    }

    return [];
  });

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getVehicles(page, rowsPerPage, {
          code_or_plate_number_cont: searchTerm,
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const toggleSelection = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems([...selectedItems.filter(item => item !== id)]);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const isAllListSelected =
    vehicleList.length && selectedItems.length === vehicleList.length;

  const toggleSelectAll = () => {
    if (isAllListSelected) {
      setSelectedItems([]);
    } else {
      const listToBeSelected = vehicleList.map(vehicle => vehicle.id);
      setSelectedItems(listToBeSelected);
    }
  };

  const onChangePage = (event: unknown, newPage: number) => {
    if (page !== newPage) {
      setSelectedItems([]);
    }

    return handleChangePage(event, newPage);
  };

  const refetchAction = useCallback(() => {
    setSelectedItems([]);
    dispatch(fetchAction()(page, rowsPerPage));
  }, [dispatch, fetchAction, page, rowsPerPage]);

  const { readUploadFile, loading: importing } = useHandleImportVehiclesData({
    onSuccess: refetchAction,
  });

  const pageLoading = vehicleListIsLoading || importing;

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: vehiclesTotalCount,
        hasData: !!vehicleList.length,
        rowsPerPage,
        page,
        handleChangePage: onChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );

  const RenderActionButton = ({ title, onClick }) => (
    <Button
      variant="contained"
      color="default"
      type="button"
      className={classes.multiActionButton}
      onClick={() => {
        setMultiActionTitle(title);
        setActionConfirmationModalOpen(true);
        setActionCall(onClick);
      }}
    >
      {intl.formatMessage({ id: title })}
    </Button>
  );

  const assignVehiclesToGroups = onClick => {
    setMultiActionTitle('assign');
    setActionConfirmationModalOpen(true);
    setActionCall(onClick);
  };

  const hasEditPermissionOnSelectedVehicles = useTypedSelector(state =>
    canEditOperationsSubSystemEntities(state, 'Vehicles', selectedItems)
  );

  const hasSameOrg = useTypedSelector(state =>
    haveSameOrganization(state, 'Vehicles', selectedItems)
  );

  const selectedEntitiesOrganizationId = useTypedSelector(state =>
    getSelectedEntityOrganizationIdById(state, {
      entityName: 'Vehicles',
      entityId: selectedItems?.[0],
    })
  );

  const EnhancedTableToolbar = () => {
    if (selectedItems?.length) {
      return (
        <div className={classes.selectedTableHead}>
          <Checkbox
            checkedIcon={
              isAllListSelected ? (
                <CheckBoxIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              ) : (
                <UnselectIcon
                  fill="#0fa66d"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                  }}
                />
              )
            }
            checked={!!selectedItems?.length}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setSelectedItems([]);
            }}
          />

          {hasEditPermissionOnSelectedVehicles && (
            <>
              {hasSameOrg && (
                <GroupsMenuMultiSelect
                  entityName="Vehicles"
                  onAddGroups={group_ids =>
                    assignVehiclesToGroups(() =>
                      editMultipleVehicles(selectedItems, { group_ids })
                    )
                  }
                  selectedOrganizationId={selectedEntitiesOrganizationId}
                />
              )}

              {hasSameOrg && (
                <RenderActionButton
                  title="clearGroups"
                  onClick={() =>
                    editMultipleVehicles(selectedItems, { group_ids: [] })
                  }
                />
              )}

              <RenderActionButton
                title="enable"
                onClick={() =>
                  editMultipleVehicles(selectedItems, { disabled: false })
                }
              />

              <RenderActionButton
                title="disable"
                onClick={() =>
                  editMultipleVehicles(selectedItems, { disabled: true })
                }
              />

              <RenderActionButton
                title="delete"
                onClick={() => deleteMultipleVehicles(selectedItems)}
              />
            </>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <CardTitle title={intl.formatMessage({ id: 'vehicles' })}>
        <div>
          {hasVehicleWriteAccess && (
            <ExcelFileInput
              onChange={readUploadFile}
              icon={ImportIcon}
              labelKey="import"
            />
          )}

          {false && (
            <ExcelFileInput
              onChange={readUploadFile}
              icon={ExportIcon}
              labelKey="export"
            />
          )}

          {hasVehicleWriteAccess && (
            <Button
              component={Link}
              to={`${baseURL}/new`}
              variant="contained"
              color="secondary"
              className={classes.basicButton}
            >
              {intl.formatMessage({ id: 'addVehicle' })}
            </Button>
          )}
        </div>
      </CardTitle>

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterVehicleByName
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
              showFilters: !isSearching,
              filterVehicles,
              isSearching,
            }}
          />
        </Grid>

        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <EnhancedTableToolbar />

      <ActionAlert
        {...{
          isOpen: isActionConfirmationModalOpen,
          setOpen: setActionConfirmationModalOpen,
          setMultiActionTitle,
          setActionCall,
          action: actionCall,
          entityType: intl.formatMessage({ id: 'vehicles' }),
          actionName: multiActionTitle,
          onSuccess: refetchAction,
        }}
      />

      <DataTableContainer alignItems={pageLoading ? 'center' : 'flex-start'}>
        {vehicleListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {pageLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {vehicleList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Vehicles Table"
                  >
                    <TableHead>
                      {!selectedItems?.length && (
                        <TableRow
                          style={{ backgroundColor: '#4b506d !important' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={!!isAllListSelected}
                              onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();
                                toggleSelectAll();
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'vehicleType' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'vehicleCode' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'plateNumber' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'organization' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'status' })}
                          </TableCell>

                          <TableCell>
                            {intl.formatMessage({ id: 'groups' })}
                          </TableCell>

                          <TableCell align="center">
                            {intl.formatMessage({ id: 'actions' })}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>

                    <TableBody>
                      {vehicleList.map(vehicle => (
                        <VehicleListItem
                          key={vehicle.id}
                          {...{ vehicle, toggleSelection }}
                          isSelected={selectedItems.includes(vehicle.id)}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={hasVehicleWriteAccess}
                      baseURL={baseURL}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>

      <Pagination />
    </>
  );
};

export { VehicleList };
