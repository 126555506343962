import React, { useCallback, useContext, useMemo, useState } from 'react';

import {
  makeStyles,
  createStyles,
  TextField,
  CircularProgress,
  ListItemText,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import useDebouncedEffect from 'use-debounced-effect';

import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';
import { subSystemEntity } from 'types';

export const useStyles = makeStyles(() =>
  createStyles({
    option: {
      padding: '0px 0px',
      margin: '0px 0px',
    },
    listItem: {
      borderBottom: '1px solid rgba(223, 224, 235, 1)',
      padding: '15px 35px',
      margin: '0px',
    },
    progress: {
      marginRight: '30px',
    },
    noOptions: {
      backgroundColor: '#FFFFFF',
    },
    errorText: {
      color: '#FC0D1C',
      paddingLeft: 14,
    },
  })
);

interface AsyncAutoComplete {
  id: string;
  defaultValue: subSystemEntity;
  action: (dispatch: Dispatch) => FluxStandardAction;
  onChange: () => void;
  labelParam?: string;
  placeholder?: string;
  filterKey?: string;
  error?: string;
  selectKey?: string;
  fixedFilterKey?: string;
  fixedFilterValue?: string;
}

const AsyncAutoComplete = ({
  id,
  defaultValue,
  action,
  onChange,
  labelParam,
  placeholder,
  filterKey,
  error = false,
  selectKey = 'id',
  fixedFilterKey,
  fixedFilterValue,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<subSystemEntity[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { locale } = useContext(LanguageContext);

  const classes = useStyles();

  const label = useMemo(() => {
    if (labelParam) return labelParam;

    return locale === LOCALES.ARABIC ? 'ar_name' : 'en_name';
  }, [locale, labelParam]);

  useDebouncedEffect(
    () => {
      const loadData = async input => {
        setIsLoading(true);

        const res = await dispatch(
          action(0, 10, {
            [filterKey]: input,
            [fixedFilterKey]: fixedFilterValue,
          })
        );

        const newOptions = res.action.payload?.data.response.data;

        const shouldAddDefaultOption =
          defaultValue?.[labelParam]?.includes(inputValue) &&
          newOptions?.some(
            option => option[selectKey] !== defaultValue[selectKey]
          );

        if (shouldAddDefaultOption) {
          setOptions([defaultValue, ...newOptions]);
        } else {
          setOptions(newOptions);
        }

        setIsLoading(false);
      };

      loadData(inputValue);
    },
    { timeout: 350, ignoreInitialCall: false },
    [action, dispatch, filterKey, inputValue]
  );

  const renderOption = useCallback(
    value => (
      <ListItemText primary={value[label]} className={classes.listItem} />
    ),
    [classes.listItem, label]
  );

  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        placeholder={placeholder}
        variant="outlined"
        error={!!error}
        InputProps={{
          ...params.InputProps,
          style: {
            padding: '7px 8px',
            backgroundColor: '#FFFFFF',
          },
          endAdornment: (
            <>
              {isLoading ? (
                <CircularProgress
                  color="inherit"
                  size={20}
                  className={classes.progress}
                />
              ) : null}

              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [classes.progress, isLoading, placeholder, error]
  );

  return (
    <FormControl fullWidth>
      <Autocomplete
        onInputChange={(event, newInputValue) => {
          if (!newInputValue || event?.type === 'change')
            setInputValue(newInputValue);
        }}
        id={id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        classes={{
          option: classes.option,
          noOptions: classes.noOptions,
          loading: classes.noOptions,
        }}
        onChange={(__, newValue) => {
          onChange(newValue?.[selectKey] || newValue);
        }}
        getOptionLabel={option => option[label]}
        options={options}
        loading={isLoading}
        placeholder={placeholder}
        size="small"
        renderOption={renderOption}
        ListboxProps={{
          style: { maxHeight: 224, backgroundColor: '#ffffff', padding: 0 },
        }}
        renderInput={renderInput}
        defaultValue={defaultValue}
        key={defaultValue || id}
      />

      {error && (
        <FormHelperText className={classes.errorText}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export { AsyncAutoComplete };
