import { createStore, applyMiddleware, compose } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';

import rootReducer from 'reducers';

const configureProdStore = createStore(
  rootReducer,
  compose(applyMiddleware(thunkMiddleware, promiseMiddleware))
);

export { configureProdStore };
