import React, { useContext, useEffect, useMemo, useRef } from 'react';

import {
  LinearProgress,
  Button,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getOrganizations } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { getInputProps } from 'app/components/shared/groups-menu-multi-select/utils';
import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import {
  GroupFormState,
  GroupFormSchema,
} from 'app/operations/groups/group-helpers';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, useTypedSelector } from 'helpers';
import { getOrganizationsWithWritePermission } from 'selectors';
import { Organization } from 'types';

import { useStyles } from '../drivers/driver-form';

interface GroupForm {
  initialState: GroupFormState;
  handleSubmit: (
    values: GroupFormState,
    { setSubmitting }: FormikHelpers<GroupFormState>
  ) => void;
  isEditForm?: boolean;
}

const GroupForm: React.FC<GroupForm> = ({
  initialState,
  handleSubmit,
  isEditForm = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const { direction, locale } = useContext(LanguageContext);

  const isRTL = direction === DIRECTIONS.RTL;

  useEffect(() => {
    dispatch(getOrganizations(0, 100));
  }, [dispatch]);

  const formikRef = useRef<FormikProps<GroupFormState>>(null);

  const organizationList = useTypedSelector(state =>
    getOrganizationsWithWritePermission(state, 'Groups')
  ) as Organization[];

  const hasWriteAccessOnOrganizations = organizationList?.length > 0;

  const organizations = useMemo(() => {
    if (hasWriteAccessOnOrganizations) return organizationList;
    if (initialState.organization) return [initialState.organization];

    return null;
  }, [initialState, organizationList, hasWriteAccessOnOrganizations]);

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [formikRef, direction]);

  return (
    <>
      <CardTitle
        title={intl.formatMessage({
          id: isEditForm ? 'editGroup' : 'addGroup',
        })}
      />

      <div className={classes.formWrapper}>
        <Formik
          innerRef={formikRef}
          initialValues={initialState}
          validationSchema={() => GroupFormSchema(intl)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div>
                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'englishName' })}
                  </InputLabel>

                  <Field
                    name="en_name"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    <span>{intl.formatMessage({ id: 'arabicName' })}</span>
                  </InputLabel>

                  <Field
                    name="ar_name"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'code' })}
                  </InputLabel>

                  <Field
                    name="code"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: isRTL ? 'row' : 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'organization' })}
                  </InputLabel>

                  <Field
                    name="organizationId"
                    component={TextField}
                    type="text"
                    select
                    variant="outlined"
                    size="small"
                    inputProps={getInputProps(isRTL)}
                  >
                    {organizations?.map(organization => (
                      <MenuItem
                        key={organization.id}
                        value={organization.id}
                        className={classes.selectMenuItem}
                      >
                        {getTranslated(organization, locale)}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({
                    id: isEditForm ? 'update' : 'addGroup',
                  })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => history.go(-1)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({
                    id: 'cancel',
                  })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { GroupForm };
