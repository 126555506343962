import React from 'react';

import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import EmptyStateIcon from './empty-state-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    noDataWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,
    },
    title: {
      fontWeight: 800,
      fontSize: 25,
      letterSpacing: -0.04,
      margin: '32px 0 18px',
    },
    addButton: {
      marginTop: 28,
      borderRadius: 30,
      height: 44,
      padding: '14px 44px',
    },
  })
);

export const SystemEmptyState: React.FC<{
  hasWriteAccess: boolean;
  baseURL: string;
  showNote?: boolean;
}> = ({ hasWriteAccess, baseURL, showNote }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.noDataWrapper}>
      <img src={EmptyStateIcon} alt="No Content" />

      <Typography
        align="center"
        variant="h3"
        color="primary"
        className={classes.title}
      >
        {intl.formatMessage({ id: 'noResultYet' })}
      </Typography>

      {showNote && (
        <Typography align="center" variant="subtitle1" color="textSecondary">
          {intl.formatMessage({ id: 'noResultNote' })}
        </Typography>
      )}

      {hasWriteAccess && (
        <Button
          component={Link}
          to={`${baseURL}/new`}
          variant="contained"
          className={classes.addButton}
          color="secondary"
        >
          {intl.formatMessage({ id: 'addBtn' })}
        </Button>
      )}
    </div>
  );
};
