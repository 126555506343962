import React, { useMemo } from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { editStop, getStation, successAlert } from 'actions';
import { StopFormState } from 'app/operations/stations/stop-helpers';
import { useTypedSelector } from 'helpers';
import { getStationStop } from 'selectors';
import { Station, Stop } from 'types';

import { StopForm } from './stop-form';

const getLatLon = (point?: string) => {
  if (!point) return { lat: '', lon: '' };

  const [lon, lat] = point.replace('POINT (', '').replace(')', '').split(' ');

  return { lat, lon };
};

interface EditStopProps {
  setOpen: (newState: boolean) => void;
  stopId: string;
}

const EditStop: React.FC<EditStopProps> = ({ setOpen, stopId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const match = useRouteMatch<{ id: string }>();

  const stationId = match.params.id;

  const stationData = useTypedSelector(
    state => state.stations.collection[stationId] as Station | undefined
  );

  const stop = useTypedSelector(
    state => getStationStop(state, { stationId, stopId }) as Stop | undefined
  );

  const initialState: StopFormState = useMemo(() => {
    const { lat, lon } = getLatLon(stop?.lonlat);

    return {
      ar_name: stop?.ar_name || '',
      en_name: stop?.en_name || '',
      lon,
      lat,
      code: stop?.code || '',
    };
  }, [stop]);

  const handleSubmit = async (
    values: StopFormState,
    { setSubmitting }: FormikHelpers<StopFormState>
  ) => {
    setSubmitting(true);
    try {
      await Promise.resolve(
        dispatch(
          editStop(stopId, {
            ...values,
            station_id: stationId,
            organisation_id: stationData?.organisation_id,
          })
        )
      );

      setOpen(false);

      dispatch(getStation(stationId));

      dispatch(successAlert(intl.formatMessage({ id: 'successEditStop' })));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StopForm {...{ initialState, handleSubmit, setOpen, isEditForm: true }} />
  );
};

export { EditStop };
