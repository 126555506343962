import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { deleteRoute, getRoute, getStops } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { useStyles } from 'app/components/shared/styles/view-entity';
import { StatusCell } from 'app/components/shared/table';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithReadPermission,
} from 'selectors';
import { Axis, Route } from 'types';

import { AxisModal } from './axis-modal';
import AxisRow from './axis-row';

import DeleteIcon from 'assets/delete-icon.svg';
import ActionEditIcon from 'assets/edit-icon.svg';

export const getMenuItemStyles = (
  item: string,
  value: string[],
  theme: Theme
) => ({
  fontWeight:
    value.indexOf(item) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
});

const ViewRoute: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showAddAxisModal, setShowAddAxisModal] = useState(false);

  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);

  const intl = useIntl();

  const routeId = match.params.id;

  const route = useTypedSelector(
    state => state.routes.collection[routeId] as Route | undefined
  );

  const [axesIds, setAxesIds] = useState<string[]>([]);

  const { locale } = useContext(LanguageContext);

  const isEnglish = locale.toLowerCase() === LOCALES.ENGLISH;
  const entityName = isEnglish ? route?.en_name : route?.ar_name;

  useEffect(() => {
    if (route) {
      setAxesIds(route.axes?.map((axis: Axis) => axis.id));
    }
  }, [route]);

  useEffect(() => {
    dispatch(getStops(0, 100));
  }, [dispatch]);

  const hasRouteWriteAccess = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Routes', routeId)
  );

  const parentOrganization = getTranslated(route?.organisation, locale);

  const groups = useTypedSelector(state =>
    getEntityGroupsWithReadPermission(state, 'Routes', routeId)
  );

  const renderCardActions = () => {
    if (hasRouteWriteAccess)
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.editButton}
            component={Link}
            to={`${baseURL}/edit`}
          >
            <img
              src={ActionEditIcon}
              alt="Edit"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'edit' })}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            className={`${classes.editButton} ${classes.deleteButton}`}
            component={Button}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'delete' })}
          </Button>
        </>
      );

    return null;
  };

  useEffect(() => {
    (async () => {
      await dispatch(getRoute(routeId));
      setLoading(false);
    })();
  }, [dispatch, routeId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.viewContainer}>
      <CardTitle title={intl.formatMessage({ id: 'routeInfo' })} />

      <div
        className={[
          classes.infoWrapper,
          classes.customInfoWrapperMaxWidth,
        ].join(' ')}
      >
        <TableContainer className={classes.tableContainer}>
          <Table size="small" aria-label="Route Info Table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeadCell}>
                  {isEnglish ? route?.en_name : route?.ar_name}
                </TableCell>

                <TableCell
                  style={{ textAlign: 'end' }}
                  className={classes.tableHeadCell}
                >
                  {renderCardActions()}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'englishName' })}
                </TableCell>

                <TableCell align="center">{route?.en_name}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'arabicName' })}
                </TableCell>

                <TableCell align="center">{route?.ar_name}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'code' })}</TableCell>

                <TableCell align="center">{route?.code}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'organization' })}
                </TableCell>

                <TableCell align="center">{parentOrganization}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell> {intl.formatMessage({ id: 'status' })}</TableCell>

                <StatusCell isDisabled={route?.disabled} showLabel />
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'groups' })}</TableCell>

                <TableCell align="center">
                  <div>
                    {!!groups?.length && (
                      <>
                        {groups.map(group => (
                          <Chip
                            key={group.id}
                            label={getTranslated(group, locale)}
                            style={{
                              margin: 2,
                              border: '1px solid #DADCE0',
                              backgroundColor: '#fff',
                            }}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'axes' })}</TableCell>

                <TableCell align="center">
                  {hasRouteWriteAccess && (
                    <Button
                      variant="outlined"
                      className={classes.addAxis}
                      onClick={() => setShowAddAxisModal(true)}
                    >
                      {intl.formatMessage({ id: 'addAxis' })}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {!!route?.axes?.length && (
            <div style={{ padding: '15px 0' }}>
              {route?.axes.map((axis: Axis) => (
                <AxisRow key={axis.id} {...{ ...axis, axesIds, routeId }} />
              ))}
            </div>
          )}
        </TableContainer>
      </div>

      <Button
        variant="outlined"
        color="default"
        type="button"
        className={classes.backButton}
        onClick={() => history.replace('/operations/routes')}
      >
        {intl.formatMessage({ id: 'backToRoutesList' })}
      </Button>

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteRoute,
          entityType: 'route',
          entityName: entityName || ' ',
          entityId: routeId,
          onfinishNavigation: '/operations/routes',
        }}
      />

      <AxisModal
        {...{
          isOpen: showAddAxisModal,
          setOpen: setShowAddAxisModal,
        }}
      />
    </div>
  );
};

export { ViewRoute };
