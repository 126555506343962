import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { addStop, getStation, successAlert } from 'actions';
import { StopFormState } from 'app/operations/stations/stop-helpers';
import { useTypedSelector } from 'helpers';
import { Station } from 'types';

import { StopForm } from './stop-form';

const initialState: StopFormState = {
  ar_name: '',
  en_name: '',
  lon: '',
  lat: '',
  code: '',
};

interface AddStopProps {
  setOpen: (newState: boolean) => void;
}

const AddStop: React.FC<AddStopProps> = ({ setOpen }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const match = useRouteMatch<{ id: string }>();

  const stationId = match.params.id;

  const stationData = useTypedSelector(
    state => state.stations.collection[stationId] as Station | undefined
  );

  const handleSubmit = async (
    values: StopFormState,
    { setSubmitting }: FormikHelpers<StopFormState>
  ) => {
    setSubmitting(true);
    try {
      await Promise.resolve(
        dispatch(
          addStop({
            ...values,
            station_id: stationId,
            organisation_id: stationData?.organisation_id,
          })
        )
      );

      setOpen(false);

      dispatch(getStation(stationId));

      dispatch(successAlert(intl.formatMessage({ id: 'successAddStop' })));
    } finally {
      setSubmitting(false);
    }
  };

  return <StopForm {...{ initialState, handleSubmit, setOpen }} />;
};

export { AddStop };
