// @ts-nocheck
import React, { createRef, useCallback, useEffect, useState } from "react";
//import Header from "components/Header";
import EditIcon from '@mui/icons-material/Edit';
import L from "leaflet";
import {  TileLayer, FeatureGroup, Marker, Popup ,Polygon,Polyline,Tooltip, useMapEvents, useMapEvent} from "react-leaflet";
import { MapContainer, useMap } from 'react-leaflet'
import { EditControl } from "react-leaflet-draw";
import osm from "./osm-providers";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import 'leaflet-draw/dist/leaflet.draw.css';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//import ExternalInfo from "components/ExternalInfo";
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import {axios} from './zonesAxios'
import EditZoneModal from "./EditZoneModal";
import { useDispatch, useSelector } from "react-redux";
import { storeAllZones, storeZones } from "actions/zoning.actions";
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import PlaceIcon from '@mui/icons-material/Place';
import Cookies from "js-cookie";
import PaidIcon from '@mui/icons-material/Paid';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Modal, Button, Form } from "react-bootstrap";
import "leaflet-polylinedecorator";
import MarkerToAndCreateDynamicZone from "./MarkerToAndCreateDynamicZone";
import Loading from "../images/loading2.svg";
import { useIntl } from 'react-intl';
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const markerIcon = new L.Icon({
  iconUrl: require('../images/btn2.png'),
  iconSize: [15, 15],

  popupAnchor: [0, -16], //[left/right, top/bottom]
});
const markerStartIcon = new L.Icon({
  iconUrl: require('../images/startR.png'),
  iconSize: [17, 17],

  popupAnchor: [0, -16], //[left/right, top/bottom]
});
const markerEndIcon = new L.Icon({
  iconUrl: require('../images/endR.png'),
  iconSize: [17, 17],

  popupAnchor: [0, -16], //[left/right, top/bottom]
});
  interface MyComponentProps {
    lineStringSearch: any;
    TMSRouteData: any;
    lineStringInfo: any;
    axisid: any;
    axiscode: any;
    boundsIsZones: any;
    permissionValue:any
  }
  
  const OurMap: React.FC<MyComponentProps> = ({ lineStringSearch, TMSRouteData, lineStringInfo, axisid, axiscode, boundsIsZones,permissionValue }) => {
    const intl = useIntl();
  console.log(lineStringSearch); //tms route id
  console.log(lineStringInfo); //  tms route geometry handled
  console.log(TMSRouteData);  //data 
	const dispatch= useDispatch();
  const allZones = useSelector (state => state.zoning.zones);
  const [boundsData,setBoundsData] = useState<any[]>([]);
  const [stopsData,setStopsData] =  useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [zones,setZones] = useState<any[]>([]);
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
  const ZOOM_LEVEL = 14;
  const cashPrice = useRef<HTMLInputElement>(null);
  const cardPrice = useRef<HTMLInputElement>(null);
  let user: any = '';
  let user2 :any= '';
  let userRefresh:any = '';
  const authData = localStorage.getItem("authData");
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    user = parsedAuthData.userData;
    user2 = parsedAuthData.userToken;
    userRefresh = parsedAuthData.userToken;
  }
  const [isFetchingAddZone, setIsFetchingAddZone] = useState(false); // State to manage button disabled state
  const [isFetchingDeleteZone, setIsFetchingDeleteZone] = useState(false); // State to manage button disabled state
  useEffect(()=>{
    //alert(JSON.stringify({ lineStringSearch, TMSRouteData, lineStringInfo, axisid, axiscode, boundsIsZones,permissionValue }));
    console.log(lineStringSearch); //tms route id
    console.log(lineStringInfo); //  tms route geometry handled
    console.log(TMSRouteData);  //data 
            const getStopsDetails  =  () => {
               axios.get(`${process.env.REACT_APP_MID_LINK}/getStopsDetails?id=${lineStringSearch}`,
              { headers:{Authorization : 'ApiKey ' + "0bf99d2e76c84c55816a21026db92e18" }})
              .then((res)=> {  
                console.log(res.data);
                console.log(res.data.stopList);
if(res.data==''){
  setStopsData([{lat : 2000,lng :  2000 , stopName : "FAKE_STOP_NAME",stopShortName : "FAKE_STOP_SHORT_NAME" ,stopCode: "FAKE_CODE"}]);
  setBoundsData([[2000,2000]]);     
        }else{
          if(res.data.stopList?.length==0){
            setStopsData([{lat : 2000,lng :  2000 , stopName : "FAKE_STOP_NAME",stopShortName : "FAKE_STOP_SHORT_NAME" ,stopCode: "FAKE_CODE"}]);
            setBoundsData([[2000,2000]]);  
          }else{
            let arrayOfCordinates=[];
          
            (res.data.stopList)?.forEach((rs,is) =>{
             let lat = Number(rs.geometry.replace("POINT", "").replace("(", "").replace(")", "").trim().split(" ")[rs.geometry.replace("POINT", "").replace("(", "").replace(")", "").trim().split(" ").length-1])
             let lng = Number(rs.geometry.replace("POINT", "").replace("(", "").replace(")", "").trim().split(" ")[0])
             arrayOfCordinates.push({"lat" : lat,"lng" : lng , "stopName" : rs.stopName,"stopShortName" : rs.stopShortName ,"stopCode": rs.stopCode});
           })
           let arrayBounds=[];
           for (var i = 0; i < arrayOfCordinates?.length; i++) {
            arrayBounds?.push(
            [
             (arrayOfCordinates[i].lat) ,
              (arrayOfCordinates[i].lng) 
            ])    
          }
          setBoundsData(arrayBounds);
          setStopsData(arrayOfCordinates);
          console.log(arrayBounds);
console.log(arrayOfCordinates);
          }
          }
             }).catch((err)=>{
                 console.log(err);
                 toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #m1`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                  }) 
                })
              }
              if(lineStringSearch=="EMPTY_ID"){
      setStopsData([{lat : 2000,lng :  2000 , stopName : "FAKE_STOP_NAME",stopShortName : "FAKE_STOP_SHORT_NAME" ,stopCode: "FAKE_CODE"}]);
              }else{
                getStopsDetails();
              }     
              const getZones =  () => {
                console.log(stopsData);
                console.log(boundsData);
                setIsLoading(true); // Set loading to true when data is being fetched
            
                 //setTimeout(() => { 
                 axios.get(`${process.env.REACT_APP_MID_LINK}/getZones?fareAxisID=${axisid}`)
                .then((res)=> {   
                  if(res.data.zones!=undefined){
                    dispatch(storeZones((res.data.zones)));
                    boundryBasedOnZones(res.data.zones);
                    console.log(res.data.zones);
                    setIsLoading(false); 
                  }else{
                    setZones([]);
                    dispatch(storeZones([]));
                    boundryBasedOnZones([]);      
                    setIsLoading(false);  
                  }    
                  }).catch((err)=>{
                   console.log(err);
                   toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #m2`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                    }) 
                    setIsLoading(false); 
                  })
               // },3000)
                }
                getZones();
                const boundryBasedOnZones=async(zzz)=>{
                  if(lineStringSearch=="EMPTY_ID"||boundsIsZones==1){
                    function get_polygon_centroid(pts) {
                      var first = pts[0], last = pts[pts.length-1];
                      if (first.x != last.x || first.y != last.y) pts.push(first);
                      var twicearea=0,
                      x=0, y=0,
                      nPts = pts.length,
                      p1, p2, f;
                      for ( var i=0, j=nPts-1 ; i<nPts ; j=i++ ) {
                         p1 = pts[i]; p2 = pts[j];
                         f = p1.x*p2.y - p2.x*p1.y;
                         twicearea += f;          
                         x += ( p1.x + p2.x ) * f;
                         y += ( p1.y + p2.y ) * f;
                      }
                      f = twicearea * 3;
                      return { x:x/f, y:y/f };
                   }
                   let arrayBoundsx=[];
                   (zzz)?.forEach((rss,iss) =>{
                    arrayBoundsx.push([get_polygon_centroid(rss.zoneGeometry[0]).x,get_polygon_centroid(rss.zoneGeometry[0]).y]);
                      })
                   if(arrayBoundsx?.length==0){
                    setBoundsData([[30.044420 , 31.235712]]);
                   }else{
                    setBoundsData(arrayBoundsx);
                   } 
                    console.log(arrayBoundsx);
                    console.log(zzz);
                }}
             console.log(boundsData);
           },[])
           const deleteZone = (zoneId,fareAxisId) => {
                 // Disable the button while the API request is being sent
    setIsFetchingDeleteZone(true);
             axios.post(`${process.env.REACT_APP_MID_LINK}/deleteZone?zoneId=${zoneId}&fareAxisId=${fareAxisId}`)
            .then((res)=> {  
            console.log(res.data);
            //setZones(res.data.zones);
            dispatch(storeZones((res.data.zones)));
            toast.success("Deleted Successfuly", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
              }) 
              }).catch((err)=>{
               console.log(err);
               toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #m3`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
                }) 
               setIsFetchingDeleteZone(false);
              }).finally(() => {
                // Re-enable the button after the API request is complete (success or error)
                setIsFetchingDeleteZone(false);
              })
          };
          const addZone = (zoneName,tmsRouteId,tmsRouteCode,fareAxisId
            ,fareAxisCode,stopCode,stopLatitude,stopLongitude,UID) => {
              console.log(zoneName);
              console.log(fareAxisId);
              console.log(fareAxisCode);
              console.log(stopCode);
              console.log(stopLatitude);
            // Disable the button while the API request is being sent
    setIsFetchingAddZone(true);
              if(cashPrice.current?.value.trim().length>5 || cardPrice.current?.value.trim().length>5){
                toast.error("Price Entered Is Very High", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                  })
                  setIsFetchingAddZone(false);
                }else if(cashPrice.current?.value.trim().length==0 || cardPrice.current?.value.trim().length==0){
                  toast.error("Empty Fields", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                    })
                    setIsFetchingAddZone(false);
                  }  
              else{
            
             axios.post(
              `${process.env.REACT_APP_MID_LINK}/addZone?zoneName=${zoneName}&tmsRouteId=${tmsRouteId}&tmsRouteCode=${tmsRouteCode}&fareAxisId=${fareAxisId}&fareAxisCode=${fareAxisCode}&stopCode=${stopCode}&stopLatitude=${stopLatitude}&stopLongitude=${stopLongitude}

              &zoneCashPrice=${cashPrice.current.value}&zoneCardPrice=${cardPrice.current.value}&UID=${UID}`
              
              )
            .then((res)=> {  
           
              if(res.data != "Request failed with status code 400"){
                console.log(res.data);
                dispatch(storeZones((res.data.zones)));
                toast.success("Added Successfuly", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                  }) 
                  handleClose();
              }else{
                toast.error(`Sorry , ${res.data}  , Code : #m6`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                  }) 
                  setIsFetchingAddZone(false);
              }
              }).catch((err)=>{
  
               console.log(err);
               toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #m7`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
                }) 
              
               setIsFetchingAddZone(false);
              }).finally(() => {
            
                // Re-enable the button after the API request is complete (success or error)
                setIsFetchingAddZone(false);
              })     

             }
             
          };

const purpleOptions = { color: '#2891CD' }
const greenOptions = { color: '#E33C39' }
  const [show, setShow] = useState(false);
  const [newStopName0, setNewStopName0] = useState("");
  const [newStopShortName, setNewStopShortName] = useState("");
  const [newStopName, setNewStopName] = useState("");
  const [newStopCode, setNewStopCode] = useState(null);
  const [newStopLat, setNewStopLat] = useState(0);
  const [newStopLng, setNewStopLng] = useState(0);
  const handleClose = () =>{
    setShow(false);
    setIsFetchingAddZone(false);
  } 
  const handleShow = (stopName0,stopShortName,stopName,stopCode,stopLat,stopLng) =>{

    setShow(true);
    setNewStopName0(stopName0);
    setNewStopShortName(stopShortName);
    setNewStopName(stopName);
    setNewStopCode(stopCode);
    setNewStopLat(stopLat);
    setNewStopLng(stopLng);
  }
  const takenOrNo=(x)=>{
       
    return  (allZones)?.filter(function checkAdult22(one) {
      return  one.stopCode == x
    })
   }  
  return ( 
    <div className="container"  >
     { user && user2 ? <div className="row ">
      <div className="col-md-8"style={{
            margin:"0px",padding:"0" }}>
{/*model for add new zone */}
<Modal show={show} onHide={handleClose} style={{zIndex:"9999969"}}>
    
    <Modal.Body>
    <h5> <center>
                        { (currentLanguageCode == "en-us" ? newStopName0 + " Zone " : " نطاق "  + newStopShortName) }
                        </center>
                       </h5>
    <div className="addZoneSec">
                               <h6 className='head-filter' style={{margin:"3px",textAlign:currentLanguageCode == "en-us" ? "left" : "right"}}> {intl.formatMessage({ id: 'CashPrice' })} </h6>
                               <input type="number" ref={cashPrice} className="form-control" style={{margin:"2px"}} />
                               <h6 className='head-filter' style={{margin:"3px",textAlign:currentLanguageCode == "en-us" ? "left" : "right"}}> {intl.formatMessage({ id: 'CardPrice' })}</h6>
                               <input type="number" ref={cardPrice}  className="form-control" style={{margin:"2px"}}  />
    
                             </div> 
    </Modal.Body>
    <Modal.Footer>
   
      <Button variant="outline-dark" onClick={handleClose}>
      {intl.formatMessage({ id: 'Cancel' })}
      </Button>
      <Button variant="success"disabled={isFetchingAddZone}  onClick={()=>addZone(newStopName,lineStringSearch,TMSRouteData?.routeCode,axisid,axiscode,newStopCode,newStopLat,newStopLng ,user2.uid)} >
      {intl.formatMessage({ id: 'Add' })}
      </Button>
    </Modal.Footer>
  </Modal>
{(boundsData.length>0 && stopsData.length>0 && lineStringInfo.length>0 )&& <MapContainer style={{
            width:"100%",height: '580px',borderRadius:"8px",margin:"2px" }}
          
            bounds={lineStringSearch =="EMPTY_ID" ||boundsIsZones==1 ? boundsData : lineStringInfo } zoom={ZOOM_LEVEL}  >  
             {/*<LocationFinderDummy /> */}
                       {allZones?.length>0 &&
		 allZones?.map((rs,is) =>{
      let handleCor=[];
      (rs.zoneGeometry[0])?.forEach((rss,iss) =>{
   handleCor.push([rss.x,rss.y]);
     })
      return(
             <Polygon pathOptions={greenOptions} key={is + Math.random()} positions={handleCor}>
               { <Popup>
                <h5 style={{textAlign:"center",padding:"3px"}}><ShareLocationIcon/> {intl.formatMessage({ id: 'ZoneInfo' })}  </h5>
          <h6 style={{textAlign:currentLanguageCode == "en-us" ? "left" : "right"}}>{(intl.formatMessage({ id: 'Name'})  + " : "+ rs?.zoneName )}</h6>  
         <h6 style={{textAlign:currentLanguageCode == "en-us" ? "left" : "right"}}>   {(intl.formatMessage({ id: 'CashPrice'})  + " : "+ rs?.zoneCashPrice )}</h6> 
         <h6 style={{textAlign:currentLanguageCode == "en-us" ? "left" : "right"}}>{(intl.formatMessage({ id: 'CardPrice'})  + " : "+ rs?.zoneCardPrice )} </h6>
          </Popup> }
             </Polygon> 
             )})
                 }
            <Polyline pathOptions={purpleOptions} positions={lineStringInfo}>     
           </Polyline>
               { (stopsData)?.map((rs,is) =>{

                      return(
                        <Marker key={is + Math.random()}   icon={
                          TMSRouteData?.routeVariant.toLowerCase() != "round" ? ( stopsData?.length>1 ?  ( is==0 ? markerStartIcon : (is==(stopsData?.length)-1) ? markerEndIcon : markerIcon ) : markerIcon ) : markerIcon}  
                            position={{
                              
                          lat: rs.lat,
                          lng:  rs.lng 
                        }}>
                        <Popup>
            
                       <h5> <center>
                        { (currentLanguageCode == "en-us" ? rs.stopName + " Stop " : " محطة "  + rs.stopShortName) }
                        </center>
                       </h5>
            
                       {
              
              takenOrNo(rs.stopCode)?.length>0 ?  <h6>{intl.formatMessage({ id: 'ThisStopIsIn' })}  <span style={{fontWeight:"bold"}}>{(currentLanguageCode == "en-us" ? rs.stopName + " Zone " :   rs.stopShortName)}</span></h6> :
            <div style={{textAlign:"center"}}>
{/*open add zone modal */}
           { permissionValue && <Button style={{margin:"5px"}} variant="success" onClick={() => handleShow(rs.stopName,rs.stopShortName,(rs.stopName + " / " + rs.stopShortName),rs.stopCode,rs.lat,rs.lng )}>
            {intl.formatMessage({ id: 'AddZone'})}
            </Button>}</div>
    
                       }
                     </Popup>

                       </Marker>           
                           )     
   }) }
                      <TileLayer
                        url={osm.maptiler.url}
                        attribution={osm.maptiler.attribution}
                      />
                      {/* marker to create dynamic zone */}
                      {permissionValue && <MarkerToAndCreateDynamicZone lineStringSearch={lineStringSearch} TMSRouteData={TMSRouteData}axisid={axisid}axiscode={axiscode} />}
                    </MapContainer>} </div>
                    <div className="col-md-4">
            <div className='Info-all' style={{width:"100%",padding:"2px",margin:"0px",border:"1px solid #f2efe9",borderRadius:"8px"}}>
   <h3 style={{textAlign:"center",padding:"5px",border:"1px solid lightgray"}}><ShareLocationIcon style={{ margin:"3px",marginBottom:"7px",fontSize: '25px' }}/> {intl.formatMessage({ id: 'zones' })}</h3> 
        { user && user2 ?
        <div className="parentTable3  " style={{padding:"2px",margin:"0px",height:"510px",borderRadius:"8px"}}>
      <table className="table   ">
    <thead>
    <tr>
				
<th> {intl.formatMessage({ id: 'Name' })}</th>
<th> <PaidIcon/></th>
<th>  <CreditCardIcon/></th>
<th> </th>
<th> </th>
    </tr>
  </thead>
  <tbody >

        {
   
              isLoading ? (
  
                <tr> <td colSpan={5}style={{textAlign:"center",padding:"8px"}}>
        <img src={Loading} alt="loading"  style={{width:"50px"}} /></td>
        </tr>
               
            ) : 
        allZones?.length>0 ? 
		 allZones?.map((rs,is) =>{

      return(
      <tr key={rs.zoneId + Math.random()}>

        <td>{rs.zoneName}</td>
        <td >{rs.zoneCashPrice}</td>
        <td >{rs.zoneCardPrice}</td>
 <td style={{padding:"5px 0 0 0"}}> 
 {/*edit zone in table */}
        {permissionValue && <EditZoneModal axisid={axisid} axiscode={axiscode} tmsRouteId={lineStringSearch} tmsRouteCode={TMSRouteData?.routeCode} zoneId={rs.zoneId} zoneName={rs.zoneName}
          zoneCashPrice ={rs.zoneCashPrice} zoneCardPrice = {rs.zoneCardPrice} stopCode={rs.stopCode} 
      stopsData={stopsData} zoneGeometry ={rs.zoneGeometry} /> }
    </td>
<td style={{padding:"5px 0 0 0"}}>    
{permissionValue && <button  className="delBtn"  data-bs-toggle="modal" data-bs-target={"#exampleModal"+ rs.zoneId + 100000000000000}><DeleteIcon/></button>}
<div className="modal fade" style={{zIndex:"9999969"}} id={"exampleModal"+ rs.zoneId + 100000000000000} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
     
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <h5>  {currentLanguageCode == "en-us" ? `Are You Sure You Want To Delete` : "هل أنت متأكد انك تريد الحذف"}</h5>
        </div>
      <div className="modal-footer">
      <button type="button"onClick={()=>setIsFetchingDeleteZone(false)} className="btn btn-outline-dark" data-bs-dismiss="modal">{intl.formatMessage({ id: 'Cancel' })} </button>
        <button type="button" 
        className="btn btn-danger"data-bs-dismiss="modal"
        disabled={isFetchingDeleteZone}
      onClick={()=>deleteZone(rs.zoneId,axisid)}>{intl.formatMessage({ id: 'Delete' })} </button>
  
      </div>
    </div>
  </div>
</div>
</td>
      </tr>)})

		:  <tr> <td colSpan={5}style={{textAlign:"center",padding:"15px"}}>{intl.formatMessage({ id: 'NoDateAvailable'})}</td></tr>

	} 
  </tbody>  </table> </div> : <h2>
      <center> {intl.formatMessage({ id: 'LoginAgain' })} </center>
        </h2>}
    </div></div>
    </div>: <h2>
      <center> {intl.formatMessage({ id: 'LoginAgain' })} </center>
       </h2>}
    <div>

    </div>
    </div>)
 
}

export default React.memo(OurMap)