import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';

import rootReducer from 'reducers';

const loggerMiddleware = createLogger({ collapsed: true });

const configureDevStore = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware, promiseMiddleware, loggerMiddleware)
  )
);

export { configureDevStore };
