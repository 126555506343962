import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { permissionConstants } from '_constants';
import { API } from 'helpers';
import { AppState } from 'types';

const getAdminFullPermissions =
  (admin_id: string) =>
  (dispatch: Dispatch, getState: () => AppState): FluxStandardAction => {
    const state = getState();
    const loggedInAdminId = state.authentication?.user.userData.id;

    return dispatch({
      type: permissionConstants.GET_ADMIN_FULL_PERMISSIONS,
      payload: API.get('/v2/admin_general_permissions/get_full_permissions', {
        params: { admin_id },
      }),
      meta: { admin_id, isLoggedInAdmin: loggedInAdminId === admin_id },
    });
  };

const getGeneralPermissions =
  () =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: permissionConstants.GET_ِGENERAL_PERMISSIONS,
      payload: API.get('/v2/general_permissions'),
    });

const fetchLoggedAdminCategories =
  () =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: permissionConstants.GET_LOGGED_IN_ADMIN_CATEGORIES,
      payload: API.get('/v2/admin_general_permissions/get_admin_categories/'),
    });

const getLoggedAdminOrganizations =
  (categoryName: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: permissionConstants.GET_LOGGED_IN_ADMIN_ORGANIZATIONS,
      payload: API.get(
        `/v2/admin_general_permissions/get_admin_organisations?category=${categoryName}`
      ),
    });

const getLoggedAdminGroups =
  (categoryName: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: permissionConstants.GET_LOGGED_IN_ADMIN_GROUPS,
      payload: API.get(
        `/v2/admin_general_permissions/get_admin_groups?category=${categoryName}`
      ),
    });

const getAvailableAdminPermissions =
  (
    admin_id: string,
    category?: string,
    permission_level_type?: string,
    permission_level_id?: string
  ) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: permissionConstants.GET_ADMIN_AVAILABLE_PERMISSIONS,
      payload: API.get(
        '/v2/admin_general_permissions/list_of_available_permission/',
        {
          params: {
            admin_id,
            category,
            permission_level_type,
            permission_level_id,
          },
        }
      ),
      meta: {
        category,
        permission_level_type,
        permission_level_id,
        admin_id,
      },
    });

const editAdminCategoryPermissions =
  (
    admin_id: string,
    permissions_ids: string[],
    permission_level_type?: 'Group' | 'Organisation',
    permission_level_id?: string,
    category?: string
  ) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: permissionConstants.EDIT_ADMIN_CATEGORY_PERMISSIONS,
      payload: API.post(
        '/v2/admin_general_permissions/edit_permissions_for_admin_in_category',
        {
          admin_id,
          category,
          permission_level_id,
          permission_level_type,
          permissions_ids,
        }
      ),
    });

const editAllPermissionsForOrganizationOrGroup =
  (
    admin_id: string,
    permission_level_type: 'Organisation' | 'Group',
    permission_level_id: string,
    type: 'allow' | 'deny'
  ) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: permissionConstants.EDIT_ALL_PERMISSIONS_FOR_ORGANIZATION_OR_GROUP,
      payload: API.post(
        '/v2/admin_general_permissions/give_full_organisation_or_group_permissions_to_admin',
        {
          admin_id,
          permission_level_type,
          permission_level_id,
          type,
        }
      ),
    });

const grantGeneralPermission =
  (
    admin_id: string,
    general_permission_id: string,
    permissions_to_be_added: string[],
    permissions_to_be_deleted: string[]
  ) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: permissionConstants.GRANT_GENERAL_PERMISSION,
      payload: API.post('/v2/admin_general_permissions/grant', {
        admin_id,
        general_permission_id,
        permissions_to_be_added,
        permissions_to_be_deleted,
      }),
    });

export {
  getAvailableAdminPermissions,
  getGeneralPermissions,
  getAdminFullPermissions,
  fetchLoggedAdminCategories,
  getLoggedAdminOrganizations,
  getLoggedAdminGroups,
  editAdminCategoryPermissions,
  editAllPermissionsForOrganizationOrGroup,
  grantGeneralPermission,
};
