import axios, { AxiosError, AxiosInstance } from 'axios';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

import { userConstants, alertConstants } from '_constants';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import store from 'store';

const getToken = () => store.getState().authentication?.user.userToken;

const instanceAPI = (): AxiosInstance => {
  const instance = axios.create({
    baseURL:
      process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_BASE_URL
        : process.env.REACT_APP_LOCAL_BASE_URL,
  });

  instance.interceptors.request.use(config => {
    const newConfig = { ...config };
    const token = getToken();

    if (token) {
      const currentLang =
        localStorage.getItem('EASY_MOBILITY_LANGUAGE') === LOCALES.ARABIC
          ? 'ar'
          : 'en';

      newConfig.headers = {
        Accept: 'application/json',
        ...token,
      };

      newConfig.params = {
        ...newConfig.params,
        lang: currentLang,
      };
    }

    newConfig.paramsSerializer = params =>
      qs.stringify(params, { arrayFormat: 'brackets' });

    return newConfig;
  });

  instance.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      const token = getToken();

      const hasCustomErrors =
        !isEmpty(error.response?.data?.errors) &&
        !isArray(error.response?.data?.errors);

      const errorMessage = error.response?.data?.errors?.[0];
      const err = errorMessage || error.toString();

      if (token) {
        if (error.config && error.response?.status === 404) {
          if (error.config.url === '/auth/sign_out') {
            return store.dispatch({
              error: true,
              type: userConstants.USER_LOGOUT_INVALID_TOKEN,
            });
          }
        }

        if (error.config && error.response?.status === 401) {
          if (error.config.url === '/auth/validate_token') {
            return store.dispatch({
              error: true,
              type: userConstants.USER_LOGOUT_INVALID_TOKEN,
            });
          }

          return instance
            .get('/auth/validate_token')
            .catch(() => Promise.reject(error));
        }
      }

      // Error should be handled by the caller if it has custom errors
      if (!hasCustomErrors) {
        store.dispatch({ type: alertConstants.ERROR, payload: err });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const API = instanceAPI();

export { instanceAPI, API };
