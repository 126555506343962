import React, { useContext, useEffect } from 'react';

import {
  Button,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Header } from 'app/super-dash/home-header';
import { SideNavContext } from 'context/side-nav.context';

import AccessDeniedIcon from './access-denied-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      inset: 0,
      paddingTop: 72,
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: { position: 'fixed', top: 0, left: 0, right: 0 },
    card: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: { textAlign: 'center' },
    title: {
      fontWeight: 800,
      fontSize: 25,
      letterSpacing: -0.04,
      margin: '24px 0 18px',
    },
    backButton: { marginTop: 28, width: 154, borderRadius: 30, height: 44 },
  })
);

const NotAuthorized: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { showSideNav, setShowSideNav } = useContext(SideNavContext);

  useEffect(() => {
    if (showSideNav) {
      setShowSideNav?.(false);
    }

    return () => {
      if (!showSideNav) {
        setShowSideNav?.(true);
      }
    };
  }, [setShowSideNav, showSideNav]);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Header />
      </div>

      <Card variant="outlined" className={classes.card}>
        <CardContent className={classes.content}>
          <img src={AccessDeniedIcon} alt="Access Denied" />

          <Typography
            align="center"
            variant="h3"
            color="primary"
            className={classes.title}
          >
            Access Denied
          </Typography>

          <Typography align="center" variant="subtitle1" color="textSecondary">
            You do not have access to this area of the website.
          </Typography>

          <Button
            variant="contained"
            color="primary"
            className={classes.backButton}
            onClick={() => history.go(-1)}
          >
            Go Back
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export { NotAuthorized };
