import { FluxStandardAction } from 'redux-promise-middleware';

import { vehicleClassesConstants } from '_constants';
import { AppState, VehicleClass } from 'types';

const vehicleClasses = (
  state: AppState['vehicleClasses'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['vehicleClasses'] => {
  switch (action.type) {
    case `${vehicleClassesConstants.GET_VEHICLE_CLASSES}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${vehicleClassesConstants.GET_VEHICLE_CLASSES}_FULFILLED`: {
      const vehicleClassList: AppState['vehicleClasses']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: VehicleClass) => {
          vehicleClassList[item.id] = item;
        });

        return {
          collection: { ...vehicleClassList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }
    case `${vehicleClassesConstants.GET_VEHICLE_CLASSES}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${vehicleClassesConstants.GET_VEHICLE_CLASS}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${vehicleClassesConstants.GET_VEHICLE_CLASS}_FULFILLED`: {
      const vehicleClassData = action.payload.data?.response;

      if (vehicleClassData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [vehicleClassData.id]: vehicleClassData,
          },
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${vehicleClassesConstants.ADD_VEHICLE_CLASS}_FULFILLED`: {
      const vehicleClassData = action.payload.data?.response;

      return {
        ...state,
        collection: {
          ...state.collection,
          [vehicleClassData.id]: vehicleClassData,
        },
      };
    }

    case `${vehicleClassesConstants.EDIT_VEHICLE_CLASS}_FULFILLED`: {
      const vehicleClassData = action.payload.data?.response;

      return {
        ...state,
        collection: {
          ...state.collection,
          [vehicleClassData.id]: vehicleClassData,
        },
      };
    }

    case `${vehicleClassesConstants.DELETE_VEHICLE_CLASS}_FULFILLED`: {
      const deletedVehicleClassId = action.meta.vehicleClassId;

      const newState = { ...state };
      delete newState.collection[deletedVehicleClassId];

      return newState;
    }

    default:
      return state;
  }
};

export { vehicleClasses };
