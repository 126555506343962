import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TableHead,
  Chip,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { getVehicle, deleteVehicle } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { useStyles } from 'app/components/shared/styles/view-entity';
import { StatusCell } from 'app/components/shared/table';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithReadPermission,
} from 'selectors';
import { Vehicle } from 'types';

import DeleteIcon from 'assets/delete-icon.svg';
import EditIcon from 'assets/edit-icon.svg';

const ViewVehicle: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);

  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const vehicleId = match.params.id;

  const vehicle = useTypedSelector(
    state => state.vehicles.collection[vehicleId] as Vehicle | undefined
  );

  const prevVehicle = useRef(vehicle);

  const fullName = `${getTranslated(vehicle?.vehicle_type, locale, 'model')} ${
    vehicle?.code || ''
  }`;

  const hasVehicleWriteAccess = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Vehicles', vehicleId)
  );

  const parentOrganization = getTranslated(vehicle?.organisation, locale);

  const groups = useTypedSelector(state =>
    getEntityGroupsWithReadPermission(state, 'Vehicles', vehicleId)
  );

  // eslint-disable-next-line consistent-return
  const renderCardActions = () => {
    if (hasVehicleWriteAccess)
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.editButton}
            component={Link}
            to={`${baseURL}/edit`}
          >
            <img
              src={EditIcon}
              alt="Edit"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'edit' })}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            className={`${classes.editButton} ${classes.deleteButton}`}
            component={Button}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'delete' })}
          </Button>
        </>
      );
  };

  useEffect(() => {
    if (vehicle) {
      setLoading(false);
      prevVehicle.current = vehicle;
    } else if (!prevVehicle.current) {
      dispatch(getVehicle(vehicleId));
    }
  }, [dispatch, vehicle, vehicleId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.viewContainer}>
      <CardTitle title={intl.formatMessage({ id: 'vehicleInfo' })} />

      <div className={classes.infoWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" aria-label="Vehicle Info Table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeadCell}>
                  {fullName}
                </TableCell>

                <TableCell
                  style={{ textAlign: 'end' }}
                  className={classes.tableHeadCell}
                >
                  {renderCardActions()}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'vehicleType' })}
                </TableCell>

                <TableCell>
                  {getTranslated(vehicle?.vehicle_type, locale, 'model')}
                </TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'vehicleCode' })}
                </TableCell>

                <TableCell>{vehicle?.code}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'plateNumber' })}
                </TableCell>

                <TableCell>{vehicle?.plate_number}</TableCell>
              </TableRow>

              <TableRow className={classes.row} style={{ paddingTop: 10 }}>
                <TableCell>
                  {intl.formatMessage({ id: 'organization' })}
                </TableCell>

                <TableCell>{parentOrganization}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'groups' })}</TableCell>

                <TableCell align="center" className={classes.groupsContainer}>
                  {groups?.length
                    ? groups.map(elem => {
                        if (elem.en_name) {
                          return (
                            <Chip
                              label={getTranslated(elem, locale)}
                              variant="outlined"
                              key={elem.id}
                              className={classes.groupNameStyle}
                            />
                          );
                        }

                        return null;
                      })
                    : null}
                </TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'status' })}</TableCell>

                <StatusCell isDisabled={vehicle?.disabled} showLabel />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Button
        variant="outlined"
        color="default"
        type="button"
        className={classes.backButton}
        onClick={() => history.replace('/operations/vehicles')}
      >
        {intl.formatMessage({ id: 'backToVehiclesList' })}
      </Button>

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteVehicle,
          entityType: 'vehicle',
          entityName: fullName || ' ',
          entityId: vehicleId,
          onfinishNavigation: '/operations/vehicles',
        }}
      />
    </div>
  );
};

export { ViewVehicle };
