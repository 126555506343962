import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, getRoute, editRoute, assignRouteGroups } from 'actions';
import { NotAuthorized } from 'app/components/not-authorized';
import { RouteForm } from 'app/operations/routes/route-form';
import { RouteFormState } from 'app/operations/routes/route-helpers';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithWritePermission,
} from 'selectors';
import { Route } from 'types';

const EditRoute: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const [isLoading, setLoading] = useState(true);
  const intl = useIntl();

  const routeId = match.params.id;

  const routeData = useTypedSelector(
    state => state.routes.collection[routeId] as Route | undefined
  );

  useEffect(() => {
    if (routeData) {
      setLoading(false);
    } else {
      dispatch(getRoute(routeId));
    }
  }, [dispatch, routeData, routeId]);

  const groups = useTypedSelector(state =>
    getEntityGroupsWithWritePermission(state, 'Routes', routeId)
  );

  const initialState: RouteFormState = useMemo(
    () => ({
      englishName: routeData?.en_name || '',
      arabicName: routeData?.ar_name || '',
      code: routeData?.code || '',
      organizationId: routeData?.organisation_id || '',
      organization: routeData?.organisation || undefined,
      groups: groups?.map(group => group.id) || [],
      status: routeData ? !routeData?.disabled : true,
    }),
    [routeData, groups]
  );

  const hasWriteAccessToCurrentRoute = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Routes', routeId)
  );

  const handleSaveGroups = async currentValues => {
    const groupsToBeAdded = currentValues.filter(
      (id: string) => !initialState.groups.includes(id)
    );

    const groupsToBeDeleted = initialState.groups.filter(
      id => !currentValues.includes(id)
    );

    await Promise.resolve(
      dispatch(
        assignRouteGroups({
          routeIdList: [routeId],
          addedGroupIdList: groupsToBeAdded,
          removedGroupIdList: groupsToBeDeleted,
        })
      )
    );
  };

  const handleSubmit = (
    values: RouteFormState,
    { setSubmitting }: FormikHelpers<RouteFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        editRoute(routeId, {
          en_name: values.englishName,
          ar_name: values.arabicName,
          code: values.code,
          organisation_id: values.organizationId,
          disabled: !values.status,
        })
      )
    )
      .then(async () => {
        await handleSaveGroups(values.groups);
        history.push(`${baseURL}`.replace('edit', ''));

        dispatch(successAlert(intl.formatMessage({ id: 'successEditRoute' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!hasWriteAccessToCurrentRoute) {
    return <NotAuthorized />;
  }

  return <RouteForm {...{ initialState, handleSubmit, isEditForm: true }} />;
};

export { EditRoute };
