import { FluxStandardAction } from 'redux-promise-middleware';

import { tripSaleConstants } from '_constants';
import { AppState, TripSale } from 'types';

const tripSales = (
  state: AppState['tripSales'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['tripSales'] => {
  switch (action.type) {
    case `${tripSaleConstants.GET_TRIP_SALES}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${tripSaleConstants.GET_TRIP_SALES}_FULFILLED`: {
      const tripSaleList: AppState['tripSales']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: TripSale) => {
          tripSaleList[item.id] = item;
        });

        return {
          collection: { ...tripSaleList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${tripSaleConstants.GET_TRIP_SALES}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    default:
      return state;
  }
};

export { tripSales };
