/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
import _, { keyBy, keys, map, mergeWith, pick } from 'lodash';
import toArray from 'lodash/toArray';
import { createSelector } from 'reselect';

import { getLoggedAdminData, getLoggedAdminId } from 'selectors';
import {
  AppState,
  AdminPermissionList,
  MasterConfigSubSystem,
  OperationsSubSystem,
  Permission,
  Group,
  SuperAdminCategories,
  PermissionCategories,
  Organization,
} from 'types';

function mergeObjectsWithArrays(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return _.unionWith(objValue, srcValue, _.isEqual);
  }
  if (_.isObject(objValue)) {
    return _.mergeWith(objValue, srcValue, mergeObjectsWithArrays);
  }
  if (_.isArray(srcValue)) {
    return _.unionWith(srcValue, objValue, _.isEqual);
  }

  return srcValue;
}

export const getIsSuperAdmin = (state: AppState) => {
  const admin = getLoggedAdminData(state);

  return admin?.is_super_admin;
};

export const getGeneralPermissions = (state: AppState) =>
  state.permissions.generalPermissions;

export const getSuperAdminPermissionCategories = createSelector(
  getGeneralPermissions,
  generalPermissions => {
    const categories = {} as { [key: string]: SuperAdminCategories };

    generalPermissions.forEach(permission => {
      const { category } = permission;

      // eslint-disable-next-line no-unsafe-optional-chaining
      const [subSystem, permissionType] = permission.action?.split('.');
      if (permissionType) {
        mergeWith(
          categories,
          {
            [category]: {
              identifier: category,
              en_name: permission.category_name_en,
              ar_name: permission.category_name_ar,
              permissions: {
                [subSystem]: [
                  {
                    action_name: permissionType,
                    ...pick(permission, ['ar_name', 'en_name', 'id']),
                  },
                ],
              },
            },
          },
          mergeObjectsWithArrays
        );
      }
    });

    const { master_configuration, ...otherCategories } = categories;

    return { master_configuration, subSystems: otherCategories };
  }
);

export const getAdminMasterConfigPermissionsById = (
  state: AppState,
  id: string
) => {
  const permissionList = state.permissions.collection[id];
  let masterConfigPermissions: AdminPermissionList['master_configuration'] = {};

  if (permissionList) {
    permissionList.configurations_permission_level.forEach(subSystem => {
      masterConfigPermissions = {
        ...masterConfigPermissions,
        [subSystem.sub_system_name]: subSystem.allowed_actions,
      };
    });
  }

  return masterConfigPermissions;
};

export const getAdminGroupLevelPermissionsById = (
  state: AppState,
  id: string
) => {
  const permissionList = state.permissions.collection[id];
  let groupLevelPermissions: AdminPermissionList['permissionLevel']['groups_permission_level'] =
    {};

  if (permissionList) {
    permissionList.groups_permission_level.forEach(({ group, sub_systems }) => {
      const subSystems = {};

      sub_systems.forEach(system => {
        subSystems[system.sub_system_name] = system.allowed_actions;
      });

      groupLevelPermissions = {
        ...groupLevelPermissions,
        [group.id]: {
          id: group.id,
          ar_name: group.ar_name,
          en_name: group.en_name,
          organisation: group.organisation,
          subSystems: { ...subSystems },
        },
      };
    });
  }

  return groupLevelPermissions;
};

export const getAdminOrganizationLevelPermissionsById = (
  state: AppState,
  id: string
) => {
  const permissionList = state.permissions.collection[id];
  let organizationLevelPermissions: AdminPermissionList['permissionLevel']['organizations_permission_level'] =
    {};

  if (permissionList) {
    permissionList.organisations_permission_level.forEach(
      ({ organisation, sub_systems }) => {
        const subSystems = {};

        sub_systems.forEach(system => {
          subSystems[system.sub_system_name] = system.allowed_actions;
        });

        organizationLevelPermissions = {
          ...organizationLevelPermissions,
          [organisation.id]: {
            id: organisation.id,
            ar_name: organisation.ar_name,
            en_name: organisation.en_name,
            subSystems: { ...subSystems },
          },
        };
      }
    );
  }

  return organizationLevelPermissions;
};

export const getAdminPermissionLevelCategoriesById = (
  state: AppState,
  id: string
) => {
  const permissionList = state.permissions.collection[id];

  const result: AdminPermissionList['permissionLevelCategories'] =
    {} as AdminPermissionList['permissionLevelCategories'];

  if (permissionList) {
    permissionList.allowed_modules_and_subsystem
      // to be removed
      .filter(
        module =>
          !['master_configuration', 'user_management'].includes(
            module.module_name
          )
      )
      .forEach(module => {
        const categoryName = module.module_name;
        let data = {};

        let categoryData = {
          identifier: categoryName,
          en_name: module.category_name_en,
          ar_name: module.category_name_ar,
          permissionLevels: {},
        };

        module.allowed_sub_systems.forEach(subSystem => {
          const subSystemName = subSystem.sub_system;

          subSystem.permission_levels.forEach(level => {
            permissionList[level].forEach(entity => {
              const isGroupLevel = level.includes('group');

              const entityInfo = isGroupLevel
                ? entity.group
                : entity.organisation;

              const subSystemInfo = entity.sub_systems.find(
                system => system.sub_system_name === subSystem.sub_system
              );

              if (subSystemInfo)
                mergeWith(
                  data,
                  {
                    [level]: {
                      [entityInfo.id]: {
                        ...entityInfo,
                        subSystems: {
                          [subSystemName]: subSystemInfo.allowed_actions,
                        },
                      },
                    },
                  },
                  mergeObjectsWithArrays
                );
            });
          });

          categoryData.permissionLevels = data;
        });

        result[categoryName] = {
          ...result[categoryName],
          ...categoryData,
        };
      });
  }

  return result;
};

const reshapePermissionLevels = (result, permissionLevel) => {
  permissionLevel?.forEach(permission => {
    const organization =
      permission.organisation || permission.group.organisation;

    const subSystems = _(permission.sub_systems).reduce((res, value) => {
      res[value.sub_system_name] = {
        ...(permission.group && { groupsWithAccess: [permission.group] }),
        permissions: value.allowed_actions,
      };

      return res;
    }, {});

    mergeWith(
      result,
      {
        [organization.id]: {
          organization,
          subSystems,
        },
      },
      mergeObjectsWithArrays
    );
  });
};

export const getAdminPermissionsByOrganization = (
  state: AppState,
  id: string
) => {
  const permissionList = state.permissions.collection[id];

  const groups_permission_level = permissionList?.groups_permission_level;

  const organisations_permission_level =
    permissionList?.organisations_permission_level;

  const result: AdminPermissionList['permissionsByOrganization'] =
    {} as AdminPermissionList['permissionsByOrganization'];

  reshapePermissionLevels(result, groups_permission_level);
  reshapePermissionLevels(result, organisations_permission_level);

  return result;
};

export const getAdminAllowedModulesAndSubSystemsById = (
  state: AppState,
  id: string
) => {
  const permissionList = state.permissions.collection[id];
  let allowedModulesAndSubsystem: AdminPermissionList['allowed_modules_and_subsystem'] =
    {};

  if (permissionList) {
    permissionList.allowed_modules_and_subsystem.forEach(module => {
      let subSystems;

      if (module.module_name === 'master_configuration') {
        subSystems = module.allowed_sub_systems.map(
          subSystem => subSystem.sub_system
        );
      } else {
        subSystems = {};

        module.allowed_sub_systems.forEach(subSystem => {
          subSystems[subSystem.sub_system] = subSystem.permission_levels;
        });
      }

      allowedModulesAndSubsystem = {
        ...allowedModulesAndSubsystem,
        [module.module_name]: subSystems,
      };
    });
  }

  return allowedModulesAndSubsystem;
};

export const getLoggedAdminPermissions = (
  state: AppState
): AdminPermissionList | null => {
  const adminId = getLoggedAdminId(state);

  const adminPermissions = adminId
    ? state.permissions.collection[adminId]
    : null;

  if (adminId && adminPermissions) {
    const permissionLevelCategories = getAdminPermissionLevelCategoriesById(
      state,
      adminId
    );

    const permissionsByOrganization = getAdminPermissionsByOrganization(
      state,
      adminId
    );

    const allowed_modules_and_subsystem =
      getAdminAllowedModulesAndSubSystemsById(state, adminId);

    const master_configuration = getAdminMasterConfigPermissionsById(
      state,
      adminId
    );

    const groups_permission_level = getAdminGroupLevelPermissionsById(
      state,
      adminId
    );

    const organizations_permission_level =
      getAdminOrganizationLevelPermissionsById(state, adminId);

    return {
      allowed_modules_and_subsystem,
      master_configuration,
      permissionLevelCategories,
      permissionLevel: {
        groups_permission_level,
        organizations_permission_level,
      },
      permissionsByOrganization,
    };
  }

  return null;
};

export const makeGetCategoriesForPermissionLevel = () =>
  createSelector(
    [
      getLoggedAdminPermissions,
      getIsSuperAdmin,
      getSuperAdminPermissionCategories,
      (__, props) => props.type,
      (__, props) => props.permissionLevelId,
    ],
    (
      permissions,
      isSuperAdmin,
      superAdminCategories,
      type,
      permissionLevelId
    ) => {
      if (isSuperAdmin) {
        return toArray(superAdminCategories?.subSystems || {}) || {};
      }
      console.log(type); // to be removed

      const allowedCategories = [] as
        | AdminPermissionList['permissionLevelCategories'][];

      if (permissions) {
        Object.values(permissions.permissionLevelCategories)?.forEach(
          (category: AdminPermissionList['permissionLevelCategories']) => {
            if (
              category?.permissionLevels?.organisations_permission_level?.[
                permissionLevelId
              ] ||
              toArray(category?.permissionLevels?.groups_permission_level).some(
                level => level.organisation?.id === permissionLevelId
              )
            ) {
              allowedCategories.push(category);
            }
          }
        );
      }

      return allowedCategories;
    }
  ) as (
    state: AppState,
    props: {
      type: 'Group' | 'Organisation' | undefined;
      permissionLevelId: string | undefined;
    }
  ) => PermissionCategories;

export const makeGetSubSystemPermissions = () =>
  createSelector(
    [
      getSuperAdminPermissionCategories,
      getIsSuperAdmin,
      getLoggedAdminPermissions,
      (__, props) => props.level,
      (__, props) => props.systemName,
      (__, props) => props.permissionLevelId,
    ],
    (
      superAdminCategories,
      isSuperAdmin,
      loggedAdminPermissions,
      level,
      systemName,
      permissionLevelId
    ) => {
      if (isSuperAdmin) {
        return (
          keys(
            superAdminCategories?.subSystems?.[systemName]?.permissions || {}
          ) || []
        );
      }
      console.log(level); // to be removed

      const organizationPermissions = keys(
        loggedAdminPermissions?.permissionLevelCategories?.[systemName]
          ?.permissionLevels?.organisations_permission_level?.[
          permissionLevelId as string
        ]?.subSystems || {}
      );

      const filteredGroupsData = _.pickBy(
        loggedAdminPermissions?.permissionLevelCategories?.[systemName]
          ?.permissionLevels.groups_permission_level,
        group => group.organisation.id === permissionLevelId
      );

      const subsystemsKeys = _.uniq(
        _.flatMap(filteredGroupsData, group => _.keys(group.subSystems))
      );

      return [...organizationPermissions, ...subsystemsKeys];
    }
  ) as (
    state: AppState,
    props: {
      level: string;
      systemName: string;
      permissionLevelId: string | undefined;
    }
  ) => string[];

export const getAdminListOfPermissionsById = (
  state: AppState,
  id: string
): AdminPermissionList | null => {
  const adminPermissions = state.permissions.collection[id];

  if (adminPermissions) {
    const permissionLevelCategories = getAdminPermissionLevelCategoriesById(
      state,
      id
    );

    const permissionsByOrganization = getAdminPermissionsByOrganization(
      state,
      id
    );

    const master_configuration = getAdminMasterConfigPermissionsById(state, id);

    const groups_permission_level = getAdminGroupLevelPermissionsById(
      state,
      id
    );

    const organizations_permission_level =
      getAdminOrganizationLevelPermissionsById(state, id);

    return {
      master_configuration,
      permissionLevelCategories,
      permissionLevel: {
        groups_permission_level,
        organizations_permission_level,
      },
      permissionsByOrganization,
    };
  }

  return null;
};

export const getCurrentAdminCategories = (state: AppState) =>
  state.permissions.categories;

export const hasReadAccessToMasterConfigSubSystem = (
  state: AppState,
  subSystem: MasterConfigSubSystem
): boolean => {
  const isSuperAdmin = getIsSuperAdmin(state);

  if (isSuperAdmin) return true;

  const adminPermissions = getLoggedAdminPermissions(state);

  if (adminPermissions) {
    const configPermissions = adminPermissions?.master_configuration;
    const subSystemPermissions = configPermissions?.[subSystem];

    if (subSystemPermissions) {
      return subSystemPermissions.some(
        permission => permission.action_name === 'read'
      );
    }
  }

  return false;
};

export const getLoggedAdminMasterConfigPermissions = createSelector(
  [
    getIsSuperAdmin,
    getLoggedAdminPermissions,
    getSuperAdminPermissionCategories,
  ],
  (isSuperAdmin, loggedAdminPermissions, superAdminPermissionCategories) => {
    if (isSuperAdmin) {
      return (
        superAdminPermissionCategories?.master_configuration?.permissions || {}
      );
    }

    return loggedAdminPermissions?.master_configuration || {};
  }
);

export const hasWriteAccessToMasterConfigSubSystem = (
  state: AppState,
  subSystem: MasterConfigSubSystem
): boolean => {
  const isSuperAdmin = getIsSuperAdmin(state);
  if (isSuperAdmin) return true;

  const adminPermissions = getLoggedAdminPermissions(state);

  if (adminPermissions) {
    const configPermissions = adminPermissions?.master_configuration;
    const subSystemPermissions = configPermissions?.[subSystem];
    if (subSystemPermissions) {
      return subSystemPermissions.some(
        permission => permission.action_name === 'write'
      );
    }
  }

  return false;
};

export const canReadMasterConfig = (state: AppState): boolean =>
  hasReadAccessToMasterConfigSubSystem(state, 'Organizations');

export const getSystemReadPermissions = (
  state: AppState,
  system: string
): { [key: string]: boolean } => {
  const adminPermissions = getLoggedAdminPermissions(state);
  const subSystems = {} as { [key: string]: boolean };

  const systemPermissions =
    adminPermissions?.allowed_modules_and_subsystem?.[system];

  if (systemPermissions) {
    _(systemPermissions)
      .toPairs()
      .forEach(([subSystem, permissions]) => {
        if (permissions?.length) {
          subSystems[subSystem] = true;
        }
      });
  }

  return subSystems;
};

export const canViewSystem = (state, system, entities) => {
  
  
  const isSuperAdmin = getIsSuperAdmin(state);

  if (isSuperAdmin) return true;

  const operationsPermissions = getSystemReadPermissions(state, system);

  const canRead = _(operationsPermissions)
    .keys()
    .some(subSystem => entities.includes(subSystem));

  return canRead;
};

/** Admins */

export const canViewAdminsSubSystems = (
  state: AppState,
  ...subSystems: string[]
) => canViewSystem(state, 'user_management', subSystems);

export const canReadAdminManagement = (state: AppState) =>
  canViewAdminsSubSystems(state, 'Admins');

/** Operations */

export const canViewOperationsSubSystems = (
  state: AppState,
  ...subSystems: string[]
) => canViewSystem(state, 'operations', subSystems);

export const canReadOperations = (state: AppState) =>
  canViewOperationsSubSystems(
    state,
    'Routes',
    'Drivers'
    // 'Devices',
    // 'Groups',
    // 'Vehicles',
    // 'Stations'
  );

/** Fares */

export const canViewFaresSubSystems = (
  state: AppState,
  ...subSystems: string[]
) => canViewSystem(state, 'fares', subSystems);

export const canReadFares = (state: AppState) =>
  canViewFaresSubSystems(state, 'Fares', 'Tickets');

/** FinancialControl */

export const canViewFinancialControlSubSystems = (
  state: AppState,
  ...subSystems: string[]
) => canViewSystem(state, 'financial_control', subSystems);

export const canReadFinancialControl = (state: AppState) =>
  canViewFinancialControlSubSystems(state, 'TripPaymentOperations');
/** Zoning **/ 
export const canViewZoningSubSystems = (
  state: AppState,
  ...subSystems: string[]
) => canViewSystem(state, 'zoning', subSystems);

export const canReadZoning = (state: AppState) =>
canViewZoningSubSystems(state, 'ZoningOp'); 


const getAvailablePermissions = (state: AppState) =>
  state.permissions.availablePermissions.collection;

export const makeGetAvailablePermissionsByAdminId = () =>
  createSelector(
    [getAvailablePermissions, (__, props) => props.adminId],
    (availablePermissions, adminId) => availablePermissions[adminId]
  );

export const makeGetSystemAvailablePermissionsByAdminId = () =>
  createSelector(
    [makeGetAvailablePermissionsByAdminId(), (__, props) => props.systemName],
    (availablePermissions, systemName) => availablePermissions?.[systemName]
  ) as (
    state: AppState,
    props: {
      adminId: string;
      systemName: string;
    }
  ) => string[];

export const getLoggedAdminCategories = (state: AppState) =>
  state.permissions.categories;

export const hasGrantAccessWithPermissionLevel = (state: AppState): boolean => {
  const adminCategories = getLoggedAdminCategories(state);

  

  if (adminCategories.includes('grant_access_with_permission_level')) {
    return true;
  }

  return false;
};

export const hasGrantAccessWithOutPermissionLevel = (
  state: AppState
): boolean => {
  const adminCategories = getLoggedAdminCategories(state);

  if (adminCategories.includes('grant_access_without_permission_level')) {
    return true;
  }

  return false;
};

export const hasGrantAccessForCategory = (
  state: AppState,
  category?: string
): boolean => {
  const isSuperAdmin = getIsSuperAdmin(state);
  if (isSuperAdmin) return true;

  if (category === 'master_configuration') {
    return hasGrantAccessWithOutPermissionLevel(state);
  }

  return hasGrantAccessWithPermissionLevel(state);
};

export const getOrganizationOfOperationsSubSystemEntity = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entityId: string
) => {
  const adminId = getLoggedAdminId(state);

  const organizations_permission_level = adminId
    ? getAdminOrganizationLevelPermissionsById(state, adminId)
    : null;

  const entity =
    state[
      subSystem.toLowerCase() as 'routes' | 'drivers' | 'vehicles' | 'stations'
    ].collection[entityId];

  const entityParentOrganization =
    organizations_permission_level?.[entity?.organisation_id as string];

  if (entity && entity.organisation_id && entityParentOrganization) {
    return entityParentOrganization;
  }

  return null;
};

export const getOrganizationsWithPermissionForOperationsSubSystem = (
  state: AppState,
  subSystem: OperationsSubSystem,
  permissionType: 'read' | 'write'
) => {
  const adminId = getLoggedAdminId(state);

  const organizations_permission_level = adminId
    ? getAdminOrganizationLevelPermissionsById(state, adminId)
    : null;

  const allOrganizations =
    organizations_permission_level &&
    Object.values(organizations_permission_level);

  const organizationsWithWritePermission = Array.isArray(allOrganizations)
    ? allOrganizations.filter(org =>
        org.subSystems[subSystem]?.some(
          permission => permission.action_name === permissionType
        )
      )
    : null;

  return organizationsWithWritePermission;
};

export const getOrganizationsWithReadPermissionForOperationsSubSystem = (
  state: AppState,
  subSystem: OperationsSubSystem
) => {
  const organizationsWithReadPermission =
    getOrganizationsWithPermissionForOperationsSubSystem(
      state,
      subSystem,
      'read'
    );

  return organizationsWithReadPermission;
};

export const getOrganizationsWithWritePermissionForOperationsSubSystem = (
  state: AppState,
  subSystem: OperationsSubSystem
) => {
  const organizationsWithWritePermission =
    getOrganizationsWithPermissionForOperationsSubSystem(
      state,
      subSystem,
      'write'
    );

  return organizationsWithWritePermission;
};

export const getOrganizationsWithReadPermission = (
  state: AppState,
  subSystem: OperationsSubSystem
): Organization[] => {
  const organizations = toArray(state.organizations.collection);

  const isSuperAdmin = getIsSuperAdmin(state);

  if (isSuperAdmin) {
    return organizations;
  }

  const organizationsWithReadPermission =
    getOrganizationsWithReadPermissionForOperationsSubSystem(state, subSystem);

  if (organizationsWithReadPermission) {
    return organizationsWithReadPermission;
  }

  return [];
};

export const getGroupsWithWritePermissionForOperationsSubSystem = (
  state: AppState,
  subSystem: OperationsSubSystem
) => {
  const adminId = getLoggedAdminId(state);

  const groups_permission_level = adminId
    ? getAdminGroupLevelPermissionsById(state, adminId)
    : null;

  const allGroups =
    groups_permission_level && Object.values(groups_permission_level);

  const groupsWithWritePermission = Array.isArray(allGroups)
    ? allGroups.filter(group =>
        group.subSystems[subSystem]?.some(
          permission => permission.action_name === 'write'
        )
      )
    : null;

  return groupsWithWritePermission;
};

export const getGroupsWithReadPermissionForOperationsSubSystem = (
  state: AppState,
  subSystem: OperationsSubSystem
) => {
  const adminId = getLoggedAdminId(state);

  const groups_permission_level = adminId
    ? getAdminGroupLevelPermissionsById(state, adminId)
    : null;

  const allGroups =
    groups_permission_level && Object.values(groups_permission_level);

  const groupsWithReadPermission = Array.isArray(allGroups)
    ? allGroups.filter(group =>
        group.subSystems[subSystem]?.some(
          permission => permission.action_name === 'read'
        )
      )
    : null;

  return groupsWithReadPermission;
};

const hasOrganizationWritePermissionForSubSystemEntity = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entityId: string
) => {
  const entityParentOrganization = getOrganizationOfOperationsSubSystemEntity(
    state,
    subSystem,
    entityId
  );

  if (entityParentOrganization) {
    const entityPermissions = entityParentOrganization.subSystems?.[subSystem];

    const hasWriteAccess = !!entityPermissions?.some(
      permission => permission.action_name === 'write'
    );

    return hasWriteAccess;
  }

  return false;
};

const hasOrganizationReadPermissionForSubSystemEntity = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entityId: string
) => {
  const entityParentOrganization = getOrganizationOfOperationsSubSystemEntity(
    state,
    subSystem,
    entityId
  );

  if (entityParentOrganization) {
    const entityPermissions = entityParentOrganization.subSystems?.[subSystem];

    const hasWriteAccess = !!entityPermissions?.some(
      permission => permission.action_name === 'read'
    );

    return hasWriteAccess;
  }

  return false;
};

export const makeGetCategoryPermissions = () =>
  createSelector(
    [
      state => state,
      getLoggedAdminData,
      getSuperAdminPermissionCategories,
      (__, props) => props.adminId,
      (__, props) => props.systemName,
      (__, props) => props.systemType,
      (__, props) => props.systemId,
    ],
    (
      state,
      currentAdmin,
      superAdminCategories,
      adminId,
      systemName,
      systemType,
      systemId
    ) => {
      if (currentAdmin?.id === adminId && currentAdmin?.is_super_admin) {
        if (systemName === 'master_configuration') {
          return superAdminCategories.master_configuration?.permissions;
        }

        return superAdminCategories.subSystems?.[systemName]?.permissions;
      }
      console.log(systemType); // to be removed

      const permissions = getAdminListOfPermissionsById(state, adminId);
      let result = {};
      if (systemName === 'master_configuration') {
        result = { ...permissions?.master_configuration };
      } else {
        const organisationPermissionsSubSystems =
          permissions?.permissionLevelCategories[systemName]?.permissionLevels
            ?.organisations_permission_level?.[systemId]?.subSystems;

        const mergedGroupsPermissionsSubSystems = Object.assign(
          {},
          ..._(
            permissions?.permissionLevelCategories?.[systemName]
              ?.permissionLevels.groups_permission_level
          )
            .pickBy(group => group.organisation.id === systemId)
            .flatMap('subSystems')
            .value()
        );

        result = {
          ...organisationPermissionsSubSystems,
          ...mergedGroupsPermissionsSubSystems,
        };
      }

      return result;
    }
  ) as (
    state: AppState,
    props: {
      adminId: string;
      systemName: string;
      systemType?: string;
      systemId?: string;
    }
  ) => any;

export const makeGetCategoryAvailablePermissions = () =>
  createSelector(makeGetCategoryPermissions(), permissions => {
    const result = { ...permissions };

    _(result)
      .entries()
      .forEach(([category, categoryPermissions]: [string, Permission]) => {
        result[category] = keyBy(categoryPermissions, 'action_name');
      });

    return result;
  });

export const makeGetCategorySelectedPermissions = () =>
  createSelector(makeGetCategoryPermissions(), permissions => {
    const result = { ...permissions };

    _(result)
      .entries()
      .forEach(([category, categoryPermissions]: [string, any]) => {
        result[category] = map(categoryPermissions, 'id');
      });

    return result;
  });

export const makeGetAllPermissionsIds = () =>
  createSelector(makeGetCategoryPermissions(), permissions => {
    if (permissions) {
      const newValues = _(permissions)
        .toArray()
        .flatMap(val => val)
        .map('id')
        .value();

      return newValues;
    }

    return [];
  }) as (
    state: AppState,
    props: {
      adminId: string;
      systemName?: string;
      systemType?: string;
      systemId?: string;
    }
  ) => any;

const hasGroupWritePermissionForSubSystemEntity = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entityId: string
) => {
  const entity =
    state[subSystem.toLowerCase() as 'routes' | 'drivers'].collection[entityId];

  if (entity?.groups) {
    const groupsWithWritePermission =
      getGroupsWithWritePermissionForOperationsSubSystem(state, subSystem);

    const groupsWithWritePermissionIds = groupsWithWritePermission?.map(
      group => group.id
    );

    return !!entity.groups.some(group =>
      groupsWithWritePermissionIds?.includes(group.id)
    );
  }

  return false;
};

export const canEditOperationsSubSystemEntity = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entityId: string
): boolean => {
  const isSuperAdmin = getIsSuperAdmin(state);

  // Super Admin can edit any entity
  if (isSuperAdmin) {
    return true;
  }

  // Admin with write access to Entity Organization can edit entity
  const hasOrganizationWritePermission =
    hasOrganizationWritePermissionForSubSystemEntity(
      state,
      subSystem,
      entityId
    );

  if (hasOrganizationWritePermission) {
    return true;
  }

  // Admin with write access to any of Entity Groups can edit entity
  const hasGroupWritePermission = hasGroupWritePermissionForSubSystemEntity(
    state,
    subSystem,
    entityId
  );

  return hasGroupWritePermission;
};

export const canEditOperationsSubSystemEntities = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entitiesIds: string[]
): boolean =>
  entitiesIds.every(entityId =>
    canEditOperationsSubSystemEntity(state, subSystem, entityId)
  );

export const haveSameOrganization = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entitiesIds: string[]
): boolean => {
  const organizationsIds = entitiesIds.map(entityId => {
    const entity =
      state[subSystem.toLowerCase() as 'routes' | 'drivers'].collection[
        entityId
      ];

    return entity.organisation_id;
  });

  const notSameOrg = organizationsIds.some(
    organizationId => organizationId !== organizationsIds[0]
  );

  return !notSameOrg;
};

// Legacy
export const canEditWithGroupActions = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entitiesIds: string[]
): boolean => {
  const haveSameOrg = haveSameOrganization(state, subSystem, entitiesIds);

  if (!haveSameOrg) {
    return false;
  }

  const hasWriteAccessToAllEntities = canEditOperationsSubSystemEntities(
    state,
    subSystem,
    entitiesIds
  );

  return hasWriteAccessToAllEntities;
};

export const getEntityGroupsWithReadPermission = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entityId: string
): Group[] => {
  const isSuperAdmin = getIsSuperAdmin(state);

  const entity = state[subSystem.toLowerCase()].collection[entityId];
  const groups = entity?.groups;

  // Super Admin can see all groups
  if (isSuperAdmin) {
    return groups;
  }

  // Admin with read access to Entity Organization can see all Organization groups
  const hasOrganizationReadPermission =
    hasOrganizationReadPermissionForSubSystemEntity(state, subSystem, entityId);

  if (hasOrganizationReadPermission) {
    return groups;
  }

  // Admin can see all groups he have read access to
  const groupsWithReadPermission =
    getGroupsWithReadPermissionForOperationsSubSystem(state, subSystem);

  if (entity?.groups && groupsWithReadPermission) {
    const groupsWithReadPermissionIds = groupsWithReadPermission?.map(
      group => group.id
    );

    return entity.groups.filter(group =>
      groupsWithReadPermissionIds?.includes(group.id)
    );
  }

  return [];
};

export const getEntityGroupsWithWritePermission = (
  state: AppState,
  subSystem: OperationsSubSystem,
  entityId: string
): Group[] => {
  const isSuperAdmin = getIsSuperAdmin(state);

  const entity = state[subSystem.toLowerCase()].collection[entityId];
  const groups = entity?.groups;

  // Super Admin can see all groups
  if (isSuperAdmin) {
    return groups;
  }

  // Admin with write access to Entity Organization can see all Organization groups
  const hasOrganizationWritePermission =
    hasOrganizationWritePermissionForSubSystemEntity(
      state,
      subSystem,
      entityId
    );

  if (hasOrganizationWritePermission) {
    return groups;
  }

  // Admin can see all groups he have write access to
  const groupsWithWritePermission =
    getGroupsWithWritePermissionForOperationsSubSystem(state, subSystem);

  if (entity?.groups && groupsWithWritePermission) {
    const groupsWithWritePermissionIds = groupsWithWritePermission?.map(
      group => group.id
    );

    return entity.groups.filter(group =>
      groupsWithWritePermissionIds?.includes(group.id)
    );
  }

  return [];
};

export const getHasWritePermissionOnOrganization = (
  state: AppState,
  subSystem: OperationsSubSystem,
  organizationId: string
): boolean => {
  const isSuperAdmin = getIsSuperAdmin(state);

  if (isSuperAdmin) {
    return true;
  }

  const organizationsWithWritePermission =
    getOrganizationsWithWritePermissionForOperationsSubSystem(state, subSystem);

  if (organizationsWithWritePermission) {
    const hasWritePermission = organizationsWithWritePermission.some(
      organization => organization.id === organizationId
    );

    return hasWritePermission;
  }

  return false;
};

export const getGroupsWithWritePermission = (
  state: AppState,
  subSystem: OperationsSubSystem,
  organisationId?: string
): Group[] => {
  const groups = toArray(state.groups.collection);

  const isSuperAdmin = getIsSuperAdmin(state);

  if (isSuperAdmin) {
    return groups;
  }

  if (organisationId) {
    const hasWritePermissionOnOrganization =
      getHasWritePermissionOnOrganization(state, subSystem, organisationId);

    if (hasWritePermissionOnOrganization) {
      return groups;
    }
  }

  const groupsWithWritePermission =
    getGroupsWithWritePermissionForOperationsSubSystem(state, subSystem);

  if (groupsWithWritePermission) {
    return groupsWithWritePermission;
  }

  return [];
};

export const getOrganizationsWithWritePermission = (
  state: AppState,
  subSystem: OperationsSubSystem
): Group[] => {
  const organizations = toArray(state.organizations.collection);

  const isSuperAdmin = getIsSuperAdmin(state);

  if (isSuperAdmin) {
    return organizations;
  }

  const organizationsWithWritePermission =
    getOrganizationsWithWritePermissionForOperationsSubSystem(state, subSystem);

  if (organizationsWithWritePermission) {
    return organizationsWithWritePermission;
  }

  return [];
};

export const getOrganizationsWithWritePermissionByName = createSelector(
  getOrganizationsWithWritePermission,
  orgs => keyBy(orgs, org => org.en_name.toLowerCase())
);

export const getGroupsWithWritePermissionByName = createSelector(
  getGroupsWithWritePermission,
  groups => keyBy(groups, group => group.en_name.toLowerCase())
);

export const getGroupsWithReadPermission = (
  state: AppState,
  subSystem: OperationsSubSystem
): Group[] => {
  const groups = toArray(state.groups.collection);

  const isSuperAdmin = getIsSuperAdmin(state);

  if (isSuperAdmin) {
    return groups;
  }

  const organisationsWithReadPermission = getOrganizationsWithReadPermission(
    state,
    subSystem
  );

  const groupsWithReadPermission =
    getGroupsWithReadPermissionForOperationsSubSystem(state, subSystem);

  const mergedGroups = groups?.filter(
    group =>
      organisationsWithReadPermission?.some(
        org => org.id === group.organisation?.id
      ) || groupsWithReadPermission?.some(g => g.id === group.id)
  );

  if (mergedGroups) {
    return mergedGroups;
  }

  return [];
};

export const canAddNewEntity = (
  state: AppState,
  subSystem: OperationsSubSystem
) => {
  const isSuperAdmin = getIsSuperAdmin(state);
  if (isSuperAdmin) {
    return true;
  }
  const organizationsWithWritePermissionForEntity =getOrganizationsWithWritePermissionForOperationsSubSystem(state, subSystem);
  const groupsWithWritePermissionForEntity = getGroupsWithWritePermission(
    state,
    subSystem
  );

  const hasWritePermission =
    !!organizationsWithWritePermissionForEntity?.length ||
    !!groupsWithWritePermissionForEntity.length;

  return hasWritePermission;
};
