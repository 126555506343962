import { FluxStandardAction } from 'redux-promise-middleware';

import { vehicleConstants } from '_constants';
import { AppState, Vehicle } from 'types';

const vehicles = (
  state: AppState['vehicles'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['vehicles'] => {
  switch (action.type) {
    case `${vehicleConstants.GET_VEHICLES}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${vehicleConstants.GET_VEHICLES}_FULFILLED`:
    case `${vehicleConstants.GET_UNASSIGNED_VEHICLES}_FULFILLED`: {
      const vehicleList: AppState['vehicles']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: Vehicle) => {
          vehicleList[item.id] = item;
        });

        return {
          collection: { ...vehicleList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }
    case `${vehicleConstants.GET_VEHICLES}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${vehicleConstants.GET_VEHICLE}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${vehicleConstants.GET_VEHICLE}_FULFILLED`: {
      const vehicleData = action.payload.data?.response;

      if (vehicleData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [vehicleData.id]: vehicleData,
          },
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${vehicleConstants.ADD_VEHICLE}_FULFILLED`: {
      const vehicleData = action.payload.data?.response;

      return {
        ...state,
        collection: {
          ...state.collection,
          [vehicleData.id]: vehicleData,
        },
      };
    }

    case `${vehicleConstants.EDIT_VEHICLE}_FULFILLED`: {
      const vehicleData = action.payload.data?.response;

      return {
        ...state,
        collection: {
          ...state.collection,
          [vehicleData.id]: vehicleData,
        },
      };
    }

    case `${vehicleConstants.DELETE_VEHICLE}_FULFILLED`: {
      const deletedVehicleId = action.meta.vehicleId;

      const newState = { ...state };
      delete newState.collection[deletedVehicleId];

      return newState;
    }

    default:
      return state;
  }
};

export { vehicles };
