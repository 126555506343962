import { ReactElement, useEffect, useState } from 'react';

import { isString } from 'formik';
import moment from 'moment';
import { read, utils } from 'xlsx';

import { LOCALES } from 'app/i18n-locale/locales-constants';

export const isArabicText = (text: string | ReactElement) => {
  let result = false;
  if (isString(text)) {
    const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
    result = pattern.test(text);
  }

  return result;
};

export const useResizeEffect = (onResize: () => void, deps: any = []) => {
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, onResize]);
};

export const splitValues = (values: string) => {
  const splittedValues = values
    ?.split(/(,|،)/)
    .map(value => value.trim())
    .filter(value => ![',', '،'].includes(value));

  return splittedValues;
};

export const useNetwork = () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);

    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  return isOnline;
};

export const formatPrice = (price: any, locale: string) => {
  const lang = locale.toLowerCase() === LOCALES.ARABIC ? 'ar-EG' : 'en-US';

  return Intl.NumberFormat(lang, {
    style: 'currency',
    currency: 'EGP',
  }).format(price);
};

export const reorderList = (
  list: any[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const formatDateTime = (date: string) =>
  moment(date).format('DD/MM/YYYY HH:MM');

export const getTranslated = (
  item: any,
  locale: string,
  param = 'name'
): string => {
  const attribute =
    locale.toLowerCase() === LOCALES.ARABIC ? `ar_${param}` : `en_${param}`;

  if (!item?.[attribute]) return '';

  return item[attribute];
};

export const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const isValidDate = (date: string) =>
  moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();

export const generateYears = () => {
  const max = new Date().getFullYear();
  const min = 1900;
  const years = [] as { id: number; name: number }[];

  // eslint-disable-next-line no-plusplus
  for (let i = max; i >= min; i--) {
    years.push({ id: i, name: i });
  }

  return years;
};

export const convertFileToBase64 = (
  file: File | Blob,
  removeMeta = true
): Promise<string> =>
  new Promise((resolve, reject) => {
    let baseURL = '';
    const reader = new FileReader();

    reader.onload = () => {
      baseURL = reader.result as string;
      if (removeMeta) {
        const base64 = baseURL.split(',')[1];
        resolve(base64);
      } else {
        resolve(baseURL);
      }
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

export const readExcelFile = async (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      const data = e.target?.result;

      const workbook = read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      resolve(json);
    };

    reader.readAsArrayBuffer(file);
    reader.onerror = reject;
  });
