import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { vehicleConstants } from '_constants';
import { API } from 'helpers';

interface VehicleFilterParams {
  code_or_plate_number_cont?: string;
  organisation_id_in?: [string];
  vehicle_groups_group_id_in?: [string];
  disabled_in?: [boolean | undefined];
}

const getVehicles =
  (page: number, size: number, params?: VehicleFilterParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: vehicleConstants.GET_VEHICLES,
      payload: API.get('/v2/vehicles', {
        params: { page: pageNumber, page_count: size, export: 0, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getUnassignedVehicles =
  (page: number, size: number, params?: VehicleFilterParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: vehicleConstants.GET_UNASSIGNED_VEHICLES,
      payload: API.get('/v2/vehicles/non_assigned', {
        params: { page: pageNumber, page_count: size, export: 0, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getVehicle =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: vehicleConstants.GET_VEHICLE,
      payload: API.get(`/v2/vehicles/${id}`),
    });

interface VehicleActionParams {
  vehicle_type_id?: string;
  plate_number?: string;
  code?: string;
  model_year?: string;
  group_ids?: string[];
  color?: string;
  organisation_id?: string;
  disabled?: boolean;
}

const addVehicle =
  (data: VehicleActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { vehicle: data };

    return dispatch({
      type: vehicleConstants.ADD_VEHICLE,
      payload: API.post('/v2/vehicles', requestData),
    });
  };

const editVehicle =
  (id: string, data: VehicleActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { vehicle: data };

    return dispatch({
      type: vehicleConstants.EDIT_VEHICLE,
      payload: API.put(`/v2/vehicles/${id}`, requestData),
    });
  };

const deleteVehicle =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: vehicleConstants.DELETE_VEHICLE,
      payload: API.delete(`/v2/vehicles/${id}`),
      meta: { vehicleId: id },
    });

const editMultipleVehicles =
  (ids: string[], data: VehicleActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { ids, vehicle: data };

    return dispatch({
      type: vehicleConstants.EDIT_MULTIPLE_VEHICLES,
      payload: API.put('/v2/vehicles/update_all', requestData),
    });
  };

const assignVehiclesGroups =
  (ids: string[], currentGroups: string[], newGroups: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = {
      vehicles: ids,
      groups_to_be_added: newGroups,
      groups_to_be_deleted: currentGroups,
    };

    return dispatch({
      type: vehicleConstants.ADD_TO_GROUPS,
      payload: API.post('/v2/vehicles/assign_vehicles_groups', requestData),
      meta: { vehicleIds: ids },
    });
  };

const deleteMultipleVehicles =
  (ids: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { ids };

    return dispatch({
      type: vehicleConstants.DELETE_MULTIPLE_VEHICLES,
      payload: API.delete('/v2/vehicles/destroy_all', {
        data: requestData,
      }),
    });
  };

const importVehicles =
  (base64File: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: vehicleConstants.IMPORT_VEHICLES,
      payload: API.post('/v2/vehicles/import', { file: base64File }),
    });

export {
  getVehicles,
  getUnassignedVehicles,
  getVehicle,
  addVehicle,
  editVehicle,
  deleteVehicle,
  editMultipleVehicles,
  assignVehiclesGroups,
  deleteMultipleVehicles,
  importVehicles,
};
