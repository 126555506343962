import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TableHead,
  Chip,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import {
  getDriver,
  deleteDriver,
  successAlert,
  regenerateDriverPassword,
} from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { useStyles } from 'app/components/shared/styles/view-entity';
import { StatusCell } from 'app/components/shared/table';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithReadPermission,
} from 'selectors';
import { Driver } from 'types';

import DeleteIcon from 'assets/delete-icon.svg';
import EditIcon from 'assets/edit-icon.svg';
import eyeOpen from 'assets/eye-open.svg';

const ViewDriver: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [isRegeneratingPassword, setIsRegeneratingPassword] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);

  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const driverId = match.params.id;

  const driver = useTypedSelector(
    state => state.drivers.collection[driverId] as Driver | undefined
  );

  const prevDriver = useRef(driver);

  const fullName = driver && `${driver?.first_name} ${driver?.last_name}`;

  const hasDriverWriteAccess = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Drivers', driverId)
  );

  const parentOrganization = getTranslated(driver?.organisation, locale);

  const groups = useTypedSelector(state =>
    getEntityGroupsWithReadPermission(state, 'Drivers', driverId)
  );

  const regeneratePassword = () => {
    setIsRegeneratingPassword(true);

    Promise.resolve(dispatch(regenerateDriverPassword(driverId)))
      .then(() => {
        dispatch(
          successAlert(intl.formatMessage({ id: 'successRegeneratedPassword' }))
        );
      })
      .finally(() => {
        setIsRegeneratingPassword(false);
      });
  };

  // eslint-disable-next-line consistent-return
  const renderCardActions = () => {
    if (hasDriverWriteAccess)
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.editButton}
            component={Link}
            to={`${baseURL}/edit`}
          >
            <img
              src={EditIcon}
              alt="Edit"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'edit' })}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            className={`${classes.editButton} ${classes.deleteButton}`}
            component={Button}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'delete' })}
          </Button>
        </>
      );
  };

  useEffect(() => {
    if (driver) {
      setLoading(false);
      prevDriver.current = driver;
    } else if (!prevDriver.current) {
      dispatch(getDriver(driverId));
    }
  }, [dispatch, driver, driverId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.viewContainer}>
      <CardTitle title={intl.formatMessage({ id: 'driverInfo' })} />

      <div className={classes.infoWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" aria-label="Driver Info Table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeadCell}>
                  {fullName}
                </TableCell>

                <TableCell
                  style={{ textAlign: 'end' }}
                  className={classes.tableHeadCell}
                >
                  {renderCardActions()}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.row} style={{ paddingTop: 10 }}>
                <TableCell>
                  {intl.formatMessage({ id: 'organization' })}
                </TableCell>

                <TableCell>{parentOrganization}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'status' })}</TableCell>

                <StatusCell isDisabled={driver?.disabled} showLabel />
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'phone' })}</TableCell>

                <TableCell>{driver?.phone}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'nationalId' })}
                </TableCell>

                <TableCell>{driver?.national_id}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'licenseNumber' })}
                </TableCell>

                <TableCell>{driver?.license_number}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'groups' })}</TableCell>

                <TableCell align="center" className={classes.groupsContainer}>
                  {groups?.length
                    ? groups.map(elem => {
                        if (elem.en_name) {
                          return (
                            <Chip
                              label={getTranslated(elem, locale)}
                              variant="outlined"
                              key={elem.id}
                              className={classes.groupNameStyle}
                            />
                          );
                        }

                        return null;
                      })
                    : null}
                </TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'userName' })}</TableCell>

                <TableCell>{driver?.username}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'password' })}</TableCell>

                <TableCell>
                  {showPassword ? (
                    <span>{driver?.password_digest}</span>
                  ) : (
                    <span style={{ fontSize: 16 }}>••••</span>
                  )}

                  <Button
                    onClick={() => setShowPassword(!showPassword)}
                    className={classes.eyeIcon}
                  >
                    <img src={eyeOpen} alt="show password" />
                  </Button>

                  {hasDriverWriteAccess && (
                    <Button
                      variant="outlined"
                      className={classes.regeneratePasswordBtn}
                      onClick={regeneratePassword}
                    >
                      {isRegeneratingPassword ? (
                        <CircularProgress size={14} style={{ color: '#fff' }} />
                      ) : (
                        intl.formatMessage({ id: 'regeneratePassword' })
                      )}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Button
        variant="outlined"
        color="default"
        type="button"
        className={classes.backButton}
        onClick={() => history.replace('/operations/drivers')}
      >
        {intl.formatMessage({ id: 'backToDriversList' })}
      </Button>

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteDriver,
          entityType: intl.formatMessage({ id: 'driver' }),
          entityName: fullName || ' ',
          entityId: driverId,
          onfinishNavigation: '/operations/drivers',
        }}
      />
    </div>
  );
};

export { ViewDriver };
