import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TableHead,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { getGroup, deleteGroup } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { useStyles } from 'app/components/shared/styles/view-entity';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import { canEditOperationsSubSystemEntity } from 'selectors';
import { Group } from 'types';

import DeleteIcon from 'assets/delete-icon.svg';
import ActionEditIcon from 'assets/edit-icon.svg';

const ViewGroup: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);

  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const groupId = match.params.id;

  const group = useTypedSelector(
    state => state.groups.collection[groupId] as Group | undefined
  );

  const prevGroup = useRef(group);

  const fullName = getTranslated(group, locale);

  const hasGroupWriteAccess = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Groups', groupId)
  );

  const parentOrganization = getTranslated(group?.organisation, locale);

  const renderCardActions = () => {
    if (hasGroupWriteAccess)
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.editButton}
            component={Link}
            to={`${baseURL}/edit`}
          >
            <img
              src={ActionEditIcon}
              alt="Edit"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'edit' })}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            className={`${classes.editButton} ${classes.deleteButton}`}
            component={Button}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'delete' })}
          </Button>
        </>
      );

    return null;
  };

  useEffect(() => {
    if (group) {
      setLoading(false);
      prevGroup.current = group;
    } else if (!prevGroup.current) {
      dispatch(getGroup(groupId));
    }
  }, [dispatch, group, groupId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.viewContainer}>
      <CardTitle title={intl.formatMessage({ id: 'groupInfo' })} />

      <div className={classes.infoWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" aria-label="Group Info Table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeadCell}>
                  {fullName}
                </TableCell>

                <TableCell
                  style={{ textAlign: 'end' }}
                  className={classes.tableHeadCell}
                >
                  {renderCardActions()}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'englishName' })}
                </TableCell>

                <TableCell>{group?.en_name}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'arabicName' })}
                </TableCell>

                <TableCell>{group?.ar_name}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'code' })}</TableCell>

                <TableCell>{group?.code}</TableCell>
              </TableRow>

              <TableRow className={classes.row} style={{ paddingTop: 10 }}>
                <TableCell>
                  {intl.formatMessage({ id: 'organization' })}
                </TableCell>

                <TableCell>{parentOrganization}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Button
        variant="outlined"
        color="default"
        type="button"
        className={classes.backButton}
        onClick={() => history.replace('/operations/groups')}
      >
        {intl.formatMessage({ id: 'backToGroupsList' })}
      </Button>

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteGroup,
          entityType: 'group',
          entityName: fullName || ' ',
          entityId: groupId,
          onfinishNavigation: '/operations/groups',
        }}
      />
    </div>
  );
};

export { ViewGroup };
