import { string as yupString, object as yupObject, ref as yupRef } from 'yup';

import { passwordRegex } from 'app/admin-management/admins/admin-helpers';

export interface ChangePasswordFormState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const passwordRulesMessage = intl.formatMessage({
    id: 'passwordRulesNotMatchMessage',
  });

  const passwordMatchValidation = intl.formatMessage({
    id: 'passwordMatchValidation',
  });

  return yupObject().shape({
    currentPassword: yupString().required(validationRequiredMessage),
    newPassword: yupString()
      .required(validationRequiredMessage)
      .matches(passwordRegex, passwordRulesMessage),
    confirmPassword: yupString()
      .required(validationRequiredMessage)
      .oneOf([yupRef('newPassword')], passwordMatchValidation),
  });
};

export { ChangePasswordFormSchema };
