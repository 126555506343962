import React, { useEffect, useMemo } from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getAdmin, editAdmin, successAlert } from 'actions';
import { AdminForm } from 'app/admin-management/admins/admin-form';
import { AdminFormState } from 'app/admin-management/admins/admin-helpers';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { Admin } from 'types';

const EditAdmin: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const adminId = match.params.id;

  const adminData = useTypedSelector(
    state => state.admins.collection[adminId] as Admin | undefined
  );

  useEffect(() => {
    if (!adminData) {
      dispatch(getAdmin(adminId));
    }
  }, [dispatch, adminData, adminId]);

  const initialState: AdminFormState = useMemo(() => {
    if (adminData) {
      return {
        firstName: adminData.first_name,
        lastName: adminData.last_name,
        username: adminData.username,
        status: !adminData.disabled,
        email: adminData.email || '',
        phone: adminData.phone || '',
        organizationId: adminData?.organisation_id || '',
        organization: adminData?.organisation || undefined,
        is_super_admin: adminData?.is_super_admin,
      };
    }

    return {
      firstName: '',
      lastName: '',
      username: '',
      status: true,
      email: '',
      phone: '',
      organizationId: '',
      organization: undefined,
    };
  }, [adminData]);

  const handleSubmit = (
    values: AdminFormState,
    { setSubmitting }: FormikHelpers<AdminFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        editAdmin(adminId, {
          first_name: values.firstName,
          last_name: values.lastName,
          username: values.username,
          disabled: !values.status,
          email: values.email || undefined,
          phone: values.phone,
          organisation_id: values.organizationId,
          is_super_admin: values.is_super_admin,
        })
      )
    )
      .then(() => {
        history.push(`${baseURL}`.replace('edit', ''));

        dispatch(successAlert(intl.formatMessage({ id: 'successEditAdmin' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <AdminForm {...{ initialState, handleSubmit, isEditForm: true }} />;
};

export { EditAdmin };
