import { permissionAccessLevel } from '_constants';
import { AdminAvailablePermissions, Permission } from 'types';

export const getCurrentAccessLevel = (permissions: Permission[]) => {
  const hasReadAccess = permissions.some(
    permission => permission.action_name === 'read'
  );

  const hasWriteAccess = permissions.some(
    permission => permission.action_name === 'write'
  );

  const hasFullAccess = hasReadAccess && hasWriteAccess;

  if (hasFullAccess) {
    return permissionAccessLevel.fullAccess;
  }

  if (hasReadAccess) {
    return permissionAccessLevel.readOnly;
  }

  return permissionAccessLevel.noAccess;
};

export const generatePermissionLevelsValues = (permissionList?: {
  read?: Permission;
  write?: Permission;
}) => {
  const options: {
    [option in permissionAccessLevel]?: string[];
  } = {};

  if (permissionList) {
    options[permissionAccessLevel.fullAccess] = [
      ...(permissionList.read ? [permissionList.read.id] : []),
      ...(permissionList.write ? [permissionList.write.id] : []),
    ];

    options[permissionAccessLevel.readOnly] = [
      ...(permissionList.read ? [permissionList.read.id] : []),
    ];

    options[permissionAccessLevel.noAccess] = [];
  }

  return options;
};

export const getBackupOfOtherSelectedPermissions = (
  adminAvailablePermissions:
    | AdminAvailablePermissions['master_configuration' | 'operations']
    | null,
  subSystemName: string
): string[] => {
  const result: string[] = [];

  const backupSubSystemsNames =
    adminAvailablePermissions &&
    Object.keys(adminAvailablePermissions).filter(
      item => item !== subSystemName
    );

  if (adminAvailablePermissions && backupSubSystemsNames) {
    backupSubSystemsNames.forEach(subSystem => {
      Object.values<Permission>(adminAvailablePermissions[subSystem]).forEach(
        permission => {
          if (permission?.selected) {
            result.push(permission.id);
          }
        }
      );
    });
  }

  return result;
};
