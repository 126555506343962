import { FluxStandardAction } from 'redux-promise-middleware';

import { vehicleTypesConstants } from '_constants';
import { AppState, VehicleType } from 'types';

const vehicleTypes = (
  state: AppState['vehicleTypes'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['vehicleTypes'] => {
  switch (action.type) {
    case `${vehicleTypesConstants.GET_VEHICLE_TYPES}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${vehicleTypesConstants.GET_VEHICLE_TYPES}_FULFILLED`: {
      const vehicleList: AppState['vehicleTypes']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: VehicleType) => {
          vehicleList[item.id] = item;
        });

        return {
          collection: { ...vehicleList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }
    case `${vehicleTypesConstants.GET_VEHICLE_TYPES}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${vehicleTypesConstants.GET_VEHICLE_TYPE}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${vehicleTypesConstants.GET_VEHICLE_TYPE}_FULFILLED`: {
      const vehicleTypeData = action.payload.data?.response;

      if (vehicleTypeData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [vehicleTypeData.id]: vehicleTypeData,
          },
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${vehicleTypesConstants.ADD_VEHICLE_TYPE}_FULFILLED`: {
      const vehicleTypeData = action.payload.data?.response;

      return {
        ...state,
        collection: {
          ...state.collection,
          [vehicleTypeData.id]: vehicleTypeData,
        },
      };
    }

    case `${vehicleTypesConstants.EDIT_VEHICLE_TYPE}_FULFILLED`: {
      const vehicleTypeData = action.payload.data?.response;

      return {
        ...state,
        collection: {
          ...state.collection,
          [vehicleTypeData.id]: vehicleTypeData,
        },
      };
    }

    case `${vehicleTypesConstants.DELETE_VEHICLE_TYPE}_FULFILLED`: {
      const deletedVehicleTypeId = action.meta.vehicleTypeId;

      const newState = { ...state };
      delete newState.collection[deletedVehicleTypeId];

      return newState;
    }

    default:
      return state;
  }
};

export { vehicleTypes };
