import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { AddVehicle } from 'app/operations/vehicles/vehicle-list/add-vehicle';
import { EditVehicle } from 'app/operations/vehicles/vehicle-list/edit-vehicle';
import { VehicleList } from 'app/operations/vehicles/vehicle-list/vehicle-list';
import { ViewVehicle } from 'app/operations/vehicles/vehicle-list/view-vehicle';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

const Vehicles: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasVehicleAddAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Vehicles')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasVehicleAddAccess ? <AddVehicle /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          <EditVehicle />
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewVehicle />
        </Route>

        <Route exact path={`${baseURL}`}>
          <VehicleList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { Vehicles };
