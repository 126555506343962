import { FluxStandardAction } from 'redux-promise-middleware';

import { stationConstants } from '_constants';
import { AppState, Station } from 'types';

const stations = (
  state: AppState['stations'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['stations'] => {
  switch (action.type) {
    case `${stationConstants.GET_STATIONS}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${stationConstants.GET_STATIONS}_FULFILLED`: {
      const stationList: AppState['stations']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: Station) => {
          stationList[item.id] = item;
        });

        return {
          collection: { ...stationList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }
    case `${stationConstants.GET_STATIONS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${stationConstants.GET_STATION}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${stationConstants.GET_STATION}_FULFILLED`: {
      const stationData = action.payload.data?.response?.data;

      if (stationData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [stationData.id]: stationData,
          },
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${stationConstants.ADD_STATION}_FULFILLED`: {
      const stationData = action.payload.data?.response?.station;

      return {
        ...state,
        collection: {
          ...state.collection,
          [stationData.id]: stationData,
        },
      };
    }

    case `${stationConstants.EDIT_STATION}_FULFILLED`: {
      const stationData = action.payload.data?.response?.station;

      return {
        ...state,
        collection: {
          ...state.collection,
          [stationData.id]: stationData,
        },
      };
    }

    case `${stationConstants.DELETE_STATION}_FULFILLED`: {
      const deletedStationId = action.meta.stationId;

      const newState = { ...state };
      delete newState.collection[deletedStationId];

      return newState;
    }

    default:
      return state;
  }
};

export { stations };
