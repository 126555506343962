import React, { useContext, useMemo } from 'react';

import { Button, createStyles, makeStyles } from '@material-ui/core';
import isArray from 'lodash/isArray';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { actionButtonStyles } from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { isValidDate, getTranslated, useTypedSelector } from 'helpers';
import { makeGetEntitiesByIds } from 'selectors';

import closeIcon from 'assets/close-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    multiActionButton: {
      ...actionButtonStyles,
      height: '',
      marginLeft: '',
      padding: '8px 20px',
      backgroundColor: '#E6E7F2',
      color: '#4b506D',
      margin: '0px 11px 5px 0px',
      textAlign: 'left',
      '&:hover': {
        opacity: 0.8,
        backgroundColor: '#E6E7F2 !important',
      },
    },
    entityName: {
      fontWeight: 'bold',
      paddingRight: 4,
    },
    closeIcon: {
      marginLeft: 10,
    },
  })
);

function AppliedFilters({
  entity,
  filterKey,
  values,
  onClick,
  label,
  nameKey = 'name',
}) {
  const { locale } = useContext(LanguageContext);
  const intl = useIntl();

  const getEntity = useMemo(makeGetEntitiesByIds, []);

  const entities = useTypedSelector(state =>
    getEntity(state, { entityName: entity, ids: values })
  );

  const labels = useMemo(() => {
    if (entities) {
      const labelsArray = entities?.map(val =>
        getTranslated(val, locale, nameKey)
      );

      return labelsArray;
    }

    if (isArray(values)) {
      return values.map(value =>
        intl.formatMessage({ id: value.toLowerCase() })
      );
    }

    return null;
  }, [entities, intl, locale, nameKey, values]);

  const classes = useStyles();

  const getDisplayedValue = () => {
    if (labels) {
      return labels.join(', ');
    }
    if (isValidDate(values)) {
      return moment(values).format('MM/DD/YYYY');
    }

    return values;
  };

  return (
    <Button
      variant="outlined"
      color="default"
      type="button"
      className={classes.multiActionButton}
      onClick={() => onClick(filterKey)}
    >
      <span className={classes.entityName}>
        {intl.formatMessage({ id: label })}:
      </span>

      <span>{getDisplayedValue()}</span>

      <img src={closeIcon} alt="clear filter" className={classes.closeIcon} />
    </Button>
  );
}

export default AppliedFilters;
