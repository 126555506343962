import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  successAlert,
  getDriver,
  editDriver,
  changeDriversGroups,
} from 'actions';
import { NotAuthorized } from 'app/components/not-authorized';
import { DriverForm } from 'app/operations/drivers/driver-form';
import { DriverFormState } from 'app/operations/drivers/driver-helpers';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithWritePermission,
} from 'selectors';
import { Driver } from 'types';

const EditDriver: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const [isLoading, setLoading] = useState(true);
  const intl = useIntl();

  const driverId = match.params.id;

  const driverData = useTypedSelector(
    state => state.drivers.collection[driverId] as Driver | undefined
  );

  useEffect(() => {
    if (driverData) {
      setLoading(false);
    } else {
      dispatch(getDriver(driverId));
    }
  }, [dispatch, driverData, driverId]);

  const groups = useTypedSelector(state =>
    getEntityGroupsWithWritePermission(state, 'Drivers', driverId)
  );

  const initialState: DriverFormState = useMemo(
    () => ({
      firstName: driverData?.first_name || '',
      lastName: driverData?.last_name || '',
      phone: driverData?.phone || '',
      licenseNumber: driverData?.license_number || '',
      nationalId: driverData?.national_id || '',
      organizationId: driverData?.organisation_id || '',
      organization: driverData?.organisation || undefined,
      groups: groups?.map(group => group.id) || [],
      status: driverData ? !driverData?.disabled : true,
    }),
    [driverData, groups]
  );

  const hasWriteAccessToCurrentDriver = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Drivers', driverId)
  );

  const handleSaveGroups = async currentValues => {
    const groupsToBeAdded = currentValues.filter(
      (id: string) => !initialState.groups.includes(id)
    );

    const groupsToBeDeleted = initialState.groups.filter(
      id => !currentValues.includes(id)
    );

    await Promise.resolve(
      dispatch(
        changeDriversGroups([driverId], groupsToBeDeleted, groupsToBeAdded)
      )
    );
  };

  const handleSubmit = async (
    values: DriverFormState,
    { setSubmitting }: FormikHelpers<DriverFormState>
  ) => {
    setSubmitting(true);
    try {
      await Promise.resolve(
        dispatch(
          editDriver(driverId, {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            license_number: values.licenseNumber,
            national_id: values.nationalId,
            organisation_id: values.organizationId,
            disabled: !values.status,
          })
        )
      );

      await handleSaveGroups(values.groups);

      await Promise.resolve(dispatch(getDriver(driverId)));

      history.push(`${baseURL}`.replace('edit', ''));

      dispatch(successAlert(intl.formatMessage({ id: 'successEditDriver' })));
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!hasWriteAccessToCurrentDriver) {
    return <NotAuthorized />;
  }

  return <DriverForm {...{ initialState, handleSubmit, isEditForm: true }} />;
};

export { EditDriver };
