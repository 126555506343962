import { FluxStandardAction } from 'redux-promise-middleware';

import { adminConstants } from '_constants';
import { AppState, Admin } from 'types';

const admins = (
  state: AppState['admins'] = {
    collection: {},
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['admins'] => {
  switch (action.type) {
    case `${adminConstants.GET_ADMINS}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${adminConstants.GET_ADMINS}_FULFILLED`: {
      const adminList: AppState['admins']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: Admin) => {
          adminList[item.id] = item;
        });

        return { collection: { ...adminList }, isLoading: false };
      }

      return { ...state };
    }

    case `${adminConstants.GET_ADMINS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${adminConstants.GET_ADMIN}_FULFILLED`: {
      const adminData = action.payload.data?.response.admin;

      if (adminData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [adminData.id]: adminData,
          },
        };
      }

      return { ...state };
    }

    case `${adminConstants.ADD_ADMIN}_FULFILLED`: {
      const adminData = action.payload.data?.response.admin;

      return {
        ...state,
        collection: {
          ...state.collection,
          [adminData.id]: adminData,
        },
      };
    }

    case `${adminConstants.EDIT_ADMIN}_FULFILLED`: {
      const adminData = action.payload.data?.response.admin;

      return {
        ...state,
        collection: {
          ...state.collection,
          [adminData.id]: adminData,
        },
      };
    }

    case `${adminConstants.DELETE_ADMIN}_FULFILLED`: {
      const deletedAdminId = action.meta.adminId;

      const newState = { ...state };
      delete newState.collection[deletedAdminId];

      return newState;
    }

    default:
      return state;
  }
};

export { admins };
