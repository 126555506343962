import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { driverConstants } from '_constants';
import { API } from 'helpers';

interface DriverFilterParams {
  first_name_or_last_name_or_username_cont?: string;
  organisation_id_in?: [string];
  driver_group_groups_id_in?: [string];
  disabled_in?: [boolean | undefined];
}

const getDrivers =
  (page: number, size: number, params?: DriverFilterParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: driverConstants.GET_DRIVERS,
      payload: API.get('/v2/drivers', {
        params: { page: pageNumber, page_count: size, export: 0, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getDriver =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: driverConstants.GET_DRIVER,
      payload: API.get(`/v2/drivers/${id}`),
    });

interface DriverActionParams {
  first_name: string;
  last_name: string;
  phone: string;
  license_number: string;
  national_id: string;
  organisation_id: string;
  group_ids?: Array<string> | [];
  disabled?: boolean;
}

const addDriver =
  (data: DriverActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { driver: data };

    return dispatch({
      type: driverConstants.ADD_DRIVER,
      payload: API.post('/v2/drivers', requestData),
    });
  };

const editDriver =
  (id: string, data: DriverActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { driver: data };

    return dispatch({
      type: driverConstants.EDIT_DRIVER,
      payload: API.put(`/v2/drivers/${id}`, requestData),
    });
  };

const deleteDriver =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: driverConstants.DELETE_DRIVER,
      payload: API.delete(`/v2/drivers/${id}`),
      meta: { driverId: id },
    });

const deleteDrivers =
  (ids: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { drivers: ids };

    return dispatch({
      type: driverConstants.DELETE_DRIVERS,
      payload: API.post('/v2/drivers/delete_multiple_drivers', requestData),
      meta: { driverIds: ids },
    });
  };

const changeDriversStatus =
  (ids: string[], status: 'disabled' | 'enabled') =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { drivers: ids, status };

    return dispatch({
      type: driverConstants.CHANGE_DRIVERS_STATUS,
      payload: API.post('/v2/drivers/change_drivers_status', requestData),
      meta: { driverIds: ids, status },
    });
  };

const clearDriversGroups =
  (ids: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { drivers: ids };

    return dispatch({
      type: driverConstants.CLEAR_GROUPS,
      payload: API.post(
        '/v2/drivers/clear_drivers_groups_assignment',
        requestData
      ),
      meta: { driverIds: ids },
    });
  };

const changeDriversGroups =
  (ids: string[], currentGroups: string[], newGroups: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = {
      drivers: ids,
      groups_to_be_added: newGroups,
      groups_to_be_deleted: currentGroups,
    };

    return dispatch({
      type: driverConstants.ADD_TO_GROUPS,
      payload: API.post('/v2/drivers/assign_drivers_groups', requestData),
      meta: { driverIds: ids },
    });
  };

const regenerateDriverPassword =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: driverConstants.REGENERATE_DRIVER_PASSWORD,
      payload: API.post('/v2/drivers/regenerate_password', { driver_id: id }),
      meta: { driver_id: id },
    });

const importDrivers =
  (base64File: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: driverConstants.IMPORT_DRIVERS,
      payload: API.post('/v2/drivers/import', { file: base64File }),
    });

export {
  getDrivers,
  getDriver,
  addDriver,
  editDriver,
  deleteDriver,
  deleteDrivers,
  changeDriversStatus,
  clearDriversGroups,
  changeDriversGroups,
  regenerateDriverPassword,
  importDrivers,
};
