import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  successAlert,
  getVehicle,
  editVehicle,
  assignVehiclesGroups,
} from 'actions';
import { NotAuthorized } from 'app/components/not-authorized';
import { VehicleForm } from 'app/operations/vehicles/vehicle-list/vehicle-form';
import { VehicleFormState } from 'app/operations/vehicles/vehicle-list/vehicle-helpers';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canEditOperationsSubSystemEntity,
  getEntityGroupsWithWritePermission,
} from 'selectors';
import { Vehicle } from 'types';

const EditVehicle: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const [isLoading, setLoading] = useState(true);
  const intl = useIntl();

  const vehicleId = match.params.id;

  const vehicleData = useTypedSelector(
    state => state.vehicles.collection[vehicleId] as Vehicle | undefined
  );

  useEffect(() => {
    Promise.resolve(dispatch(getVehicle(vehicleId))).then(() => {
      setLoading(false);
    });
  }, [dispatch, vehicleId]);

  const groups = useTypedSelector(state =>
    getEntityGroupsWithWritePermission(state, 'Vehicles', vehicleId)
  );

  const initialState: VehicleFormState = useMemo(
    () => ({
      vehicle_type_id: vehicleData?.vehicle_type_id || '',
      plateNumber: vehicleData?.plate_number || '',
      code: vehicleData?.code || '',
      modelYear: vehicleData?.model_year || '',
      groups: groups?.map(group => group.id) || [],
      color: vehicleData?.color || '',
      organisationId: vehicleData?.organisation_id || '',
      organization: vehicleData?.organisation || undefined,
      enabled: !vehicleData?.disabled,
      deviceId: vehicleData?.obc?.id || '',
    }),
    [vehicleData, groups]
  );

  const hasWriteAccessToCurrentVehicle = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Vehicles', vehicleId)
  );

  const handleSaveGroups = async currentValues => {
    const groupsToBeAdded = currentValues.filter(
      (id: string) => !initialState.groups.includes(id)
    );

    const groupsToBeDeleted = initialState.groups.filter(
      id => !currentValues.includes(id)
    );

    await Promise.resolve(
      dispatch(
        assignVehiclesGroups([vehicleId], groupsToBeDeleted, groupsToBeAdded)
      )
    );
  };

  const handleSubmit = async (
    values: VehicleFormState,
    { setSubmitting }: FormikHelpers<VehicleFormState>
  ) => {
    setSubmitting(true);
    try {
      await Promise.resolve(
        dispatch(
          editVehicle(vehicleId, {
            vehicle_type_id: values.vehicle_type_id,
            plate_number: values.plateNumber,
            code: values.code,
            model_year: values.modelYear,
            color: values.color,
            organisation_id: values.organisationId,
            disabled: !values.enabled,
          })
        )
      );

      await handleSaveGroups(values.groups);

      await Promise.resolve(dispatch(getVehicle(vehicleId)));

      history.push(`${baseURL}`.replace('edit', ''));

      dispatch(successAlert(intl.formatMessage({ id: 'successEditVehicle' })));
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!hasWriteAccessToCurrentVehicle) {
    return <NotAuthorized />;
  }

  return <VehicleForm {...{ initialState, handleSubmit, isEditForm: true }} />;
};

export { EditVehicle };
