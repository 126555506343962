import { FluxStandardAction } from 'redux-promise-middleware';

import { permissionConstants } from '_constants';
import { AppState } from 'types';

const initialState: AppState['permissions'] = {
  collection: {},
  isLoading: false,
  categories: [],
  availablePermissions: { collection: {} },
  generalPermissions: [],
};

const permissions = (
  state = initialState,
  action: FluxStandardAction
): AppState['permissions'] => {
  switch (action.type) {
    case `${permissionConstants.GET_ADMIN_FULL_PERMISSIONS}_PENDING`: {
      const isLoggedInAdmin: boolean = action.meta?.isLoggedInAdmin;

      if (isLoggedInAdmin) {
        return {
          ...state,
          // only enable loading state while fetching logged in admin permissions
          isLoading: true,
        };
      }

      return { ...state };
    }

    case `${permissionConstants.GET_ADMIN_FULL_PERMISSIONS}_FULFILLED`: {
      const adminId: string = action.meta?.admin_id;

      if (action.payload.data) {
        return {
          ...state,
          isLoading: false,
          collection: {
            ...state.collection,
            [adminId]: action.payload.data.response,
          },
        };
      }

      return { ...state };
    }

    case `${permissionConstants.GET_ADMIN_FULL_PERMISSIONS}_REJECTED`: {
      return { ...state, isLoading: false };
    }

    case `${permissionConstants.GET_LOGGED_IN_ADMIN_CATEGORIES}_FULFILLED`: {
      if (action.payload.data) {
        return {
          ...state,
          categories: [...action.payload.data.response.categories],
        };
      }

      return { ...state };
    }

    case `${permissionConstants.GET_ADMIN_AVAILABLE_PERMISSIONS}_FULFILLED`: {
      const adminId: string = action.meta?.admin_id;

      if (action.payload.data) {
        const categoryName = action.meta.category;

        const listOfPermissions =
          action.payload.data.response.list_of_permissions;

        const availablePermissions = {};

        if (listOfPermissions) {
          listOfPermissions.forEach(permission => {
            const substringsArray = permission.action.split('.');
            const category = substringsArray[0];
            const permissionType = substringsArray[1];

            availablePermissions[category] = {
              ...(availablePermissions[category] || {}),
              [permissionType]: permission,
            };
          });
        }

        return {
          ...state,
          availablePermissions: {
            ...state.availablePermissions,
            collection: {
              ...state.availablePermissions.collection,
              [adminId]: {
                ...state.availablePermissions.collection[adminId],
                [categoryName]: availablePermissions,
              },
            },
          },
        };
      }

      return { ...state };
    }
    case `${permissionConstants.GET_ِGENERAL_PERMISSIONS}_FULFILLED`: {
      if (action.payload.data) {
        return {
          ...state,
          isLoading: false,
          generalPermissions: action.payload.data.response.data,
        };
      }

      return { ...state };
    }

    default:
      return state;
  }
};

export { permissions };
