import { string as yupString, object as yupObject } from 'yup';

import { Organization } from 'types';

const IPv4Regex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export interface DeviceFormState {
  serial: string;
  device_identifier: string;
  type: string;
  ip: string;
  organizationId: string;
  organization?: Organization;
  vehicleId?: string;
}

const DeviceFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const invalidIpValidationMessage = intl.formatMessage({
    id: 'ipIsInvalid',
  });

  return yupObject().shape({
    serial: yupString().required(validationRequiredMessage),
    device_identifier: yupString().required(validationRequiredMessage),
    type: yupString().required(validationRequiredMessage),
    ip: yupString()
      .required(validationRequiredMessage)
      .matches(IPv4Regex, invalidIpValidationMessage),
    organizationId: yupString().required(validationRequiredMessage),
  });
};

export { DeviceFormSchema };
