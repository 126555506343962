import React, { useContext } from 'react';

import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';
import { useTypedSelector } from 'helpers';
import {
  getAdminPermissionsByOrganization,
  getOrganizationsWithReadPermission,
} from 'selectors';

import { SystemPermissions } from '../system-permissions';

import organizationIcon from 'assets/organization.svg';

const OperationsPermissions: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const adminId = match.params.id;
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const currentAdminPermissions = useTypedSelector(state =>
    getAdminPermissionsByOrganization(state, adminId)
  );

  const loggedAdminOperationsOrganizationLevelPermissions = useTypedSelector(
    state => getOrganizationsWithReadPermission(state, 'Admins')
  );

  const isEnglish = locale.toLowerCase() === LOCALES.ENGLISH;

  return (
    <>
      {loggedAdminOperationsOrganizationLevelPermissions?.map(organization => {
        const name = isEnglish ? organization.en_name : organization.ar_name;

        const title = `${intl.formatMessage({
          id: 'organization',
        })}:`;

        return (
          <SystemPermissions
            key={organization.id}
            {...{
              accordionSummaryProps: {
                ariaControls: organization.en_name,
                title,
                icon: organizationIcon,
                entityName: name,
              },
              permissions:
                currentAdminPermissions?.[organization.id]?.subSystems || {},
              permissionLevelType: 'Organisation',
              permissionLevelId: organization.id,
            }}
          />
        );
      })}
    </>
  );
};

export { OperationsPermissions };
