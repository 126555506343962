// @ts-nocheck
import React from 'react';

import MomentFnsUtils from '@date-io/moment';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import {
  StylesProvider,
  jssPreset,
  createTheme,
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import rtl from 'jss-rtl';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { App } from 'app';
import { I18nProvider } from 'helpers/i18n-provider';
import store from 'store';

import 'index.css';
//start dev zoning
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

//end dev zoning
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const theme = createTheme({
  typography: {
    fontFamily: 'Manrope',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          background: 'linear-gradient(0deg, #F4F6FB, #F4F6FB)',
        },
      },
    },
    MuiOutlinedInput: {
      adornedEnd: { paddingRight: 5 },
      input: {
        '&::placeholder': {
          fontSize: 14,
        },
      },
    },
    MuiTablePagination: {
      caption: { color: '#9FA2B4', '&:nth-of-type(even)': { fontWeight: 800 } },
      input: {
        border: '1px solid #DADCE0',
        borderRadius: 10,
        color: '#4B506D',
        marginRight: 20,
        height: 36,
      },
      actions: { marginLeft: 8 },
    },
    MuiTableRow: {
      head: {
        backgroundColor: '#fff !important',
        height: '40px !important',
        borderTop: '1px solid #DFE0EB',
        borderBottom: '1px solid #DFE0EB',
      },
      root: {
        height: 70,
        '&.Mui-selected': { background: '#E6E6F1 !important' },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
        lineHeight: '19px !important',
      },
      body: {
        wordBreak: 'break-word',
      },
      root: {
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: 0.2,
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
      list: {
        padding: 0,
      },
    },
  },
  palette: {
    common: { black: '#000', white: '#fff' },
    background: {
      paper: 'rgba(244, 246, 251, 1)',
      default: 'rgba(244, 246, 251, 1)',
    },
    primary: {
      light: 'rgba(147, 152, 183, 1)',
      main: 'rgba(75, 80, 109, 1)',
      dark: 'rgba(54, 55, 64, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(61, 203, 150, 1)',
      main: 'rgba(15, 166, 109, 1)',
      dark: 'rgba(4, 123, 79, 1)',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: 'rgba(252, 13, 27, 1)',
      dark: 'rgba(206, 11, 36, 1)',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(37, 39, 51, 1)',
      secondary: 'rgba(159, 162, 180, 1)',
      disabled: 'rgba(181, 183, 189, 1)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>

    <Provider store={store}>
      <I18nProvider>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentFnsUtils}>
              <CssBaseline />

              <App />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </StylesProvider>
      </I18nProvider>
    </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);
