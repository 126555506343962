import { FluxStandardAction } from 'redux-promise-middleware';

import { organizationConstants } from '_constants';
import { AppState, Organization } from 'types';

const organizations = (
  state: AppState['organizations'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['organizations'] => {
  switch (action.type) {
    case `${organizationConstants.GET_ORGANIZATIONS}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${organizationConstants.GET_ORGANIZATIONS}_FULFILLED`: {
      const organizationList: AppState['organizations']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response?.data.forEach((item: Organization) => {
          organizationList[item.id] = item;
        });

        return {
          collection: { ...organizationList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${organizationConstants.GET_ORGANIZATIONS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${organizationConstants.GET_ORGANIZATION}_FULFILLED`: {
      const organizationData = action.payload.data?.response.organisation;

      if (organizationData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [organizationData.id]: organizationData,
          },
        };
      }

      return { ...state };
    }

    case `${organizationConstants.ADD_ORGANIZATION}_FULFILLED`: {
      const organizationData = action.payload.data?.response.organisation;

      return {
        ...state,
        collection: {
          ...state.collection,
          [organizationData.id]: organizationData,
        },
      };
    }

    case `${organizationConstants.EDIT_ORGANIZATION}_FULFILLED`: {
      const organizationData = action.payload.data?.response.organisation;

      return {
        ...state,
        collection: {
          ...state.collection,
          [organizationData.id]: organizationData,
        },
      };
    }

    case `${organizationConstants.DELETE_ORGANIZATION}_FULFILLED`: {
      const deletedOrganizationId = action.meta.organizationId;

      const newState = { ...state };
      delete newState.collection[deletedOrganizationId];

      return newState;
    }

    default:
      return state;
  }
};

export { organizations };
