import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { stopConstants } from '_constants';
import { API } from 'helpers';

const getStops =
  (page: number, size: number) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: stopConstants.GET_STOPS,
      payload: API.get('/v2/stops', {
        params: { page: pageNumber, page_count: size, export: 0 },
      }),
      meta: { page: pageNumber },
    });
  };

const getStop =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: stopConstants.GET_STOP,
      payload: API.get(`/v2/stops/${id}`),
    });

interface StopActionParams {
  en_name: string;
  ar_name: string;
  code: string;
  station_id: string;
  organisation_id?: string;
  lat?: string;
  lon?: string;
}

const addStop =
  (data: StopActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { stop: data };

    return dispatch({
      type: stopConstants.ADD_STOP,
      payload: API.post('/v2/stops', requestData),
    });
  };

const editStop =
  (id: string, data: StopActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { stop: data };

    return dispatch({
      type: stopConstants.EDIT_STOP,
      payload: API.put(`/v2/stops/${id}`, requestData),
    });
  };

const deleteStop =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: stopConstants.DELETE_STOP,
      payload: API.delete(`/v2/stops/${id}`),
      meta: { stopId: id },
    });

export { getStops, getStop, addStop, editStop, deleteStop };
