import React, { useCallback, useState } from 'react';

import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { deleteOrganization, getOrganizations } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { TableRowActions } from 'app/components/table-row-actions';
import {
  PaginationComponent,
  usePagination,
} from 'app/components/use-pagination';
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { hasWriteAccessToMasterConfigSubSystem } from 'selectors';

import { FilterOrganizationByName } from './filter-organization';

const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 600, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
  })
);

const OrganizationList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getOrganizations(page, rowSize, {
        en_name_or_ar_name_or_code_cont: searchTerm,
      }),
    [searchTerm]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    return getOrganizations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  const hasOrganizationWriteAccess = useTypedSelector(state =>
    hasWriteAccessToMasterConfigSubSystem(state, 'Organizations')
  );

  const organizationListIsLoading = useTypedSelector(
    state => state.organizations.isLoading as boolean
  );

  const organizationListHasError = useTypedSelector(
    state => state.organizations.hasError
  );

  const organizationsTotalCount = useTypedSelector(
    state => state.organizations.totalCount
  );

  const organizationList = useTypedSelector(state => {
    if (
      !state.organizations.isLoading &&
      Object.values(state.organizations).length
    ) {
      return Object.values(state.organizations.collection).sort((a, b) =>
        a.en_name.localeCompare(b.en_name)
      );
    }

    return [];
  });

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    handleChangePage(null, 0);
  };

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getOrganizations(page, rowsPerPage, {
          en_name_or_ar_name_or_code_cont: searchTerm,
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: organizationsTotalCount,
        hasData: !!organizationList.length,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );

  return (
    <>
      <CardTitle title={intl.formatMessage({ id: 'organizations' })}>
        {hasOrganizationWriteAccess && (
          <Button
            component={Link}
            to={`${baseURL}/new`}
            variant="contained"
            className={classes.addButton}
            color="secondary"
          >
            {intl.formatMessage({ id: 'addOrganization' })}
          </Button>
        )}
      </CardTitle>

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterOrganizationByName
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
            }}
          />
        </Grid>

        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <DataTableContainer
        alignItems={organizationListIsLoading ? 'center' : 'flex-start'}
      >
        {organizationListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {organizationListIsLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {organizationList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Organizations Table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {intl.formatMessage({ id: 'englishName' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'arabicName' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'actions' })}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {organizationList.map(organization => (
                        <TableRow
                          onClick={() => {
                            history.push(`${baseURL}/${organization.id}`);
                          }}
                          key={organization.id}
                          className={classes.row}
                          hover
                        >
                          <TableCell align="center">
                            {organization.en_name}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontFamily: 'Cairo' }}
                          >
                            {organization.ar_name}
                          </TableCell>

                          <TableRowActions
                            hasWriteAccess={hasOrganizationWriteAccess}
                            editPath={`${baseURL}/${organization.id}/edit`}
                            entityId={organization.id}
                            entityName={organization.en_name}
                            entityType="organization"
                            deleteAction={deleteOrganization}
                          />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={hasOrganizationWriteAccess}
                      baseURL={baseURL}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>
    </>
  );
};

export { OrganizationList };
