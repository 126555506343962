import {
  string as yupString,
  object as yupObject,
  number as yubNumber,
} from 'yup';

export interface AxisFormState {
  en_name: string;
  ar_name: string;
  code: string;
  cash_price: string;
  card_price: string;
  stops: string[];
  en_description?: string;
  ar_description?: string;
  disabled?: boolean;
}

const AxisFormSchema = intl => {
  const validationRequiredMessage = intl.formatMessage({
    id: 'requiredValidation',
  });

  const priceValidateNumberMessage = intl.formatMessage({
    id: 'priceNumberValidation',
  });

  return yupObject().shape({
    en_name: yupString().required(validationRequiredMessage),
    ar_name: yupString().required(validationRequiredMessage),
    code: yupString().required(validationRequiredMessage),
    cash_price: yubNumber()
      .typeError(priceValidateNumberMessage)
      .required(validationRequiredMessage),
    card_price: yubNumber()
      .typeError(priceValidateNumberMessage)
      .required(validationRequiredMessage),
  });
};

export { AxisFormSchema };
