import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  LinearProgress,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getGroups, getOrganizations } from 'actions';
import { getVehicleType, getVehicleTypes } from 'actions/vehicle-types.actions';
import { AsyncAutoComplete } from 'app/components/async-auto-complete';
import AsyncMultiSelect from 'app/components/shared/async-multi-select';
import { CardTitle } from 'app/components/shared/card-title';
import { getInputProps } from 'app/components/shared/groups-menu-multi-select/utils';
import { ActionAlert } from 'app/components/shared/on-action-alert';
import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import { useStyles } from 'app/operations/drivers/driver-form';
import {
  VehicleFormState,
  VehicleClassFormSchema,
} from 'app/operations/vehicles/vehicle-list/vehicle-helpers';
import { LanguageContext } from 'context/intl.context';
import { generateYears, getTranslated, useTypedSelector } from 'helpers';
import {
  getGroupsWithWritePermission,
  getOrganizationsWithReadPermission,
} from 'selectors';
import { Organization } from 'types';

const Years = generateYears();
interface VehicleForm {
  initialState: VehicleFormState;
  handleSubmit: (
    values: VehicleFormState,
    { setSubmitting }: FormikHelpers<VehicleFormState>
  ) => void;
  isEditForm?: boolean;
}

const VehicleForm: React.FC<VehicleForm> = ({
  initialState,
  handleSubmit,
  isEditForm = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const { direction, locale } = useContext(LanguageContext);
  const [initialVehicleType, setInitialVehicleType] = useState();
  const [isActionAlertOpen, setIsActionAlertOpen] = useState(false);
  const [action, setAction] = useState(undefined) as any;

  const isRTL = direction === DIRECTIONS.RTL;

  const initialVehicleTypeId = initialState.vehicle_type_id;

  const getCurrentVehicleType = async () => {
    const res: any = await dispatch(getVehicleType(initialVehicleTypeId));
    setInitialVehicleType(res?.value?.data.response);
  };

  const organizationList = useTypedSelector(state =>
    getOrganizationsWithReadPermission(state, 'Vehicles')
  ) as Organization[];

  const hasWriteAccessOnOrganizations = organizationList?.length > 0;

  const organizations = useMemo(() => {
    if (hasWriteAccessOnOrganizations) return organizationList;
    if (initialState.organization) return [initialState.organization];

    return null;
  }, [initialState, organizationList, hasWriteAccessOnOrganizations]);

  const formikRef = useRef<FormikProps<VehicleFormState>>(null);

  useEffect(() => {
    dispatch(getOrganizations(0, 100));
    if (initialVehicleTypeId) {
      getCurrentVehicleType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, initialVehicleTypeId]);

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [formikRef, direction]);

  const onSubmit = (
    values: VehicleFormState,
    { setSubmitting }: FormikHelpers<VehicleFormState>
  ) => {
    if (
      initialState.deviceId &&
      initialState.organisationId !== values.organisationId
    ) {
      setIsActionAlertOpen(true);

      setAction(
        () => () =>
          handleSubmit(values, {
            setSubmitting,
          } as FormikHelpers<VehicleFormState>)
      );

      setSubmitting(false);
    } else {
      handleSubmit(values, {
        setSubmitting,
      } as FormikHelpers<VehicleFormState>);
    }
  };

  return (
    <>
      <CardTitle
        title={intl.formatMessage({
          id: isEditForm ? 'editVehicle' : 'addVehicle',
        })}
      />

      <div className={classes.formWrapper}>
        <Formik
          innerRef={formikRef}
          initialValues={initialState}
          validationSchema={() => VehicleClassFormSchema(intl)}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, values, touched, errors }) => (
            <Form>
              <div className={classes.fieldGroupWrapper}>
                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'vehicleType' })}
                  </InputLabel>

                  <Field
                    id="vehicleType"
                    name="vehicle_type_id"
                    component={AsyncAutoComplete}
                    action={getVehicleTypes}
                    labelParam={isRTL ? 'ar_model' : 'en_model'}
                    onChange={_id =>
                      setFieldValue('vehicle_type_id', _id || '')
                    }
                    defaultValue={initialVehicleType}
                    filterKey="en_model_or_ar_model_cont"
                    error={touched.vehicle_type_id && errors.vehicle_type_id}
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    <span>
                      {intl.formatMessage({ id: 'vehicleModelYear' })}
                    </span>
                  </InputLabel>

                  <Field
                    name="modelYear"
                    component={TextField}
                    type="text"
                    select
                    variant="outlined"
                    size="small"
                    inputProps={getInputProps(isRTL)}
                  >
                    {Years?.map(year => (
                      <MenuItem
                        key={year.id}
                        value={year.id}
                        className={classes.selectMenuItem}
                      >
                        {year.name}
                      </MenuItem>
                    ))}
                  </Field>
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    <span>{intl.formatMessage({ id: 'vehicleCode' })}</span>
                  </InputLabel>

                  <Field
                    name="code"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'plateNumber' })}
                  </InputLabel>

                  <Field
                    name="plateNumber"
                    component={TextField}
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </div>

                <div className={classes.fieldWrapper}>
                  <InputLabel
                    className={classes.formLabel}
                    style={{ flexDirection: isRTL ? 'row' : 'row-reverse' }}
                  >
                    <strong className={classes.requiredAstrisk}>*</strong>

                    {intl.formatMessage({ id: 'organization' })}
                  </InputLabel>

                  <Field
                    name="organisationId"
                    component={TextField}
                    type="text"
                    select
                    variant="outlined"
                    size="small"
                    inputProps={getInputProps(isRTL)}
                  >
                    {organizations?.map(organization => (
                      <MenuItem
                        key={organization.id}
                        value={organization.id}
                        className={classes.selectMenuItem}
                      >
                        {getTranslated(organization, locale)}
                      </MenuItem>
                    ))}
                  </Field>
                </div>

                <div className={classes.fieldWrapper}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingBottom: 24,
                    }}
                  >
                    <FormControl size="small" fullWidth variant="outlined">
                      <Field
                        name="groups"
                        component={AsyncMultiSelect}
                        sendSelectedOptions={(_, selectedGroups) =>
                          setFieldValue('groups', selectedGroups)
                        }
                        selected={values?.groups || []}
                        label="groups"
                        entity="groups"
                        action={() => getGroups(0, 100)}
                        showPlaceholder={false}
                        filterBy={{
                          filterKey: 'organisation_id',
                          filterValue: values.organisationId,
                        }}
                        requiredAstrisk
                        error={touched.groups && errors.groups}
                        customSelector={state =>
                          getGroupsWithWritePermission(
                            state,
                            'Vehicles',
                            values.organisationId
                          )
                        }
                      />
                    </FormControl>
                  </div>
                </div>

                <div className={classes.fieldWrapper} style={{ width: '100%' }}>
                  <InputLabel className={classes.formLabel}>
                    {intl.formatMessage({ id: 'status' })}
                  </InputLabel>

                  <FormControlLabel
                    control={
                      <Field
                        name="enabled"
                        type="checkbox"
                        component={Switch}
                        color="secondary"
                      />
                    }
                    label={intl.formatMessage({
                      id: 'enabled',
                    })}
                  />
                </div>
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({
                    id: isEditForm ? 'update' : 'addVehicle',
                  })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => history.go(-1)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({
                    id: 'cancel',
                  })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <ActionAlert
        {...{
          isOpen: isActionAlertOpen,
          setOpen: setIsActionAlertOpen,
          title: intl.formatMessage({ id: 'changeVehicleOrgMsg' }),
          setActionCall: setAction,
          action,
        }}
      />
    </>
  );
};

export { VehicleForm };
