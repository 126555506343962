export enum routeConstants {
  GET_ROUTES = 'GET_ROUTES',
  GET_ROUTE = 'GET_ROUTE',
  ADD_ROUTE = 'ADD_ROUTE',
  EDIT_ROUTE = 'EDIT_ROUTE',
  DELETE_ROUTE = 'DELETE_ROUTE',
  ASSIGN_ROUTE_GROUPS = 'ASSIGN_ROUTE_GROUPS',
  DELETE_ROUTES = 'DELETE_ROUTES',
  CHANGE_ROUTES_STATUS = 'CHANGE_ROUTES_STATUS',
  CLEAR_GROUPS = 'CLEAR_GROUPS',
  GET_ROUTE_AXIS = 'GET_ROUTE_AXIS',
  ADD_ROUTE_AXIS = 'ADD_ROUTE_AXIS',
  EDIT_ROUTE_AXIS = 'EDIT_ROUTE_AXIS',
  DELETE_ROUTE_AXES = 'DELETE_ROUTE_AXES',
  EDIT_BASE_FARE = 'EDIT_BASE_FARE',
  ADD_ROUTE_AXIS_STOPS = 'ADD_ROUTE_AXIS_STOPS',
  SEARCH_ROUTES = 'SEARCH_ROUTES',
  FILTER_ROUTES = 'FILTER_ROUTES',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}
