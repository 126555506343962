import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { stationConstants } from '_constants';
import { API } from 'helpers';

interface StationFilterParams {
  en_name_or_ar_name_cont?: string;
  organisation_id_in?: [string];
  station_groups_group_id_in?: [string];
}

const getStations =
  (page: number, size: number, params?: StationFilterParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: stationConstants.GET_STATIONS,
      payload: API.get('/v2/stations', {
        params: { page: pageNumber, page_count: size, export: 0, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getStation =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: stationConstants.GET_STATION,
      payload: API.get(`/v2/stations/${id}`),
    });

interface StationActionParams {
  en_name?: string;
  ar_name?: string;
  organisation_id?: string;
  disabled?: boolean;
  group_ids?: string[];
}

const addStation =
  (data: StationActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { station: data };

    return dispatch({
      type: stationConstants.ADD_STATION,
      payload: API.post('/v2/stations', requestData),
    });
  };

const editStation =
  (id: string, data: StationActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { station: data };

    return dispatch({
      type: stationConstants.EDIT_STATION,
      payload: API.put(`/v2/stations/${id}`, requestData),
    });
  };

const deleteStation =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: stationConstants.DELETE_STATION,
      payload: API.delete(`/v2/stations/${id}`),
      meta: { stationId: id },
    });

const changeStationsStatus =
  (ids: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { stations: ids };

    return dispatch({
      type: stationConstants.CHANGE_STATION_STATUS,
      payload: API.post('/v2/stations/change_stations_status', requestData),
    });
  };

const assignStationsToGroups =
  (ids: string[], data: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = {
      stations: ids,
      groups_to_be_added: data,
      groups_to_be_deleted: [],
    };

    return dispatch({
      type: stationConstants.ASSIGN_STATIONS_TO_GROUPS,
      payload: API.post('/v2/stations/assign_stations_groups', requestData),
    });
  };

const clearStationsGroups =
  (ids: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { stations: ids };

    return dispatch({
      type: stationConstants.CLEAR_STATIONS_GROUPS,
      payload: API.post(
        '/v2/stations/clear_stations_groups_assignment',
        requestData
      ),
    });
  };

const deleteMultipleStations =
  (ids: string[]) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { stations: ids };

    return dispatch({
      type: stationConstants.DELETE_MULTIPLE_STATIONS,
      payload: API.post('/v2/stations/delete_multiple_stations', requestData),
    });
  };

export {
  getStations,
  getStation,
  addStation,
  editStation,
  deleteStation,
  changeStationsStatus,
  assignStationsToGroups,
  deleteMultipleStations,
  clearStationsGroups,
};
