import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

import { AddVehicleType } from './add-vehicle-type';
import { EditVehicleType } from './edit-vehicle-type';
import { VehicleTypeList } from './vehicle-type-list';
import { ViewVehicleType } from './view-vehicle-type';

const VehicleTypes: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasVehicleAddAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Vehicles')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasVehicleAddAccess ? <AddVehicleType /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          <EditVehicleType />
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewVehicleType />
        </Route>

        <Route exact path={`${baseURL}`}>
          <VehicleTypeList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { VehicleTypes };
