/* eslint-disable react/jsx-newline */
import React, { useContext, useState } from 'react';

import {
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import { deleteRouteAxes } from 'actions';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';
import { formatPrice, isArabicText, useTypedSelector } from 'helpers';
import { canEditOperationsSubSystemEntity } from 'selectors';
import { Stop } from 'types';

import { AxisModal } from './axis-modal';
import DeleteIcon from './delete-icon.svg';
import EditPenIcon from './edit-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      backgroundColor: '#fff',
      margin: '5px 15px 5px 45px',
      alignItems: 'center',
      padding: '5px 7px 5px 5px',
      minWidth: '900px',
      minHeight: '53px',
    },
    item: {
      flex: 1,
      padding: '0px 10px',
      whiteSpace: 'nowrap',
    },
    maxWidth: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tooltip: {
      backgroundColor: '#4b506d',
      padding: '18px 15px',
      fontSize: 14,
      maxWidth: 230,
    },
    tooltipPlacementBottom: {
      marginTop: '10px',
    },
  })
);

const TextWithTip = ({ label, maxWidth = 300, ...props }) => {
  const classes = useStyles();
  const dir = isArabicText(label) ? 'rtl' : 'ltr';

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
      }}
      title={label}
      dir={dir}
    >
      <Typography
        style={{ maxWidth }}
        className={[classes.item, classes.maxWidth].join(' ')}
        {...props}
      >
        {label}
      </Typography>
    </Tooltip>
  );
};

const AxisRow = ({
  id,
  en_name,
  ar_name,
  code,
  routeId,
  stops = [] as Stop[],
  base_fare = {} as { cash_price: string; card_price: string },
}) => {
  const [showAddAxisModal, setShowAddAxisModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const classes = useStyles();
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);
  const isEnglish = locale.toLowerCase() === LOCALES.ENGLISH;

  const hasRouteWriteAccess = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Routes', routeId)
  );

  const entityName = isEnglish ? en_name : ar_name;

  const deleteAction = _id => deleteRouteAxes(routeId, [_id]);

  return (
    <div className={classes.container}>
      <TextWithTip label={en_name} />

      <TextWithTip label={ar_name} />

      <TextWithTip label={code} maxWidth={100} align="right" />

      <Typography className={classes.item} align="right">
        {intl.formatMessage({ id: 'cash' })}:{' '}
        {formatPrice(base_fare?.cash_price, locale)}
      </Typography>

      <Typography className={classes.item} align="right">
        {intl.formatMessage({ id: 'card' })}:{' '}
        {formatPrice(base_fare?.card_price, locale)}
      </Typography>

      <Typography className={classes.item} align="right">
        {stops?.length} {intl.formatMessage({ id: '_stops' }).toLowerCase()}
      </Typography>

      {hasRouteWriteAccess && (
        <div
          className={classes.item}
          style={{
            flex: 0.8,
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 0,
          }}
        >
          <IconButton
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setShowAddAxisModal(true);
            }}
          >
            <img src={EditPenIcon} alt="Edit" />
          </IconButton>

          <IconButton
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            <img src={DeleteIcon} alt="Delete" />
          </IconButton>
        </div>
      )}

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction,
          entityType: 'axis',
          entityName: entityName || ' ',
          entityId: id,
        }}
      />

      <AxisModal
        {...{
          isOpen: showAddAxisModal,
          setOpen: setShowAddAxisModal,
          isEditing: true,
          axisId: id,
        }}
      />
    </div>
  );
};

export default AxisRow;
