import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, addVehicleType } from 'actions';
import { VehicleTypeForm } from 'app/operations/vehicles/vehicle-types/vehicle-type-form';
import { VehicleTypeFormState } from 'app/operations/vehicles/vehicle-types/vehicle-type-helpers';
import { trimTrailingSlash } from 'helpers/url-helpers';

const initialState: VehicleTypeFormState = {
  en_model: '',
  ar_model: '',
  capacity: '',
  vehicle_class_id: '',
};

const AddVehicleType: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const handleSubmit = (
    values: VehicleTypeFormState,
    { setSubmitting }: FormikHelpers<VehicleTypeFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        addVehicleType({
          en_model: values.en_model,
          ar_model: values.ar_model,
          capacity: values.capacity,
          vehicle_class_id: values.vehicle_class_id,
        })
      )
    )
      .then(({ action }: any) => {
        const newVehicleId = action.payload.data?.response.id;
        history.push(`${baseURL.replace('/new', '')}/${newVehicleId}`);

        dispatch(
          successAlert(intl.formatMessage({ id: 'successAddVehicleType' }))
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <VehicleTypeForm {...{ initialState, handleSubmit }} />;
};

export { AddVehicleType };
