import { User } from 'types';

const getAuthData = (): User | null => {
  const auth = localStorage.getItem('authData');

  if (auth) {
    return JSON.parse(auth);
  }

  return auth as null;
};

const setAuthData = (auth: User): void => {
  localStorage.setItem('authData', JSON.stringify(auth));
};

const clearAuthData = (): void => {

  localStorage.removeItem('authData');
};

export { getAuthData, setAuthData, clearAuthData };
