// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { CardContainer } from 'app/components/shared/card-container';
import { trimTrailingSlash } from 'helpers';
import RoutesC from './pages/routes';
import InfoOfRoute from './pages/InfoOfRoute';
import InfoOfAxis from './pages/InfoOfAxis';
import Error from './pages/Error';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useGetFilterOrganizations } from 'hooks';
import { useGetFilterGroups } from 'hooks/useGetFilterGroups';
import { toast } from 'react-toastify';
import ErrorComponent from './components/ErrorComponent';

const ZoningRoutes: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const history = useHistory();
  const intl = useIntl();
  const [permissionValue, setPermissionValue] = useState<any>(true);
  const [goToErrorComponent,setGoToErrorComponent] = useState<any>(false);
  const [errorComponentData,setErrorComponentData] = useState<any>({code:'10000',message:'AnErrorHappend'});
 
  let user: any = '';
  let user2 :any= '';
  let userRefresh:any = '';
  
  const authData = localStorage.getItem("authData");
  
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    user = parsedAuthData.userData;
    user2 = parsedAuthData.userToken;
    userRefresh = parsedAuthData.userToken;
  }
  
  
 useEffect(()=>{
  const hasRoutesSubSystem = () => {
     axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/v2/general_permissions`,

    { headers:{'access-token' : user2['access-token'],'uid' :user2.uid , 'client': user2.client }})
   .then((res)=> {

//is superAdmin

}).catch((err)=>{   
  if (err.response && err.response.status === 403) {
  //is not super
  const checkRoute= (organisations_permission_level,groups_permission_level) => {
    // Loop through the data array and check if "sub_system_name" is "Routes"
    for (const item of organisations_permission_level) {
      const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === 'Routes');
      if (subSystem) {
        // If "Routes" sub-system is found, return true
        return true;
      }
    }
        // If "Routes" sub-system is not found, return false
        for (const item of groups_permission_level) {
          const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === 'Routes');
          if (subSystem) {
            // If "Routes" sub-system is found, return true
            return true;
          }
        }
    // If "Routes" sub-system is not found, return false
    return false;
  };
const checks= ()=>{
   axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/v2/admin_general_permissions/get_full_permissions?admin_id=${user.id}`,

  { headers:{'access-token' : user2['access-token'],'uid' :user2.uid , 'client': user2.client }})
 .then((res)=> {
 console.log(res.data.response.organisations_permission_level);
 setPermissionValue(checkRoute(res.data.response.organisations_permission_level,res.data.response.groups_permission_level))
 console.log("value : " ,checkRoute(res.data.response.organisations_permission_level,res.data.response.groups_permission_level));
 
if(checkRoute(res.data.response.organisations_permission_level,res.data.response.groups_permission_level)==false){
   /* const searchParams = new URLSearchParams();

      // Set query parameters
      searchParams.set('message','MakeSureYouHaveOpPermission' );
      searchParams.set('code', '403');
      searchParams.set('backToLink', "/super-dash");

      // Update the URL with the new query parameters
      history.push({
        pathname: '/zoning/zoningRoutes/Error',
        search: `?${searchParams.toString()}`,
      });*/
      setGoToErrorComponent(true)
      setErrorComponentData({code:'#403',message:'MakeSureYouHaveOpPermission'})
}
    })
    .catch((err)=>{
      console.log("API ERROR");
      toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #i1`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
        }) 
  
  })
}
checks()
  
  }})}
  hasRoutesSubSystem()
},[]);


  return (
    <CardContainer>
    { goToErrorComponent ? <ErrorComponent code={errorComponentData.code} message={errorComponentData.message}/>
      :<Switch>
        <Route exact path={`${baseURL}`}>
          <RoutesC />
        </Route>
        <Route path={`${baseURL}/routes/:id`}>
          <InfoOfRoute />
        </Route>
        <Route path={`${baseURL}/axis`}>
          <InfoOfAxis />
        </Route>
      
      </Switch> }

    </CardContainer>
  );
};

export { ZoningRoutes };
