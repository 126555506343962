import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { adminConstants } from '_constants';
import { API } from 'helpers';
import { Admin } from 'types';

interface AdminQueryParams {
  full_name_or_username_or_phone_or_email_cont?: string;
  disabled_in?: (boolean | undefined)[];
  is_super_admin_eq?: boolean;
}

const getAdmins =
  (queryParams?: AdminQueryParams) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: adminConstants.GET_ADMINS,
      payload: API.get('/v2/admins', {
        params: {
          q: queryParams,
        },
      }),
    });

const getAdmin =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: adminConstants.GET_ADMIN,
      payload: API.get(`/v2/admins/${id}`),
    });

const addAdmin =
  (data: {
    first_name: string;
    last_name: string;
    username: string;
    password?: string;
    phone?: string;
    email?: string;
    organisation_id: string;
    is_super_admin?: boolean;
  }) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { admin: data };

    return dispatch({
      type: adminConstants.ADD_ADMIN,
      payload: API.post('/v2/admins', requestData),
    });
  };

const editAdmin =
  (id: string, data: Partial<Admin>) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { admin: data };

    return dispatch({
      type: adminConstants.EDIT_ADMIN,
      payload: API.put(`/v2/admins/${id}`, requestData),
    });
  };

const deleteAdmin =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: adminConstants.DELETE_ADMIN,
      payload: API.delete(`/v2/admins/${id}`),
      meta: { adminId: id },
    });

const regenerateAdminPassword =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: adminConstants.REGENERATE_ADMIN_PASSWORD,
      payload: API.post('/v2/admins/regenerate_password', { admin_id: id }),
      meta: { admin_id: id },
    });

export {
  getAdmins,
  getAdmin,
  addAdmin,
  editAdmin,
  deleteAdmin,
  regenerateAdminPassword,
};
