import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TableHead,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { getVehicleType, deleteVehicleType } from 'actions';
import { CardTitle } from 'app/components/shared/card-title';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { useStyles } from 'app/components/shared/styles/view-entity';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import { VehicleType } from 'types';

import DeleteIcon from 'assets/delete-icon.svg';
import EditIcon from 'assets/edit-icon.svg';

const ViewVehicleType: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);

  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const vehicleTypeId = match.params.id;

  const vehicleType = useTypedSelector(
    state =>
      state.vehicleTypes.collection[vehicleTypeId] as VehicleType | undefined
  );

  const prevVehicle = useRef(vehicleType);

  const fullName = getTranslated(vehicleType, locale, 'model');

  const hasVehicleWriteAccess = true;

  // eslint-disable-next-line consistent-return
  const renderCardActions = () => {
    if (hasVehicleWriteAccess)
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={classes.editButton}
            component={Link}
            to={`${baseURL}/edit`}
          >
            <img
              src={EditIcon}
              alt="Edit"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'edit' })}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            className={`${classes.editButton} ${classes.deleteButton}`}
            component={Button}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className={classes.actionButtonIcon}
            />

            {intl.formatMessage({ id: 'delete' })}
          </Button>
        </>
      );
  };

  useEffect(() => {
    if (vehicleType) {
      setLoading(false);
      prevVehicle.current = vehicleType;
    } else if (!prevVehicle.current) {
      dispatch(getVehicleType(vehicleTypeId));
    }
  }, [dispatch, vehicleType, vehicleTypeId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.viewContainer}>
      <CardTitle title={intl.formatMessage({ id: 'vehicleTypeInfo' })} />

      <div className={classes.infoWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" aria-label="VehicleType Info Table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeadCell}>
                  {fullName}
                </TableCell>

                <TableCell
                  style={{ textAlign: 'end' }}
                  className={classes.tableHeadCell}
                >
                  {renderCardActions()}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'englishName' })}
                </TableCell>

                <TableCell>{vehicleType?.en_model}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'arabicName' })}
                </TableCell>

                <TableCell>{vehicleType?.ar_model}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'vehicleClass' })}
                </TableCell>

                <TableCell>
                  {getTranslated(vehicleType?.vehicle_class, locale)}
                </TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>
                  {intl.formatMessage({ id: 'averageSpeed' })}
                </TableCell>

                <TableCell>{vehicleType?.capacity}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Button
        variant="outlined"
        color="default"
        type="button"
        className={classes.backButton}
        onClick={() => history.replace('/operations/vehicle-types')}
      >
        {intl.formatMessage({ id: 'backToVehicleTypesList' })}
      </Button>

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteVehicleType,
          entityType: 'vehicleType',
          entityName: fullName || ' ',
          entityId: vehicleTypeId,
          onfinishNavigation: '/operations/vehicle-types',
        }}
      />
    </div>
  );
};

export { ViewVehicleType };
