import React, { useContext, useEffect, useRef } from 'react';

import {
  LinearProgress,
  Button,
  makeStyles,
  Theme,
  createStyles,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  ChangePasswordFormState,
  ChangePasswordFormSchema,
} from 'app/operations/profile/change-password/change-password-helpers';
import { LanguageContext } from 'context/intl.context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: { padding: '0 32px' },
    fieldWrapper: {
      width: 347,
      marginRight: 24,
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      margin: '0 0 15px',
      [theme.breakpoints.down('sm')]: { margin: '0 0 15px' },
    },
    fieldGroupWrapper: {
      width: '70%',
      [theme.breakpoints.down('sm')]: { width: '100%' },
    },
    buttonsWrapper: {
      marginTop: 10,
    },
    actionButton: {
      textTransform: 'capitalize',
      marginRight: 10,
      borderRadius: 30,
      [theme.breakpoints.down('sm')]: {
        margin: '0 0 10px',
        width: '100%',
      },
      padding: '7px 17px',
      borderColor: '#DFE0EB',
    },
    submitButton: {
      backgroundColor: '#0FA66D',
      '&:hover': {
        backgroundColor: '#0FA66D',
        opacity: 0.9,
      },
      color: '#fff',
      borderColor: '#0FA66D',
      padding: '7px 25px',
    },
    formLabel: {
      color: '#444',
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 700,
    },
  })
);

interface ChangePasswordForm {
  initialState: ChangePasswordFormState;
  handleSubmit: (
    values: ChangePasswordFormState,
    { setSubmitting }: FormikHelpers<ChangePasswordFormState>
  ) => void;
}

const ChangePasswordForm: React.FC<ChangePasswordForm> = ({
  initialState,
  handleSubmit,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();

  const { direction } = useContext(LanguageContext);

  const formikRef = useRef<FormikProps<ChangePasswordFormState>>(null);

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [formikRef, direction]);

  return (
    <div className={classes.formWrapper}>
      <div style={{ marginBottom: 22 }}>
        <Typography variant="subtitle2">
          {intl.formatMessage({ id: 'changePasswordRoles' })}
        </Typography>

        <ul className="list">
          <li>{intl.formatMessage({ id: 'sixOrMoreCharacters' })}</li>

          <li>{intl.formatMessage({ id: 'atLeastOneLetter' })}</li>

          <li>{intl.formatMessage({ id: 'atLeastOneNumber' })}</li>
        </ul>
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={initialState}
        validationSchema={() => ChangePasswordFormSchema(intl)}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={classes.fieldGroupWrapper}>
              <div className={classes.fieldWrapper}>
                <InputLabel className={classes.formLabel}>
                  {intl.formatMessage({ id: 'currentPassword' })}
                </InputLabel>

                <Field
                  name="currentPassword"
                  component={TextField}
                  type="text"
                  variant="outlined"
                  size="small"
                />
              </div>

              <div className={classes.fieldWrapper}>
                <InputLabel className={classes.formLabel}>
                  {intl.formatMessage({ id: 'newPassword' })}
                </InputLabel>

                <Field
                  name="newPassword"
                  component={TextField}
                  type="text"
                  variant="outlined"
                  size="small"
                />
              </div>

              <div className={classes.fieldWrapper}>
                <InputLabel className={classes.formLabel}>
                  {intl.formatMessage({ id: 'confirmPassword' })}
                </InputLabel>

                <Field
                  name="confirmPassword"
                  component={TextField}
                  type="text"
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>

            {isSubmitting && <LinearProgress />}

            <div className={classes.buttonsWrapper}>
              <Button
                variant="outlined"
                type="submit"
                disabled={isSubmitting}
                className={`${classes.submitButton} ${classes.actionButton}`}
              >
                {intl.formatMessage({
                  id: 'save',
                })}
              </Button>

              <Button
                variant="outlined"
                color="default"
                type="button"
                disabled={isSubmitting}
                onClick={() => history.go(-1)}
                className={classes.actionButton}
              >
                {intl.formatMessage({
                  id: 'cancel',
                })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { ChangePasswordForm };
