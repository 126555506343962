// reducers/zoning.reducer.ts
const initialState = {
	zones: [],
	allZones: [],
	boolValue:false
  };
  
  const zoning = (state = initialState, action) => {
	switch (action.type) {
	  case "storeZones":
		return {
		  ...state,
		  zones: action.payload
		};
	  case "storeAllZones":
		return {
		  ...state,
		  allZones: [...state.allZones, action.payload]
		};
		case "visabilityOfErrorPage":
			return {
			  ...state,
			  boolValue: action.payload
			};
	  default:
		return state;
	}
  };
  
  export {zoning};
  