import React from 'react';

import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { PageNotFound } from 'app/components/page-not-found';
import { SystemHeader, SystemIcon } from 'app/components/shared/system-header';
import { TripSales } from 'app/financial-control/trip-sales';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canViewFinancialControlSubSystems } from 'selectors';

const FinancialControl: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const canViewTripSales = useTypedSelector(state =>
    canViewFinancialControlSubSystems(state, 'TripPaymentOperations')
  );

  return (
    <>
      <SystemHeader
        headerTitle={intl.formatMessage({ id: 'financialControl' })}
        headerIcon={SystemIcon.financialControl}
      />

      <Switch>
        <Redirect from={baseURL} exact to={`${baseURL}/tripSales`} />

        <Route path={`${baseURL}/tripSales`}>
          {canViewTripSales ? <TripSales /> : <NotAuthorized />}
        </Route>

        <Route path="/">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};

export { FinancialControl };
