/* eslint-disable react/jsx-newline */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import {
  getAdmin,
  getAdminFullPermissions,
  deleteAdmin,
  regenerateAdminPassword,
  editAdmin,
  successAlert,
  getOrganizations,
  getGroups,
} from 'actions';
import { AdminPermissions } from 'app/admin-management/admins/permissions';
import { CardTitle } from 'app/components/shared/card-title';
import { DeleteAlert } from 'app/components/shared/delete-alert';
import { useStyles } from 'app/components/shared/styles/view-entity';
import { StatusCell } from 'app/components/shared/table';
import { formatDateTime, trimTrailingSlash, useTypedSelector } from 'helpers';
import {
  canEditOperationsSubSystemEntity,
  getAdminListOfPermissionsById,
  getIsSuperAdmin,
} from 'selectors';
import { Admin } from 'types';

import { AdminPasswordSchema } from './admin-helpers';

import reset from 'assets/reset.svg';

const ViewAdmin: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const adminId = match.params.id;
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  const hasAdminsWriteAccess = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Admins', adminId)
  );

  const admin = useTypedSelector(
    state => state.admins.collection[adminId] as Admin | undefined
  );

  const prevAdmin = useRef(admin);

  const adminPermissions = useTypedSelector(state =>
    getAdminListOfPermissionsById(state, adminId)
  );

  const isSuperAdmin = useTypedSelector(getIsSuperAdmin);

  const loadData = useCallback(async () => {
    try {
      if (!admin?.is_super_admin) {
        if (isSuperAdmin) {
          await dispatch(getOrganizations(0, 100));
        }
        await dispatch(getGroups(0, 100));
      }
    } finally {
      //  console.log(e);
    }

    if (admin) {
      setLoading(false);
      prevAdmin.current = admin;
    } else if (!prevAdmin.current) {
      dispatch(getAdmin(adminId));
    }
  }, [admin, isSuperAdmin, dispatch, adminId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!adminPermissions) {
      dispatch(getAdminFullPermissions(adminId));
    }
  }, [adminId, adminPermissions, dispatch]);

  const regeneratePassword = async () => {
    const res = (await dispatch(regenerateAdminPassword(adminId))) as any;
    const newPass = res?.value?.data?.response?.password;

    if (newPass) {
      setNewPassword(newPass);
    }
  };

  const handleSubmit = async (values: { password: string }) => {
    await dispatch(editAdmin(adminId, { password: values.password }));

    setNewPassword('');
    setIsResettingPassword(false);
    dispatch(successAlert(intl.formatMessage({ id: 'successEditAdmin' })));
  };

  const renderCardActions = () => {
    if (hasAdminsWriteAccess) {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.editButton}
            component={Link}
            to={`${baseURL}/edit`}
          >
            {intl.formatMessage({ id: 'editAdminInfo' })}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className={`${classes.editButton} ${classes.deleteButton}`}
            component={Button}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setDeleteModalOpen(true);
            }}
          >
            {intl.formatMessage({ id: 'delete' })}
          </Button>
        </>
      );
    }

    return null;
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.viewContainer}>
      <CardTitle title={intl.formatMessage({ id: 'adminInfo' })} />

      <div className={classes.infoWrapper}>
        <TableContainer className={classes.tableContainer}>
          <Table size="small" aria-label="Admin Info Table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeadCell}>
                  {admin?.first_name} {admin?.last_name}
                </TableCell>

                <TableCell
                  style={{ textAlign: 'end' }}
                  className={classes.tableHeadCell}
                >
                  {renderCardActions()}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'lastLogin' })}</TableCell>

                <TableCell align="center">
                  {admin?.last_sign_in_at
                    ? formatDateTime(admin.last_sign_in_at)
                    : ''}
                </TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'userName' })}</TableCell>

                <TableCell align="center">{admin?.username}</TableCell>
              </TableRow>

              <TableRow className={classes.row}>
                <TableCell>{intl.formatMessage({ id: 'status' })}</TableCell>

                <StatusCell isDisabled={admin?.disabled} showLabel />
              </TableRow>

              {hasAdminsWriteAccess && (
                <TableRow className={classes.row}>
                  <TableCell
                    style={{ paddingBottom: isResettingPassword ? 100 : 0 }}
                  >
                    {intl.formatMessage({ id: 'password' })}
                  </TableCell>

                  <TableCell align="center">
                    {!isResettingPassword ? (
                      <Button
                        variant="outlined"
                        className={classes.regeneratePasswordBtn}
                        onClick={() => setIsResettingPassword(true)}
                        style={{ width: 150, padding: '0 20px' }}
                      >
                        {intl.formatMessage({ id: 'resetPassword' })}
                      </Button>
                    ) : (
                      <>
                        <Formik
                          initialValues={{ password: newPassword }}
                          onSubmit={handleSubmit}
                          validationSchema={AdminPasswordSchema()}
                          enableReinitialize
                          validateOnMount
                        >
                          {({
                            isValid,
                            values,
                            handleChange,
                            isSubmitting,
                          }) => (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingBottom: 12,
                              }}
                            >
                              <TextField
                                name="password"
                                type="text"
                                variant="outlined"
                                size="small"
                                className={classes.input}
                                value={values.password}
                                onChange={handleChange}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      style={{
                                        outline: 'none',
                                      }}
                                    >
                                      <IconButton onClick={regeneratePassword}>
                                        <img src={reset} alt="reset" />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              <Button
                                variant="outlined"
                                className={classes.regeneratePasswordBtn}
                                type="submit"
                                style={{
                                  width: 60,
                                  margin: '0 10px',
                                  opacity: isValid && !isSubmitting ? 1 : 0.7,
                                  color: '#fff',
                                }}
                                disabled={!isValid || isSubmitting}
                              >
                                {intl.formatMessage({ id: 'save' })}
                              </Button>

                              <Button
                                variant="outlined"
                                className={classes.cancelButton}
                                onClick={() => {
                                  setIsResettingPassword(false);
                                  setNewPassword('');
                                }}
                              >
                                {intl.formatMessage({ id: 'cancel' })}
                              </Button>
                            </div>
                          )}
                        </Formik>

                        <Typography style={{ fontSize: 12 }}>
                          {intl.formatMessage({
                            id: 'theNewPasswordMustHave',
                          })}
                          :
                        </Typography>

                        <ul className={classes.list}>
                          <li>
                            {intl.formatMessage({
                              id: 'sixOrMoreCharacters',
                            })}
                          </li>

                          <li>
                            {intl.formatMessage({
                              id: 'atLeastOneLetter',
                            })}
                          </li>

                          <li>
                            {intl.formatMessage({
                              id: 'atLeastOneNumber',
                            })}
                          </li>
                        </ul>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {!admin?.is_super_admin && <AdminPermissions />}

      <Button
        variant="outlined"
        color="default"
        type="button"
        className={classes.backButton}
        onClick={() => history.replace('/adminManagement/admins')}
      >
        {intl.formatMessage({ id: 'back' })}
      </Button>

      <DeleteAlert
        {...{
          isOpen: isDeleteModalOpen,
          setOpen: setDeleteModalOpen,
          deleteAction: deleteAdmin,
          entityType: 'admin',
          entityName: admin?.first_name || ' ',
          entityId: adminId,
          onfinishNavigation: '/adminManagement/admins',
        }}
      />
    </div>
  );
};

export { ViewAdmin };
