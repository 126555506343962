import React from 'react';

import {
  makeStyles,
  createStyles,
  Grid,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
  createStyles({
    inputContainer: {
      backgroundColor: '#fff',
      height: 50,
    },
    startDate: {
      paddingRight: 3,
    },
    endDate: {
      paddingLeft: 3,
    },
    formLabel: {
      color: '#444',
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 700,
    },
    formControl: {
      marginBottom: 22,
    },
  })
);

type ParamTypes = {
  minDateValue: string;
  maxDateValue: string;
  minDateFilterName: string;
  maxDateFilterName: string;
  onChangeMinDate: (
    filterName: string,
    date: string | Moment | undefined
  ) => void;
  onChangeMaxDate: (
    filterName: string,
    date: string | Moment | undefined
  ) => void;
};

const FiltersDateRangeSelector = ({
  minDateValue,
  maxDateValue,
  minDateFilterName,
  maxDateFilterName,
  onChangeMinDate,
  onChangeMaxDate,
}: ParamTypes) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container>
      <Grid item sm={6} className={classes.startDate}>
        <FormControl fullWidth className={classes.formControl}>
          <FormLabel className={classes.formLabel}>
            {intl.formatMessage({ id: 'fromDate' })}
          </FormLabel>

          <DatePicker
            variant="inline"
            format="DD/MM/yyyy"
            id="date-picker-inline"
            value={minDateValue || null}
            onChange={date => {
              onChangeMinDate(minDateFilterName, date?.toISOString());
            }}
            disableFuture
            style={{ backgroundColor: '#fff' }}
            inputVariant="outlined"
            InputProps={{
              className: classes.inputContainer,
              style: {
                color: minDateValue ? '#000' : '#aaa',
              },
            }}
            emptyLabel={moment().format('DD/MM/YYYY')}
            maxDate={maxDateValue}
            disableToolbar
            autoOk
          />
        </FormControl>
      </Grid>

      <Grid item sm={6} className={classes.endDate}>
        <FormControl fullWidth className={classes.formControl}>
          <FormLabel className={classes.formLabel}>
            {intl.formatMessage({ id: 'toDate' })}
          </FormLabel>

          <DatePicker
            variant="inline"
            format="DD/MM/yyyy"
            id="date-picker-inline"
            value={maxDateValue || null}
            onChange={date => {
              onChangeMaxDate(maxDateFilterName, date?.toISOString());
            }}
            disableFuture
            style={{ backgroundColor: '#fff' }}
            inputVariant="outlined"
            InputProps={{
              className: classes.inputContainer,
              style: {
                color: maxDateValue ? '#000' : '#aaa',
              },
            }}
            minDate={minDateValue}
            emptyLabel={moment().format('DD/MM/YYYY')}
            disableToolbar
            autoOk
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export { FiltersDateRangeSelector };
