import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { AddRoute } from 'app/operations/routes/add-route';
import { EditRoute } from 'app/operations/routes/edit-route';
import { RouteList } from 'app/operations/routes/route-list';
import { ViewRoute } from 'app/operations/routes/view-route';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

const Routes: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasRouteAddAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Routes')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasRouteAddAccess ? <AddRoute /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          <EditRoute />
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewRoute />
        </Route>

        <Route exact path={`${baseURL}`}>
          <RouteList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { Routes };
