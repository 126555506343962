import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { vehicleTypesConstants } from '_constants';
import { API } from 'helpers';

interface getVehicleTypesParams {
  en_model_or_ar_model_cont?: string;
}

const getVehicleTypes =
  (page: number, size: number, params?: getVehicleTypesParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: vehicleTypesConstants.GET_VEHICLE_TYPES,
      payload: API.get('/v2/vehicle_types', {
        params: { page: pageNumber, page_count: size, export: 0, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getVehicleType =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: vehicleTypesConstants.GET_VEHICLE_TYPE,
      payload: API.get(`/v2/vehicle_types/${id}`),
    });

interface VehicleTypeActionParams {
  ar_model?: string;
  en_model?: string;
  capacity?: string;
  vehicle_class_id?: string;
}

const addVehicleType =
  (data: VehicleTypeActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { vehicle_type: data };

    return dispatch({
      type: vehicleTypesConstants.ADD_VEHICLE_TYPE,
      payload: API.post('/v2/vehicle_types', requestData),
    });
  };

const editVehicleType =
  (id: string, data: VehicleTypeActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { vehicle_type: data };

    return dispatch({
      type: vehicleTypesConstants.EDIT_VEHICLE_TYPE,
      payload: API.put(`/v2/vehicle_types/${id}`, requestData),
    });
  };

const deleteVehicleType =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: vehicleTypesConstants.DELETE_VEHICLE_TYPE,
      payload: API.delete(`/v2/vehicle_types/${id}`),
      meta: { vehicleTypeId: id },
    });

export {
  getVehicleTypes,
  getVehicleType,
  addVehicleType,
  editVehicleType,
  deleteVehicleType,
};
