import React, { useState, useEffect } from 'react';

import {
  Typography,
  Card,
  CardContent,
  makeStyles,
  CardMedia,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { login } from 'actions';
import logo from 'assets/logo_with_name.png';
import { useTypedSelector } from 'helpers';

const useStyles = makeStyles(theme => ({
  alignItemsAndJustifyContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginCard: {
    width: '100%',
    marginTop: 52,
    padding: '52px 64px 87px',
    borderRadius: 35,
    backgroundColor: '#fff',
    border: '1px solid #F1F3FB',
    [theme.breakpoints.down('sm')]: { padding: '20px 0' },
  },
  container: { paddingTop: 100 },
  logo: { width: 270, marginBottom: 42 },
  content: {
    [theme.breakpoints.up('md')]: { padding: 0 },
  },
  inputField: { width: '100%', marginBottom: 14, borderRadius: 10 },
  loginButton: {
    height: '38px',
    marginTop: 21,
    width: '100%',
    borderRadius: 28,
    backgroundColor: '#542187',
    boxShadow: '0 1px 4px 0 rgba(125,125,125,0.5)',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#542187',
      opacity: 0.9,
    },
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const authentication = useTypedSelector(state => state.authentication);
  const location = useLocation<{ from: { pathname: string } }>();
  const [isSubmitting, setSubmitting] = useState(false);
  const intl = useIntl();

  const {
    from: { pathname },
  } = location.state || { from: { pathname: '/' } };

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (authentication?.loggedIn) {
      history.push(pathname);
    }
  }, [authentication, pathname, history]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (userName && password) {
      setSubmitting(true);

      Promise.resolve(dispatch(login(userName, password))).finally(() =>
        setSubmitting(false)
      );
    }
  };

  return (
    <Container
      maxWidth="sm"
      className={`${classes.alignItemsAndJustifyContent} ${classes.container}`}
    >
      <Typography variant="h4" align="center" gutterBottom>
        {intl.formatMessage({ id: 'welcome' })}
      </Typography>

      <Typography variant="subtitle1" align="center">
        {intl.formatMessage({ id: 'loginHint' })}
      </Typography>

      <Card
        variant="outlined"
        className={`${classes.loginCard} ${classes.alignItemsAndJustifyContent}`}
      >
        <CardMedia
          className={classes.logo}
          component="img"
          src={logo}
          title="Mwasalat Misr logo"
        />

        <CardContent className={classes.content}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              label={intl.formatMessage({ id: 'userName' })}
              variant="outlined"
              className={classes.inputField}
              margin="dense"
              value={userName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUserName(e.target.value);
              }}
              required
            />

            <TextField
              label={intl.formatMessage({ id: 'password' })}
              variant="outlined"
              className={classes.inputField}
              margin="dense"
              value={password}
              type="password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
              }}
              autoComplete="off"
              required
            />

            <Button
              variant="contained"
              className={classes.loginButton}
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                intl.formatMessage({ id: 'login' })
              )}
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export { Login };
