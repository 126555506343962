import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, addGroup } from 'actions';
import { GroupForm } from 'app/operations/groups/group-form';
import { GroupFormState } from 'app/operations/groups/group-helpers';
import { trimTrailingSlash } from 'helpers/url-helpers';

const initialState: GroupFormState = {
  en_name: '',
  ar_name: '',
  code: '',
  organizationId: '',
};

const AddGroup: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const handleSubmit = (
    values: GroupFormState,
    { setSubmitting }: FormikHelpers<GroupFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        addGroup({
          en_name: values.en_name,
          ar_name: values.ar_name,
          code: values.code,
          organisation_id: values.organizationId,
        })
      )
    )
      .then(({ action }: any) => {
        const newGroupId = action.payload.data?.response.group.id;
        history.push(`${baseURL.replace('/new', '')}/${newGroupId}`);

        dispatch(successAlert(intl.formatMessage({ id: 'successAddGroup' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <GroupForm {...{ initialState, handleSubmit }} />;
};

export { AddGroup };
