/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { getDevices, getGroups } from 'actions';
import { ExcelFileInput } from 'app/components/excel-file-input';
import { CardTitle } from 'app/components/shared/card-title';
import { DataTableContainer } from 'app/components/shared/data-table-container';
import { LoadingSpinner } from 'app/components/shared/loading-spinner';
import { TextCell } from 'app/components/shared/table';
import { SystemEmptyState } from 'app/components/system-empty-state';
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { getTranslated, trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity, canEditOperationsSubSystemEntity } from 'selectors';
import { Device } from 'types';

import ImportIcon from 'assets/import-icon.svg';
import AssignVehicle from './assign-vehicle';
import { FilterDevicesByName } from './filter-devices';
import { useHandleImportDevicesData } from './hooks';
import {
  PaginationComponent,
  usePagination,
} from '../../components/use-pagination';


const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
    limitedWidth: {
      maxWidth: 150,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

const DeviceListItem = ({
  device,
  onAssignVehicle,
}: {
  device: Device;
  onAssignVehicle: () => void;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const parentOrganization = getTranslated(device?.organisation, locale);

  const hasEditPermissionOnOrganizationDevice = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Devices', device.id)
  );

  return (
    <TableRow
      onClick={() => {
        history.push(`${baseURL}/${device.id}`);
      }}
      className={classes.row}
      hover
    >
      <TextCell
        label={device.vehicle?.code || intl.formatMessage({ id: 'unassigned' })}
        maxWidth={150}
        align="center"
      />

      <TextCell label={device.serial} maxWidth={150} align="center" />

      <TextCell label={device.obc_type} align="center" maxWidth={150} />

      <TextCell label={parentOrganization} maxWidth={150} align="center" />

      <TextCell label={device.ip} maxWidth={150} align="center" />

      {hasEditPermissionOnOrganizationDevice ? (
        <AssignVehicle deviceId={device.id} onAssign={onAssignVehicle} />
      ) : (
        <TableCell />
      )}
    </TableRow>
  );
};

const DeviceList: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const { direction } = useContext(LanguageContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearching, setSearching] = useState(false);
  const [isFiltering, setFiltering] = useState(false);

  const [filterParams, setFilterParams] = useState<{ [key: string]: string }>(
    {}
  );

  const hasDeviceWriteAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Devices')
  );

  useEffect(() => {
    dispatch(getGroups(0, 500));
  }, [dispatch]);

  const fetchSearchResults = useCallback(
    (page: number, rowSize: number) =>
      getDevices(page, rowSize, {
        vehicle_code_or_serial_cont: searchTerm,
      }),
    [searchTerm]
  );

  const fetchFilterResults = useCallback(
    (page: number, rowSize: number) => getDevices(page, rowSize, filterParams),
    [filterParams]
  );

  const fetchAction = useCallback(() => {
    if (isSearching) return fetchSearchResults;

    if (isFiltering) return fetchFilterResults;

    return getDevices;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFilterResults, isFiltering, isSearching]);

  const deviceListIsLoading = useTypedSelector(
    state => state.devices.isLoading as boolean
  );

  const deviceListHasError = useTypedSelector(state => state.devices.hasError);
  const devicesTotalCount = useTypedSelector(state => state.devices.totalCount);

  const deviceList = useTypedSelector(state => {
    if (!state.devices.isLoading && Object.values(state.devices).length) {
      return Object.values(state.devices.collection);
    }

    return [];
  });

  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ fetchAction: fetchAction() });

  const resetPageState = () => {
    handleChangePage(null, 0);
  };

  const filterDevices = filters => {
    if (Object.values(filters)?.length) {
      setFiltering(true);
      setSearching(false);
      setSearchTerm('');
    }

    resetPageState();

    setFilterParams(filters);
  };

  const handleSearch = () => {
    if (searchTerm) {
      dispatch(
        getDevices(page, rowsPerPage, {
          vehicle_code_or_serial_cont: searchTerm,
        })
      );

      setSearching(true);
    } else {
      setSearching(false);
    }

    resetPageState();
  };

  const { readUploadFile, loading: importing } = useHandleImportDevicesData({
    onSuccess: () => dispatch(fetchAction()(page, rowsPerPage)),
  });

  const pageLoading = deviceListIsLoading || importing;

  const Pagination = () => (
    <PaginationComponent
      {...{
        totalCount: devicesTotalCount,
        hasData: !!deviceList.length,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
      }}
    />
  );

  return (
    <div dir={direction}>
      <CardTitle title={intl.formatMessage({ id: 'devices' })}>
        <div>
          {hasDeviceWriteAccess && (
            <ExcelFileInput
              onChange={readUploadFile}
              icon={ImportIcon}
              labelKey="import"
            />
          )}

          {hasDeviceWriteAccess && (
            <Button
              component={Link}
              to={`${baseURL}/new`}
              variant="contained"
              className={classes.addButton}
              color="secondary"
            >
              {intl.formatMessage({ id: 'addDevice' })}
            </Button>
          )}
        </div>
      </CardTitle>

      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FilterDevicesByName
            {...{
              searchTerm,
              setSearchTerm,
              handleSearch,
              showFilters: !isSearching,
              filterDevices,
              isSearching,
            }}
          />
        </Grid>

        <Grid
          item
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <Pagination />
        </Grid>
      </Grid>

      <DataTableContainer alignItems={pageLoading ? 'center' : 'flex-start'}>
        {deviceListHasError ? (
          <Typography align="center" variant="h5" color="textSecondary">
            {intl.formatMessage({ id: 'somethingWrong' })}
          </Typography>
        ) : (
          <>
            {pageLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {deviceList.length ? (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="Devices Table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {intl.formatMessage({ id: 'vehicleCode' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'serial' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'type' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'organization' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'ip' })}
                        </TableCell>

                        <TableCell align="center">
                          {intl.formatMessage({ id: 'actions' })}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {deviceList.map(device => (
                        <DeviceListItem
                          key={device.id}
                          {...{ device }}
                          onAssignVehicle={() => {
                            dispatch(fetchAction()(page, rowsPerPage));
                          }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <SystemEmptyState
                      hasWriteAccess={hasDeviceWriteAccess}
                      baseURL={baseURL}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </DataTableContainer>

      <Pagination />
    </div>
  );
};

export { DeviceList };
