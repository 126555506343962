import { FluxStandardAction } from 'redux-promise-middleware';

import { driverConstants } from '_constants';
import { AppState, Driver } from 'types';

const drivers = (
  state: AppState['drivers'] = {
    collection: {},
    totalCount: 0,
    isLoading: false,
    hasError: false,
  },
  action: FluxStandardAction
): AppState['drivers'] => {
  switch (action.type) {
    case `${driverConstants.GET_DRIVERS}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${driverConstants.GET_DRIVERS}_FULFILLED`: {
      const driverList: AppState['drivers']['collection'] = {};

      if (action.payload.data) {
        action.payload.data.response.data.forEach((item: Driver) => {
          driverList[item.id] = item;
        });

        return {
          collection: { ...driverList },
          totalCount: action.payload.data.response.size,
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${driverConstants.GET_DRIVERS}_REJECTED`: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case `${driverConstants.GET_DRIVER}_PENDING`: {
      return { ...state, isLoading: true };
    }

    case `${driverConstants.GET_DRIVER}_FULFILLED`: {
      const driverData = action.payload.data?.response.driver;

      if (driverData) {
        return {
          ...state,
          collection: {
            ...state.collection,
            [driverData.id]: driverData,
          },
          isLoading: false,
        };
      }

      return { ...state };
    }

    case `${driverConstants.ADD_DRIVER}_FULFILLED`: {
      const driverData = action.payload.data?.response.driver;

      return {
        ...state,
        collection: {
          ...state.collection,
          [driverData.id]: driverData,
        },
      };
    }

    case `${driverConstants.EDIT_DRIVER}_FULFILLED`: {
      const driverData = action.payload.data?.response.driver;

      return {
        ...state,
        collection: {
          ...state.collection,
          [driverData.id]: driverData,
        },
      };
    }

    case `${driverConstants.DELETE_DRIVER}_FULFILLED`: {
      const deletedDriverId = action.meta.driverId;

      const newState = { ...state };
      delete newState.collection[deletedDriverId];

      return newState;
    }

    case `${driverConstants.REGENERATE_DRIVER_PASSWORD}_FULFILLED`: {
      const driverId = action.meta.driver_id;

      return {
        ...state,
        collection: {
          ...state.collection,
          [driverId]: {
            ...state.collection[driverId],
            password_digest: action.payload.data.response.password,
          },
        },
      };
    }

    default:
      return state;
  }
};

export { drivers };
