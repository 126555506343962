import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { vehicleClassesConstants } from '_constants';
import { API } from 'helpers';

interface getVehicleClassesParams {
  en_name_or_ar_name_cont?: string;
}

const getVehicleClasses =
  (page: number, size: number, params?: getVehicleClassesParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: vehicleClassesConstants.GET_VEHICLE_CLASSES,
      payload: API.get('/v2/vehicle_classes', {
        params: { page: pageNumber, page_count: size, export: 0, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getVehicleClass =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: vehicleClassesConstants.GET_VEHICLE_CLASS,
      payload: API.get(`/v2/vehicle_classes/${id}`),
    });

interface VehicleClassActionParams {
  ar_name?: string;
  en_name?: string;
  avg_speed?: string;
}

const addVehicleClass =
  (data: VehicleClassActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { vehicle_class: data };

    return dispatch({
      type: vehicleClassesConstants.ADD_VEHICLE_CLASS,
      payload: API.post('/v2/vehicle_classes', requestData),
    });
  };

const editVehicleClass =
  (id: string, data: VehicleClassActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { vehicle_class: data };

    return dispatch({
      type: vehicleClassesConstants.EDIT_VEHICLE_CLASS,
      payload: API.put(`/v2/vehicle_classes/${id}`, requestData),
    });
  };

const deleteVehicleClass =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: vehicleClassesConstants.DELETE_VEHICLE_CLASS,
      payload: API.delete(`/v2/vehicle_classes/${id}`),
      meta: { vehicleClassId: id },
    });

export {
  getVehicleClasses,
  getVehicleClass,
  addVehicleClass,
  editVehicleClass,
  deleteVehicleClass,
};
