// @ts-nocheck
import React, { useMemo, useState } from 'react';
import { Accordion, AccordionDetails, withStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Modal,Button as BootstrapButton } from "react-bootstrap";
import {
  editAdminCategoryPermissions,
  getAdminFullPermissions,
  grantGeneralPermission,
  successAlert,
} from 'actions';
import { useTypedSelector } from 'helpers';
import {
  getLoggedAdminId,
  hasGrantAccessForCategory,
  makeGetAllPermissionsIds,
  makeGetSubSystemPermissions,
} from 'selectors';
import { AdminPermissionList } from 'types';

import { AccordionSummary } from './accordation-summary';
import { GroupedSystemPermissions } from './grouped-system-permissions';
import { toast } from 'react-toastify';

const StyledAccordion = withStyles({
  root: {
    backgroundColor: '#F7F7F8',
    border: '0px',
    boxShadow: 'none',
    marginBottom: 12,

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

interface SubSystemPermissionsProps {
  systemName: string;
  categoryName: string;
  permissions:
    | AdminPermissionList['permissionsByOrganization'][0]['subSystems']
    | AdminPermissionList['master_configuration'];
  permissionLevelType?: 'Group' | 'Organisation' | undefined;
  permissionLevelId?: string;
}

const SubSystemPermissions: React.FC<SubSystemPermissionsProps> = ({
  systemName,
  categoryName,
  permissions,
  permissionLevelType,
  permissionLevelId,
}) => {
  const match = useRouteMatch<{ id: string }>();
  const adminId = match.params.id;
  const dispatch = useDispatch();
  const intl = useIntl();

  const currentAdminId = useTypedSelector(getLoggedAdminId) as string;

  const canGrantAccessWithPermissionLevel = useTypedSelector(state =>
    hasGrantAccessForCategory(state, systemName)
  );

  const level =
    permissionLevelType === 'Group'
      ? 'groups_permission_level'
      : 'organisations_permission_level';

  const getSubSystemPermissions = useMemo(makeGetSubSystemPermissions, []);

  const subSystems = useTypedSelector(state =>
    getSubSystemPermissions(state, { level, systemName, permissionLevelId })
  );

  const getAllPermissionsIds = useMemo(makeGetAllPermissionsIds, []);

  const allPermissionsIds = useTypedSelector(state =>
    getAllPermissionsIds(state, {
      systemName,
      adminId: currentAdminId,
      systemType: permissionLevelType,
      systemId: permissionLevelId,
    })
  );
const adminPermissions = useSelector(state => state.permissions.collection);
const adminPermissionsAll = useSelector(state => state.permissions);
const adminGroups = useSelector(state => state.groups)

  //model 
  const [show, setShow] = useState(false);
  const [modelMessage, setModelMessage] = useState('');
  const [routeOrFareOrgID, setRouteOrFareOrgID] = useState(null);
  const [type, setType] = useState('');
  const [sendedData, setData] = useState([]);
  const history = useHistory();
  const formatPermission = (permissionsIds, isOrganization?: boolean) =>
  permissionsIds.map(id => ({
    id,
    type: isOrganization ? 'Organisation' : 'Group',
  }));
    //handle Error function
    const handleError = (message,code)=>{
      const searchParams = new URLSearchParams();
    
                // Set query parameters
                searchParams.set('message',message );
                searchParams.set('code', code);
                searchParams.set('backToLink', `/adminManagement/admins/${adminId}`);
            
                // Update the URL with the new query parameters
                history.push({
                  pathname: '/Error',
                  search: `?${searchParams.toString()}`,
                });
                toast.error( intl.formatMessage({ id: 'someThingWentWrongError'}), {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                  })  
    }

  const handleClose = () =>{
    setShow(false);
    setModelMessage('');
    setRouteOrFareOrgID(null)
    setType('')
    setData([])
    setShow(false);
  } 
  const handleShow = (m,o,type,data) =>{
    setModelMessage(m);
    setRouteOrFareOrgID(o)
    setType(type)
    setData(data)
    setShow(true);
  } 
  const GrantOrDenyRouteOrFareFunc = async(id,type,data)=>{
    
    if(adminPermissionsAll && adminPermissionsAll.generalPermissions && adminGroups && adminId){
    if(type =="grant"){
      grantRouteReadOnly(id,data)
    }
    else{
      denyFares(id,data)
    }

    //at last
    handleClose()
  }else{
    handleError('someThingWentWrongError','ssp-1')
  }
  }
  const grantRouteReadOnly = async(OrgId,data)=>{
    const routesReadId = adminPermissionsAll.generalPermissions
   .filter(obj => obj.action === 'Routes.read')
   .map(obj => obj.id);
    await Promise.resolve(
      dispatch(
        grantGeneralPermission(
          adminId,
          routesReadId[0],
          [{id:OrgId, type: 'Organisation'}],
          []
        )
      )
    );
    await dispatch(getAdminFullPermissions(adminId));
    dispatch(
      successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
    );
      await Promise.resolve(
        dispatch(
          editAdminCategoryPermissions(
            adminId,
            data[0],
            data[1],
            OrgId,
            data[2]
          )
        )
      )
        .then(() => dispatch(getAdminFullPermissions(adminId)))
        .then(() =>
          dispatch(
            successAlert(intl.formatMessage({ id: `successGrantFullControl` }))
          ))

  }
  const denyFares = async(id,routeDeny)=>{ 
 
 
    const faresReadIds = adminPermissionsAll.generalPermissions
    .filter(obj => obj.action === 'Fares.read')
    .map(obj => obj.id);
  
  const faresWriteIds = adminPermissionsAll.generalPermissions
    .filter(obj => obj.action === 'Fares.write')
    .map(obj => obj.id);
    const groupIds =  Object.values(adminGroups.collection)
  .filter(group => group.organisation_id === id)
  .map(group => group.id)
  const OrgClearPermissionsForFares = async()=>{
    const groupsToBeDeleted = groupIds;
    console.log( "GROUPS : ",groupsToBeDeleted);
    
        const permissionsToBeDeleted = true
          ? [permissionLevelId]
          : groupsToBeDeleted;
    
        if (permissionsToBeDeleted.length) {
          [faresReadIds[0],faresWriteIds[0]].forEach(async id => {
            if (id)
              await Promise.resolve(
                dispatch(
                  grantGeneralPermission(
                    adminId,
                    id,
                    [],
                    formatPermission(permissionsToBeDeleted, true)
                  )
                )
              );
          });
        }
  }
  const GroupsClearPermissionsForFares = async()=>{
      const groupsToBeDeleted2 = groupIds;
      console.log( "GROUPS : ",groupsToBeDeleted2);
      
          const permissionsToBeDeleted2 = false
            ? [permissionLevelId]
            : groupsToBeDeleted2;
      
          if (permissionsToBeDeleted2.length) {
            [faresReadIds[0],faresWriteIds[0]].forEach(async id => {
              if (id)
                await Promise.resolve(
                  dispatch(
                    grantGeneralPermission(
                      adminId,
                      id,
                      [],
                      formatPermission(permissionsToBeDeleted2, false)
                    )
                  )
                );
            });
          }
        }
   
        await OrgClearPermissionsForFares()
        await GroupsClearPermissionsForFares()
   
        await dispatch(getAdminFullPermissions(adminId));
        dispatch(
          successAlert(intl.formatMessage({ id: `successUpdatePermissions` }))
        );

      await Promise.resolve(
        dispatch(
          editAdminCategoryPermissions(
            adminId,
            [],
            routeDeny[0],
            id,
            routeDeny[1]
          )
        )
      )
        .then(() => dispatch(getAdminFullPermissions(adminId)))
        .then(() =>
          dispatch(
            successAlert(intl.formatMessage({ id: `successDenyAllAccess` }))
          )
        );
    await dispatch(getAdminFullPermissions(adminId));
  }
  const onGrantFullControl = async () => {
 if(adminPermissions && adminId){
  console.log(adminId,
    allPermissionsIds,
    permissionLevelType,
    permissionLevelId,
    systemName);
    const checkRouteOfOrgIsExist = async () => {
      const ExecuteCheckRouteOfOrgIsExist = async (organisations_permission_level, groups_permission_level, permissionLevelId) => {

          for (const item of organisations_permission_level) {
            if (item.organisation.id === permissionLevelId) {
              const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === 'Routes');
              if (subSystem) {
                return true;
              }
            }
          }
          for (const item of groups_permission_level) {
            if (item.group.organisation.id === permissionLevelId) {
              const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === 'Routes');
              if (subSystem) {
                return true;
              }
            }
          }
        return false;
      };
    
      const isRouteEnabled = await ExecuteCheckRouteOfOrgIsExist(
        adminPermissions[adminId].organisations_permission_level,
        adminPermissions[adminId].groups_permission_level,
        permissionLevelId
      );
    
      if (!isRouteEnabled) {

        handleShow('ThisActionNeedToGrantControlForRoutesOfTheOrganisationFirst',permissionLevelId,"grant",[allPermissionsIds,permissionLevelType,systemName]);
        return false;
      } else {
      
        return true;
      }
    }; 
    if(systemName == 'fares'){
      console.log(allPermissionsIds,
        permissionLevelType,
        permissionLevelId,
        systemName);
      
      if(await checkRouteOfOrgIsExist()){

  await Promise.resolve(
    dispatch(
      editAdminCategoryPermissions(
        adminId,
        allPermissionsIds,
        permissionLevelType,
        permissionLevelId,
        systemName
      )
    )
  )
    .then(() => dispatch(getAdminFullPermissions(adminId)))
    .then(() =>
      dispatch(
        successAlert(intl.formatMessage({ id: `successGrantFullControl` }))
      )
    );}
      }else if(systemName == 'operations'){
        await Promise.resolve(
          dispatch(
            editAdminCategoryPermissions(
              adminId,
              allPermissionsIds,
              permissionLevelType,
              permissionLevelId,
              systemName
            )
          )
        )
          .then(() => dispatch(getAdminFullPermissions(adminId)))
          .then(() =>
            dispatch(
              successAlert(intl.formatMessage({ id: `successGrantFullControl` }))
            )
          );
        toast.warn(intl.formatMessage({ id: 'noteFaresSybPermissonsChangedToBeLikeRoutesPermissions'}), {
          position: "top-center",
          autoClose: 50000000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          }) 
      }else{
        await Promise.resolve(
          dispatch(
            editAdminCategoryPermissions(
              adminId,
              allPermissionsIds,
              permissionLevelType,
              permissionLevelId,
              systemName
            )
          )
        )
          .then(() => dispatch(getAdminFullPermissions(adminId)))
          .then(() =>
            dispatch(
              successAlert(intl.formatMessage({ id: `successGrantFullControl` }))
            )
          );   
      }
 }else{
  handleError('someThingWentWrongError','ssp-2')
 }

  };

  const onDenyAllAccess = async () => {
    if(adminPermissions && adminId){
      const checkIfFareOfRouteHaveControl = async () => {
        const ExecuteCheckIfFareOfRouteHaveControl = async (organisations_permission_level, groups_permission_level, permissionLevelId) => {
  
            for (const item of organisations_permission_level) {
              if (item.organisation.id ===permissionLevelId) {
                const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === 'Fares');
                if (subSystem) {
                  return true;
                }
              }
            }
            for (const item of groups_permission_level) {
              if (item.group.organisation.id === permissionLevelId) {
                const subSystem = item.sub_systems.find((sub) => sub.sub_system_name === 'Fares');
                if (subSystem) {
                  return true;
                }
              }
            }
  
        
          return false;
        };
      
        const isRouteEnabled = await ExecuteCheckIfFareOfRouteHaveControl(
          adminPermissions[adminId].organisations_permission_level,
          adminPermissions[adminId].groups_permission_level,
          permissionLevelId
        );
      
        if (isRouteEnabled) {
          return true;
        } else {
        
          return false;
        }
      };
      if(systemName == 'operations'){
        if(await checkIfFareOfRouteHaveControl()){
          handleShow('ThisActionNeedToDenyControlForFaresOfTheOrganisationFirst',permissionLevelId,"deny",[permissionLevelType,systemName]);
        }else{
          await Promise.resolve(
            dispatch(
              editAdminCategoryPermissions(
                adminId,
                [],
                permissionLevelType,
                permissionLevelId,
                systemName
              )
            )
          )
            .then(() => dispatch(getAdminFullPermissions(adminId)))
            .then(() =>
              dispatch(
                successAlert(intl.formatMessage({ id: `successDenyAllAccess` }))
              )
            );
        }
      }else{
        await Promise.resolve(
          dispatch(
            editAdminCategoryPermissions(
              adminId,
              [],
              permissionLevelType,
              permissionLevelId,
              systemName
            )
          )
        )
          .then(() => dispatch(getAdminFullPermissions(adminId)))
          .then(() =>
            dispatch(
              successAlert(intl.formatMessage({ id: `successDenyAllAccess` }))
            )
          );
      }
    }else{
      handleError('someThingWentWrongError','ssp-3')
    }

  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{direction:"ltr"}}>
          <Modal.Title> {intl.formatMessage({ id:'Atten'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <h6 style={{padding:"20px 10px 10px 10px",textAlign:"center",color:"red"}}>{intl.formatMessage({ id: modelMessage||'NoMessageFound'})}</h6>
        </Modal.Body>
        <Modal.Footer>
        
          <BootstrapButton    variant="success" onClick={()=>GrantOrDenyRouteOrFareFunc(routeOrFareOrgID,type,sendedData)}>
             {type == "grant" ? intl.formatMessage({ id:'Grant'}) : intl.formatMessage({ id:'Deny'}) }
          </BootstrapButton>
          <BootstrapButton variant="outline-secondary" onClick={handleClose}>
             {intl.formatMessage({ id:'Cancel'})}
          </BootstrapButton>
        </Modal.Footer>
      </Modal>
    <StyledAccordion key={systemName}>
      <AccordionSummary
        ariaControls={systemName}
        entityName={categoryName}
        onDenyAllAccess={onDenyAllAccess}
        onGrantFullControl={onGrantFullControl}
        showActions={canGrantAccessWithPermissionLevel}
        variant="secondary"
      />

      <AccordionDetails>
        <GroupedSystemPermissions
          {...{
            systemName,
            subSystems,
            permissions,
            permissionLevelType,
            permissionLevelId,
          }}
        />
      </AccordionDetails>
    </StyledAccordion>
    </>
  );
};

export { SubSystemPermissions };
