import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';
import { AddDriver } from 'app/operations/drivers/add-driver';
import { DriverList } from 'app/operations/drivers/driver-list';
import { EditDriver } from 'app/operations/drivers/edit-driver';
import { ViewDriver } from 'app/operations/drivers/view-driver';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canAddNewEntity } from 'selectors';

const Drivers: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasDriverAddAccess = useTypedSelector(state =>
    canAddNewEntity(state, 'Drivers')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasDriverAddAccess ? <AddDriver /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
          <EditDriver />
        </Route>

        <Route path={`${baseURL}/:id`}>
          <ViewDriver />
        </Route>

        <Route exact path={`${baseURL}`}>
          <DriverList />
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { Drivers };
