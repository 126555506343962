import React from 'react';

import {
  Button,
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import { mainButtonStyles } from 'app/shared/styles';

import DarkSearchIcon from 'assets/grey-search-icon.svg';
import LightSearchIcon from 'assets/light-search-icon.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      marginLeft: 32,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 15,
      },
    },
    searchField: {
      width: 222,
      height: 43,
    },
    searchButton: {
      ...mainButtonStyles,
      height: 30,
      width: 52,
      minWidth: 52,
      borderRadius: 30,
      marginLeft: 0,
    },
    notchedOutline: {
      borderColor: '#DFE4EA',
      borderRadius: 8,
    },
    multiActionButton: {
      ...mainButtonStyles,
      height: '',
      marginLeft: '',
      padding: '8px 20px',
      margin: '0px 11px 5px 0px',
      textAlign: 'left',
    },
    filterListCont: {
      paddingBottom: 8,
    },
  })
);

const FilterOrganizationByName: React.FC<{
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => void;
}> = ({ searchTerm, setSearchTerm, handleSearch }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.container}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}
      >
        <TextField
          className={classes.searchField}
          variant="outlined"
          margin="dense"
          size="small"
          placeholder={intl.formatMessage({ id: 'searchByName' })}
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleSearch();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={DarkSearchIcon} alt="search" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSearch}
                  className={classes.searchButton}
                >
                  <img src={LightSearchIcon} alt="search" />
                </Button>
              </InputAdornment>
            ),
            style: { height: 40 },
            classes: { notchedOutline: classes.notchedOutline },
          }}
        />
      </div>
    </div>
  );
};

export { FilterOrganizationByName };
