// @ts-nocheck
import React from 'react'
import logo from '../images/logo.png';
import { Link, useLocation } from "react-router-dom";

import cookies from 'js-cookie'
import classNames from 'classnames'
import { useIntl } from 'react-intl';
interface MyComponentProps {
    code: any;
    message: any;

  }
const ErrorComponent: React.FC<MyComponentProps> = ({code,message}) => {
  const intl = useIntl();
  //const { search} = useLocation();
  //const code = new URLSearchParams(search).get("code") || '404';
  //const message = new URLSearchParams(search).get("message") || 'PageNotFound';

  return (
    <div className="container">
    <div id="notfound" style={{overflow:"hidden"}}>
    <div className="notfound">
    <h3 className=" text-center"> <img src={logo} width="260px"alt="logo img" /></h3>
    <h1 style={{textTransform:"capitalize",color:"red"}}>{intl.formatMessage({ id: message })}</h1>
    <h6 className=" text-center" style={{color:"black"}}>{code} </h6>
    </div></div>
    </div>
  )
}

export default ErrorComponent
