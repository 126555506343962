import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, addOrganization } from 'actions';
import { OrganizationForm } from 'app/configuration/organizations/organization-form';
import { OrganizationFormState } from 'app/configuration/organizations/organization-helpers';
import { trimTrailingSlash } from 'helpers/url-helpers';

const initialState: OrganizationFormState = {
  englishName: '',
  arabicName: '',
};

const AddOrganization: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const handleSubmit = (
    values: OrganizationFormState,
    { setSubmitting }: FormikHelpers<OrganizationFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        addOrganization({
          en_name: values.englishName,
          ar_name: values.arabicName,
        })
      )
    )
      .then(({ action }: any) => {
        const newOrganizationId = action.payload.data?.response.organisation.id;
        history.push(`${baseURL.replace('/new', '')}/${newOrganizationId}`);

        dispatch(
          successAlert(intl.formatMessage({ id: 'successAddOrganization' }))
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <OrganizationForm {...{ initialState, handleSubmit }} />;
};

export { AddOrganization };
