import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, getDevice, editDevice } from 'actions';
import { NotAuthorized } from 'app/components/not-authorized';
import { DeviceForm } from 'app/operations/devices/device-form';
import { DeviceFormState } from 'app/operations/devices/device-helpers';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { canEditOperationsSubSystemEntity } from 'selectors';
import { Device } from 'types';

const EditDevice: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const [isLoading, setLoading] = useState(true);
  const intl = useIntl();

  const deviceId = match.params.id;

  const deviceData = useTypedSelector(
    state => state.devices.collection[deviceId] as Device | undefined
  );

  useEffect(() => {
    if (deviceData) {
      setLoading(false);
    } else {
      dispatch(getDevice(deviceId));
    }
  }, [dispatch, deviceData, deviceId]);

  const initialState: DeviceFormState = useMemo(
    () => ({
      serial: deviceData?.serial || '',
      device_identifier: deviceData?.device_identifier || '',
      type: deviceData?.obc_type || '',
      ip: deviceData?.ip || '',
      organizationId: deviceData?.organisation_id || '',
      organization: deviceData?.organisation || undefined,
      vehicleId: deviceData?.vehicle?.id || '',
    }),
    [deviceData]
  );

  const hasWriteAccessToCurrentDevice = useTypedSelector(state =>
    canEditOperationsSubSystemEntity(state, 'Devices', deviceId)
  );

  const handleSubmit = (
    values: DeviceFormState,
    { setSubmitting }: FormikHelpers<DeviceFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        editDevice(deviceId, {
          serial: values.serial,
          device_identifier: values.device_identifier,
          obc_type: values.type,
          ip: values.ip,
          organisation_id: values.organizationId,
        })
      )
    )
      .then(() => {
        history.push(`${baseURL}`.replace('edit', ''));

        dispatch(successAlert(intl.formatMessage({ id: 'successEditDevice' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!hasWriteAccessToCurrentDevice) {
    return <NotAuthorized />;
  }

  return <DeviceForm {...{ initialState, handleSubmit, isEditForm: true }} />;
};

export { EditDevice };
