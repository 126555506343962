import React from 'react';

import { Typography, makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: '#252733',
      fontSize: 18,
      fontWeight: 'bold',
      padding: '0 24px 0 32px',
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        padding: '0 16px 24px',
      },
    },
  })
);

const CardTitle: React.FC<{ title: string }> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Typography variant="h6" color="textSecondary" className={classes.title}>
      {title}

      {children}
    </Typography>
  );
};

export { CardTitle };
