import React, { useEffect, useMemo } from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, getOrganization, editOrganization } from 'actions';
import { OrganizationForm } from 'app/configuration/organizations/organization-form';
import { OrganizationFormState } from 'app/configuration/organizations/organization-helpers';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { Organization } from 'types';

const EditOrganization: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const organizationId = match.params.id;

  const organizationData = useTypedSelector(
    state =>
      state.organizations.collection[organizationId] as Organization | undefined
  );

  useEffect(() => {
    if (!organizationData) {
      dispatch(getOrganization(organizationId));
    }
  }, [dispatch, organizationData, organizationId]);

  const initialState: OrganizationFormState = useMemo(() => {
    if (organizationData) {
      return {
        englishName: organizationData.en_name,
        arabicName: organizationData.ar_name,
        status: !organizationData.disabled,
      };
    }

    return { englishName: '', arabicName: '' };
  }, [organizationData]);

  const handleSubmit = (
    values: OrganizationFormState,
    { setSubmitting }: FormikHelpers<OrganizationFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        editOrganization(organizationId, {
          en_name: values.englishName,
          ar_name: values.arabicName,
          disabled: !values.status,
        })
      )
    )
      .then(() => {
        history.push(`${baseURL}`.replace('edit', ''));

        dispatch(
          successAlert(intl.formatMessage({ id: 'successEditOrganization' }))
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <OrganizationForm {...{ initialState, handleSubmit, isEditForm: true }} />
  );
};

export { EditOrganization };
