import { createSelector } from 'reselect';

import { AppState, Axis, Group, Stop, User } from 'types';

export * from 'selectors/permissions.selectors';

export const getLoggedAdminId = (state: AppState): string | null => {
  const user = state.authentication?.user;

  if (user) {
    return user.userData.id;
  }

  return null;
};

export const getLoggedAdminData = (
  state: AppState
): User['userData'] | null => {
  const user = state.authentication?.user;

  if (user) {
    return user.userData;
  }

  return null;
};

export const makeGetCollectionValues = () =>
  createSelector(
    [(state, props) => state[props.entityName]],
    entity => Object.values(entity?.collection) || []
  ) as (state: AppState, value: { entityName: string }) => unknown[];

export const makeGetEntitiesByIds = () =>
  createSelector(
    [(state, props) => state[props.entityName], (_state, props) => props.ids],
    (entity, ids) => {
      if (entity) {
        return ids?.map((id: string) => entity?.collection?.[id]);
      }

      return null;
    }
  ) as (
    state: AppState,
    value: { entityName: string; ids: string[] }
  ) => string[];

export const getRouteAxis = createSelector(
  [
    (state, props) => state.routes.collection[props.routeId],
    (_, props) => props.axisId,
  ],
  (route, axisId) => route?.axes?.find(axis => axis.id === axisId)
) as (
  state: AppState,
  props: { routeId: string | undefined; axisId: string | undefined }
) => Axis | undefined;

export const getStationStop = createSelector(
  [
    (state, props) => state.stations.collection[props.stationId],
    (_, props) => props.stopId,
  ],
  (station, stopId) => station?.stops?.find(stop => stop.id === stopId)
) as (
  state: AppState,
  props: { stopId: string | undefined; stationId: string | undefined }
) => Stop | undefined;

export const makeGetStationGroups = () =>
  createSelector(
    [(state, props) => state.stations.collection[props.stationId]],
    (station = []) => station.station_groups?.map(groups => groups.group)
  ) as (state: AppState, props: { stationId: string | undefined }) => Group[];

export const getSelectedEntityOrganizationIdById = createSelector(
  [
    (state, props) =>
      state[props.entityName.toLowerCase()]?.collection[props.entityId],
  ],
  entity => entity?.organisation_id
) as (
  state: AppState,
  props: { entityName: string; entityId: string }
) => string | undefined;
