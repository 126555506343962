import React from 'react';

import {
  LinearProgress,
  Button,
  makeStyles,
  Theme,
  createStyles,
  InputLabel,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  EditProfileFormState,
  EditProfileFormSchema,
} from 'app/operations/profile/edit-profile/edit-profile-helpers';
// import { useTypedSelector } from 'helpers';
// import { getOrganizationsWithWritePermissionForOperationsSubSystem } from 'selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: { padding: '0 32px' },
    fieldWrapper: {
      width: 347,
      marginRight: 24,
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      margin: '0 0 15px',
      [theme.breakpoints.down('sm')]: { margin: '0 0 15px' },
    },
    fieldGroupWrapper: {
      width: '70%',
      [theme.breakpoints.down('sm')]: { width: '100%' },
    },
    buttonsWrapper: {
      marginTop: 10,
    },
    actionButton: {
      marginRight: 10,
      borderRadius: 30,
      [theme.breakpoints.down('sm')]: {
        margin: '0 0 10px',
        width: '100%',
      },
    },
    submitButton: {
      backgroundColor: '#0FA66D',
      '&:hover': {
        backgroundColor: '#0FA66D',
        opacity: 0.9,
      },
    },
    formLabel: {
      color: '#444',
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 700,
    },
  })
);

interface EditProfileForm {
  initialState: EditProfileFormState;
  handleSubmit: (
    values: EditProfileFormState,
    { setSubmitting }: FormikHelpers<EditProfileFormState>
  ) => void;
}

const EditProfileForm: React.FC<EditProfileForm> = ({
  initialState,
  handleSubmit,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.formWrapper}>
      <Formik
        initialValues={initialState}
        validationSchema={() => EditProfileFormSchema(intl)}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <div className={classes.fieldGroupWrapper}>
              <div className={classes.fieldWrapper}>
                <InputLabel className={classes.formLabel}>
                  {intl.formatMessage({ id: 'firstName' })}
                </InputLabel>

                <Field
                  name="firstName"
                  component={TextField}
                  type="text"
                  variant="outlined"
                  size="small"
                />
              </div>

              <div className={classes.fieldWrapper}>
                <InputLabel className={classes.formLabel}>
                  {intl.formatMessage({ id: 'lastName' })}
                </InputLabel>

                <Field
                  name="lastName"
                  component={TextField}
                  type="text"
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>

            {isSubmitting && <LinearProgress />}

            <div className={classes.buttonsWrapper}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                onClick={submitForm}
                className={`${classes.submitButton} ${classes.actionButton}`}
              >
                {intl.formatMessage({
                  id: 'save',
                })}
              </Button>

              <Button
                variant="contained"
                color="default"
                type="button"
                disabled={isSubmitting}
                onClick={() => history.go(-1)}
                className={classes.actionButton}
              >
                {intl.formatMessage({
                  id: 'cancel',
                })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { EditProfileForm };
