export enum vehicleConstants {
  GET_VEHICLES = 'GET_VEHICLES',
  GET_UNASSIGNED_VEHICLES = 'GET_UNASSIGNED_VEHICLES',
  GET_VEHICLE = 'GET_VEHICLE',
  ADD_VEHICLE = 'ADD_VEHICLE',
  EDIT_VEHICLE = 'EDIT_VEHICLE',
  EDIT_MULTIPLE_VEHICLES = 'EDIT_MULTIPLE_VEHICLES',
  ADD_TO_GROUPS = 'ADD_TO_GROUPS',
  DELETE_VEHICLE = 'DELETE_VEHICLE',
  DELETE_MULTIPLE_VEHICLES = 'DELETE_MULTIPLE_VEHICLES',
  IMPORT_VEHICLES = 'IMPORT_VEHICLES',
}
