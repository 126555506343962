import { createContext } from 'react';

import ar from 'app/i18n-locale/ar-EG';
import en from 'app/i18n-locale/en-US';
import { LOCALES } from 'app/i18n-locale/locales-constants';

const messages = { ...ar, ...en };
interface LanguageContextProps {
  locale: string;
  messages: { [key: string]: string };
  direction: 'ltr' | 'rtl';
  setLocale?: (value: string) => void;
}

const LanguageContext = createContext<LanguageContextProps>({
  locale: LOCALES.ENGLISH,
  messages: messages[LOCALES.ENGLISH],
  direction: 'ltr',
  setLocale: undefined,
});

export { LanguageContext };
