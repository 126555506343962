/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext, useMemo } from 'react';

import {
  LinearProgress,
  Button,
  InputLabel,
  DialogTitle,
  Typography,
  Grid,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';

import AutoComplete from 'app/components/shared/Autocomplete';
import { Values } from 'app/components/shared/multi-select';
import { DIRECTIONS, LOCALES } from 'app/i18n-locale/locales-constants';
import {
  AxisFormState,
  AxisFormSchema,
} from 'app/operations/routes/axis-helpers';
import { mainButtonStyles } from 'app/shared/styles';
import { LanguageContext } from 'context/intl.context';
import { reorderList, useTypedSelector } from 'helpers';

import pickIcon from 'assets/pick-icon.svg';
import trash from 'assets/trash.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: { padding: '0 32px', width: '100%' },
    fieldWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    fieldGroupWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: { width: '100%' },
    },
    buttonsWrapper: {
      marginTop: 10,
    },
    actionButton: {
      textTransform: 'capitalize',
      marginRight: 10,
      borderRadius: 30,
      [theme.breakpoints.down('sm')]: {
        margin: '0 0 10px',
        width: '100%',
      },
      padding: '7px 17px',
      borderColor: '#DFE0EB',
    },
    submitButton: {
      backgroundColor: '#0FA66D',
      '&:hover': {
        backgroundColor: '#0FA66D',
        opacity: 0.9,
      },
      color: '#fff',
      borderColor: '#0FA66D',
      padding: '7px 25px',
    },
    formLabel: {
      color: '#444',
      marginBottom: 7,
      fontSize: 14,
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'start',
    },
    requiredAstrisk: {
      color: 'red',
    },
    stopsSection: {
      border: '1px solid #DADCE0',
      padding: '22px 15px 8px 15px',
      marginBottom: 20,
    },
    addStopButton: {
      ...mainButtonStyles,
      height: 45,
      marginLeft: 10,
      minWidth: 113,
      padding: '5px 10px',
      marginBottom: 12,
    },
    stopContainer: {
      height: 42,
      backgroundColor: 'rgba(240, 240, 242, 1)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 5,
      paddingLeft: 8,
      borderRadius: 3,
    },
    stopName: {
      fontSize: 14,
      fontWeight: 500,
      color: 'rgba(68, 68, 68, 1)',
      marginLeft: 13,
    },
    dragLabel: {
      marginBottom: 14,
    },
  })
);

interface AxisForm {
  initialState: AxisFormState;
  handleSubmit: (
    values: AxisFormState,
    { setSubmitting }: FormikHelpers<AxisFormState>
  ) => void;
  setOpen: (newState: boolean) => void;
  isEditForm?: boolean;
}

const AxisForm: React.FC<AxisForm> = ({
  initialState,
  handleSubmit,
  setOpen,
  isEditForm = false,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [selectedStops, setSelectedStops] = React.useState<string[]>([]);

  const [currentStops, setCurrentStops] = React.useState<string[]>(
    initialState.stops
  );

  const { direction, locale } = useContext(LanguageContext);

  const isRTL = direction === DIRECTIONS.RTL;

  const stops = useTypedSelector(state => state.stops.collection);

  const handleAddStops = () => {
    const newStops = [...currentStops, ...selectedStops];
    setCurrentStops(newStops);
    setSelectedStops([]);
  };

  const stopsData = useMemo(() => {
    const data: Values[] = Object.values(stops).map(stop => ({
      id: stop.id,
      name: locale === LOCALES.ENGLISH ? stop.en_name : stop.ar_name,
    }));

    return data.filter(stop => !currentStops.includes(stop.id));
  }, [currentStops, locale, stops]);

  const removeStop = stop => {
    const newStops = currentStops.filter(_stop => stop !== _stop);
    setCurrentStops(newStops);
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = reorderList(
      currentStops,
      result.source.index,
      result.destination.index
    );

    setCurrentStops(items);
  };

  return (
    <>
      <DialogTitle>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          {intl.formatMessage({
            id: isEditForm ? 'editAxis' : 'addAxis',
          })}
        </Typography>
      </DialogTitle>

      <div className={classes.formWrapper}>
        <Formik
          initialValues={initialState}
          validationSchema={() => AxisFormSchema(intl)}
          onSubmit={(values, helpers) =>
            handleSubmit({ ...values, stops: currentStops }, helpers)
          }
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div
                className={classes.fieldGroupWrapper}
                style={{ flexDirection: 'column' }}
              >
                <Grid container spacing={3}>
                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'englishName' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="en_name"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'arabicName' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="ar_name"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'code' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="code"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'cashPriceEGP' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="cash_price"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item className={classes.fieldWrapper} md={6}>
                    <InputLabel className={classes.formLabel}>
                      {intl.formatMessage({ id: 'cardPriceEGP' })}

                      <strong className={classes.requiredAstrisk}>*</strong>
                    </InputLabel>

                    <Field
                      name="card_price"
                      component={TextField}
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel
                      className={classes.formLabel}
                      style={{ flexDirection: isRTL ? 'row' : 'row-reverse' }}
                    >
                      {intl.formatMessage({ id: 'stops' })}
                    </InputLabel>

                    <div className={classes.stopsSection}>
                      {currentStops?.length > 1 && (
                        <Typography className={classes.dragLabel}>
                          {intl.formatMessage({ id: 'dragRowsToReorder' })}
                        </Typography>
                      )}

                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {provided => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              // style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {currentStops?.map((id, index) => (
                                <Draggable
                                  key={id}
                                  draggableId={id}
                                  index={index}
                                >
                                  {provided => (
                                    <div
                                      key={id}
                                      className={classes.stopContainer}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div style={{ display: 'flex' }}>
                                        <img src={pickIcon} alt="drag" />

                                        <div className={classes.stopName}>
                                          {stops[id]?.en_name}
                                        </div>
                                      </div>

                                      <Button onClick={() => removeStop(id)}>
                                        <img src={trash} alt="drag" />
                                      </Button>
                                    </div>
                                  )}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <AutoComplete
                          sendSelectedValues={(_, selected) => {
                            setSelectedStops(selected);
                          }}
                          selected={selectedStops || []}
                          values={stopsData}
                          label=""
                          placeholderText="selectAStop"
                        />

                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={handleAddStops}
                          className={classes.addStopButton}
                        >
                          {intl.formatMessage({ id: 'addStop' })}
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {isSubmitting && <LinearProgress />}

              <div className={classes.buttonsWrapper}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={`${classes.submitButton} ${classes.actionButton}`}
                >
                  {intl.formatMessage({ id: isEditForm ? 'update' : 'add' })}
                </Button>

                <Button
                  variant="outlined"
                  color="default"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => setOpen(false)}
                  className={classes.actionButton}
                >
                  {intl.formatMessage({ id: 'cancel' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export { AxisForm };
