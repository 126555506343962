import { FluxStandardAction } from 'redux-promise-middleware';

import { alertConstants } from '_constants';

const successAlert = (message: string): FluxStandardAction => ({
  type: alertConstants.SUCCESS,
  payload: message,
});

const errorAlert = (message: string): FluxStandardAction => ({
  type: alertConstants.ERROR,
  payload: message,
});

const clearAlerts = (): FluxStandardAction => ({ type: alertConstants.CLEAR });

export { successAlert, errorAlert, clearAlerts };
