import { Dispatch } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';

import { deviceConstants } from '_constants';
import { API } from 'helpers';

interface DeviceFilterParams {
  vehicle_code_or_serial_cont?: string;
  organisation_id_eq?: string;
  obc_type_eq?: string;
}

const getDevices =
  (page: number, size: number, params?: DeviceFilterParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const pageNumber = page + 1;

    return dispatch({
      type: deviceConstants.GET_DEVICES,
      payload: API.get('/v2/obcs', {
        params: { page: pageNumber, page_count: size, export: 0, q: params },
      }),
      meta: { page: pageNumber },
    });
  };

const getDevice =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: deviceConstants.GET_DEVICE,
      payload: API.get(`/v2/obcs/${id}`),
    });

interface DeviceActionParams {
  serial: string;
  obc_type: string;
  ip: string;
  organisation_id: string;
  device_identifier?: string;
}

const addDevice =
  (data: DeviceActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { obc: data };

    return dispatch({
      type: deviceConstants.ADD_DEVICE,
      payload: API.post('/v2/obcs', requestData),
    });
  };

const editDevice =
  (id: string, data: DeviceActionParams) =>
  (dispatch: Dispatch): FluxStandardAction => {
    const requestData = { obc: data };

    return dispatch({
      type: deviceConstants.EDIT_DEVICE,
      payload: API.put(`/v2/obcs/${id}`, requestData),
    });
  };

const deleteDevice =
  (id: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: deviceConstants.DELETE_DEVICE,
      payload: API.delete(`/v2/obcs/${id}`),
      meta: { deviceId: id },
    });

const assignVehicleToDevice =
  (deviceId: string, vehicleId: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: deviceConstants.ASSIGN_VEHICLE_TO_DEVICE,
      payload: API.post(`/v2/obcs/${deviceId}/assign`, {
        vehicle_id: vehicleId,
      }),
      meta: { deviceId, vehicleId },
    });

const importDevices =
  (base64File: string) =>
  (dispatch: Dispatch): FluxStandardAction =>
    dispatch({
      type: deviceConstants.IMPORT_DEVICES,
      payload: API.post('/v2/obcs/import', { file: base64File }),
    });

export {
  getDevices,
  getDevice,
  addDevice,
  editDevice,
  deleteDevice,
  assignVehicleToDevice,
  importDevices,
};
