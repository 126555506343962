import React from 'react';

import { Button, createStyles, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { basicButtonStyles } from 'app/shared/styles';

const useStyles = makeStyles(() =>
  createStyles({
    basicButton: { ...basicButtonStyles },
    rightMargin: { marginRight: 10 },
  })
);

const ExcelFileInput = ({ labelKey, onChange, icon }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <label htmlFor={`${labelKey}-button`}>
      <input
        value=""
        hidden
        id={`${labelKey}-button`}
        name={`${labelKey}-button`}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={onChange}
      />

      <Button
        variant="outlined"
        component="span"
        className={`${classes.basicButton} ${classes.rightMargin}`}
      >
        <img
          src={icon}
          alt={`${labelKey}-icon`}
          className={classes.rightMargin}
        />

        {intl.formatMessage({ id: labelKey })}
      </Button>
    </label>
  );
};

export { ExcelFileInput };
