import React from 'react';

import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { successAlert, addVehicleClass } from 'actions';
import { VehicleClassForm } from 'app/operations/vehicles/vehicle-classes/vehicle-class-form';
import { VehicleClassFormState } from 'app/operations/vehicles/vehicle-classes/vehicle-class-helpers';
import { trimTrailingSlash } from 'helpers/url-helpers';

const initialState: VehicleClassFormState = {
  en_name: '',
  ar_name: '',
  avg_speed: '',
};

const AddVehicleClass: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();

  const handleSubmit = (
    values: VehicleClassFormState,
    { setSubmitting }: FormikHelpers<VehicleClassFormState>
  ) => {
    setSubmitting(true);

    Promise.resolve(
      dispatch(
        addVehicleClass({
          en_name: values.en_name,
          ar_name: values.ar_name,
          avg_speed: values.avg_speed,
        })
      )
    )
      .then(({ action }: any) => {
        const newVehicleId = action.payload.data?.response.id;
        history.push(`${baseURL.replace('/new', '')}/${newVehicleId}`);

        dispatch(successAlert(intl.formatMessage({ id: 'successAddVehicle' })));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return <VehicleClassForm {...{ initialState, handleSubmit }} />;
};

export { AddVehicleClass };
